import * as React from "react";
import {useEffect, useState} from "react";
import TopNavBar from "./TopNavBar";
import {FormValidationError, HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import ReCAPTCHA from "react-google-recaptcha";
import {useHistory} from "react-router-dom";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnErrorModal, returnDefaultModalState
} from "devso-react-library";
import {SecondaryButton} from "devso-react-library";
import {ModalState} from "devso-react-library";

export type InviteDetails = {
    persons_name: string,
    email: string,
    pending_invite_id: number,
    organisation_id: number,
    auth_token: string,
    member_type: string,
}

export type RegisterNewUserProps = {
    match: any
}

function RegisterNewUser(props: RegisterNewUserProps)
{
    const [recaptcha, setRecaptcha] = useState('');
    const [organisation_name, setOrganisationName] = useState('');
    const [invite_details, setInviteDetails] = useState<InviteDetails>(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inviteFound, setInviteFound] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [modalState, setModalState] = useState<ModalState>(null);
    const history = useHistory();

    const submitPassword = async (formData) => {


        const postArray : any = {
            name: invite_details.persons_name,
            email: invite_details.email,
            password: formData.password,
            pending_invite_id: invite_details.pending_invite_id,
            organisation_id: invite_details.organisation_id,
            auth_token: invite_details.auth_token,
            member_type: invite_details.member_type,
            gRecaptchaResponse: recaptcha
        };

        try
        {
            const response = await sendRequest(postArray, '/users', HTTP_METHOD.PUT);

            if (response.status === 0)
            {
                if (response.message === "InvalidInviteLink")
                {
                    const api_result = {
                        status: 0,
                        message: "Invalid invite link was used. Please check the URL and that the link hasn't already been used or expired",
                        data: null
                    }
                    const tempModalState = returnErrorModal(api_result, "An error has occurred", setModalState);
                    setModalState({...tempModalState});
                }
                else if (response.message === "EmailRegistered") {
                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Email Already Registered";
                    tempModalState.content = <>
                        <p>This email address is already registered.</p>
                        <p>
                            Please contact the account manager to reset your password or use the forgotten password link
                            on the login page
                        </p>
                    </>
                    tempModalState.buttons = [
                        <SecondaryButton label='OK' onClick={() => {
                            const temp = returnDefaultModalState();
                            setModalState({...temp});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
                else {
                    //window.location.href = '/dashboard';
                    history.push('/dashboard');
                }
            }
            else
            {

                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        catch (e)
        {
            if (e instanceof FormValidationError)
            {
                setFormErrors(e['errors']);
            }
            else
            {
                const tempModalState = returnErrorModal(e, "An error has occurred", setModalState);
                setModalState({...tempModalState});
                //setAPIResult(e.response.data);
            }

        }
    }

    const getInvitationDetails = async () => {
        try
        {
            const params = props.match.params.invite_params;

            const base64 = atob(params);
            const json = JSON.parse(base64);


            const postArray = {
                organisation_id: json.organisation_id,
                auth_token: json.auth_token
            }
            const response = await sendRequest(postArray, `/invitations/${json.pending_invite_id}/${params}`);
            if (response.status !== 0)
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
            else
            {
                setInviteFound(true);
                setOrganisationName(response.data.organisation_name);
                setInviteDetails(response.data)
            }
        }
        catch (err)
        {
            if (err.response.status === 404)
            {
                setInviteFound(false);
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
    }

    useEffect(() => {
        getInvitationDetails();
    }, [props]);

    const loading = <div className='text-center'>
        <img className='text-center ml-auto mr-auto' src='/images/loading-large.gif' alt='loading' />
        <p className='font-bold mt-28'>
            Please wait, loading your invitation details
        </p>
    </div>

    const inviteForm = <div style={{width: 80 + '%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
        <img className='ml-auto mr-auto text-center' src='/images/logo.png' style={{width: 220}} alt='logo' />
        <p style={{fontSize: 'x-large', fontWeight: 'bold'}}>
            You've been invited to join the team at {organisation_name}.
        </p>
        <p style={{fontSize: 'large', fontWeight: 'bold'}}>
            Enter your password below to join the team
        </p>

        <Form handleSubmit={submitPassword} errors={formErrors} loading={formLoading}>
            <TextField type={TextFieldType.PASSWORD} label='Password' placeholder='Enter Password'
                       api_field_name='password' value={password} onChange={setPassword} />
            <TextField type={TextFieldType.PASSWORD} label='Confirm Password' placeholder='Confirm Password'
                       api_field_name='confirm_password' value={confirmPassword} onChange={setConfirmPassword}/>

            <FormButtonContainer>
                <div className='form_group_recaptcha'>
                    <ReCAPTCHA
                        sitekey="6Lev9s0ZAAAAANopO71AnVTAdVVKjYWrY09oV1rL"
                        onChange={setRecaptcha} />
                </div>

                <PrimaryButton label='Join Team' />
            </FormButtonContainer>
        </Form>
    </div>

    if (inviteFound === null || inviteFound) {
        const display = organisation_name.length === 0 ? loading : inviteForm;

        return (
            <div className='wrapper'>

                <TopNavBar displayTimePeriodButton={false} title='Join Your Team' allowCreateProject={false}/>

                <div className='mainContentNoSidebar'>
                    <div className='w-1/2 ml-auto mr-auto text-center'>
                        {display}
                    </div>


                </div>

                <ModalDialog {...modalState} />
            </div>
        )
    }
    else
    {
        return (
            <div className='wrapper'>

                <TopNavBar displayTimePeriodButton={false} title='Join Your Team' allowCreateProject={false}/>

                <div className='mainContentNoSidebar'>
                    <div className='w-1/2 ml-auto mr-auto text-center'>
                        <h1>Invite Was Not Found</h1>
                        <p className='mt-10'>
                            The invite link you followed was not found or the link may have expired.
                        </p>
                        <p>
                            Please ask your account manager to resend you an invite.
                        </p>
                    </div>

                </div>

            </div>
        )
    }
}

export default RegisterNewUser;