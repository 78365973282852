export const getIndexOfSpaceBeforeCaretPosition = (elem: HTMLInputElement, caret_pos: number) => {
    const text = elem.value;

    for (let i = caret_pos; i > 0; i--)
    {
        if (text[i] === ' ')
        {
            return i;
        }
    }

    //If we get here, we're at the beginning of the string
    return 0;
}