import * as React from "react";

export type CustomDeviceDetailsProps = {
    operating_system: string,
    kernel_version: string,
    custom_device_properties: string
}

function CustomDeviceDetails(props: CustomDeviceDetailsProps)
{
    const PrettyPrintJSON : any = ({data}) => {
        if ((data !== null) && data.length !== 0)
        {

            return (
                <div>
                    <pre>{JSON.stringify(JSON.parse(data), null, 2)}</pre>
                </div>
            );
        }
        else
        {
            return "N/A";
        }
    }

    return (
        <table className='dataTable'>
            <tbody>
                <tr>
                    <td>Operating System</td>
                    <td>{props.operating_system}</td>
                </tr>
                <tr>
                    <td>Kernel Version:</td>
                    <td>{props.kernel_version}</td>
                </tr>
                <tr>
                    <td>Custom Device Properties</td>
                    <td>
                        <PrettyPrintJSON data={props.custom_device_properties} />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CustomDeviceDetails;