import * as React from "react";
import {useEffect, useState} from "react";
import {FaTimes} from "react-icons/fa";
import {useGlobalState} from "./GlobalStateProvider";
import {Link, useHistory} from 'react-router-dom'
import {convertEpochToHumanDate} from "../JSFuncs/DataTimeManager";
import ReactTooltip from "react-tooltip";
import Cookies from 'js-cookie';

export default function TrialOrPaymentBanner() {
    const { setGlobalState, globalState } = useGlobalState();
    const history = useHistory();
    const handleHidingMessage = () => {
        const temp = globalState;

        temp.trialOrPaymentBannerShow = false;
        localStorage.setItem("global_state", JSON.stringify(temp));
        setGlobalState({...temp});
    }

    useEffect(() => {
        //Check if authenticated
        console.log("UserID Cookie is: ", Cookies.get("UserID"));
        if (Cookies.get("UserID") === "" && Cookies.get("UserID") === "" && Cookies.get("UserID") !== undefined)
        {
            history.push("/login/You need to login first");
        }
    })

    return (
        <div className={`${globalState?.trialOrPaymentBannerShow !== undefined 
            && globalState?.trialOrPaymentBannerShow ? "absolute" : "hidden"} bottom-0 p-4 bg-primary text-white w-full z-50`}>
            <div className='flex'>
                <div className='flex-1 flex-grow items-center justify-items-center content-center'>
                    {
                        globalState?.trial?.in_trial ?
                            <div className='mx-auto text-center'>
                                <p className='p-0 m-0 text-xl'>
                                    {globalState?.trial?.days_of_trial_remaining} days remaining of trial
                                </p>
                                <Link to='/account-billing' className='text-sm text-white'>Click to upgrade</Link>
                            </div> : null
                    }
                    {
                        globalState?.payment_failed?.last_payment_failed ?
                            <div className='mx-auto text-center'>
                                <p className='p-0 m-0 text-xl'>
                                    Your last payment failed with the error '{globalState?.payment_failed?.last_payment_failed_reason}' &nbsp;
                                    - payment is due on {convertEpochToHumanDate(globalState?.payment_failed?.payment_time_required, false)}
                                </p>
                                <Link to='/account-billing' className='text-sm text-white'>Update Payment Details</Link>
                            </div> : null
                    }
                </div>
                <div className='text-white text-right right-0 items-end justify-end justify-items-end p-2'>
                    <FaTimes data-tip='Hide this message' onClick={handleHidingMessage} className='text-white text-right text-2xl font-bold cursor-pointer' />
                </div>
            </div>
            <ReactTooltip />
        </div>
    )
}