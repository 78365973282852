import * as React from "react";
import {useEffect, useState} from "react";
import '../ComponentStyles/GroupToggleButton.css';
import { useMediaQuery } from 'react-responsive'
import {FaSortDown} from "react-icons/fa";
import {generateRandomString} from "../JSFuncs/HelperFunctions";
import '../ComponentStyles/DropdowMenu.css'

export type ButtonOptions = {
    label: string,
    selected: boolean,
    icon?: string,
    subTitle?: string,
    selectedLabel?: string,
    handleToggleChange?: Function,
    selectable?: boolean|null
}

export type GroupToggleButtonsProps = {
    selectedLabel?: string,
    handleToggleChange?: Function,
    buttonOptions?: Array<ButtonOptions>,
    buttonSize?: string,
    layout?: string,
    groupLabel?: string
}

function GroupToggleButton(props: GroupToggleButtonsProps)
{
    //const [selectedLabel, setSelectedLabel] = useState('');
    //const [selected, setSelected] = useState('');
    const [state, setState] = useState({
        selectedLabel: props.selectedLabel,
        selected: props.selectedLabel
    });

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    const buttonViews = [];

    for (let i = 0; i < props.buttonOptions.length; i++)
    {
        const buttonProperties = props.buttonOptions[i];
        let classes = (typeof props.buttonSize === typeof undefined || props.buttonSize === "small")
            ? 'groupButtonToggle' : 'groupButtonToggleLarge'

        if (/*buttonProperties.selected ||*/ buttonProperties.label === state.selectedLabel)
        {
            classes += (typeof props.buttonSize === typeof undefined || props.buttonSize === "small")
                ? ' groupButtonToggleActive' : ' groupButtonToggleLargeActive'
        }
        if (typeof buttonProperties !== typeof undefined)
        {
            const clickHandler = typeof buttonProperties.selectable === typeof undefined || buttonProperties.selectable ? () => handleToggleButtonClick(buttonProperties.label) : null
            if (clickHandler === null)
            {
                classes += ' groupButtonToggleNotSelectable';
            }
            if (typeof props.layout === typeof undefined || props.layout === 'inline') {
                buttonViews.push(
                    <div key={i} onClick={clickHandler}
                         className={classes + ' text-center items-center justify-items-center shadow-lg'}>
                        {
                            <div className='items-center justify-items-center text-center'>
                                {
                                    (typeof buttonProperties.icon !== typeof undefined) ?
                                        <img src={buttonProperties.icon} alt={buttonProperties.label}
                                             style={{width: 60 + 'px', height: 60 + 'px'}}/> : ''
                                }
                            </div>
                        }

                        {
                            <span style={{fontWeight: 'bold'}}>{buttonProperties.label}</span>
                        }
                        {
                            (typeof buttonProperties.subTitle !== typeof undefined) ?
                                <span>{buttonProperties.subTitle}</span> : ''
                        }
                    </div>
                );
            }
            else
            {
                classes += ' p-2'
                buttonViews.push(
                    <div key={i} onClick={clickHandler}
                         className={classes}>
                        {
                            <div className='items-center justify-items-center ml-auto mr-auto text-center' style={{textAlign: 'center'}}>
                                {
                                    (typeof buttonProperties.icon !== typeof undefined) ?
                                        <img src={buttonProperties.icon} alt={buttonProperties.label}
                                             style={{width: 60 + 'px', height: 60 + 'px'}}/> : ''
                                }
                            </div>
                        }
                        {
                            <span style={{fontWeight: 'bold'}}>{buttonProperties.label}</span>
                        }
                        {
                            (typeof buttonProperties.subTitle !== typeof undefined) ?
                                <p style={{margin: 0, padding: 0}}>{buttonProperties.subTitle}</p> : <p>&nbsp;</p>
                        }
                    </div>
                );

            }
        }
    }

    let containerClass;
    if (typeof props.layout === typeof undefined || props.layout === 'inline' || props.buttonSize === 'large') {
        containerClass = '';
    }
    else
    {
        containerClass = 'flex flex-row';
    }

    const handleToggleButtonClick = (clickedValue) => {

        for (let i = 0; i < props.buttonOptions.length; i++)
        {
            //console.log("Button Label: " + props.buttonOptions[i].label + "\tClicked Value: " + clickedValue);
            if (props.buttonOptions[i].label === clickedValue)
            {
                props.buttonOptions[i].selected = true;
            }
            else
            {
                props.buttonOptions[i].selected = false;
            }
        }

        setState({
            selectedLabel: clickedValue,
            selected: clickedValue
        });
        props.handleToggleChange(clickedValue);
    }

    if (!isTabletOrMobileDevice) {
        return (
            <div className={containerClass}>
                {typeof props.groupLabel !== typeof undefined ? <span className='mr-2'>{props.groupLabel}</span> : null }{buttonViews}
            </div>
        )
    }
    else
    {
        return SelectionButton(props);
    }
}

function SelectionButton(props: GroupToggleButtonsProps)
{
    const [randomId] = useState(generateRandomString());
    const [menuVisible, setMenuVisible] = useState(false);
    const [mousePos, setMousePos] = useState({x: 0, y: 0});

    const showHideButtonSelection = (event) => {
        setMenuVisible(!menuVisible);

        let xPosition = 0;
        let yPosition = 0;

        xPosition = event.pageX;
        yPosition = event.pageY + 15;

        setMousePos({x: xPosition, y: yPosition});
    }

    useEffect(() => {

        document.querySelector("body").addEventListener('click', function(){
            // @ts-ignore
            const dropdownMenu = document.getElementById(randomId) as HTMLElement
            if (dropdownMenu !== null) {
                dropdownMenu.style.visibility = "hidden";
                if (menuVisible) {
                    setMenuVisible(false);
                }
                //setMenuVisible(current => !current)
            }
        });
        return () => {
            document.querySelector("body").removeEventListener("click", null);
        }
    }, [randomId, menuVisible]);

    useEffect(() => {
        const dropdownContainer = document.getElementById(randomId) as HTMLElement;
        if (dropdownContainer !== null)
        {
            if (menuVisible)
            {
                dropdownContainer.style.visibility = "visible";
                dropdownContainer.style.opacity = "1";
                if (mousePos.x - 200 < 0)
                {
                    dropdownContainer.style.left = (mousePos.x).toString() + "px";
                }
                else
                {
                    dropdownContainer.style.left = (mousePos.x - 200).toString() + "px";
                }

                //const yOffset = dropdownContainer.offsetParent.clientTop;
                dropdownContainer.style.top = (mousePos.y).toString() + "px";
            }
            else
            {
                dropdownContainer.style.visibility = "invisible";
                dropdownContainer.style.opacity = "0";
                dropdownContainer.style.top = "0";
                dropdownContainer.style.left = "0";
            }
        }
    }, [menuVisible, randomId, mousePos]);

    const handleSelectionChange = (selectedItem : ButtonOptions) => {
        //setMenuVisible(false);
        props.handleToggleChange(selectedItem.label);
    }

    const returnMenuItems = () => {
        const items = [];

            props.buttonOptions.forEach((button, index) => {
                items.push(
                    <li className='text-white' key={index} onClick={() => handleSelectionChange(button)}>
                        {button.label}
                    </li>
                )
            });

        return items;
    };

    return (
        <div className='m-0 p-0 md:-mt-10'>
            {typeof props.groupLabel !== typeof undefined ? <span className='text-xs block' >{props.groupLabel}</span> : null}
            <button onClick={showHideButtonSelection} className='btn btn-primary text-sm'>{props.selectedLabel} <FaSortDown className='text-white inline mb-3' /></button>
            <ul id={randomId} className='dropdownMenu'>
                {returnMenuItems()}
            </ul>
        </div>
    )
}

export default GroupToggleButton;