import {useEffect, useState} from "react";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";

export default function useNumberOfAffectedUsers(project_id: string, time_frame: string) {

    const [numberOfAffectedUsers, setNumberOfAffectedUsers] = useState(0);
    const [numberOfAffectedUsersErrors, setNumberOfAffectedUsersErrors] = useState(null);
    const [numberOfAffectedUsersLoading, setNumberOfAffectedUsersLoading] = useState(false);

    const getNumberOfCrashes = async () => {
        try
        {
            setNumberOfAffectedUsersLoading(true);
            setNumberOfAffectedUsersErrors(null);

            const data = {
                project_id: project_id,
                time_frame: time_frame
            }

            const response = await sendRequest(data, '/dashboard/affected-users', HTTP_METHOD.POST);
            setNumberOfAffectedUsers(response.data);
        }
        catch (err)
        {
            setNumberOfAffectedUsersErrors(err);
        }
        finally {
            setNumberOfAffectedUsersLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getNumberOfCrashes();
            }
        )()
    }, [project_id, time_frame])

    return {numberOfAffectedUsers, numberOfAffectedUsersErrors, numberOfAffectedUsersLoading}
}