import * as React from 'react'
import {FaEnvelope, FaFacebook, FaLinkedin, FaPhone, FaTwitter} from 'react-icons/fa'
import {Link} from 'react-router-dom'

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className='hidden md:block !max-h-[80px] w-full  absolute bottom-0 bg-white z-0 !text-gray-400 text-sm w-full p-4 border-t-[1px] border-gray-300'>
            <div className='w-full grid grid-cols-3'>
                <div>
                    <span className='mx-4'>
                        <Link className='!text-gray-400' to='/privacy-policy'>
                            Privacy Policy
                        </Link>
                    </span>
                    <span className='mx-4'>
                        <Link className='!text-gray-400' to='/terms-of-service'>
                            Terms of Service
                        </Link>
                    </span>
                </div>
                <div className='text-center'>
                    <img src='/images/logo_bw.png' className='mx-auto' alt='Logo' />
                </div>
                <div className='text-right'>
                    <span>
                        <a className='!text-gray-400' href='https://docs.crashcatch.com' target='_blank' title='Crash Catch Docs'>Docs</a>
                    </span>
                </div>
            </div>
        </footer>
    )
    /*return (
        <footer className='bg-primary text-white w-full shadow-md shadow-primary border-t-[1px] border-primary mt-5 pb-3 bottom-0'>
            <div className='w-full justify-center sm:w-3/4 mx-auto flex grid-cols-2 space-x-14 items-center'>
                <div className='flex flex-col w-auto items-center'>
                    <p className='pt-2'>
                        Crash Catch by
                    </p>
                    <img src='/images/devso_logo.png' className='w-[120px] h-[139px]' alt='Devso Logo'/>
                    <span className='text-sm'>Copyright &copy; 2021 - {year}</span>
                    <div className='grid grid-cols-3 mt-3 space-x-3'>
                        <Link to='https://facebook.com/devsoio'>
                            <a target='_blank'><FaFacebook className='w-10 h-10 text-white' /></a>
                        </Link>
                        <Link to='https://twitter.com/devso_io'>
                            <a target='_blank'><FaTwitter className='w-10 h-10 text-white' /></a>
                        </Link>
                        <Link to='http://linkedin.com/company/boardies-it-solutions'>
                            <a target='_blank'><FaLinkedin className='w-10 h-10 text-white' /></a>
                        </Link>
                    </div>
                </div>

                <div className='hidden sm:flex flex-row space-x-20 justify-end items-end text-right  w-full mx-auto'>
                    <div>
                        <p>
                            <a href='https://support.devso.io' className='text-white'>Support Portal</a>
                        </p>
                        <p>
                            <a href='https://status.devso.io' className='text-white'>Service Status</a>
                        </p>
                        <p>
                            <FaPhone className='inline mr-2' />  +44 (0) 2393 162487
                        </p>
                        <p>
                            <FaEnvelope className='inline mr-2' /> <a className='text-white' href='mailto://support@devso.io'>info@devso.io</a>
                        </p>
                    </div>
                </div>
                
            </div>
            
            
        </footer>
    )*/
}