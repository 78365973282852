import * as React from "react";
import {FaCheck, FaPlus, FaTimes, FaTrash} from "react-icons/fa";
import {CurrentTeamMembersProps} from "../../Models/ManageTeamMembers";
import {convertEpochToHumanDate} from "../../JSFuncs/DataTimeManager";
import { Link } from "react-router-dom";

function CurrentTeamMembers(props: CurrentTeamMembersProps)
{
    const buildTeamMemberRows = () => {
        const teamMembersArr = [];
        if (props.teamMembers !== null) {
            for (let i = 0; i < props.teamMembers.length; i++) {
                const IsAdmin = props.teamMembers[i].is_admin === 1 ? FaCheck : FaTimes
                const isAdminStyle = props.teamMembers[i].is_admin === 1 ? {color: 'green'} : {color: 'red'};
                teamMembersArr.push(
                    <tr key={i}>
                        <td>{props.teamMembers[i].name}</td>
                        <td className='hidden sm:table-cell'>{props.teamMembers[i].email}</td>
                        <td className='text-center items-center'><IsAdmin className='text-center ml-auto mr-auto' style={isAdminStyle}/></td>
                        <td className='text-center items-center'>{props.teamMembers[i].project_count}</td>
                        <td className='hidden sm:table-cell '>{convertEpochToHumanDate(props.teamMembers[i].last_logged_in, true)}</td>
                        <td className='text-center items-center'>
                            <FaTrash onClick={() => props.confirmDeleteTeamMember(props.teamMembers[i].id, props.teamMembers[i].name)}
                                 className='text-red-700 text-center ml-auto mr-auto cursor-pointer' />
                        </td>
                    </tr>
                )
            }
        }
        return teamMembersArr;
    }


    return (
        <div>
            <h2 className='mt-5 mb-5'>Current Team Members</h2>

            <Link to='/add-team-member' className='btn btn-link btn-custom-link mt-5 mb-5'><FaPlus className='inline' /> Add Team Member</Link>



            <table className='w-full mt-5 text-left'>
                <thead>
                <tr>
                    <th>Team Member's Name</th>
                    <th className='hidden sm:table-cell'>Email</th>
                    <th style={{textAlign: 'center'}}>Is Admin</th>
                    <th className='text-center'>Project Count</th>
                    <th className='hidden sm:table-cell'>Last Logged In</th>
                    <th style={{textAlign: 'center'}}>Delete User</th>
                </tr>
                </thead>
                <tbody>
                {buildTeamMemberRows()}
                </tbody>
            </table>
        </div>
    )
}

export default CurrentTeamMembers;