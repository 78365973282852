import React, {useEffect, useState} from "react";
import TopNavBar from "../TopNavBar";
import GroupToggleButtons from "../GroupToggleButtons";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {convertFileInputToBase64} from '../../JSFuncs/HelperFunctions';
import {useMediaQuery} from 'react-responsive'
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import {FaExclamationTriangle} from "react-icons/fa";

import {useHistory} from "react-router-dom";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnDefaultModalState,
    SecondaryButton, returnErrorModal
} from "devso-react-library";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";

function AddProject()
{
    const [modalState, setModalState] = useState(null);
    const isDesktop = useMediaQuery({query: '(min-device-width: 1224px)'})
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [projectName, setProjectName] = useState('');
    const [formErrors, setFormErrors] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const history = useHistory();


    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: "Add Project",
            link: "/add-project"
        }
    ]

    const groupButtonOptions = [
        {
            label: "Android",
            subTitle: 'Java',
            selected: false,
            icon: '/images/platforms/android.png'
        },
        {
            label: 'ReactJS',
            selected: false,
            icon: '/images/platforms/reactjs.png'
        },
        {
            label: "PHP",
            selected: false,
            icon: '/images/platforms/php.png'
        },
        {
            label: "Flutter",
            selected: false,
            selectable: true,
            icon: '/images/platforms/flutter.png'
        },
        {
            label: "Custom SDK",
            selected: false,
            icon: '/images/logo.png'
        },
        {
            label: "C++",
            subTitle: 'Coming Soon',
            selected: false,
            selectable: false,
            icon: '/images/platforms/cpp.png'
        },
        {
            label: "C#",
            subTitle: 'Coming Soon',
            selected: false,
            selectable: false,
            icon: '/images/platforms/c_sharp.png'
        },
        {
            label: "Javascript",
            subTitle: 'Coming Soon',
            selected: false,
            selectable: false,
            icon: '/images/platforms/js.png'
        },
        {
            label: "Python",
            subTitle: 'Coming Soon',
            selected: false,
            selectable: false,
            icon: '/images/platforms/python.png'
        },
        {
            label: "Ruby",
            subTitle: 'Coming Soon',
            selected: false,
            selectable: false,
            icon: '/images/platforms/ruby.png'
        }
    ]

    const handleProjectNameFormSubmit = async (postArray) => {

        try {
            postArray.project_type = selectedPlatform;

            if (selectedPlatform === "ReactJS") {
                // @ts-ignore
                const fileInput = document.querySelector("#source_map_input").files;
                if (fileInput.length > 0) {
                    const file = fileInput[0];
                    convertFileInputToBase64(file).then(async function (result) {
                        postArray.sourceMap = result;

                        await sendProjectSubmitRequest(postArray);
                    });
                } else {
                    await sendProjectSubmitRequest(postArray);
                    //sendProjectSubmitRequest(postArray);
                }
            } else {
                await sendProjectSubmitRequest(postArray);
            }
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else
            {
                returnErrorModal(err, "Failed to add project", setModalState);
            }
        }
    }

    const sendProjectSubmitRequest = async (postArray) => {

        try
        {
            const response = await sendRequest(postArray, '/projects', HTTP_METHOD.PUT);
            if (response.message === "ProjectExists")
            {
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = 'Project Already Exists';
                tempModalState.content = <p>
                    The project '{projectName}' already exists. Please choose another
                </p>
                //tempModalState.icon = <FaExclamationTriangle />
                tempModalState.buttons = [
                    <SecondaryButton label='OK' onClick={() => {
                        const tempModalState = returnDefaultModalState();
                        setModalState({...tempModalState});
                    }} />
                ]
                setModalState({...tempModalState});
            }
            else
            {
                //window.location.href = "/final-project-setup/" + selectedPlatform + "/" + response.data.project_id + "/" + response.data.api_key;
                history.push(`/final-project-setup/${selectedPlatform}/${response.data.project_id}/${response.data.api_key}`);
            }
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else
            {
                returnErrorModal(err, "Failed to submit project", setModalState);
            }
        }
    }

    const handleToggleChange = (selectedPlatform) => {
        setSelectedPlatform(selectedPlatform);
    }

    const formStyle : any = {display: 'none'}
    if ((typeof selectedPlatform !== typeof undefined) && selectedPlatform.length > 0)
    {
        formStyle.display = 'block';
        formStyle.width = 80 + '%';
        formStyle.marginLeft = 'auto';
        formStyle.marginRight = 'auto';
        formStyle.paddingBottom = "50px";
    }

    const sourceMapStyle = {display: 'none'};
    if (selectedPlatform === "ReactJS")
    {
        sourceMapStyle.display = 'block';
    }

    useEffect(() => {
        const form = document.getElementById("projectform");
        if (form !== null)
        {
            form.scrollIntoView({behavior: 'smooth'});
        }
    }, [selectedPlatform])

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <h1>Create Project</h1>
                    {
                        isDesktop ? <div className='w-1/2 ml-auto mr-auto'>
                        <p className='font-bold text-xl'>
                            Select your language or platform below and start monitoring errors
                        </p>

                        <GroupToggleButtons selectedLabel={selectedPlatform} buttonSize={'large'} layout={'grid'}
                                            handleToggleChange={handleToggleChange} buttonOptions={groupButtonOptions} />

                        <Form className='mt-10' handleSubmit={handleProjectNameFormSubmit} errors={formErrors} loading={formLoading}>
                            <TextField type={TextFieldType.TEXT} label='Project Name' placeholder='Enter Project Name' api_field_name='project_name' value={projectName} onChange={setProjectName} />
                            <div style={sourceMapStyle}>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>Source Map: </label>
                                    <div className='col-sm-9'>
                                        <input type='file' className='form-control' id='source_map_input' />
                                        <p style={{marginTop: 10}}>
                                            For information regarding source maps check
                                            out our <a href="https://crashcatch.com/docs/project-management/source-maps" target='noreferrer' title='Crash Catch Source Map Upload Documentation'>docs</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <FormButtonContainer>
                                <PrimaryButton label='Create Project' />
                            </FormButtonContainer>
                        </Form>
                    </div> :

                    <div className='text-center'>
                        <h1>Looks like your on a mobile or tablet device</h1>

                        <p>
                            To avoid confusion or any complication in setting up your project
                            Crash Catch prevents you from creating a project on a mobile or tablet device.
                        </p>
                        <p>
                            The reason for this is it is highly likely that you would need to add a project
                            from an actual PC such as a desktop or laptop device so you can add the Crash
                            Catch library to your project and test that it receives a crash OK on the next step,
                            plus depending on your projects language or framework you may need to upload project
                            specific settings.
                        </p>
                    </div>
                }
                </ContentWrapper>
            </div>
        </Wrapper>
    )

    /*return (
        <div className='wrapper'>
            <TopNavBar displayTimePeriodButton={false} title='Create Project'/>

            <BreadcrumbNav menu_items={breadcrumbNav} />

            <div id='mainContent' className='mainContentNoSidebar'>






            </div>

            <ModalDialog {...modalState} />

        </div>
    )*/
}

export default AddProject;