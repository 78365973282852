import * as React from "react";
import {useEffect, useState} from "react";
import TopNavBar from "../TopNavBar";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import CurrentTeamMembers from "./CurrentTeamMembers";
import PendingTeamMembers from "./PendingTeamMembers";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {User} from "../../Models/User";
import {toast} from "react-toastify";
import {ModalDialog, returnDefaultModalState, returnErrorModal,PrimaryButton, SecondaryButton} from "devso-react-library";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";

function ManageTeamMembers()
{
    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: "Team Members",
            link: "/team-members"
        }
    ]

    const [modalState, setModalState] = useState(null);

    const [teamMembers, setTeamMembers] = useState(null);
    const [pendingInvites, setPendingInvites] = useState(null);

    const fetchTeamMembers = async () => {

        try
        {
            const response : User = await sendRequest(null, '/users');
            //setAPIResult(response);
            setTeamMembers(response.data);
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }

    const fetchPendingTeamInvites = async () => {
        try
        {
            const response = await sendRequest(null, '/invitations');
            if (response.status === 0)
            {
                setPendingInvites(response.data);
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }


    const confirmDeleteTeamMember = (user_id: number, name: string) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure";
        tempModalState.content = <p>
            Are you sure you want to delete user <span className='font-bold'>{name}</span>?
        </p>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                deleteTeamMember(user_id)
            }} />,
            <SecondaryButton label='Cancel' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }

    const deleteTeamMember = async (user_id: number) => {
        try
        {
            const response = await sendRequest(null,`/users/${user_id}`, HTTP_METHOD.DELETE);
            if (response.status === 0)
            {
                await fetchTeamMembers();
                toast.success("Team member has been successfully removed from your account");
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        catch (e)
        {
            const tempModalState = returnErrorModal(e, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }

    const confirmDeletePendingInvite = async (pendingInviteId: number) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure";
        tempModalState.content = <p>
            Are you sure you want to delete this pending invite?
        </p>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={async () => {
                await deletePendingInvite(pendingInviteId);
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }

    const deletePendingInvite = async (pendingInviteId: number) => {

        try
        {
            await sendRequest(null, '/invitations/' + pendingInviteId, HTTP_METHOD.DELETE);
            toast.success("Successfully removed pending invite");
            await fetchPendingTeamInvites();
            const tempModalState = returnDefaultModalState();
            setModalState({...tempModalState})
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }

    useEffect(() => {
        (
            async () => {
                await fetchTeamMembers();
                await fetchPendingTeamInvites();
            }
        )()

    }, []);

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <div className='w-full md:w-3/4 ml-auto mr-auto'>
                        <h1>Manage Team Members</h1>
                        <Tabs>
                            <TabList>
                                <Tab>Current Team Members</Tab>
                                <Tab>Pending Team Members</Tab>
                            </TabList>

                            <TabPanel>
                                <CurrentTeamMembers teamMembers={teamMembers}
                                                    confirmDeleteTeamMember={confirmDeleteTeamMember}/>
                            </TabPanel>

                            <TabPanel>
                                <PendingTeamMembers pendingTeamMembers={pendingInvites}
                                                    handleAPIError={setModalState}
                                                    confirmDeletePendingInvite={confirmDeletePendingInvite}/>
                            </TabPanel>
                        </Tabs>
                    </div>
                </ContentWrapper>
            </div>
            <ModalDialog {...modalState} />
        </Wrapper>
    )

    /*return (


        <div className='wrapper'>
            <TopNavBar displayTimePeriodButton={false} title='Team Members'  />

            <BreadcrumbNav menu_items={breadcrumbNav} />
            <div className='mainContentNoSidebar'>

                <div className='w-full md:w-3/4 ml-auto mr-auto'>

                    <Tabs>
                        <TabList>
                            <Tab>Current Team Members</Tab>
                            <Tab>Pending Team Members</Tab>
                        </TabList>

                        <TabPanel>
                            <CurrentTeamMembers teamMembers={teamMembers}
                                                confirmDeleteTeamMember={confirmDeleteTeamMember}/>
                        </TabPanel>

                        <TabPanel>
                            <PendingTeamMembers pendingTeamMembers={pendingInvites}
                                                handleAPIError={setModalState}
                                                confirmDeletePendingInvite={confirmDeletePendingInvite}/>
                        </TabPanel>
                    </Tabs>

                </div>
            </div>
            <ModalDialog {...modalState} />
        </div>


    )*/
}

export default ManageTeamMembers;