import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import TopNavBar from "../TopNavBar";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {FaCheckCircle, FaEdit, FaSync} from "react-icons/fa";
import {toast} from "react-toastify";
import ProjectActivities from "./ProjectActivities";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactJSSourceMap from "./ReactJSSourceMap";
import {
    addUserToProject,
    deleteProjectTeamMember,
    getProjectSettings,
    getProjectTeamMembersAndOtherAvailableMembers, getProjectVersions
} from "../../Functions/ProjectManagement";
import {
    ModalDialog,
    returnDefaultModalState,
    PrimaryButton,
    SecondaryButton,
    TextField,
    TextFieldType
} from "devso-react-library";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import ReactTooltip from "react-tooltip";

function ProjectSettings(props: any)
{
    const [currentError, setAPIResult] = useState(null);
    const [projectDetails, setProjectDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    //const [projectTeamMembers, setProjectTeamMembers] = useState([]);
    const [teamMemberRows, setTeamMemberRows] = useState([]);
    const [availableUserRows, setAvailableUserRows] = useState([]);
    const [modalState, setModalState] = useState(null);
    const [projectVersions, setProjectVersions] = useState([]);

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: "Project Settings",
            link: ''
        }
    ]

    const editProjectName = () => {
        setInEditMode(true);
        setNewProjectName(projectDetails.ProjectName);
        const projectInput = document.getElementById("txtProjectName");
        projectInput.removeAttribute("readonly");
        projectInput.focus();
    }

    const submitProjectName = async () => {
        const postArray = {
            project_name: newProjectName
        };

        try
        {
            await sendRequest(postArray, `/projects/project/${props.match.params.project_id}/name`, HTTP_METHOD.POST);
            toast.success("Project name was successfully updated");
            setInEditMode(false);
            const updatedProjectedInfo = projectDetails;
            updatedProjectedInfo.project_name = newProjectName;
            setProjectDetails({...updatedProjectedInfo});

            const projectInput = document.getElementById("txtProjectName");
            projectInput.setAttribute("readonly", "true");

        }
        catch (err)
        {
            if (typeof err.response !== typeof undefined)
            {
                setAPIResult(err.response.data);
            }
            else
            {
                setAPIResult({
                    status: 100,
                    message: "An unexpected error has occurred",
                    data: null
                })
            }
        }
    }

    const showConfirmAddUserToProjectModal = useCallback((project_id: string, userId: number, name: string, showConfirmDeleteProjectTeamMember: Function, showConfirmAddUserToProjectModal: Function) => {
            const tempModalState = returnDefaultModalState();
            tempModalState.open = true;
            tempModalState.title = "Add User to Project";
            tempModalState.content = <p>
                Are you sure you want to add <span className='font-bold'>{name} </span>
                to this project.
            </p>
            tempModalState.buttons = [
                <PrimaryButton label="Yes...I'm sure" onClick={() => {
                    addUserToProject(project_id, userId, name, showConfirmDeleteProjectTeamMember, showConfirmAddUserToProjectModal).then(function (result: any) {
                        setAvailableUserRows(result.AvailableUsers);
                        setTeamMemberRows(result.TeamMemberRows);
                        const tempModalState = returnDefaultModalState();
                        setModalState({...tempModalState});
                    }).catch(err => {
                        setAPIResult(err);
                        const tempModalState = returnDefaultModalState();
                        setModalState({...tempModalState});
                    });
                }} />,
                <SecondaryButton label='No' onClick={() => {
                    const tempModalState = returnDefaultModalState();
                    setModalState({...tempModalState});
                }} />
            ]
            setModalState({...tempModalState});
    }, []);

    const showConfirmDeleteProjectTeamMember = useCallback((name: string, userId: number, showConfirmDeleteProjectTeamMember: Function, showConfirmAddUserToProjectModal: Function) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Remove Team Member";
        tempModalState.content = <p>
            Are you sure you want to remove <span className='font-bold'>{name} </span>
            from project?
        </p>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                deleteProjectTeamMember(props.match.params.project_id, name, userId, showConfirmDeleteProjectTeamMember, showConfirmAddUserToProjectModal).then(function(result: any){
                    setAvailableUserRows(result.AvailableUsers);
                    setTeamMemberRows(result.TeamMemberRows)
                    const tempModalState = returnDefaultModalState();
                    setModalState({...tempModalState});
                }).catch(function(err){
                    setAPIResult(err);
                    const tempModalState = returnDefaultModalState();
                    setModalState({...tempModalState});
                });
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }, [props.match.params.project_id]);




    const showRegenerateAPIKeyConfirmModal = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Regenerate API Key";
        tempModalState.content = <div>
            <p>
                Are you sure you want to regenerate the API key for this project?
            </p>
            <p>
                If you do this it cannot be undone, and your app will need to be updated with this new API key
            </p>
        </div>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                regenerateAPIKey()
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }

    const regenerateAPIKey = async () => {

        try
        {
            const response = await sendRequest(null, `/projects/project/${projectDetails.project_id}/api_key`, HTTP_METHOD.POST);
            if (response.status === 0)
            {
                toast.success("API Key has been successfully regenerated")
                const projectDetailsTemp = projectDetails;
                projectDetailsTemp.api_key = response.data.api_key;
                setProjectDetails(projectDetailsTemp);
                //await getProjectActivities(projectDetails.project_id);

                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }
            else
            {
                setAPIResult(response);
            }
        }
        catch(err)
        {
            if (typeof err.response.data !== typeof undefined)
            {
                setAPIResult(err.response.data);
            }
            else
            {
                setAPIResult({
                    status: 100,
                    message: 'An unexpected error has occurred',
                    data: null
                })
            }
        }
    }




    useEffect(() => {
        getProjectSettings(props.match.params.project_id).then(async function(result){
            setProjectDetails(result);
            try
            {
                const versions = await getProjectVersions(props.match.params.project_id);
                setProjectVersions(versions);
            }
            catch (err)
            {

            }

        }).catch(function(err){
            setAPIResult(err);
            setIsLoading(false);
        });
        getProjectTeamMembersAndOtherAvailableMembers(props.match.params.project_id, showConfirmDeleteProjectTeamMember, showConfirmAddUserToProjectModal).then(function(result: any){
            setAvailableUserRows(result.AvailableUsers);
            setTeamMemberRows(result.TeamMemberRows)
        }).catch(function(err){
            setAPIResult(err)
        });


    }, [props, isLoading, showConfirmAddUserToProjectModal, showConfirmDeleteProjectTeamMember]);

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full mx-2 md:w-1/2'>
                            <div className='md:m-4 mt-6'>

                                <h2>Projects Current Team Members</h2>
                                <table className='w-full text-left'>
                                    <thead>
                                    <tr>
                                        <th className='hidden sm:table-cell'>&nbsp;</th>
                                        <th className='hidden sm:table-cell'>Registered At</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        teamMemberRows
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className='mt-6'>
                                <h2>Available Users to Add to Project</h2>
                                <table className='w-full text-left'>
                                    <thead>
                                    <tr>
                                        <th className='hidden sm:table-cell'>&nbsp;</th>
                                        <th className='hidden sm:table-cell'>Registered At</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        availableUserRows
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className='flex-1 mt-6'>
                                <div>

                                    {
                                        projectDetails !== null && projectDetails.project_type === "ReactJS" ?
                                            <ReactJSSourceMap project_id={props.match.params.project_id} versions={projectVersions} handleApiError={setAPIResult} /> : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='mt-6'>
                            <h2>Project Details</h2>
                            {
                                projectDetails !== null ? <>
                                        <span>

                                        <input
                                            className="w-[240px] inline !bg-white focus:!border-primary focus:!border-[1px] !shadow !appearance-none !border !rounded!accent-primary !py-2 !px-3 !text-black !leading-tight focus:!shadow-outline"
                                            type='text'
                                            readOnly={true} id='txtProjectName'
                                            onChange={(e) => setNewProjectName(e.target.value)}
                                            value={!inEditMode ? projectDetails.project_name : newProjectName} />
                                            {
                                                !inEditMode ? <FaEdit className='ml-3 cursor-pointer inline' onClick={editProjectName} /> :
                                                    <FaCheckCircle className='ml-3 cursor-pointer inline' onClick={submitProjectName} />
                                            }
                                    </span>
                                        <p className='mt-4'>
                                            <span className='font-bold'>Project Type: </span>
                                            {projectDetails.project_type}
                                        </p>

                                        <p style={{cursor: 'pointer'}} data-tip="Click to copy project id to clipboard">
                                            <span className='font-bold'>Project ID: </span>
                                            <CopyToClipboard text={projectDetails.project_id} onCopy={() => toast.info(projectDetails.project_id + " successfully copied")}>
                                                <span>{projectDetails.project_id}</span>
                                            </CopyToClipboard>
                                        </p>
                                        <p style={{cursor: 'pointer'}} data-tip="Click to copy api key to clipboard">
                                            <span className='font-bold'>API Key: </span>
                                            <CopyToClipboard text={projectDetails.api_key} onCopy={() => toast.info("API Key successfully copied to the platform")}>
                                                <span>{projectDetails.api_key}</span>
                                            </CopyToClipboard>
                                            <FaSync className='ml-3 mb-1 cursor-pointer inline'
                                                    data-tip='Regenerate API Key' onClick={showRegenerateAPIKeyConfirmModal} />
                                        </p>
                                    </>
                                    : "No Project Details"
                            }

                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <ModalDialog {...modalState} />
            <ReactTooltip />
        </Wrapper>
    )

        /*return (
            <div>

                <TopNavBar displayTimePeriodButton={false} title='Settings' />
                <BreadcrumbNav menu_items={breadcrumbNav} />

                <div className="wrapper">


                    <div className='mainContentNoSidebar' style={{padding: 10}}>

                        <h1>Project Settings</h1>

                        <div className='flex flex-col md:flex-row'>
                            <div className='flex-1'>
                                {
                                    projectDetails !== null ? <>
                                        <span>
                                        <input style={{width: 240}} type='text'
                                               readOnly={true} className='inline' id='txtProjectName'
                                               onChange={(e) => setNewProjectName(e.target.value)}
                                               value={!inEditMode ? projectDetails.project_name : newProjectName} />
                                                {
                                                    !inEditMode ? <FaEdit className='ml-3 cursor-pointer inline' onClick={editProjectName} /> :
                                                        <FaCheckCircle className='ml-3 cursor-pointer inline' onClick={submitProjectName} />
                                                }
                                    </span>
                                            <p className='mt-4'>
                                                <span style={{fontWeight: 'bold'}}>Project Type: </span>
                                                {projectDetails.project_type}
                                            </p>

                                        <p style={{cursor: 'pointer'}} data-tip="Click to copy project id to clipboard">
                                        <span style={{fontWeight: 'bold'}}>Project ID: </span>
                                        <CopyToClipboard text={projectDetails.ProjectId} onCopy={() => toast.info(projectDetails.project_id + " successfully copied")}>
                                        <span>{projectDetails.project_id}</span>
                                        </CopyToClipboard>
                                        </p>
                                        <p style={{cursor: 'pointer'}} data-tip="Click to copy api key to clipboard">
                                        <span style={{fontWeight: 'bold'}}>API Key: </span>
                                        <CopyToClipboard text={projectDetails.api_key} onCopy={() => toast.info("API Key successfully copied to the platform")}>
                                        <span>{projectDetails.api_key}</span>
                                        </CopyToClipboard>
                                        <FaSync className='ml-3 mb-1 cursor-pointer inline'
                                        data-tip='Regenerate API Key' onClick={showRegenerateAPIKeyConfirmModal} />
                                        </p>
                                    </>
                                    : "No Project Details"
                                }

                                <div className='-m-2 md:m-4 mt-6'>

                                    <h2>Projects Current Team Members</h2>
                                    <table className='w-full text-left'>
                                        <thead>
                                        <tr>
                                            <th className='hidden sm:table-cell'>&nbsp;</th>
                                            <th className='hidden sm:table-cell'>Registered At</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            teamMemberRows
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='-m-2 md:m-4 mt-6'>
                                    <h2>Available Users to Add to Project</h2>
                                    <table className='w-full text-left'>
                                        <thead>
                                        <tr>
                                            <th className='hidden sm:table-cell'>&nbsp;</th>
                                            <th className='hidden sm:table-cell'>Registered At</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            availableUserRows
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className='flex-1'>
                                <div>

                                    {
                                        projectDetails !== null && projectDetails.project_type === "ReactJS" ?
                                            <ReactJSSourceMap project_id={props.match.params.project_id} versions={projectVersions} handleApiError={setAPIResult} /> : null
                                    }

                                    <ProjectActivities project_id={props.match.params.project_id} />
                                </div>
                            </div>
                        <div>
                    </div>
                </div>

            </div>
        </div>
                <ModalDialog {...modalState} />
            </div>
        )*/
}

export default ProjectSettings;