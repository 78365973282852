import * as React from "react";
import {ProjectInstructionsTypeProps} from "../Projects/FinalProjectSetup";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";

function ReactJSInstructions(props: ProjectInstructionsTypeProps)
{
    return (
        <div>
            <div className='text-center items-center justify-items-center content-center'>
                <img className='ml-auto mr-auto' style={{width: 80}} src='/images/platforms/reactjs.png' alt='ReactJS' />
            </div>

            <h2>Installation</h2>
            <p>
                The Crash Catch library for ReactJS can be installed using NPM. From your project root directory run the following:
            </p>
            <SyntaxHighlighter  language="shell">
npm install crashcatchlib-reactjs
            </SyntaxHighlighter>

            <p>
                If you get an error about not being able to resolve the dependency tree, this may be because
                if a ReactJS version mismatch. You can round this by using the --legacy-peer-deps flag to the install
                as shown below
            </p>
            <SyntaxHighlighter language="shell">
npm install crashcatchlib-reactjs --legacy-peer-deps
            </SyntaxHighlighter>

            <p>
                Once installed, in your app.js or app.tsx you need to import and the as shown below:
            </p>

            <SyntaxHighlighter language='javascript'>
                {`
import {CrashCatch, CrashCatchProvider} from "crashcatchlib-reactjs";   
                `}
            </SyntaxHighlighter>

            <p>
                Then in the function above the return statement, create a new instance of CrashCatch and initialise
                the library, using your project id, API key and your projects version number as shown below:

            </p>

            <SyntaxHighlighter language="javascript">
                {`
const crash_catch = new CrashCatch();
crash_catch.initialiseCrashCatch("`+props.project_id+`", "`+props.api_key+`", "<project version number>");
                `}
            </SyntaxHighlighter>

            <p>
                Then you need to wrap everything in the return of app.js in a and pass the value of a full example
                of your app.js might look like below:
            </p>

            <SyntaxHighlighter language="javascript">
                {`
import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import {CrashCatch, CrashCatchProvider, CrashCatchContext} from "crashcatchlib-reactjs";

function App() {

    const crash_catch = new CrashCatch();
    crash_catch.initialiseCrashCatch("`+props.project_id+`", "`+props.api_key+`", "1.0.0");
  return (

      <CrashCatchProvider value={crash_catch}>
          <ErrorBoundary>
              <BrowserRouter>
                  <Routes>
                      <Route index element={<Home />} />
                      <Route path='*' element={<NotFound />} />
                  </Routes>
              </BrowserRouter>
          </ErrorBoundary>
      </CrashCatchProvider>

  );
}
export default App;
                `}
            </SyntaxHighlighter>

            <p>
                You may have noticed under there is another component called . You need to create this component with
                the following in as a minimum. You can add extra handling if you wish to this component
                but the below is required to submit unhandled crash exceptions:
            </p>

            <SyntaxHighlighter language="javascript">
                {`
import * as React from "react";
import {CrashCatchContext} from 'crashcatchlib-reactjs';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo)
    {
        this.setState({hasError: true});
        const crash_catch = this.context;
        crash_catch.reportUnhandledException(error);
    }

    render() {
        return this.props.children
    }
}
export default ErrorBoundary
                `}
            </SyntaxHighlighter>

            <p>
                If you just want to send unhandled exceptions, that's all you need to do, but you
                can manually submit crashes that are handled such as in try/catch blocks. Its
                recommended to at least do this now to prove that the everything is working as expected.
            </p>

            <p>
                In one of your components, you would import the CrashCatchContext and use its context to
                have access to the reportCrash method. An example is shown below:
            </p>

            <SyntaxHighlighter language="javascript">
                {`
import * as React from 'react'
import {CrashCatchContext} from 'crashcatchlib-reactjs';

export default function MyComponent() {

  const crash_catch = useContext(CrashCatchContext);

  //If your project is typescript then you would need to do the following:
  //const crash_catch : CrashCatchContext = useContext(CrashCatchContext);

  const sendHandledCrash = () => {
    try
    {
      //Do something that would trigger an error
    }
    catch (err)
    {
      //The second parameter should be Low, Medium or High
      crash_catch.reportCrash(err, "Low");
    }
  }
  
  return (
    <button onClick={() => sendHandledCrash()}>Test</button>
  )
}
                `}
            </SyntaxHighlighter>

            <p>
                That's it, you should now see the crash you just generated above in your accout on Crash Catch.
                Crash catch will automatically send crashes that aren't caught but if you want to report errors
                that you catch using a try/catch block you can use the reportCrash function as you did above.
            </p>
            <p>
                You can also parse a 3rd parameter which is JSON object or array to the reportCrash method
                so you can pass custom properties to the crash report such as state parameters or props or any
                other data that you see as relevant to know to help you investigate a crash
            </p>
            <p>
                For more information see our <a href='https://docs.crashcatch.com'>docs</a>.
            </p>
        </div>
    )
}

export default ReactJSInstructions;