import * as React from "react";
import GroupToggleButtons, {ButtonOptions} from "./GroupToggleButtons";
import DropdownMenu from "./DropdownMenu";
import Cookies from 'js-cookie';
import {FaCreditCard, FaExternalLinkAlt, FaUserAlt, FaUsers} from "react-icons/fa";
import { useMediaQuery } from 'react-responsive'
import {Link, useHistory} from "react-router-dom";
import {useGlobalState} from "./GlobalStateProvider";
import {convertEpochToHumanDate} from "../JSFuncs/DataTimeManager";
import {sendRequest} from "../JSFuncs/APIManager";
import TrialOrPaymentBanner from "./TrialOrPaymentBanner";

export type TopNavBarProps = {
    displayTimePeriodButton?: boolean,
    items?: Array<ButtonOptions>,
    allowCreateProject?: boolean,
    title?: string,
    currentTimePeriod?: string,
    handleToggleChange?: Function
    hideCreateProject?: boolean
    hideMenu?: boolean
}


const TopNavBar = React.memo(function TopNavBar(props : TopNavBarProps)
{
    //const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    const history = useHistory();

    const { setGlobalState, globalState } = useGlobalState();

    const groupButtonOptions : any = typeof props.items === typeof undefined ? [
        {
            label: "1 Hour",
            selected: false
        },
        {
            label: "3 Hours",
            selected: false
        },
        {
            label: "1 Day",
            selected: false
        },
        {
            label: "1 Week",
            selected: false
        },
        {
            label: "1 Month",
            selected: true
        },
        {
            label: "3 Months",
            selected: false
        }
    ] : props.items

    const menuItems = [
        {
            icon: FaUserAlt,
            link: '/settings',
            title: 'Settings'
        },
        {
            icon: FaCreditCard,
            link: '/account-billing',
            title: 'Account & Billing'
        },
        {
            icon: FaUsers,
            link: '/team-members',
            title: 'Manage Team Members'
        },
        {
            icon: null,
            link: async () => {
                await performLogout();
            },
            title: 'Log out'
        },
        {
            type: 'separator',
            className: 'userMenu'
        },
        {
            icon: null,
            link: 'https://status.devso.io',
            title: 'Service Status',
            endIcon: FaExternalLinkAlt,
            target: '_blank'
        },
        {
            icon: null,
            link: 'https://support.devso.io',
            title: 'Get Support',
            endIcon: FaExternalLinkAlt,
            target: '_blank'
        },
        {
            icon: null,
            link: 'https://blog.devso.io',
            title: 'View Blog',
            endIcon: FaExternalLinkAlt,
            target: '_blank'
        }
    ]

    const performLogout = async () => {
        try
        {
            await sendRequest(null, '/auth/logout');
            Object.keys(Cookies.get()).forEach(cookieName => {
                Cookies.remove(cookieName);
            })
            history.push('/login/You have been successfully logged out');
        }
        catch (err)
        {

        }
    }

    const showCreateProject = typeof props.allowCreateProject === typeof undefined
        || (typeof  props.allowCreateProject !== typeof undefined && props.allowCreateProject === true) ? {} : {display: 'none'}

    let style : any = {};
    if ((props.displayTimePeriodButton === null || typeof props.displayTimePeriodButton === typeof undefined) || props.displayTimePeriodButton)
    {
        style = {};
    }
    else
    {
        style.display = 'none';
    }


    if (typeof globalState.payment_failed !== typeof undefined && typeof globalState.trial !== typeof undefined)
    {
        return (
            <nav className="bg-primary text-white m-0 w-full pb-20" style={{height: 128}}>
                <div className="px-2 w-full">
                    <div className="relative h-16 w-full">
                        <div className={`grid grid-cols-2`}>

                            <div className='mt-3'>
                                <div className="flex flex-row w-full ml-auto mr-auto items-center text-center sm:items-start sm:text-left">
                                    <div>
                                        <img style={{height: 60, marginTop: 0, marginLeft: 'auto', marginRight: 'auto'}} className="block md:hidden lg:hidden mt-3" src="/images/logo-inverted.png" alt="Crash Catch Logo" />
                                        <img style={{height: 60, marginTop: 0, marginLeft: 20}} className="hidden md:block lg:block" src="/images/logo-inverted.png" alt="Crash Catch Logo" />
                                    </div>
                                    <div className="hidden sm:block ml-3">
                                        <p className='text-4xl m-0 p-0'>
                                            Crash Catch
                                        </p>
                                        <p className='text-xs'>
                                            Crash reporting for every platform, every language, every developer
                                        </p>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <h1 className='text-white font-normal text-base font-bold md:font-normal md:text-3xl mt-2 md:-mt-2'>{props.title}</h1>
                                </div>

                            </div>

                            {/*<div className={`${!globalState.payment_failed.last_payment_failed
                                && !globalState.trial.in_trial ? "hidden" : "block"}`}>
                                {
                                    globalState.trial.in_trial ?
                                        <div className='bg-cta border border-primary-dark border-2 rounded text-white p-2 text-center w-1/2 mt-8 ml-auto mr-auto'>
                                            <p className='p-0 m-0'>
                                                {globalState.trial.days_of_trial_remaining} days remaining of trial
                                            </p>
                                            <Link to='/account-billing' className='text-sm text-white'>Click to upgrade</Link>
                                        </div> : null
                                }
                                {
                                    globalState.payment_failed.last_payment_failed ?
                                        <div className='bg-cta border border-primary-dark border-2 rounded text-white p-2 text-center w-1/2 mt-8 ml-auto mr-auto'>
                                            <p className='p-0 m-0'>
                                                Payments was due on {convertEpochToHumanDate(globalState.payment_failed.payment_time_required, false)}
                                            </p>
                                            <Link to='/account-billing' className='text-sm text-white'>Update Payment Details</Link>
                                        </div> : null
                                }

                            </div>*/}

                            <div className='flex flex-row md:mt-6 lg:mt-0 text-right'>

                                <div className='w-full m-3 items-end justify-end' style={showCreateProject}>
                                    {
                                        !globalState.payment_failed.payment_required && !props.hideMenu ? <div className='flex flex-row-reverse text-right items-end content-end justify-items-end w-full'>
                                            <DropdownMenu title={decodeURIComponent(Cookies.get('Name')).replace("+", " ")} menuItems={menuItems} />
                                        </div> : null
                                    }

                                    {
                                        !globalState.payment_failed.payment_required && !isTabletOrMobileDevice ?
                                            <div className='flex flex-row-reverse text-right items-end content-end justify-items-end w-full' >
                                                {
                                                    !props.hideCreateProject ? <Link to='/add-project' className='btn btn-cta'>Create Project</Link> : null
                                                }

                                            </div> : null
                                    }

                                    <div className='w-full' style={style} >
                                        <GroupToggleButtons groupLabel='Selected Time Period: ' selectedLabel={props.currentTimePeriod} handleToggleChange={props.handleToggleChange} buttonOptions={groupButtonOptions} />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <TrialOrPaymentBanner />
            </nav>
        )
    }
    else
    {
        return null;
    }
});

export default TopNavBar;