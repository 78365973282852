import * as React from 'react'
import {useEffect, useState} from 'react'
import {Loading, LoadingSize, ModalState, returnErrorComponent} from "devso-react-library";
import useProjectActivities from "../../Hooks/useProjectActivities";
import ReactTooltip from "react-tooltip";

export type ProjectActivitiesProps = {
    project_id?: string
}

function ProjectActivities(props: ProjectActivitiesProps)
{
    const {projectActivities, projectActivitiesLoading, projectActivitiesError} = useProjectActivities(props.project_id);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [projectActivities])

    return (
        <>
            <h2 className='text-center'>Project Activities</h2>
            {
                (
                    () => {
                        if (projectActivitiesLoading)
                        {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!projectActivitiesLoading && projectActivitiesError !== null)
                        {
                            return returnErrorComponent(projectActivitiesError, "Unable to get project activities");
                        }
                        else if (!projectActivitiesLoading && projectActivities !== null)
                        {
                            if (projectActivities.length > 0)
                            {
                                return (
                                    projectActivities.map(activity => {
                                        const usersName = activity.activity_completed_by_details !== null ?
                                            activity.activity_completed_by_details.name : 'Crash Catch';

                                        let initial = null;
                                        let profileImageShown = false;
                                        if (activity.activity_completed_by_details !== null)
                                        {
                                            if (activity.activity_completed_by_details.profile_image.length === 0)
                                            {
                                                initial = activity.activity_completed_by_details.name[0];
                                            }
                                            else
                                            {
                                                initial = <img data-tip={usersName} className='rounded-full w-[32px] h-[32px]' aria-hidden alt='Users profile image'
                                                               src={activity.activity_completed_by_details.profile_image}/>
                                                profileImageShown = true;
                                            }
                                        }
                                        else
                                        {
                                            initial = 'C';
                                        }
                                        return (
                                            <div key={activity.activity_id} className='mt-4 mb-4 flex flex-row'>
                                                {
                                                    profileImageShown ? initial :
                                                        <div data-tip={usersName}
                                                             className='cursor-default w-[32px] h-[32px] text-center bg-primary-light rounded-full align-text-bottom p-1 text-white mr-2 mt-2'>
                                                            {initial}
                                                        </div>
                                                }

                                                <div className='w-full ml-3'>
                                                    <p className='m-0 p-0'>{activity.message}</p>
                                                    <p className='text-sm text-gray-500 m-0 p-0'>{activity.created_at}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                            else
                            {
                                return (
                                    <p className='text-center italic'>
                                        There are currently no activities for your projects
                                    </p>
                                )
                            }
                        }
                    }
                )()
            }
            <ReactTooltip />
        </>
    )
}

export default ProjectActivities;