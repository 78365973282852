import * as React from "react";
import {DashboardStatsProps} from "../../Models/DashboardStatsProps";
import useNumberOfCrashErrors from "../../Hooks/Dashboard/useNumberOfCrashErrors";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";

export default function NumberOfErrors(props: DashboardStatsProps) {

    const {numberOfCrashes, numberOfCrashesLoading, numberOfCrashErrors} = useNumberOfCrashErrors(props.project_id, props.time_frame);

    return (
        <div className='w-[180px] md:w-[230px] bg-white p-4 border-gray-400 border-[1px] inline-block rounded mr-2 my-2'>
            <p className='font-bold'>Number of Crashes</p>
            {
                (
                    () => {
                        if (numberOfCrashesLoading)
                        {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!numberOfCrashesLoading && numberOfCrashErrors !== null)
                        {
                            return returnErrorComponent(numberOfCrashErrors, "Failed to get crash count");
                        }
                        else if (!numberOfCrashesLoading && numberOfCrashErrors === null)
                        {
                            return <p className='text-3xl'>
                                {numberOfCrashes}
                            </p>
                        }
                    }
                )()
            }
        </div>
    )
}