import * as React from "react";
import {IssueCardProps, IssuesAPIData} from "../../Models/Issues";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import {FaClock} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import AssigneePicker from "../AssigneePicker";
import {ProjectTeamMembers} from "../../Models/ProjectTeamMembers";
import {PrimaryButton, returnDefaultModalState, SecondaryButton} from "devso-react-library";
import {toast} from "react-toastify";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import CrashSeverity from "../CrashSeverity";
import {newAssigneeSelected} from "../../JSFuncs/IssueHelper";
import { useMediaQuery } from 'react-responsive'
import {convertEloquentTimeToHumanReadable} from "../../JSFuncs/DataTimeManager";

export default function IssueCard(props: IssuesAPIData & IssueCardProps) {

    const [selectedAssignee] = useState(props.assigned_to)

    const history = useHistory();

    const isMobile = useMediaQuery({
        query: '(max-device-width: 428px)'
    })

    console.log("Exception group length: ", props.exception_group.length)
    return (
        <div className='p-3 border-[1px] rounded border-gray-300 bg-white'>
            <div className='flex flex-row w-full'>


                <div className='flex flex-row w-full'>

                    <div data-group='issue_card' className='w-full md:w-1/2 sizzy-red-2'>
                        <h2 data-tip={props.exception_group} className='inline mr-3 mt-0 pt-0 cursor-pointer'
                            onClick={() => history.push(`/issue/${props.crash_group_id}`)}>
                            {
                                props.exception_group.length > 18 && isMobile
                                    ? props.exception_group.substring(0, 18) + "..."
                                    : props.exception_group
                            }
                        </h2>
                        <span className='text-gray-500 text-sm'>{props.class_script_name} on line {props.line_no}</span>
                        <p>
                            {props.exception_message}
                        </p>

                        <div className='flex flex-row text-sm mt-3 w-fit'>

                            <div className='mr-2'>
                                {getProjectIconFromString(props.project_type)}
                            </div>
                            <div>
                                <span>{props.project_name}</span>
                            </div>
                            <div className='ml-5'>
                                <FaClock className='inline mr-1 mb-1 text-gray-400'/> {convertEloquentTimeToHumanReadable(props.last_occurrence, true)}
                            </div>

                            {/** This is the desktop version of the crash type and severity */}
                            <div className='flex hidden md:block'>
                                <div className='flex flex-row'>
                                    <div className='ml-5'>
                                        {
                                            props.crash_type === 'Handled' ?
                                                <span
                                                    className='px-2 py-1 bg-green-700 border-[1px] border-green-900 rounded text-white'>Handled</span> :
                                                <span
                                                    className='px-2 py-1 bg-red-700 border-[1px] border-red-900 rounded text-white'>Unhandled</span>

                                        }
                                    </div>
                                    <CrashSeverity className='ml-3' dataTip='Crash Severity' severity={props.severity} />
                                </div>

                            </div>


                        </div>

                        {/** This is the mobile version of the crash type and severity */}
                        <div className='flex block md:hidden mt-3 w-full'>
                            <div className='flex flex-row'>
                                <div className=''>
                                    {
                                        props.crash_type === 'Handled' ?
                                            <span
                                                className='px-2 py-1 bg-green-700 border-[1px] border-green-900 rounded text-white'>Handled</span> :
                                            <span
                                                className='px-2 py-1 bg-red-700 border-[1px] border-red-900 rounded text-white'>Unhandled</span>

                                    }
                                </div>
                                <CrashSeverity className='ml-3' dataTip='Crash Severity' severity={props.severity} />
                            </div>

                        </div>

                    </div>

                    <div className='hidden md:block md:w-1/2 sizzy-red-2'>
                        <div className='hidden md:flex md:flex-row'>
                            <div className='w-[150px] text-center '>
                                <span className='align-middle text-center my-auto text-2xl'>{props.affected_users}</span>
                            </div>
                            <div className='w-[150px] ml-6 text-center'>
                                <span className='align-middle my-auto text-2xl'>{props.incident_count}</span>
                            </div>
                            <div
                                className='w-[150px] text-center items-center justify-center justify-items-center mx-auto ml-12'>
                                <AssigneePicker new_selected_assignee={selectedAssignee}
                                                currently_assigned_to={props.assigned_to}
                                                current_assigned_to_details={props.assigned_to_details}
                                                setModalState={props.setModalState}
                                                crash_group_id={props.crash_group_id}
                                                project_id={props.project_id}
                                                getIssues={props.getIssues}
                                                selected_time_frame={props.selectedTimeFrame}
                                                newAssigneeSelected={newAssigneeSelected} />
                            </div>

                        </div>
                    </div>



                </div>
            </div>
            <ReactTooltip/>
        </div>
    )
}