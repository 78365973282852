import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {FaCaretDown, FaProjectDiagram} from "react-icons/fa";
import useProjects from "../../Hooks/useProjects";
import {Loading, LoadingSize, returnErrorComponent, TextField, TextFieldType} from "devso-react-library";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import {GetProjectAPIData} from "../../Models/Projects";

export interface ProjectPickerProps {
    projectSelected: (project: GetProjectAPIData) => void;
    selectedProject?: string
}

export default function ProjectPicker(props: ProjectPickerProps) {

    const [selectedProject, setSelectedProject] = useState("All Projects");
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const {projects, projectsLoading, projectError} = useProjects();

    const clickListener = useRef(false);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setExpanded(!expanded);
    }

    const handleProjectClick = (project: GetProjectAPIData) => {
        if (project !== null)
        {
            setSelectedProject(project.project_name);
            props.projectSelected(project);
        }
        else
        {
            setSelectedProject("All Projects");
            props.projectSelected(null);
        }
    }

    useEffect(() => {
        console.log("Project Picker Props: ", props);
        console.log("Project Picker Projects: ", projects);
        if (props.selectedProject !== null && props.selectedProject !== "All Projects" && projects !== null)
        {
            setSelectedProject(projects?.find(p => p.project_id === props.selectedProject)?.project_name);
        }
    }, [props])

    useEffect(() => {
        if (!clickListener.current)
        {
            const body = document.body;
            if (body !== null) {
                body.addEventListener("click", function () {
                    setExpanded(false);
                })

                clickListener.current = true;
            }
        }

        return (
            () => {
                if (clickListener.current)
                {
                    document.removeEventListener("click", function(){});
                }
            }
        )
    }, [])

    return (
        <>
            <div className='bg-gray-200 rounded border-[1px] border-gray-600 w-auto inline-block px-4 py-2 cursor-pointer'
                 onClick={(e) => handleClick(e)}>
                <p className='inline w-full m-0 p-0'>{<><FaProjectDiagram className='inline mr-4' />{selectedProject}</>}</p>
                <span className='inline'><FaCaretDown className='inline ml-2 mb-1' /></span>
            </div>

            <div className={`${expanded ? "block opacity-100" : "opacity-0 hidden"}  transition-all ease-in-out duration-300 rounded min-w-[120px] clear absolute bg-gray-200 shadow-md shadow-gray-500`}>
                {
                    (
                        () => {
                            if (projectsLoading)
                            {
                                return (
                                    <div className='px-4 py-2'>
                                        <Loading size={LoadingSize.SMALL} />
                                    </div>
                                )
                            }
                            else if (!projectsLoading && projectError !== null)
                            {
                                return returnErrorComponent(projectError, "Failed to get projects")
                            }
                            else if (!projectsLoading && projects !== null)
                            {
                                return (
                                    <>
                                        <input className='!bg-white focus:!border-primary focus:!border-[1px] !shadow !appearance-none !border !rounded !w-full !accent-primary !py-2 !px-3 !text-black !leading-tight focus:!shadow-outline' type='text'
                                                   value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                                                    placeholder='Search for Project'/>
                                        <ul className='p-0 m-0'>
                                            <li className={`${"All Projects" === selectedProject ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                                                onClick={() => handleProjectClick(null)}>
                                                <div className='w-[32px] text-left mr-3'>
                                                    <FaProjectDiagram />
                                                </div>

                                                <p>All Projects</p>
                                            </li>
                                            {
                                                projects.map(project => {
                                                    if (searchTerm === "" || project.project_name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
                                                    {
                                                        return (
                                                            <li key={project.project_id} className={`${project.project_name === selectedProject ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                                                                onClick={() => handleProjectClick(project)}>
                                                                <div className='w-[32px] text-left mr-3'>
                                                                    {getProjectIconFromString(project.project_type)}
                                                                </div>

                                                                <p>{project.project_name}</p>
                                                            </li>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return null;
                                                    }
                                                })
                                            }
                                        </ul>
                                    </>
                                )
                            }
                            else
                            {
                                return null;
                            }
                        }
                    )()
                }
            </div>
        </>
    )
}

ProjectPicker.defaultProps = {
    selectedProject: "All Projects"
}