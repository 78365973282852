import axios from 'axios'
import Cookies from 'js-cookie';

export enum HTTP_METHOD {
    POST,
    PUT,
    GET,
    DELETE
}

export const sendRequest = async (data : any, path : string, method : HTTP_METHOD = HTTP_METHOD.GET) => {
    const options : any = {}
    try
    {
        if (path !== '/auth/login' && path !== '/auth/register')
        {
            options.headers = {
                "Authorization": "Bearer " + Cookies.get("token")
            };
        }
        else
        {
            //Send a request to get a CSRF token to protect the endpoint
            await axios.get('/backend/sanctum/csrf-cookie');
        }

        const url = '/backend/api' + path;
        let res = null;

        if (method === HTTP_METHOD.GET)
        {
            if (data !== null)
            {
                options.params = data;
                res = await axios.get(url, options);
            }
            else
            {
                res = await axios.get(url, options);
            }
        }
        else if (method === HTTP_METHOD.POST)
        {
            res = await axios.post(url, data, options);
        }
        else if (method === HTTP_METHOD.PUT)
        {
            res = await axios.put(url, data, options);
        }
        else if (method === HTTP_METHOD.DELETE)
        {
            res = await axios.delete(url, options);
        }

        if (path === "/auth/login")
        {
            //res.data contains the full json message, res.data.data is
            //the data object within my api response (res.data is part of axios response object)
            Cookies.set('token', res.data.data.token);
        }

        return res.data;
    }
    catch (e)
    {
        throw e;

    }
}

export function InvalidCredentials()
{
    this.message = "Incorrect username/password used. Please try again";
}

export function APIError (e) {
    this.response = e.response.data;
    this.error_obj = e;
}

export function FormValidationError(errors)
{
    this.errors = errors;
    //this.name = "FormValidationError";
}

