import * as React from "react";
import TopNavBar from "./TopNavBar";
import PricingOptions from "./PricingOptions";
import {Link} from "react-router-dom";
import TopNav from "./TopNav";

function ChoosePlan()
{
    return (
        <div className='h-screen'>
            <TopNav />

            <div className='h-[calc(100vh-100px)] overflow-y-scroll pt-10 bg-[#f2f2f2] mx-2 pb-10'>
                <div className='w-3/4 ml-auto mr-auto'>
                    <h2 style={{color: '#0B0958', fontWeight: 'bold', textAlign: 'center'}}>
                        Stop relying on your customers reporting problems
                    </h2>

                    <p style={{fontWeight: 'bold', textAlign: 'center', marginTop: 20}}>
                        Your a couple of clicks away from a simple and lightweight crash and error reporting
                        service with an easy to way<br />to manage what's going wrong in production
                    </p>
                </div>
                <div className='w-full'>

                    <PricingOptions />

                    <p className='font-bold text-center'>
                        Already have an account, <Link to='/'>login here</Link>
                    </p>

                    <p className='text-center mt-5'>
                        Have any questions or need any help, not a problem, get in touch by sending us an email
                        to <a href='mailto://support@devso.io'>support@devso.io</a>.
                    </p>
                </div>
            </div>
        </div>

    )
}

export default ChoosePlan;