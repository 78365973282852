import * as React from "react";
import CrashSummaryBarChart from "../CrashSummaryBarChart";
import CrashGroupOccurrences from "../CrashGroupOccurrences";
import CrashComments from "../../CrashComments";
import { useMediaQuery } from 'react-responsive'
import {AffectedVersion, CrashGroup, ProjectMemberDetails} from "../../../Models/CrashDetails";

export type FlutterCrashGroupDetailsProps = {
    crashGroup: CrashGroup,
    project_id: string,
    project_type: string,
    //handleUserAssignmentClick: Function,
    selectedAssignmentDetails: ProjectMemberDetails,
    refreshCrashGroupData: Function,
    projectMembers: Array<ProjectMemberDetails>,
    affectedVersions: Array<AffectedVersion>,
    confirmDeletingComment: Function
}

function FlutterCrashGroupDetails(props: FlutterCrashGroupDetailsProps)
{
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    if (props.crashGroup !== null && typeof props.crashGroup !== typeof undefined) {
        return (
            <div>
                <div className={'flex ' + (isTabletOrMobileDevice ? 'flex-col' : 'flex-row')}>
                    <div className='tab-pane' id='summary' role='tabpanel'
                         aria-labelledby='summary-tab'>
                        <h2>Crash Group Summary</h2>

                        <div className='flex flex-row'>
                            <div className='flex-1 lg:w-full'>
                                <table className='w-full whitespace-pre-line'>
                                    <tbody>
                                    <tr>
                                        <td>First Incident:</td>
                                        <td>{props.crashGroup.first_occurrence}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Incident:</td>
                                        <td>{props.crashGroup.last_occurrence}</td>
                                    </tr>
                                    <tr>
                                        <td>Error Type:</td>
                                        <td>{props.crashGroup.exception_group}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Incidents:</td>
                                        <td>{props.crashGroup.count}</td>
                                    </tr>
                                    <tr>
                                        <td>Affected File/Line No:</td>
                                        <td>{props.crashGroup.class_script_name}:{props.crashGroup.line_no}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <h3>Affected Versions</h3>
                                <table className='table w-full'>
                                    <thead>
                                    <tr>
                                        <th>Version Name</th>
                                        <th>No. of Crashes</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.affectedVersions}
                                    </tbody>
                                </table>

                                <p style={{fontSize: 'small'}}>
                                    The first version in the table is the latest release
                                    affected by this crash group
                                </p>


                            </div>

                            <div className='flex-1 w-full'>

                                <CrashSummaryBarChart crash_group={props.crashGroup} project_id={props.project_id}/>
                            </div>
                        </div>

                    </div>

                    <div className='tab-pane fade' id='incidents' role='tabpanel' aria-labelledby='incidents-tab'>
                        <h2>Incidents</h2>

                        <CrashGroupOccurrences crashGroups={props.crashGroup} project_id={props.project_id}
                                               project_type={props.project_type}/>
                    </div>

                    <div className='tab-pane fade w-full ' id='comments' role='tabpanel' aria-labelledby='comments-tab'>
                        <h2 className='mt-5'>Comments</h2>
                        <CrashComments project_id={props.project_id} crash_group_id={props.crashGroup.crash_group_id}
                                       confirmDeletingComment={props.confirmDeletingComment} members={props.projectMembers}/>
                    </div>

                </div>

            </div>
        )
    }
    else {
        return null;
    }
}

export default FlutterCrashGroupDetails;