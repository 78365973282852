import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import Wrapper from "../Wrapper";
import {BreadcrumbNavLink} from "../BreadcrumbNav";
import ProjectPicker from "../Projects/ProjectPicker";
import {GetProjectAPIData} from "../../Models/Projects";
import useIssues from "../../Hooks/Issue/useIssues";
import {
    Loading,
    LoadingSize,
    ModalDialog,
    ModalState, returnDefaultModalState,
    returnErrorComponent,
    returnErrorModal
} from "devso-react-library";
import Footer from "../Footer";
import IssueCard from "./IssueCard";
import TimeRangePicker from "../TimeRangePicker";
import {ProjectTeamMembers} from "../../Models/ProjectTeamMembers";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import IssueTable from "./IssueTable";

export default function Issues(props) {

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Issues",
            link: ""
        }
    ]

    const [selectedTimeFrame, setSelectedTimeFrame] = useState('1 Day');
    const [selectedProject, setSelectedProject] = useState('0');
    const {issues, issueErrors, issuesLoading, getIssues} = useIssues(selectedTimeFrame);
    const maxCrashDetailsWidth = useRef(0);
    const [issueCards, setIssueCards] = useState([]);
    const [modalState, setModalState] = useState<ModalState>(null)

    const projectSelectionUpdated = (project: GetProjectAPIData) => {
        if (project === null)
        {
            setSelectedProject("0");
        }
        else
        {
            setSelectedProject(project.project_id);
        }
    }

    const buildIssueCards = () => {


    }

    useEffect(() => {
        (
            async () => {
                await getIssues(selectedTimeFrame)
            }
        )()
    }, [selectedTimeFrame])

    useEffect(() => {

        buildIssueCards();
    }, [issues, selectedProject])

    useEffect(() => {
        console.log("Issue Count Updated");
        maxCrashDetailsWidth.current = 0;
        const header = document.querySelector('[data-group="crash_details_header"]') as HTMLElement;
        if (header !== null && issueCards.length > 0) {

            const elements = document.querySelectorAll('[data-group="issue_card"]');
            elements.forEach(element => {
                console.log("Crash details client width: ", element.clientWidth)
                if (element.clientWidth > (maxCrashDetailsWidth.current + 150)) {

                    maxCrashDetailsWidth.current = element.clientWidth;
                }
            });

            console.log("Going to set the max width to: " + maxCrashDetailsWidth.current)
            if (header.clientWidth < (maxCrashDetailsWidth.current))
            {
                header.style.width = (maxCrashDetailsWidth.current) + "px";
            }


            elements.forEach(element => {
                const htmlElement = element as HTMLElement;
                if (htmlElement.clientWidth < (maxCrashDetailsWidth.current))
                {
                    console.log(`Updating crash width. Current client width = ${htmlElement.clientWidth} and max width = ${maxCrashDetailsWidth.current}`)
                    htmlElement.style.width = (maxCrashDetailsWidth.current) + "px";
                }

            })
            maxCrashDetailsWidth.current += 150;
        }
        else
        {
            console.log("Header is null");
        }
    }, [issueCards])

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <h1>Issues</h1>

                    <div className='inline-block'>
                        <ProjectPicker projectSelected={projectSelectionUpdated}
                               selectedProject={props.match.params.project_id ? props?.match?.params?.project_id : null} />
                    </div>
                    <div className='inline-block'>
                        <TimeRangePicker setTimeRangeSelected={setSelectedTimeFrame} />
                    </div>

                    {
                        (
                            () => {
                                if (issuesLoading)
                                {
                                    return <Loading size={LoadingSize.SMALL} />
                                }
                                else if (!issuesLoading && issueErrors !== null)
                                {
                                    return returnErrorComponent(issueErrors, "Failed to get issues");
                                }
                                else if (!issuesLoading && issues !== null)
                                {
                                    return (
                                        <IssueTable getIssues={() => getIssues(selectedTimeFrame)} issues={issues}
                                                    selectedTimeFrame={selectedTimeFrame}
                                                    project_id={selectedProject}
                                                    setModalState={setModalState} />
                                    )
                                }
                                else
                                {
                                    return null;
                                }
                            }
                        )()
                    }

                </ContentWrapper>
                <Footer/>
            </div>
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}