import * as React from "react";
import {useEffect, useState} from "react";
import {FaAngleDown} from "react-icons/fa";
import {IconType} from "react-icons";
import {generateRandomString} from "../JSFuncs/HelperFunctions";
import {Link} from "react-router-dom";

import '../ComponentStyles/DropdowMenu.css'

export type DropdownMenuProps = {
    onMenuItemClick?: Function,
    style?: any,
    icon?: string | IconType,
    textAndIconColor?: string,
    menuStyleOverride?: any,
    menuItems: Array<any>,
    title: string|JSX.Element,
    stroke?: string,
    fill?: string
}

function DropdownMenu(props: DropdownMenuProps)
{
    const [randomId] = useState(generateRandomString());
    //const [mounted, setMounted] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [mousePos, setMousePos] = useState({x: 0, y: 0});
    //const [currentSelectionTitle, setCurrentSelectionTitle] = useState('');

    useEffect(() => {

            document.querySelector("body").addEventListener('click', function () {
                // @ts-ignore
                const dropdownMenu = document.getElementById(randomId) as HTMLElement
                if (dropdownMenu !== null) {
                    dropdownMenu.style.visibility = "hidden";
                    if (menuVisible) {
                        setMenuVisible(false);
                    }
                }
            });
        //setMounted(true);
        return () => {
            document.querySelector("body").removeEventListener('click', null);
        }
    }, [randomId, menuVisible]);

    const showHideUserMenu = (event) => {

        event.stopPropagation();
        setMenuVisible(!menuVisible);

        let xPosition;
        let yPosition;

        xPosition = event.pageX;
        yPosition = event.pageY + 15;



        setMousePos({x: xPosition, y: yPosition});
    };

    const handleCustomClickHandler = (event, selectedItem, title) => {
        event.stopPropagation();
        event.preventDefault();
        setMenuVisible(false);
        //setCurrentSelectionTitle(title);
        props.onMenuItemClick(selectedItem, title);
    };

    const DropdownMenuIcon = (typeof props.icon === typeof undefined || props.icon === "downArrow") ?
        FaAngleDown : props.icon;

    const menuStyleOverride : any = {
        paddingRight: 5 + 'px'
    };

    if ((typeof props.textAndIconColor !== typeof undefined) && props.textAndIconColor.length > 0)
    {
        menuStyleOverride.color = props.textAndIconColor;
    }
    else
    {
        menuStyleOverride.color = "#ffffff";
    }

    const menuItems = [];
    if (typeof props.menuItems !== typeof undefined) {
        for (let i = 0; i < props.menuItems.length; i++) {
            let item = null;

            const target = (typeof props.menuItems[i].target === typeof undefined) ? '_self' : props.menuItems[i].target;

            if (typeof props.menuItems[i].link !== typeof undefined)
            {
                if (typeof props.menuItems[i].link === "string")
                {
                    if (props.menuItems[i].icon !== null)
                    {
                        //These are internal links so use React Link component
                        const MenuIcon = props.menuItems[i].icon
                        /*item = <a href={props.menuItems[i].link}
                                  title={props.menuItems[i].title}
                                  target={target}><MenuIcon className='inline ml-2' /> {props.menuItems[i].title}</a>*/
                        item = <Link to={props.menuItems[i].link}  title={props.menuItems[i].title}><MenuIcon className='inline mr-2' /> {props.menuItems[i].title}</Link>
                    }
                    else if ((props.menuItems[i].endIcon !== null) && typeof props.menuItems[i].endIcon !== typeof undefined)
                    {
                        //These are external links so we're ok to continue using an a href link
                        const MenuIcon = props.menuItems[i].endIcon
                        item = <a href={props.menuItems[i].link}
                                  title={props.menuItems[i].title}
                                  target={target}>{props.menuItems[i].title} <MenuIcon className='inline ml-2' /></a>
                    }
                    else
                    {
                        /*item = <a href={props.menuItems[i].link}
                                  title={props.menuItems[i].title}
                                  target={target}>{props.menuItems[i].title}</a>*/
                        item = <Link to={props.menuItems[i].link}
                                     title={props.menuItems[i].title}
                                     target={target}>{props.menuItems[i].title}</Link>
                    }
                }
                else
                {
                    if (typeof props.onMenuItemClick !== typeof undefined)
                    {
                        item = <button onClick={(e) => handleCustomClickHandler(e, props.menuItems[i].link, props.menuItems[i].title)}
                                  title={props.menuItems[i].title}
                                  style={{cursor: 'pointer'}}>{props.menuItems[i].title}</button>
                    }
                    else
                    {
                        item = <button onClick={props.menuItems[i].link}
                                  title={props.menuItems[i].title}
                                  style={{cursor: 'pointer'}}>{props.menuItems[i].title}</button>
                    }
                }
                menuItems.push(
                    <li key={i}>{item}</li>
                )
            }
            else
            {
                //If its not a link then it has to be a seperator
                menuItems.push(<hr key={i} className={props.menuItems[i].className} />);
            }
        }
    }

    useEffect(() => {
        const dropdownContainer = document.getElementById(randomId) as HTMLElement;
        if (dropdownContainer !== null)
        {
            if (menuVisible)
            {
                dropdownContainer.style.visibility = "visible";
                dropdownContainer.style.opacity = "1";
                if (mousePos.x - 200 < 0)
                {
                    dropdownContainer.style.left = (mousePos.x).toString() + "px";
                }
                else
                {
                    dropdownContainer.style.left = (mousePos.x - 200).toString() + "px";
                }

                const scrollOffset = dropdownContainer.scrollTop;

                dropdownContainer.style.top = (mousePos.y - scrollOffset).toString() + "px";
            }
            else
            {
                dropdownContainer.style.visibility = "invisible";
                dropdownContainer.style.opacity = "0";
                dropdownContainer.style.top = "0";
                dropdownContainer.style.left = "0";
            }
        }

    }, [menuVisible, mousePos, randomId]);


    return (
        <div onClick={showHideUserMenu}>
                <span className='userContainer'>
                    <span className='hidden md:inline' style={menuStyleOverride}>{props.title}</span>
                    <DropdownMenuIcon className='inline mt-4 md:mt-0' color={menuStyleOverride.color} />
                </span>
            <ul id={randomId} className='dropdownMenu text-left text-white'>
                {menuItems}
            </ul>
        </div>
    )
}



export default DropdownMenu;