import * as React from "react";
import {useEnvironment} from "../JSFuncs/HelperFunctions";
import TopNavBar from "./TopNavBar";

function Status()
{
    const environment = useEnvironment();
    
    return (
        <div className='container-fluid'
             style={{minHeight: 100 + '% !important', margin: 0 + ' !important', padding: 0 + ' !important'}}>

            <div className="mainContentNoSidebar">

                <TopNavBar displayTimePeriodButton={false} allowCreateProject={false} hideMenu={true} title='Crash Catch Service Status'/>

                    <iframe title='Crash Catch Status' style={{width: '80%', height: 800, margin: '0 auto', display: 'block', backgroundColor: '#efefef'}} frameBorder={0}
                            src={
                                environment === "production" ? "https://status.devso.io"
                                    : "http://status.home.local"
                            } />


            </div>
        </div>
    )
}

export default Status;