import * as React from "react";
import {FaCheck, FaEnvelope, FaPlus, FaTimes, FaTrash} from "react-icons/fa";
import {PendingTeamMembersProps} from "../../Models/ManageTeamMembers";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import OverflowMenu, {OverflowMenuItem} from "../OverflowMenu";
import {toast} from "react-toastify";
import { Link } from "react-router-dom";

function PendingTeamMembers(props: PendingTeamMembersProps)
{
    const buildPendingInvitesRow = () => {
        const pendingInvitesArr = [];
        if (props.pendingTeamMembers !== null && props.pendingTeamMembers.length > 0) {
            for (let i = 0; i < props.pendingTeamMembers.length; i++) {

                const overflowMenuItems: Array<OverflowMenuItem> = [];

                overflowMenuItems.push(
                    {
                        label: 'Resend Invitation Email',
                        link: () => resendInvitationFlag(props.pendingTeamMembers[i].pending_invite_id)
                    },
                    {
                        label: 'Delete Pending Invite',
                        link: () => props.confirmDeletePendingInvite(props.pendingTeamMembers[i].pending_invite_id)
                    }
                )


                const IsAdmin = props.pendingTeamMembers[i].member_type === "Admin" ? FaCheck : FaTimes
                const isAdminStyle = props.pendingTeamMembers[i].member_type === "Admin" ? {color: 'green'} : {color: 'red'};
                pendingInvitesArr.push(
                    <tr key={i}>
                        <td>{props.pendingTeamMembers[i].persons_name}</td>
                        <td className='hidden sm:table-cell '>{props.pendingTeamMembers[i].email}</td>
                        <td className='text-center'><IsAdmin style={isAdminStyle}/></td>
                        <td>{props.pendingTeamMembers[i].email_sent === 0 ? "Pending" : "Yes"}</td>
                        <td className='hidden md:table-cell text-center items-center cursor-pointer justify-items-center'>
                            <FaEnvelope className='text-center ml-auto mr-auto'
                                onClick={() => resendInvitationFlag(props.pendingTeamMembers[i].pending_invite_id)}/>
                        </td>
                        <td className='hidden md:table-cell text-red-700 text-center items-center cursor-pointer justify-items-center'>
                            <FaTrash className='ml-auto mr-auto text-center'
                                     onClick={() => props.confirmDeletePendingInvite(props.pendingTeamMembers[i].pending_invite_id)} />
                        </td>
                        <td className='sm:hidden'>
                            <OverflowMenu menu_items={overflowMenuItems} id={props.pendingTeamMembers[i].pending_invite_id} />
                        </td>
                    </tr>
                )
            }

        }
        else
        {
            pendingInvitesArr.push(
                <tr key={0}>
                    <td colSpan={6} className='italic text-center'>
                        There are no current pending invites
                    </td>
                </tr>
            )
        }
        return pendingInvitesArr;
    }

    const resendInvitationFlag = async (inviteId) => {
        const response = await sendRequest(null, '/invites/' + inviteId, HTTP_METHOD.POST);
        if (response.status !== 0)
        {
            props.handleAPIError(response);
        }
        else
        {
            toast.success("Pending invite has been successfully resent");
            //TODO Refresh the pending invite
        }
    }

    return (
        <div className='h-auto'>
            <h2 className='mt-5 mb-5'>Pending Team Members</h2>

            <Link to='/add-team-member' className='btn btn-link btn-custom-link mt-5 mb-5'><FaPlus className='inline' /> Add Team Member</Link>

            <table className='w-full mt-5 text-left h-fill'>
                <thead>
                <tr>
                    <th>Team Member's Name</th>
                    <th className='hidden sm:table-cell'>Email</th>
                    <th className='text-center'>Is Admin</th>
                    <th>Invite Sent</th>
                    <th className='hidden sm:table-cell text-center'>Resend Invite</th>
                    <th className='hidden sm:table-cell text-center'>Delete Invite</th>
                    <th className='sm:hidden'>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {buildPendingInvitesRow()}
                </tbody>
            </table>
        </div>
    )
}

export default PendingTeamMembers;