import * as React from "react";
import {DashboardStatsProps} from "../../Models/DashboardStatsProps";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";
import useActiveUserAnalysis from "../../Hooks/Dashboard/useActiveUserAnalysis";
import {Bar} from "react-chartjs-2";
import useCrashAnalysis from "../../Hooks/Dashboard/useCrashAnalysis";

export default function CrashAnalysis(props: DashboardStatsProps) {

    const {crashAnalysis, crashAnalysisError, crashAnalysisLoading, rawCrashAnalysis}
        = useCrashAnalysis(props.project_id, props.time_frame);

    const chartOptions = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:false,
                    min: 0,
                    //stepSize: 1,
                    autoSkip: true,
                    userCallback: function (label) {
                        if (Math.floor(label) === label){
                            return label;
                        }
                    }
                }
            }],
            dataset: {
                barPercentage: 0.4
            }
            /*xAxes: [{
                barPercentage: 0.4
            }]*/
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label;

                    if (tooltipItem.datasetIndex === 0)
                    {
                        label += ": " + tooltipItem.yLabel;
                    }
                    else if (tooltipItem.datasetIndex === 1) {
                        label = "Version " + rawCrashAnalysis.chart_data.version_name_release[tooltipItem.index] + " detected";
                    }
                    return label;
                }
            }
        }
    }

    return (
        <div className='bg-white w-[390px] md:w-[480px] h-[300px] inline-block p-4 border-gray-400 border-[1px] rounded mr-2 my-2'>
            <p className='font-bold'>Crashes Received</p>

            {
                (
                    () => {
                        if (crashAnalysisLoading)
                        {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!crashAnalysisLoading && crashAnalysisError !== null)
                        {
                            return returnErrorComponent(crashAnalysisError, "Failed to get crash analysis");
                        }
                        else if (!crashAnalysisLoading && crashAnalysis !== null)
                        {
                            return (
                                <Bar data={crashAnalysis} options={chartOptions} />
                            )
                        }
                        else
                        {
                            return null;
                        }
                    }
                )()
            }
        </div>
    )
}