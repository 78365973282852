import {ProjectTeamMembers} from "../Models/ProjectTeamMembers";
import {toast} from "react-toastify";
import {PrimaryButton, returnDefaultModalState, returnErrorModal, SecondaryButton} from "devso-react-library";
import * as React from "react";
import {modalStateFunc, UserDetails} from "../Models/Default";
import {HTTP_METHOD, sendRequest} from "./APIManager";

export const newAssigneeSelected = (newAssigneeDetails: ProjectTeamMembers, currentlyAssignedTo: number,
                                    currentlyAssignedToDetails: UserDetails,
                                    setModalState: modalStateFunc, project_id: string, crash_group_id: number,
                                    selectedTimeFrame: string,
                                    getIssues: (timeFrame: string) => void) => {

    if (newAssigneeDetails !== null && newAssigneeDetails.user_id === currentlyAssignedTo)
    {
        toast.warning(`${newAssigneeDetails.name} is already assigned to that issue`);
    }
    else {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.onClose = () => {
            setModalState(returnDefaultModalState());
        }
        tempModalState.title = "Are you sure"
        if (newAssigneeDetails === null) {
            tempModalState.content = `Are you sure you want to remove '${currentlyAssignedToDetails.name}' from this this issue?`;
        } else {
            tempModalState.content = `Are you sure you want to assign '${newAssigneeDetails.name}' to this issue?`
        }
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure"
                onClick={() => updateAssigneeRequest(newAssigneeDetails, project_id, crash_group_id,selectedTimeFrame,
                    setModalState, getIssues)}/>,
            <SecondaryButton label='No' onClick={() => setModalState(returnDefaultModalState())}/>
    ]
        setModalState({...tempModalState});
    }
}

const updateAssigneeRequest = async (assignee: ProjectTeamMembers, project_id: string, crash_group_id: number,
                                     selectedTimeFrame: string,
                                     setModalState: modalStateFunc, getIssues: (timeFrame: string) => void) => {
    try
    {
        const data = {
            user_id: assignee !== null ? assignee.user_id : 0
        }
        await sendRequest(data, `/crash/${project_id}/${crash_group_id}/assign_user`, HTTP_METHOD.POST);
        setModalState({...returnDefaultModalState()})
        if (getIssues !== null)
        {
            await getIssues(selectedTimeFrame)
        }
    }
    catch (err)
    {
        setModalState({...returnErrorModal(err, "Failed to assign user", setModalState)});
    }
}