import * as React from "react";
import {ReactJSDeviceDetails} from "../../../Models/DeviceDetails";
import {useEffect} from "react";

export default function ReactJSDevice(props: ReactJSDeviceDetails)
{

    return (
        <table className='dataTable'>
            <tbody>

                <tr>
                    <td>Browser:</td>
                    <td>{props.browser}</td>
                </tr>
                <tr>
                    <td>Browser Version:</td>
                    <td>{props.browser_version}</td>
                </tr>
                <tr>
                    <td>Width x Height:</td>
                    <td>
                        {props.browser_width_height}
                    </td>
                </tr>
                <tr>
                    <td>Locale</td>
                    <td>
                        {props.locale}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}