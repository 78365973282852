import * as React from "react";

import {FaChevronRight} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useGlobalState} from "./GlobalStateProvider";
export type BreadcrumbNavLink = {
    link: any,
    label: string
}

export type BreadcrumbNavProps = {
    menu_items: Array<BreadcrumbNavLink>
}


function BreadcrumbNav(props: BreadcrumbNavProps)
{
    const {globalState} = useGlobalState();

    const menuOutput = [];
    for (let i = 0; i < props.menu_items.length; i++)
    {
        const menu_item = props.menu_items[i];

        if (i < props.menu_items.length -1)
        {
            if (typeof menu_item.link === typeof "string") {
                // @ts-ignore
                //const link = <a className={i === 0 ? 'ml-4 sm:ml-0 z-50' : 'cursor-pointer'} key={menu_item.label} href={menu_item.link} title={menu_item.label}>{menu_item.label}</a>
                const link = <Link to={menu_item.link} title={menu_item.label}>{menu_item.label}</Link>
                menuOutput.push(
                    link
                )

            }
            else
            {
                const link = <button className={i === 0 ? 'ml-4 sm:ml-0 z-50 cursor-pointer' : 'cursor-pointer'}
                            key={menu_item.label} onClick={(e) => menu_item.link(e)}
                            title={menu_item.label}>{menu_item.label}</button>
                menuOutput.push(
                    link
                )
            }

            menuOutput.push(
                <FaChevronRight key={i} className='inline mb-1 mr-2 ml-2' />
            )
        }
        else
        {
            menuOutput.push(
                <span key={i}>{menu_item.label}</span>
            )
        }

    }

    //if ((typeof globalState.payment_failed !== typeof undefined && typeof globalState.trial !== typeof undefined)
        //&& !globalState.payment_failed.payment_time_required)
    //{
        return (
            <div className='pt-[5px] mb-6 whitespace-nowrap w-full h-[33px] overflow-x-auto overflow-y-hidden'>
                <div className='overflow-x-scroll w-auto whitespace-nowrap'>
                    {menuOutput}
                </div>
            </div>
        )
    //}
    //else
    //{
    //    return null;
    //}
}

export default BreadcrumbNav;