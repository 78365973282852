import * as React from "react";

function FlutterDeviceDetails(props)
{
    return (
        <table className='dataTable'>
            <tbody>
            {
                (
                    () => {
                        if (props.browser.length > 0)
                        {
                            return (
                                <>
                                    <tr>
                                        <td>Browser:</td>
                                        <td>{props.browser}</td>
                                    </tr>
                                    <tr>
                                        <td>Browser Version:</td>
                                        <td>{props.browser_version}</td>
                                    </tr>
                                    <tr>
                                        <td>Width x Height:</td>
                                        <td>
                                            {props.browser_width_height}
                                        </td>
                                    </tr>
                                </>
                            )
                        }
                        else
                        {
                            return (
                                <>
                                    <tr>
                                        <td>OSName:</td>
                                        <td>{props.os_name}</td>
                                    </tr>
                                    <tr>
                                        <td>OS Version:</td>
                                        <td>{props.os_version}</td>
                                    </tr>
                                    <tr>
                                        <td>Screen Resolution:</td>
                                        <td>{props.screen_resolution !== null ? props.screen_resolution : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Locale:</td>
                                        <td>{props.locale !== null ? props.locale : 'N/A'}</td>
                                    </tr>
                                </>
                            )
                        }
                    }
                )()
            }

            </tbody>
        </table>
    )
}

export default FlutterDeviceDetails;