import {useGlobalState} from "../Components/GlobalStateProvider";
import {useEffect, useState} from "react";
import {sendRequest} from "../JSFuncs/APIManager";

export default function useProfileImage() {
    const {globalState, setGlobalState} = useGlobalState();
    const [profileImage, setProfileImage] = useState('');

    const getProfileImage = async () => {
        if (globalState.profileImage === null || globalState.profileImage === undefined) {

                const response = await sendRequest(null, '/users/image');
                const temp = globalState;
                temp.profileImage = response.data.profile_image;
                setProfileImage(response.data.profile_image);
                setGlobalState({...temp})

        }
    }

    useEffect(() => {
        (
            async () => {
                await getProfileImage();
            }
        )()

    }, [])
    return profileImage;
}