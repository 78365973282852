import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {FaCaretDown, FaClock, FaProjectDiagram} from "react-icons/fa";
import {Loading, LoadingSize, returnErrorComponent, TextField, TextFieldType} from "devso-react-library";

export interface TimeRangePickerProps {
    setTimeRangeSelected: (timeRange: string) => void;
}

export default function TimeRangePicker(props: TimeRangePickerProps) {

    const [selectedTimeframe, setSelectedTimeframe] = useState('1 Day');
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');



    const clickListener = useRef(false);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setExpanded(!expanded);
    }

    const handleTimeRangeClick = (timeRange: string) => {
        setSelectedTimeframe(timeRange);
        props.setTimeRangeSelected(timeRange);
    }

    useEffect(() => {
        if (!clickListener.current)
        {
            const body = document.body;
            if (body !== null) {
                body.addEventListener("click", function () {
                    setExpanded(false);
                })

                clickListener.current = true;
            }
        }

        return (
            () => {
                if (clickListener.current)
                {
                    document.removeEventListener("click", function(){});
                }
            }
        )
    }, [])

    return (
        <>
            <div id='' className='bg-gray-200 rounded border-[1px] border-gray-600 w-auto inline-block px-4 py-2 cursor-pointer'
                 onClick={(e) => handleClick(e)}>
                <p className='inline w-full m-0 p-0'>{<><FaClock className='inline mr-4' />{selectedTimeframe}</>}</p>
                <span className='inline'><FaCaretDown className='inline ml-2 mb-1' /></span>
            </div>

            <div className={`${expanded ? "block opacity-100" : "opacity-0 hidden"}  transition-all ease-in-out duration-300 rounded min-w-[120px] clear absolute bg-gray-200 shadow-md shadow-gray-500`}>

                <ul className='p-0 m-0'>
                    <li className={`${"1 Hour" === selectedTimeframe ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                        onClick={() => handleTimeRangeClick("1 Hour")}>
                        1 Hour
                    </li>
                    <li className={`${"3 Hours" === selectedTimeframe ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                        onClick={() => handleTimeRangeClick("3 Hours")}>
                        3 Hours
                    </li>
                    <li className={`${"1 Day" === selectedTimeframe ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                        onClick={() => handleTimeRangeClick("1 Day")}>
                        1 Day
                    </li>
                    <li className={`${"1 Week" === selectedTimeframe ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                        onClick={() => handleTimeRangeClick("1 Week")}>
                        1 Week
                    </li>
                    <li className={`${"1 Month" === selectedTimeframe ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                        onClick={() => handleTimeRangeClick("1 Month")}>
                        1 Month
                    </li>
                    <li className={`${"3 Months" === selectedTimeframe ? "font-bold italic" : ""} flex flex-row py-3 px-4 cursor-pointer hover:bg-gray-300 transition-all ease-in-out`}
                        onClick={() => handleTimeRangeClick("3 Months")}>
                        3 Months
                    </li>

                </ul>
            </div>
        </>
    )
}