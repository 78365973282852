import * as React from "react";
import {LoadingProps, LoadingSize} from "../../Models/StandardComponents/Loading";

function Loading(props: LoadingProps)
{
    if (props.size === LoadingSize.SMALL)
    {
        return (
            <div className='inline'>
                <img className='inline-block pr-2 text-center ml-auto mr-auto' src='/images/loading.svg' />
                {
                    props.label !== null ? <p className='font-bold'>{props.label}</p> : null
                }
            </div>

        )
    }
    else
    {
        return (
            <div className='w-full items-center justify-items-center content-center text-center'>
                <img className='text-center content-center ml-auto mr-auto' src='/images/loading-large.gif' />
                {
                    props.label !== null ? <p className='font-bold'>{props.label}</p> : null
                }
            </div>
        )
    }
}

export default Loading