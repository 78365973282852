import * as React from "react";
import {sendRequest} from "../JSFuncs/APIManager";
import {CrashGroup} from "../Models/CrashDetails";
import {useEffect, useState} from "react";

export default function useProjectMembers_old(project_id, crashGroup) {
    const [selectedAssignmentDetails, setSelectedAssignmentDetails] = useState({
        userId: 0,
        assignmentName: 'Not Assigned'
    });
    const [projectMembers, setProjectMembers] = useState([]);

    const getProjectMembers = async (project_id: string, crashGroup: CrashGroup) => {
        try
        {
            const result = await sendRequest(null, `/projects/project/${project_id}/members`);
            if (result.status === 0)
            {
                let selectedAssignmentName = '';
                let selectedAssignmentId;
                const projectMembersArr = [];

                for (let i = 0; i < result.data.ProjectTeamMembers.length; i++) {
                    if ((typeof crashGroup !== typeof undefined) && crashGroup.assigned_to !== 0) {
                        if (result.data.ProjectTeamMembers[i].user_id === crashGroup.assigned_to) {
                            selectedAssignmentName = result.data.ProjectTeamMembers[i].name;
                            selectedAssignmentId = result.data.ProjectTeamMembers[i].user_id;
                        }
                    } else {
                        selectedAssignmentName = "Not Assigned";
                        selectedAssignmentId = 9;
                    }

                    projectMembersArr.push({
                        link: result.data.ProjectTeamMembers[i].user_id,
                        title: result.data.ProjectTeamMembers[i].name
                    });
                }
                setSelectedAssignmentDetails({
                    userId: selectedAssignmentId,
                    assignmentName: selectedAssignmentName
                });

                setProjectMembers([...projectMembersArr]);
            }
            else
            {
                throw result.response;
            }
        }
        catch (err)
        {
            console.error("useProjectMembers", err);
        }

    }

    useEffect(() => {
        (
            async function () {
                await getProjectMembers(project_id, crashGroup);
            }
        )()
    }, [project_id, crashGroup])

    return {selectedAssignmentDetails, projectMembers, setSelectedAssignmentDetails};
}