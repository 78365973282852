import * as React from "react";
import {DashboardStatsProps} from "../../Models/DashboardStatsProps";
import useNumberOfCrashErrors from "../../Hooks/Dashboard/useNumberOfCrashErrors";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";
import useNumberOfAffectedUsers from "../../Hooks/Dashboard/useNumberOfAffectedUsers";
import useStatusCounts from "../../Hooks/Dashboard/useStatusCounts";
import {Pie} from "react-chartjs-2";
import useSeverityCounts from "../../Hooks/Dashboard/useSeverityCounts";

export default function SeverityCounts(props: DashboardStatsProps) {

    const {severityError, severityLoading, severity} = useSeverityCounts(props.project_id, props.time_frame);

    return (
        <div className='w-[355px] h-[221px] bg-white p-4 border-gray-400 border-[1px] inline-block rounded mr-2 my-2'>
            <p className='font-bold'>Severity Counts</p>
            {
                (
                    () => {
                        if (severityLoading)
                        {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!severityLoading && severityError !== null)
                        {
                            return returnErrorComponent(severityError, "Failed to get crash count");
                        }
                        else if (!severityLoading && severity !== null)
                        {
                            if (severity.labels.length > 0)
                            {
                                return (
                                    <Pie data={severity} />
                                )
                            }
                            else
                            {
                                return <p className='text-center italic mt-5'>
                                    No crash data matches current filtering
                                </p>
                            }
                        }
                    }
                )()
            }
        </div>
    )
}