import * as React from 'react'
import { FaArrowLeft, FaBars } from 'react-icons/fa'
import { useState } from 'react'
import {NavLink, useLocation} from "react-router-dom";
import {NavMenu, NavItem} from "devso-react-library";
import {NavLinkProps} from "../Models/Nav";

export default function TopNav() {

    return (
        <div className='w-full border-b-[1px] border-b-primary bg-white'>

            <div className='w-3/4 mx-auto items-center content-center mx-auto justify-items-center'>
                <div className='flex flex-row content-center items-center mx-auto text-center w-fit'>
                    <div className='mr-3 inline'>
                        <img className='w-[120px]' src='/images/logo.png' alt='Crash Catch Logo' />
                    </div>
                    <div className='inline'>
                        <p className='font-bold text-primary'>
                            Crash reporting for every developer
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}
