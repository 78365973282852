import * as React from "react";
import {PrimaryButton, returnDefaultModalState, SecondaryButton} from "devso-react-library";
import {modalStateFunc} from "../../Models/Default";
import {FormEvent, useState} from "react";

export interface ConfirmIgnoreIssueModal {
    setModalState: modalStateFunc,
    confirmIgnoreIssue: (versionNumberAndBelowOnly: boolean) => void
}

export default function (props: ConfirmIgnoreIssueModal) {

    const [versionNumberAndBelowOnly, setVersionNumberAndBelowOnly] = useState(true);

    return (
        <>
            <p>
                Are you sure you want to stop notifications on this issue?
            </p>
            <p>
                You can choose to stop reporting on all past, present and future
                versions of this project receiving this issue or choose to only
                stop reporting on issues on the current latest version or before.
            </p>
            <p>
                If you choose to stop reporting on the latest issue or before, then
                you will no longer receive a notification for this issue unless it
                is seen on a new version.
            </p>

            <div onChange={(e : any) => setVersionNumberAndBelowOnly(e.target.value === "1")} className='flex flex-col my-3'>
                <div>
                    <input checked={true} className='my-2' type='radio' value='1' id='past_and_current_version' name='ignore_mode' /> Ignore Issue for Past and Current Versions
                </div>
                <div>
                    <input className='my-2' type='radio' value='0' id='all_versions' name='ignore_mode' /> Ignore Issue for All Versions
                </div>

            </div>
            <div className='text-right'>
                <SecondaryButton label='Cancel' onClick={() => {
                    props.setModalState({...returnDefaultModalState()})
                }
                } />
                <PrimaryButton label='Ignore This Issue' onClick={() => props.confirmIgnoreIssue(versionNumberAndBelowOnly)} />
            </div>

        </>
    )
}