import * as React from "react";
import {useState} from "react";
import {modalStateFunc} from "../../Models/Default";
import {
    Form, FormButtonContainer,
    FormInputDirection,
    PrimaryButton,
    returnDefaultModalState,
    returnErrorModal,
    SecondaryButton,
    TextField,
    TextFieldType
} from "devso-react-library";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {toast} from "react-toastify";

export interface SaveDevelopmentDevice {
    setModalState: modalStateFunc,
    device_id: string,
    project_id: string,
    crash_group_id: number,
    getIssues: (crash_group_id: number) => void
}

export default function SaveDevelopmentDevice(props: SaveDevelopmentDevice) {

    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    const [deviceName, setDeviceName] = useState('');

    const handleSubmit = async () => {
        try
        {
            setFormLoading(true);
            setFormError(null);
            const data = {
                device_name: deviceName,
                project_id: props.project_id,
                device_id: props.device_id
            }

            await sendRequest(data, `/dev-device/${props.project_id}`, HTTP_METHOD.POST)
            toast.success("Successfully saved dev device");
            props.setModalState({...returnDefaultModalState()});
            await props.getIssues(props.crash_group_id);
        }
        catch (err)
        {
            if (err?.response?.status === 422)
            {
                setFormError(err?.response?.data?.errors);
            }
            else
            {
                props.setModalState({...returnErrorModal(err, "Failed to save device", props.setModalState)});
            }
        }
        finally
        {
            setFormLoading(false);
        }
    }

    return (
        <>
            <p>
                Are you sure you want to mark this incident as from a development device?
            </p>
            <p>
                If a further incident is raised against this device in the future, you will not
                receive any further notification.
            </p>

            <div className='my-5'>
                <Form loading={formLoading} errors={formError} handleSubmit={handleSubmit} direction={FormInputDirection.Vertical}>
                    <TextField label='Device Name' value={deviceName} onChange={setDeviceName}
                               placeholder='Device Name - used as a reference for you'
                               api_field_name='device_name' type={TextFieldType.TEXT} />
                    <FormButtonContainer loading={formLoading}>
                        <SecondaryButton label='Cancel' onClick={() => props.setModalState({...returnDefaultModalState()})} />
                        <PrimaryButton label='Save Device' />
                    </FormButtonContainer>
                </Form>
            </div>
        </>
    )
}