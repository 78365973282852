import * as React from "react";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import ContentWrapper from "../ContentWrapper";
import ProjectActivities from "../Projects/ProjectActivities";
import ProjectPicker from "../Projects/ProjectPicker";
import TimeRangePicker from "../TimeRangePicker";
import {GetProjectAPIData} from "../../Models/Projects";
import {useState} from "react";
import NumberOfErrors from "./NumberOfErrors";
import ActiveUser from "./ActiveUser";
import CrashAnalysis from "./CrashAnalysis";
import NumberOfAffectedUsers from "./NumberOfAffectedUsers";
import StatusCounts from "./StatusCounts";
import HandledVsUnhandled from "./HandledVsUnhandled";
import SeverityCounts from "./SeverityCounts";
import Wrapper from "../Wrapper";
import { useMediaQuery } from 'react-responsive'

export default function Dashboard() {

    const [selectedProject, setSelectedProject] = useState("0");
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('1 Day');

    const projectSelectionUpdated = (project: GetProjectAPIData) => {
        if (project === null)
        {
            setSelectedProject("0");
        }
        else
        {
            setSelectedProject(project.project_id);
        }
    }

    const isMobile = useMediaQuery({
        query: '(max-device-width: 428px)'
    })

    const isTablet = useMediaQuery({
        query: '(min-device-width: 833px)'
    });

    const isLandscape = useMediaQuery({
        query: '(orientation: landscape)'
    });

    const isSmallLaptop = useMediaQuery({
        query: '(min-device-width:1439px)'
    });

    const isLargeDisplay = useMediaQuery({
        query: '(min-device-width: 1920px)'
    });

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>

                    <div className='flex flex-row'>

                        <div className={`mx-4 mb-6 w-full sm:w-full lg:!w-[calc(100%-370px)] 2xl:!w-[calc(100%-320px)]`}>
                            <h1>Dashboard</h1>

                            <div className='inline-block'>
                                <ProjectPicker projectSelected={projectSelectionUpdated} />
                            </div>
                            <div className='inline-block'>
                                <TimeRangePicker setTimeRangeSelected={setSelectedTimeFrame} />
                            </div>

                            <div className='!h-[calc(100vh-160px)] overflow-y-auto items-center justify-items-center content-center'>
                                {
                                    (
                                        () => {

                                            if (isLargeDisplay)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row'>
                                                            <NumberOfErrors project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <StatusCounts project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-row'>
                                                            <CrashAnalysis project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <HandledVsUnhandled project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else if (isSmallLaptop)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row'>
                                                            <NumberOfErrors project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <StatusCounts project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-row'>
                                                            <CrashAnalysis project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>
                                                        <div className='flex flex-row'>
                                                            <HandledVsUnhandled project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else if (isTablet)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row'>
                                                            <NumberOfErrors project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={selectedProject} time_frame={selectedTimeFrame} />

                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <CrashAnalysis project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <HandledVsUnhandled project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>

                                                    </>
                                                )
                                            }
                                            else if (isMobile)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row justify-items-center mx-auto mt-4'>
                                                            <NumberOfErrors project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <CrashAnalysis project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <HandledVsUnhandled project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={selectedProject} time_frame={selectedTimeFrame} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else
                                            {
                                                return (
                                                    <>
                                                        <p>Is Mobile: {isMobile ? "true" : "false"}</p>
                                                        <p>Is Tablet: {isTablet ? "true" : "false"}</p>
                                                        <p>Is Small Laptop: {isSmallLaptop ? "true" : "false"}</p>
                                                        <p>Is Large Display: {isLargeDisplay ? "true" : "false"}</p>
                                                    </>
                                                )
                                            }
                                        }
                                    )()
                                }
                            </div>


                        </div>

                        {
                            isLargeDisplay || isSmallLaptop || isLandscape ?
                                <div className={`lg:block sm:hidden hidden !w-[280px] px-4 !h-[calc(100vh-90px)] !overflow-y-auto border-l-[1px] border-gray-400`}>
                                    <ProjectActivities />
                                </div> : null
                        }


                    </div>

                </ContentWrapper>
                <Footer />
            </div>
        </Wrapper>
    )
}