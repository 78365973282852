import {ProjectType} from "./Projects";

export type CrashSidebarProps = {
    loading: boolean,
    project_id: string,
    crash_groups: Array<CrashGroup>,
    crashGroupSelected: Function,
    assignmentFilterUpdated: Function,
    crashTypeFilterUpdated: Function,
    crashStatusFilterUpdated: Function,
    assignedTo: AssignedTo,
    crashFilterType: CrashFilterType,
    crashFilterStatus: CrashFilterStatus,
    selectedCrashGroupID: number,
    searchTermUpdated: Function,
    projectMembers: Array<ProjectMemberFromAPI>
}

export type CrashGroupItemProps = {
    selected: boolean,
    handleCrashGroupSelected: Function,
    crash_group: any
}


export type CrashSummaryProps = {
    project_id: string,
    time_period: CrashSummaryTimePeriod,
    project_stats: ProjectStats,
    affected_files: Array<JSX.Element>
}

export enum CrashSummaryTimePeriod {
    "1 Day" = "1 Day",
    "1 Week" = "1 Week",
    "1 Month" = "1 Month"
}

export type ProjectStats = {
    new_error_stats: number,
    affected_users: number
}


export type ProjectMemberDetails = {
    userId: number,
    assignmentName: string
}

export type ProjectMemberFromAPI = {
    UserID: number,
    Name: string
}

export enum CrashFilterType {
    All,
    Handled,
    Unhandled
}

export enum CrashFilterStatus {
    All,
    Open,
    Resolved
}

export type AffectedVersion = {
    version_name: string,
    count: number,
    being_reported: number
}

export type CrashGroup = {
    crash_group_id?: number
    affected_versions?: Array<AffectedVersion>,
    count?: number,
    latest_version?: string,
    filter_id?: number
    first_occurrence: string,
    last_occurrence: string,
    exception_group: string,
    class_script_name: string,
    line_no: number,
    ClassFile?: string
    status?: string
    assigned_to?: number
}

type AssignedTo = {
    name: string
}

export type CrashTabCountProps = {
    incidentCount: number
}

export type Last24HoursCrash = {
    CrashGroupID: number,
    ExceptionGroup: string,
    ClassScriptName: string,
    ProjectID: string,
    ProjectName: string,
    ProjectType: ProjectType
}