import * as React from "react";
import {PHPDeviceDetails} from "../../../Models/DeviceDetails";

export default function PHPDevice(props: PHPDeviceDetails) {
    return (
        <table className='dataTable'>
            <tbody>
                <tr>
                    <td>PHP Version</td>
                    <td>{props.php_version}</td>
                </tr>
                <tr>
                    <td>Platform</td>
                    <td>{props.platform}</td>
                </tr>
                <tr>
                    <td>Uname</td>
                    <td className='break-words'>{props.uname}</td>
                </tr>
                <tr>
                    <td>Locale</td>
                    <td>{props.locale}</td>
                </tr>
            </tbody>
        </table>
    )
}