import * as React from "react";
import {useEffect, useState} from "react";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import TopNavBar from "../TopNavBar";
import {sendRequest} from "../../JSFuncs/APIManager";
import {Billing, BillingAPIResponse} from "../../Models/Billing";
import Loading from "../StandardComponents/Loading";
import {LoadingSize} from "../../Models/StandardComponents/Loading";
import BillingInfo from "./BillingInfo";
import {toast} from "react-toastify";
import {ModalDialog, ModalState,returnDefaultModalState, returnErrorModal} from "devso-react-library";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";

export default function AccountAndBilling()
{
    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: "Account & Billing",
            link: "/"
        }
    ]

    const [billingDetails, setBillingDetails] = useState<Billing>(null);
    const [modalState, setModalState] = useState<ModalState>(null);

    const getBillingInformation = async () => {
        try
        {
            const response : BillingAPIResponse = await sendRequest(null, '/billing');
            setBillingDetails(response.data);
        }
        catch (err)
        {
            setModalState(returnErrorModal(err, "Error Getting Billing", setModalState));
        }
    }



    useEffect(() => {
        (
            async function() {
             await getBillingInformation();
            }
        )()
    }, []);

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    {
                        billingDetails === null ?
                            <Loading size={LoadingSize.LARGE} label='Please wait...Getting your billing information' />
                            :
                            <div className='w-full md:w-1/2 ml-auto mr-auto'>
                                <h2 className='mb-5'>Billing Information</h2>
                                <BillingInfo billingInfo={billingDetails} refreshBillingInfo={getBillingInformation} />
                            </div>
                    }
                    <ModalDialog {...modalState} />
                </ContentWrapper>

            </div>

        </Wrapper>
    )

}