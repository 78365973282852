import {IncidentsProps} from "../../Models/Issues";
import {FaCaretLeft, FaCaretRight} from "react-icons/fa";
import {useState} from "react";
import ReactTooltip from "react-tooltip";
import {ProjectType} from "../../Models/Projects";
import FlutterDevice from "./DeviceDetails/FlutterDeviceDetails";
import ReactJSDevice from "./DeviceDetails/ReactJSDevice";
import {convertEloquentTimeToHumanReadable} from "../../JSFuncs/DataTimeManager";
import {DangerButton, ModalDialog, ModalState, returnDefaultModalState} from "devso-react-library";
import SaveDevelopmentDevice from "../Forms/SaveDevelopmentDevice";
import AndroidDevice from "./DeviceDetails/AndroidDeviceDetails";
import ReactJson from 'react-json-view'
import PHPDevice from "./DeviceDetails/PHPDeviceDetails";

export default function Incidents (props: IncidentsProps) {

    const [incidentIndex, setIncidentIndex] = useState(0);
    const [modalState, setModalState] = useState<ModalState>(null);

    const handlePreviousIncidentClick = () => {
        if (incidentIndex > 0)
        {
            const index = incidentIndex - 1;
            setIncidentIndex(index);
        }
    }

    const handleNextIncidentClick = () => {
        if (incidentIndex < props.incidents.length-1)
        {
            const index = incidentIndex + 1;
            setIncidentIndex(index);
        }
    }

    const showDevelopmentDeviceConfirmation = () => {
        const temp = returnDefaultModalState();
        temp.open = true;
        temp.onClose = () => {
            setModalState({...returnDefaultModalState()})
        }
        temp.title = "Confirm This is Development Device";
        temp.content = <SaveDevelopmentDevice setModalState={setModalState}
                              device_id={props.incidents[incidentIndex].device_id} project_id={props.incidents[incidentIndex].project_id}
                              crash_group_id={props.incidents[incidentIndex].crash_group_id}
                              getIssues={props.getIssue(props.incidents[incidentIndex].crash_group_id)}  />
        setModalState({...temp});
    }

    return (
        <>
            <div className='flex flex-row mt-6'>
                <button onClick={handlePreviousIncidentClick} data-tip='Previous Incident' disabled={incidentIndex === 0} className={`${incidentIndex === 0 ? "bg-gray-400" : "bg-gray-300"} rounded px-3 py-2 mx-3 text-center transition-all ease-in-out`}>
                    <FaCaretLeft className={`text-center mx-auto ${incidentIndex === 0 ? "text-gray-500" : null}`} />
                </button>
                <button onClick={handleNextIncidentClick} data-tip='Next Incident' disabled={incidentIndex === props.incidents.length-1} className={`${incidentIndex === props.incidents.length-1 ? "bg-gray-400" : "bg-gray-300"} bg-gray-300 rounded px-3 py-2 mx-3 text-center transition-all ease-in-out`}>
                    <FaCaretRight className={`text-center mx-auto ${incidentIndex === props.incidents.length-1 ? "text-gray-500" : null}`} />
                </button>
            </div>

            <div className='flex flex-col md:flex-row mt-6'>
                <div className='w-full md:w-3/4 md:max-w-3/4'>
                    <h2>Incident Details</h2>
                    {/** Desktop layout of incident*/}
                    <table className='hidden md:block dataTable'>
                        <tbody>
                            <tr>
                                <td className='w-[180px]'>Occurred At: </td>
                                <td>{convertEloquentTimeToHumanReadable(props.incidents[incidentIndex].created_at, true)}</td>
                            </tr>
                            <tr>
                                <td>Exception Message: </td>
                                <td>{props.incidents[incidentIndex].exception_message}</td>
                            </tr>
                            <tr>
                                <td>Affected Version: </td>
                                <td>{props.incidents[incidentIndex].version_name}</td>
                            </tr>
                            <tr>
                                <td>Stacktrace: </td>
                                {/**<td className='break-all'>{props.incidents[incidentIndex].stacktrace}</td>*/}
                                <td>
                                    {
                                        props.incidents[incidentIndex].stacktrace.split('\n').map(line => {
                                            if (line.trim().length > 0) {
                                                return (
                                                    <div className='text-sm bg-gray-200 px-3 py-2 border-[1px] border-gray-400'>
                                                        {line}
                                                    </div>
                                                )
                                            }
                                            else
                                            {
                                                return null;
                                            }
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Properties:</td>
                                <td>
                                    {
                                       props.incidents[incidentIndex].custom_properties !== null
                                       && props.incidents[incidentIndex].custom_properties.length > 0 ?
                                            <ReactJson src={ JSON.parse(props.incidents[incidentIndex].custom_properties)}
                                            enableClipboard={false} displayDataTypes={false} displayObjectSize={false}
                                                       name={false} quotesOnKeys={false}/> : "N/A"
                                    }


                                </td>
                            </tr>
                            {
                                props.incidents[incidentIndex].ip_address !== "N/A" ?
                                    <>
                                        <tr>
                                            <td>IP Address: </td>
                                            <td>{props.incidents[incidentIndex].ip_address}</td>
                                        </tr>
                                        <tr>
                                            <td>Country: </td>
                                            <td>{props.incidents[incidentIndex].country}</td>
                                        </tr>
                                        <tr>
                                            <td>City: </td>
                                            <td>{props.incidents[incidentIndex].city}</td>
                                        </tr>
                                    </> : null
                            }

                        </tbody>
                    </table>

                    {/** Mobile Layout of Incidents */}
                    <div className='block md:hidden mb-6'>
                        <p>
                            <span className='font-bold'>Occurred At</span><br />
                            {convertEloquentTimeToHumanReadable(props.incidents[incidentIndex].created_at, true)}
                        </p>
                        <p>
                            <span className='font-bold'>Exception Message</span><br />
                            {props.incidents[incidentIndex].exception_message}
                        </p>
                        <p>
                            <span className='font-bold'>Affected Version</span><br />
                            {props.incidents[incidentIndex].version_name}
                        </p>
                        <p>
                            <span className='font-bold'>Stacktrace</span><br />
                            <span className='break-all'>
                                {
                                    props.incidents[incidentIndex].stacktrace.split('\n').map(line => {
                                        if (line.trim().length > 0) {
                                            return (
                                                <div
                                                    className='text-sm bg-gray-200 px-3 py-2 border-[1px] border-gray-400'>
                                                    {line}
                                                </div>
                                            )
                                        }
                                        else
                                        {
                                            return null;
                                        }
                                    })
                                }
                            </span>
                        </p>
                        <p>
                            <span className='font-bold'>Custom Properties</span><br />
                            <div>
                                <pre>
                                    {JSON.stringify(props.incidents[incidentIndex].custom_properties, null, 2)}
                                </pre>
                            </div>
                        </p>
                    </div>

                </div>
                <div className='w-full md:w-1/2 md:max-w-1/2'>
                    <h2 className='inline'>Device Details</h2>

                    {
                        props.incidents[incidentIndex].is_dev_device ?
                            <p className='inline bg-primary text-white p-1 ml-3 text-sm'>From Development Device</p> : null
                    }

                    {
                        (
                            () => {
                                switch (props.project_type)
                                {
                                    case ProjectType.Android:
                                        return <AndroidDevice {...props.incidents[incidentIndex].device_details} />
                                    case ProjectType.ReactJS:
                                        return <ReactJSDevice {...props.incidents[incidentIndex].device_details} />
                                    case ProjectType.Flutter:
                                        return <FlutterDevice {...props.incidents[incidentIndex].device_details} />
                                    case ProjectType.PHP:
                                        return <PHPDevice {...props.incidents[incidentIndex].device_details} />
                                }
                            }
                        )()
                    }

                    {
                        !props.incidents[incidentIndex].is_dev_device ?
                            <DangerButton className='mt-6' label='Incident From Development Device' onClick={showDevelopmentDeviceConfirmation} />
                            : null
                    }

                </div>
            </div>


            <ReactTooltip />
            <ModalDialog {...modalState} />
        </>
    )
}