import * as React from "react";

function IOSDeviceDetails(props)
{
    return (
        <table className='dataTable'>
            <tbody>
                <tr>
                    <td>Device Name: </td>
                    <td>{props.device_name}</td>
                </tr>
                <tr>
                    <td>OS Name: </td>
                    <td>{props.os_name}</td>
                </tr>
                <tr>
                    <td>OS Version: </td>
                    <td>{props.os_version}</td>
                </tr>
                <tr>
                    <td>Mobile Carrier: </td>
                    <td>{props.mobile_network}</td>
                </tr>
                <tr>
                    <td>Screen Resolution: </td>
                    <td>{props.screen_resolution}</td>
                </tr>
                <tr>
                    <td>Locale: </td>
                    <td>{props.locale}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default IOSDeviceDetails;