import * as React from "react";
import ReactTooltip from "react-tooltip";

export interface CrashSeverityProps {
    severity: string,
    className?: string,
    dataTip?: string
}

export default function CrashSeverity(props: CrashSeverityProps) {
    return (
        <div data-tip={props.dataTip} className={`inline ${props.className}`}>
            {
                (
                    () => {
                        if (props.severity === "Low") {
                            return <span
                                className='px-2 py-1 bg-green-700 border-[1px] border-green-900 rounded text-white'>Low</span>
                        } else if (props.severity === "Medium") {
                            return <span
                                className='px-2 py-1 bg-yellow-700 border-[1px] border-yellow-900 rounded text-white'>Medium</span>
                        } else if (props.severity === "High") {
                            return <span
                                className='px-2 py-1 bg-red-700 border-[1px] border-red-900 rounded text-white'>High</span>
                        }
                    }
                )()
            }
            <ReactTooltip />
        </div>
    )
}