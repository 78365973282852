import * as React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import DashboardOld from "./Components/DashboardOld";
import 'devso-react-library/dist/styles.css'
import './BaseStylesheet.css';
import './ComponentStyles/BreadcrumbNav.css'
import NotFound from "./Components/NotFound";
import AddProject from "./Components/Projects/AddProject";
import FinalProjectSetup from "./Components/Projects/FinalProjectSetup";
import CrashDetails from "./Components/CrashDetailsComponents_old/CrashDetails";
import ManageTeamMembers from "./Components/TeamMembers/ManageTeamMembers";
import AddTeamMember from "./Components/TeamMembers/AddTeamMember";
import { toast } from 'react-toastify';
import isDev from 'react-is-dev';
import 'react-toastify/dist/ReactToastify.min.css';
import RegisterNewUser from "./Components/RegisterNewUser";
import Settings from "./Components/Settings";
import ProjectSettings from "./Components/Projects/ProjectSettings";
import Login from "./Components/Login";
import ChoosePlan from "./Components/ChoosePlan";
import SignUp from "./Components/SignUp";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useEffect} from "react";
import ForgottenPassword from "./Components/ForgottenPassword";
import ResetPassword from "./Components/ResetPassword";
import Status from "./Components/Status";
import {initialiseCrashCatch} from "./JSFuncs/HelperFunctions";
import AccountAndBilling from "./Components/AccountAndBilling/AccountAndBilling";
import {GlobalStateProvider, useGlobalState} from "./Components/GlobalStateProvider";
import BaseComponent from "./Components/BaseComponent";
import TermsOfService from "./Components/TermsOfService";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TrialOrPaymentBanner from "./Components/TrialOrPaymentBanner";
import ProjectDashboard from "./Components/Projects/ProjectDashboard";
import Dashboard from "./Components/Dashboard/Dashboard";
import Activities from "./Components/Activities/Activities";
import Issues from "./Components/Issues/Issues";
import ViewIssue from "./Components/Issues/ViewIssue";
import ProjectDetails from "./Components/Projects/ProjectDetails";
import UpdatePlan from "./Components/AccountAndBilling/UpdatePlan";
import {CrashCatch, CrashCatchProvider} from "crashcatchlib-reactjs";
import ErrorBoundary from "./Components/ErrorBoundary";

function App() {

    const crash_catch = new CrashCatch();
    crash_catch.initialiseCrashCatch("18769032", "uoaqjrs75hb6txip4n9f2glce", "3.0.0");

    const ifDev = isDev(React);
    toast.configure({
        position: 'top-center',
        hideProgressBar: true
    });


    const stripePromise = loadStripe(!ifDev ? 'pk_live_TCmuTJWnmctkcSElpFoxe8I1' : "pk_test_FjLekyO3DCIy1bRAYqg4bMyc");
    const { setGlobalState } = useGlobalState();

    useEffect(() => {

    }, []);

    return (
        <Elements stripe={stripePromise}>
            <CrashCatchProvider value={crash_catch}>
                <ErrorBoundary>
                    <GlobalStateProvider>
                        <BaseComponent>
                            <BrowserRouter>

                                <TrialOrPaymentBanner />
                                <div className='h-full'>
                                    <Switch>
                                        <Route path="/" render={(props) => <Login {...props}  /> } exact />
                                        <Route path="/login/:msg?/:redirect?" render={(props) => <Login {...props} /> } exact />
                                        <Route path="/choose-plan" render={() => <ChoosePlan /> } exact />
                                        <Route path="/signup/:plan" render={(props) => <SignUp {...props} /> } exact />
                                        <Route path="/dashboard" render={() => <Dashboard  /> } exact />
                                        <Route path="/project-settings/:project_id" render={(props) => <ProjectSettings {...props} /> } />
                                        <Route path="/settings" render={() => <Settings /> } exact />
                                        <Route path='/add-project' render={() => <AddProject  /> } exact />
                                        <Route path='/final-project-setup/:platform/:project_id/:api_key' render={(props) => <FinalProjectSetup {...props}  /> } />
                                        <Route path='/crash-details/:project_id/:project_type/:crash_group_id?/:comment_id?' render={(props) => <CrashDetails {...props} /> } />
                                        <Route path='/team-members' render={() => <ManageTeamMembers  /> } />
                                        <Route path='/add-team-member' render={() => <AddTeamMember  /> } />
                                        <Route path='/register-new-user/:invite_params?' render={(props) => <RegisterNewUser {...props} /> } />
                                        <Route path='/account-billing' render={() => <AccountAndBilling /> } exact />
                                        <Route path='/forgotten-password' render={() => <ForgottenPassword /> } exact />
                                        <Route path='/reset-password/:resetPasswordData' render={(props) => <ResetPassword {...props} /> } />
                                        <Route path='/terms-of-service' render={() => <TermsOfService />} />
                                        <Route path='/privacy-policy' render={() => <PrivacyPolicy /> } />
                                        <Route path='/status' render={() => <Status /> } />

                                        <Route path='/project-dashboard' render={() => <ProjectDashboard /> } />
                                        <Route path='/project-details/:project_id' render={(props) => <ProjectDetails {...props} /> } />
                                        <Route path='/activities' render={() => <Activities /> } />
                                        <Route path='/issues/:project_id?' render={(props)=> <Issues {...props} /> } />
                                        <Route path='/issue/:crash_group_id' render={(props) => <ViewIssue {...props } /> } />
                                        <Route path='/update-plan/:current_plan' render={(props) => <UpdatePlan {...props} />} />

                                        <Route render={() => <NotFound  />}/>
                                    </Switch>
                                </div>
                            </BrowserRouter>
                        </BaseComponent>

                    </GlobalStateProvider>
                </ErrorBoundary>
            </CrashCatchProvider>
        </Elements>
    );
}

export default App;
