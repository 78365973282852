import * as React from "react";
import {useEffect, useState} from "react";
import {sendRequest} from "../JSFuncs/APIManager";
import {GetProjectAPIData} from "../Models/Projects";

export default function useProjectDetails(project_id) {

    const [projectDetails, setProjectDetails] = useState<GetProjectAPIData>(null);
    const getProjectDetails = async (project_id) => {

        try
        {
            const result = await sendRequest(null, `/projects/project/${project_id}`);
            if (result.status === 0)
            {
                setProjectDetails(result.data);
            }
            else
            {
                throw result.response.data;
            }
        }
        catch (err)
        {
            console.error("useProjectDetails", err);
        }


        // getProjectDetails(project_id).then(function(result){
        //     setProjectDetails(result);
        // }).catch(function(err){
        //     console.error('useProjectDetails', err);
        //     //setAPIResult(err);
        // });
    }

    useEffect(() => {
        (
            async function () {
                await getProjectDetails(project_id);
            }
        )()
    }, [project_id]);

    return projectDetails;

}