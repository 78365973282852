import * as React from "react";
import {ProjectInstructionsTypeProps} from "../Projects/FinalProjectSetup";

function CustomInstructions(props: ProjectInstructionsTypeProps)
{
    return (
        <div>
            <div className='text-center items-center justify-items-center content-center'>
                <img className='ml-auto mr-auto' style={{width: 120}} src='/images/logo.png' alt='Custom SDK' />
            </div>

            <h2>Installation</h2>

            <p className='font-bold'>
                Below you will find your project settings, you will need these to send
                initialisation and crash requests within your crash reporting library.
            </p>
            <p>
                <span style={{fontWeight: 'bold'}}>Project ID: </span> {props.project_id}
            </p>
            <p>
                <span style={{fontWeight: 'bold'}}>API Key: </span> {props.api_key}
            </p>

            <p>
                For information as to how to create your own crash reporting library then you can check
                our <a href='https://docs.crashcatch.com' rel='noopener noreferrer' target='_blank' title='Crash Catch Documentation'>documentation site</a>.
            </p>
        </div>
    )
}

export default CustomInstructions;