import * as React from "react";
import {useState} from "react";
import TopNavBar from "./TopNavBar";
import {HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import ReCAPTCHA from "react-google-recaptcha";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnDefaultModalState, returnErrorModal,SecondaryButton
} from "devso-react-library";

function ForgottenPassword()
{
    const [email, setEmail] = useState('');
    const [recaptcha, setRecaptcha] = useState('');
    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
    const [modalState, setModalState] = useState(null);

    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const sendPasswordResetRequest = async (postArray) => {

        /*const postArray = {
            email: email,
            captchaResponse: recaptcha
        };*/
        postArray.captchaResponse = recaptcha;

        try
        {
            setFormLoading(true);
            setFormErrors(null);
            await sendRequest(postArray, '/account-management/password-reset', HTTP_METHOD.POST);
            setEmail('');
            setPasswordResetSuccessful(true);
        }
        catch (err)
        {
            if (err.response?.status === 404)
            {
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = "Account Not Found";
                tempModalState.content = "No account was found with your email address";
                tempModalState.buttons = [
                    <SecondaryButton label='Dismiss' onClick={() => {
                        const temp = returnDefaultModalState();
                        setModalState({...temp});
                    }} />
                ]
                setModalState({...tempModalState});
            }
            else if (err.response?.status === 422)
            {
                if (typeof err.response?.data?.errors !== typeof undefined)
                {
                    setFormErrors(err.response.data.errors);
                }
                else
                {
                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Password Reset Request Failed";
                    tempModalState.content = err.response.data.message;
                    tempModalState.buttons = [
                        <SecondaryButton label='Dismiss' onClick={() => {
                            const temp = returnDefaultModalState();
                            setModalState({...temp});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        finally {
            setFormLoading(false);
        }

    }


    return (
        <div className='wrapper'>

            <TopNavBar hideCreateProject={true} hideMenu={true} displayTimePeriodButton={false} title='Forgotten My Password' />
            <div className="mainContentNoSidebar">

                <div className='w-1/2 h-1/2 m-auto'>

                    <div className='flex flex-col sm:flex-row'>
                        <div>
                            <img src='/images/logo.png' style={{width: 240}} alt='Crash Catch Logo' />
                        </div>
                        <div>
                            <p className='font-bold text-2xl mt-8 ml-10'>
                                Crash reporting for every developer, every platform and every programming language
                            </p>
                        </div>
                    </div>

                        {
                            !passwordResetSuccessful ? <div className='mt-10'>
                                <p>
                                    If you have forgotten your password, enter the email address associated with your
                                    account below and you will be able to reset your password
                                </p>

                                <Form handleSubmit={sendPasswordResetRequest} errors={formErrors} loading={formLoading}>
                                    <TextField type={TextFieldType.EMAIL} label="Email" placeholder='Enter your email address' api_field_name='email' value={email} onChange={setEmail} />

                                    <div className='form_group_recaptcha'>
                                        <ReCAPTCHA
                                            sitekey="6Lev9s0ZAAAAANopO71AnVTAdVVKjYWrY09oV1rL"
                                            onChange={setRecaptcha} />
                                    </div>

                                    <FormButtonContainer loading={formLoading}>
                                        <PrimaryButton label="Send Password Reset Request" />
                                    </FormButtonContainer>
                                </Form>

                                {/*<form onSubmit={sendPasswordResetRequest}>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>Email Address:</label>
                                        <div className='col-sm-9'>
                                            <ValidatedInput type='email' placeholder='someone@example.com' id='txtEmail'
                                                            value={email}
                                                            validateError='Please enter your email address'
                                                            onChange={setEmail} validationCompleted={validationCompleted} />
                                        </div>
                                    </div>

                                    <div className='form_group_recaptcha'>
                                        <ReCAPTCHA
                                            sitekey="6Lev9s0ZAAAAANopO71AnVTAdVVKjYWrY09oV1rL"
                                            onChange={setRecaptcha} />
                                    </div>
                                    <div className='formButtonContainer'>
                                        <button type='submit' disabled className='btn btn-primary'>Send Password Reset Request</button>
                                    </div>
                                </form>*/}
                            </div> :
                                <div className='text-center mt-20' style={{textAlign: 'center'}}>
                                    <h2>Password Reset Successful</h2>
                                    <p className='font-bold'>
                                        Please check your email for instructions on how to change your password
                                    </p>
                                </div>
                        }



                </div>
            </div>

            <ModalDialog {...modalState} />
        </div>
    )
}

export default ForgottenPassword;