import {useEffect, useState} from "react";
import {sendRequest} from "../JSFuncs/APIManager";
import {GetProjectAPIData, GetProjectAPIResponse} from "../Models/Projects";

export default function useProjects() {

    const [projects, setProjects] = useState<Array<GetProjectAPIData>>(null);
    const [projectsLoading, setProjectsLoading] = useState(false);
    const [projectError, setProjectError] = useState(null);

    const getProjects = async () => {
        try
        {
            setProjectsLoading(true);
            setProjectError(null);

            const response : GetProjectAPIResponse = await sendRequest(null, '/projects');
            setProjects(response.data);
        }
        catch (err)
        {
            setProjectError(err);
        }
        finally {
            setProjectsLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getProjects();
            }
        )()
    }, [])

    return {projects, projectsLoading, projectError, getProjects}
}