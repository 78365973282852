import * as React from "react";
import {ProjectCardProps} from "../../Models/Projects";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import OverflowMenu, {OverflowMenuItem} from "../OverflowMenu";
import {useHistory} from "react-router-dom";
import {Bar} from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import {DangerButton, returnDefaultModalState, returnErrorModal, SecondaryButton} from "devso-react-library";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {toast} from "react-toastify";

export default function Projectcard(props: ProjectCardProps)
{
    const history = useHistory();
    const overflowMenuItems: Array<OverflowMenuItem> = [];
    overflowMenuItems.push(
        {
            label: 'Project Settings',
            link: () => handleProjectSettingsClick(props.project_details.project_id, history)
        },
        {
            label: 'Delete Project',
            link: () => handleDeleteMenuClick(props.project_details.project_id),
            itemBackgroundColor: '#AB1C00',
            textColor: '#ffffff'
        }
    );

    const handleProjectSettingsClick = (project_id, history) => {
        //window.location.href = "/project-settings/" + project_id;
        //window.history.replaceState({}, '', '/project-settings/' + project_id);

        history.push(`/project-settings/${project_id}`);
    }

    const handleDeleteMenuClick = (project_id: string) => {
        const temp = returnDefaultModalState();
        temp.open = true;
        temp.onClose = () => {
            props.setModalState({...returnDefaultModalState()})
        }
        temp.title = "Are you sure";
        temp.content = <>
            <p>
                Are you sure you want to delete this project?
            </p>
            <p className='font-bold'>
                Note: Deleting the project will remove all associated crash data and this cannot be undone
            </p>
        </>
        temp.buttons = [
            <DangerButton label="Yes...I'm sure" onClick={() => {
                deleteProject(project_id)
            }} />,
            <SecondaryButton label='No' onClick={() => {
                props.setModalState({...returnDefaultModalState()})
            }} />
        ]
        props.setModalState({...temp});
    }

    const deleteProject = async (project_id: string) => {
        try
        {
            await sendRequest(null, `/projects/${project_id}`, HTTP_METHOD.DELETE);
            toast.success("Successfully deleted project");
            await props.getProjects();
            props.setModalState({...returnDefaultModalState()});
        }
        catch (err)
        {
            props.setModalState({...returnErrorModal(err, "Failed to delete project", props.setModalState)});
        }
    }

    /*const handleDeleteMenuClick = useCallback((project_id) => {

    }, [props]);*/

    return (
        <div className='bg-white p-4 rounded border-gray-400 shadow shadow-gray-400/80 m-3 w-[340px] md:w-[420px] h-[280px]'>
            <div className='flex flex-row cursor-pointer' onClick={() => history.push(`/project-details/${props.project_details.project_id}`)}>
                <div className='mr-4 align-middle mt-2'>
                    {
                        getProjectIconFromString(props.project_details.project_type)
                    }
                </div>
                <div className='w-full text-sm text-gray-500'>
                    <p className='p-0 m-0 text-md font-bold text-black'>{props.project_details.project_name}</p>
                    <p className=' p-0 m-0'>
                        <span data-tip="Errors in the last 24 hours">Errors: {props.project_details.last_day_crash_count}</span>&nbsp;|&nbsp;
                        <span>Project ID: </span>{props.project_details.project_id}
                    </p>
                </div>
                <div>
                    <OverflowMenu menu_items={overflowMenuItems} id={props.project_details.project_id}/>
                </div>
            </div>
            <div className='w-full'>
                <Bar width={420} data={props.project_details.stats} options={{
                    plugins: {
                        legend: {

                            display: false
                        },
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.yLabel;
                            }
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: false
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    precision: 0,
                                    beginAtZero: true
                                },
                            }
                        ]
                    }
                }}/>
            </div>
            <p className='font-bold text-gray-500 text-sm pb-0 mb-0 mt-4'>
                Crash Free Sessions
            </p>
            <p className='text-3xl p-0 m-0'>
                {
                    props.project_details.crash_free_sessions === -1 ? "-" : props.project_details.crash_free_sessions + '%'
                }
            </p>
            <ReactTooltip />
        </div>
    )
}