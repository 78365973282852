import * as React from "react";
import ActiveUserAnalysis from "./ActiveUserAnalysis";
import CrashCountChart from "./CrashCountChart";
import ReactTooltip from "react-tooltip";
import ProjectActivities from "../Projects/ProjectActivities";
import {CrashSummaryProps} from "../../Models/CrashDetails";

function CrashSummary(props: CrashSummaryProps) {

    return (
        <div>

            <div className='flex flex-col sm:flex-row' style={{marginTop: 20 + 'px'}}>
                <div className='flex-1'>
                    <ActiveUserAnalysis time_period={props.time_period} project_id={props.project_id}/>
                </div>
                <div className='flex-1'>
                    <CrashCountChart time_period={props.time_period} project_id={props.project_id}/>
                </div>
            </div>

            <div className='flex flex-col sm:flex-row' style={{paddingBottom: 20 + 'px'}}>
                <div className='flex-1'>
                    <h2>New Errors</h2>
                    <p style={{fontSize: 25}}>
                        {props.project_stats !== null ? props.project_stats.new_error_stats : 0}
                    </p>
                </div>

                <div className='flex-1'>
                    <h2>Affected Users</h2>
                    <p style={{fontSize: 25}}>
                        {props.project_stats !== null ? props.project_stats.affected_users : 0}
                    </p>
                </div>
            </div>

            <div className='flex flex-col sm:flex-row' style={{paddingTop: 20 + 'px'}}>
                <div className='flex-1'>
                    <h2>Affected files</h2>
                    <ul>
                        {
                            props.affected_files !== null && props.affected_files.length > 0 ?
                                props.affected_files : <span className='italic'>There are no affected files</span>
                        }
                    </ul>
                </div>
                <div className='flex-1'>
                    <ProjectActivities project_id={props.project_id} />
                </div>
            </div>

            <ReactTooltip/>
        </div>
    )
}

export default CrashSummary;