import * as React from "react";
import {useState} from "react";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {toast} from "react-toastify";
import {Loading, LoadingSize, ModalDialog, ModalState, returnDefaultModalState} from "devso-react-library";
import {useHistory} from "react-router-dom";
import PricingOptions from "../PricingOptions";

export interface UpdatePlanProps {
    params: {
        match: {
            current_plan
        }
    }
}

export default function UpdatePlan(props) {
    const [modalState, setModalState] = useState<ModalState>(null);
    const history = useHistory();

    const updatePricePlan = async (pricePlan) => {

        const postArray = {
            price_plan: pricePlan
        }

        try
        {
            const temp = returnDefaultModalState();
            temp.title = "Please Wait";
            temp.onClose = () => {
                setModalState({...returnDefaultModalState()});
            }
            temp.buttons = [];
            temp.content = <Loading size={LoadingSize.SMALL} label='Updating your plan...please wait' />
            setModalState({...temp});

            await sendRequest(postArray, '/billing/update-plan', HTTP_METHOD.POST);
            toast.success("Successfully updated price plan");
            //props.refreshBillingInfo();
            setModalState({...returnDefaultModalState()});
            history.push('/account-billing');
        }
        catch (err)
        {
            //const tempModalState = returnErrorModal(err, "Error Changing Plan", setModalState);
            //setModalState({...tempModalState});
        }
    }

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <h1>Updating Your Plan</h1>

                    <PricingOptions changePlanOptions={true}
                                        currentPlan={props.match.params.current_plan.toLowerCase()}
                                        updatePricePlan={updatePricePlan}/>
                </ContentWrapper>
            </div>
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}