import * as React from "react";

export type PHPDeviceDetailsProps = {
    php_version: string,
    platform: string,
    uname: string,
    screen_resolution: string,
    locale: string,
}

function PHPDeviceDetails(props: PHPDeviceDetailsProps)
{
    return (
        <table className='dataTable'>
            <tbody>
                <tr>
                    <td>PHP Version: </td>
                    <td>{props.php_version}</td>
                </tr>
                <tr>
                    <td>Platform: </td>
                    <td>{props.platform}</td>
                </tr>
                <tr>
                    <td>UName: </td>
                    <td>{props.uname}</td>
                </tr>
                <tr>
                    <td>Locale: </td>
                    <td>{props.locale}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default PHPDeviceDetails;