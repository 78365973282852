import * as React from "react";

export type AndroidDeviceDetailsProps = {
    rom_build: string,
    kernel_version: string,
    device_brand: string,
    device_model: string,
    api_level: number,
    mobile_network: string,
    screen_resolution: string,
    locale: string
}

function AndroidDeviceDetails(props)
{
    return (
        <table className='dataTable'>
            <tbody>
            <tr>
                <td>ROM Build:</td>
                <td>{props.rom_build}</td>
            </tr>
            <tr>
                <td>Kernel Version:</td>
                <td>{props.kernel_version}</td>
            </tr>
            <tr>
                <td>Device Brand:</td>
                <td>{props.device_brand}</td>
            </tr>
            <tr>
                <td>Device Model:</td>
                <td>{props.device_brand}</td>
            </tr>
            <tr>
                <td>Android API Level:</td>
                <td>{props.api_level}</td>
            </tr>
            <tr>
                <td>Mobile Network:</td>
                <td>{props.mobile_network}</td>
            </tr>
            <tr>
                <td>Screen Resolution:</td>
                <td>{props.screen_resolution !== null ? props.screen_resolution : 'N/A'}</td>
            </tr>
            <tr>
                <td>Locale:</td>
                <td>{props.locale !== null ? props.locale : 'N/A'}</td>
            </tr>
            </tbody>
        </table>
    )
}

export default AndroidDeviceDetails;