import React, {createContext, useState, useContext, Dispatch, SetStateAction, useEffect} from "react";
export interface GlobalStateInterface {
    trial: {
        in_trial: boolean,
        days_of_trial_remaining: number
    }
    payment_failed: {
        last_payment_failed: boolean,
        payment_time_required: number,
        last_payment_failed_reason: string,
        payment_required: boolean
    },
    trialOrPaymentBannerShow: boolean,
    profileImage: string|null
}

export const getDefaultGlobalState = () : GlobalStateInterface => {
    return {
        trial: {
            in_trial: false,
            days_of_trial_remaining: 0
        },
        payment_failed: {
            last_payment_failed: false,
            payment_time_required: 0,
            last_payment_failed_reason: '',
            payment_required: false,
        },
        trialOrPaymentBannerShow: false,
        profileImage: null
    }
}

const GlobalStateContext = createContext({
    globalState: {} as Partial<GlobalStateInterface>,
    setGlobalState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

const GlobalStateProvider = ({
                                 children,
                                 value = {} as GlobalStateInterface,
                             }: {
    children: React.ReactNode;
    value?: Partial<GlobalStateInterface>;
}) => {
    const [globalState, setGlobalState] = useState(value);

    return (
        <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("useGlobalState must be used within a GlobalStateContext");
    }
    return context;
};

export { GlobalStateProvider, useGlobalState };