import * as React from "react";
import {FaCheck} from "react-icons/fa";
import { Link } from "react-router-dom";

export type PricingOptionsProps = {
    changePlanOptions?: boolean,
    currentPlan?: string,
    updatePricePlan?: (pricePlan) => void
}


export default function PricingOptions(props: PricingOptionsProps) {

    const planFeatures = {
        solo: [
            "10,000 reports per a month",
            "1 month data retention",
            "Real time email notifications on new crash reports",
            "Post custom crash/error reports to our simple reporting API"
        ],
        startup: [
            "30,000 reports per a month",
            "3 month data retention",
            "Real time email notifications on new crash reports",
            "Post custom crash/error reports to our simple reporting API"
        ],
        pro: [
            "100,000 reports per a month",
            "6 month data retention",
            "Real time email notification on new crash reports",
            "Post custom crash/error reports to our simple reporting API"
        ],
        pro_plus: [
            "1M reports per a months",
            "12 month data retention",
            "Real time email notifications on new crash reports",
            "Post custom crash/error reports to our simple reporting API"
        ]
    }

    const Feature : React.FC<{feature: string}> = (props: {feature: string}) => {
        return (
            <div className='flex flex-row py-1'>
                <div className='inline-block mr-2 pt-1'>
                    <FaCheck className='text-primary' />
                </div>
                <div className='inline-block'>
                    <span>
                        {props.feature}
                    </span>
                </div>
            </div>
        )
    }

    const Price : React.FC<{fullPrice: number, betaPrice: number}> = (props: {fullPrice: number, betaPrice: number}) => {
        return (
            <p className='font-bold text-center mt-5'>
                <span className='line-through mr-3'>£{props.fullPrice}</span>&nbsp;
                <span className='text-3xl'>£{props.betaPrice}</span>
            </p>
        )
    }

    const Signup : React.FC<{plan: string, currentPlan: string, changingPlan: boolean, updatePricePlan: (pricePlan: string) => void}> =
        (props: {plan: string, currentPlan: string, changingPlan: boolean, updatePricePlan: (pricePLan: string) => void}) => {
        console.log("Props Current Plan: ", props.currentPlan);
        if (props.currentPlan !== null && props.plan.toLowerCase() === props.currentPlan.toLowerCase())
        {
            return (
                <div className='text-center mx-auto my-10'>
                    <p className='w-fit mx-auto text-center text-black px-4 py-3 rounded text-white font-bold text-x shadow-md active:shadow-none border-[1px] border-primary-dark'>
                        Your Current Plan
                    </p>
                </div>
            )
        }
        else {
            return (
                <div className='text-center my-10'>
                    {
                        props.changingPlan ?
                            <button
                                className='bg-gradient-to-r from-[#100B96] to-[#1653FD] px-4 py-3 rounded text-white font-bold text-x shadow-md active:shadow-none border-[1px] border-primary-dark'
                                onClick={() => props.updatePricePlan(props.plan)}>
                                Choose this plan
                            </button> :
                            <Link to={`/signup/${props.plan}`}
                                  className='bg-gradient-to-r from-[#100B96] to-[#1653FD] px-4 py-3 rounded text-white font-bold text-x shadow-md active:shadow-none border-[1px] border-primary-dark'>
                                Signup
                            </Link>
                    }
                </div>

            )
        }
    }

    return (
        <>
            <p className='text-center font-bold'>
                {
                    props.changePlanOptions ? <>
                            Change your price plan whenever you want, upgrade and downgrade your price plan
                            as your needs change.
                        </> :
                        <>
                            Charged monthly, cancel any time, no questions asked
                        </>

                }

            </p>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 pb-10 w-fit items-center justify-items-center mx-auto content-center'>
                <div className='w-[300px] p-3 bg-gray-200 border-[1px] border-gray-600 rounded shadow-md my-2 mx-5'>
                    <p className='text-2xl font-bold text-center text-primary'>Solo</p>
                    {
                        planFeatures.solo.map(feature => {
                            return <Feature feature={feature} />
                        })
                    }
                    <Price fullPrice={10.00} betaPrice={5.00} />
                    <Signup plan='solo' currentPlan={props.currentPlan}
                            changingPlan={props.changePlanOptions} updatePricePlan={props.updatePricePlan} />
                </div>

                <div className='w-[300px] p-3 bg-gray-200 border-[1px] border-gray-600 rounded shadow-md my-2 mx-5'>
                    <p className='text-2xl font-bold text-center text-primary'>Startup</p>
                    {
                        planFeatures.startup.map(feature => {
                            return <Feature feature={feature} />
                        })
                    }
                    <Price fullPrice={20.00} betaPrice={10.00} />
                    <Signup plan='startup' currentPlan={props.currentPlan}
                            changingPlan={props.changePlanOptions} updatePricePlan={props.updatePricePlan} />
                </div>

                <div className='w-[300px] p-3 bg-gray-200 border-[1px] border-gray-600 rounded shadow-md my-2 mx-5'>
                    <p className='text-2xl font-bold text-center text-primary'>Pro</p>
                    {
                        planFeatures.pro.map(feature => {
                            return <Feature feature={feature} />
                        })
                    }
                    <Price fullPrice={30.00} betaPrice={15.00} />
                    <Signup plan='pro' currentPlan={props.currentPlan}
                            changingPlan={props.changePlanOptions} updatePricePlan={props.updatePricePlan} />
                </div>

                <div className='w-[300px] p-3 bg-gray-200 border-[1px] border-gray-600 rounded shadow-md my-2 mx-5'>
                    <p className='text-2xl font-bold text-center text-primary'>Pro+</p>
                    {
                        planFeatures.pro_plus.map(feature => {
                            return <Feature feature={feature} />
                        })
                    }
                    <Price fullPrice={50.00} betaPrice={25.00} />
                    <Signup plan='pro_plus' currentPlan={props.currentPlan}
                            changingPlan={props.changePlanOptions} updatePricePlan={props.updatePricePlan} />
                </div>
            </div>
        </>
    )
}

PricingOptions.defaultProps = {
    changePlanOptions: false,
    currentPlan: null,
    updatePricePlan: null
}