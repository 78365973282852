import * as React from "react";
import {DashboardStatsProps} from "../../Models/DashboardStatsProps";
import useNumberOfCrashErrors from "../../Hooks/Dashboard/useNumberOfCrashErrors";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";
import useNumberOfAffectedUsers from "../../Hooks/Dashboard/useNumberOfAffectedUsers";
import useStatusCounts from "../../Hooks/Dashboard/useStatusCounts";
import {Pie} from "react-chartjs-2";
import useHandledVsUnhandled from "../../Hooks/Dashboard/useHandledVsUnhandled";
import { useMediaQuery } from 'react-responsive'

export default function HandledVsUnhandled(props: DashboardStatsProps) {

    const {handledVsUnhandled, handledVsUnhandledError, handledVsUnhandledLoading} = useHandledVsUnhandled(props.project_id, props.time_frame)

    const isLargeDisplay = useMediaQuery({
        query: '(min-device-width: 1920px)'
    });

    return (
        <div className='w-[355px] h-[221px]  bg-white p-4 border-gray-400 border-[1px] inline-block rounded mr-2 my-2'>
            <p className='font-bold'>Handled Vs Unhandled</p>
            {
                (
                    () => {
                        if (handledVsUnhandledLoading)
                        {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!handledVsUnhandledLoading && handledVsUnhandledError !== null)
                        {
                            return returnErrorComponent(handledVsUnhandledError, "Failed to get crash count");
                        }
                        else if (!handledVsUnhandledLoading && handledVsUnhandled !== null)
                        {
                            if (handledVsUnhandled.labels.length > 0)
                            {
                                if (isLargeDisplay) {
                                    return <Pie data={handledVsUnhandled}/>
                                }
                                else
                                {
                                    return <Pie data={handledVsUnhandled}/>
                                }
                            }
                            else
                            {
                                return <p className='text-center italic mt-5'>
                                    No crash data matches current filtering
                                </p>
                            }
                        }
                    }
                )()
            }
        </div>
    )
}