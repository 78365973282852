import * as React from "react";
import {useEffect, useRef, useState} from "react";
import ContentWrapper from "../ContentWrapper";
import Sidebar from "../Sidebar";
import Wrapper from "../Wrapper";
import useIssue from "../../Hooks/Issue/useIssue";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import {
    Loading,
    LoadingSize,
    ModalDialog,
    ModalState,
    PrimaryButton,
    returnDefaultModalState,
    returnErrorComponent,
    returnErrorModal,
    SecondaryButton
} from "devso-react-library";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import AssigneePicker from "../AssigneePicker";
import {convertEloquentTimeToHumanReadable} from "../../JSFuncs/DataTimeManager";
import CrashSeverity from "../CrashSeverity";
import ReactTooltip from "react-tooltip";
import IssueTab from "../IssueTab";
import Issues from "./Issues";
import Incidents from "./Incidents";
import CrashGroupSummary from "./CrashGroupSummary";
import {newAssigneeSelected} from "../../JSFuncs/IssueHelper";
import SmallButtonWithMenu from "../SmallButtonWithMenu";
import {ButtonMenuItem} from "../../Models/SmallButtonWithMenuProps";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {toast} from "react-toastify";
import {CrashStatus} from "../../Models/Issues";
import {FaBell, FaBellSlash, FaCheck, FaCheckCircle} from "react-icons/fa";
import CrashComments from "./CrashComments";
import useProjectMembers from "../../Hooks/useProjectMembers";
import IssueActivities from "./IssueActivities";
import ConfirmIgnoreIssueModal from "../Forms/ConfirmIgnoreIssueModal";

export default function ViewIssue(props) {

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Issues",
            link: "/issues"
        },
        {
            label: "View Issue",
            link: ""
        }
    ]


    const crash_group_id = props.match.params.crash_group_id;

    const {issue, issueLoading, issueError, getIssue} = useIssue(crash_group_id);

    const affectedUsers = useRef(0);
    const totalIncidents = useRef(0);
    const firstOccurrence = useRef(null);
    const lastOccurrence = useRef(null);

    const [selectedTab, setSelectedTab] = useState('Incidents');
    const [modalState, setModalState] = useState<ModalState>(null);

    const {projectMembers, projectMembersError, projectMembersLoading, getProjectMembers} = useProjectMembers(null);

    const confirmIssueStatusUpdate = (label: string) => {
        const temp = returnDefaultModalState();
        temp.open = true;
        temp.onClose = () => {
            setModalState({...returnDefaultModalState()});
        }
        temp.title = "Are you sure";
        temp.content = <>
            Are you sure you want to assign this issue from <span className='font-bold'>{issue.status}</span>&nbsp;
            to <span className='font-bold'>{label}</span>
        </>
        temp.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => updateCrashState(label)} />,
            <SecondaryButton label='No' onClick={() => {
                setModalState({...returnDefaultModalState()})
            }} />
        ]
        setModalState({...temp});

    }


    const updateCrashState = async (status: string) => {
        try
        {
            const data = {
                crash_status: status
            }
            await sendRequest(data, `/issues/${issue.project_id}/${issue.crash_group_id}/status`, HTTP_METHOD.PUT);
            setModalState({...returnDefaultModalState()});
            toast.success("Issue status successfully updated");
            await getIssue(issue.crash_group_id);
        }
        catch (err)
        {
            setModalState({...returnErrorModal(err, "Failed to update issue status", setModalState)})
        }
    }

    const statusMenuClick = (item: ButtonMenuItem) => {
        confirmIssueStatusUpdate(item.labelIdentifier);
    }

    const resolveMenuItems : Array<ButtonMenuItem> = [
        {
            label: "New",
            labelIdentifier: "New",
            itemClick: statusMenuClick
        },
        {
            label: "In Progress",
            labelIdentifier: "In Progress",
            itemClick: statusMenuClick
        },
        {
            label: "Under Review",
            labelIdentifier: "Under Review",
            itemClick: statusMenuClick
        },
        {
            label: "Testing",
            labelIdentifier: "Testing",
            itemClick: statusMenuClick
        }
    ]

    useEffect(() => {
        (
            async () => {
                await getProjectMembers(issue?.project_id)
            }
        )()
    }, [issue])

    useEffect(() => {
        if (issue !== null)
        {
            affectedUsers.current = 0;

            //Latest incident is in the list first, so the first incident will be at the end of the array
            firstOccurrence.current = issue.incidents[issue.incidents.length-1].created_at;
            lastOccurrence.current = issue.incidents[0].created_at;

            totalIncidents.current = issue.incidents.length;

            const temp = [];

            issue.incidents.forEach(incident => {
                if (!temp.includes(incident.device_id))
                {
                    temp.push(incident.device_id);
                    affectedUsers.current++;
                }
            })
        }

        ReactTooltip.rebuild();

    }, [issue])

    const showIgnoreIssueConfirmation = () => {
        if (issue.filter_id === 0)
        {
            const temp = returnDefaultModalState();
            temp.open = true;
            temp.onClose = () => {
                setModalState({...returnDefaultModalState()});
            }
            temp.title = "Ignore This Issue"
            temp.content = <ConfirmIgnoreIssueModal confirmIgnoreIssue={submitIgnoreIssueRequest} setModalState={setModalState} />
            setModalState({...temp});
        }
        else
        {
            const temp = returnDefaultModalState();
            temp.open = true;
            temp.onClose = () => {
                setModalState({...returnDefaultModalState()})
            }
            temp.title = "Receive Notification For This Issue"
            temp.content = <>
                <p>
                    Are you sure you want to receive notifications again for this issue.
                </p>
                <p>
                    If confirmed, you will receive notifications on any versions of this project
                </p>
            </>
            temp.buttons = [
                <PrimaryButton label="Yes...I'm sure" onClick={() => submitIgnoreIssueRequest(null)} />,
                <SecondaryButton label='No' onClick={() => setModalState({...returnDefaultModalState()})} />
            ]
            setModalState({...temp});
        }
    }

    const submitIgnoreIssueRequest = async (versionNumberAndBelowOnly) => {
        try
        {
            let data = null
            if (versionNumberAndBelowOnly !== null)
            {
                data = {
                    filter_crash_group_id: issue.filter_id > 0 ? issue.filter_id : null,
                    latest_version_and_below: versionNumberAndBelowOnly ? issue.incidents[0].version_name : 0
                }
            }
            else
            {
                data = {
                    filter_crash_group_id: issue.filter_id,
                    latest_version_and_below: null
                }
            }

            await sendRequest(data, `/crash/${issue.project_id}/${issue.crash_group_id}/stop_reporting_crash_group`, HTTP_METHOD.POST);
            setModalState({...returnDefaultModalState()});
            if (versionNumberAndBelowOnly !==  null)
            {
                if (versionNumberAndBelowOnly)
                {
                    toast.success("You will now only receive a notification for future versions of this project")
                }
                else
                {
                    toast.success("You will no longer receive notifications for this issue for this project");
                }
            }
            else
            {
                toast.success("You will receive notifications for this issue again")
            }
            await getIssue(issue.crash_group_id);
        }
        catch (err)
        {
            setModalState({...returnErrorModal(err, "Failed to ignore this issue", setModalState)});
        }
    }

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <BreadcrumbNav menu_items={breadcrumbNav} />
                    {
                        (
                            () => {
                                if (issueLoading)
                                {
                                    return <Loading size={LoadingSize.SMALL} />
                                }
                                else if (!issueLoading && issueError !== null)
                                {
                                    return returnErrorComponent(issueError, "Failed to get issue details");
                                }
                                else if (!issueLoading && issue !== null && projectMembers !== null)
                                {
                                    return (
                                        <>
                                            <div className='flex flex-row'>
                                                <div>
                                                    {
                                                        getProjectIconFromString(issue.project_details.project_type)
                                                    }
                                                </div>
                                                <div className='ml-4'>
                                                    <h1 className='pb-0 text-2xl'>{issue.exception_group} -&nbsp;
                                                    <span className='text-gray-500 text-sm pb-6'>{issue.class_script_name} on line {issue.line_no}</span></h1>

                                                    <h2 className='p-0 m-0 font-bold inline'>{issue.incidents[0].exception_message}</h2>
                                                    {/** Desktop view of crash type and severity */}
                                                    <div className='hidden md:inline'>
                                                        {
                                                            issue.crash_type === 'Handled' ?
                                                                <span className='ml-6 px-2 py-1 bg-green-700 border-[1px] border-green-900 rounded text-white'>Handled</span> :
                                                                <span className='ml-6 px-2 py-1 bg-red-700 border-[1px] border-red-900 rounded text-white'>Unhandled</span>

                                                        }
                                                        <CrashSeverity dataTip='Crash Severity' className='ml-3' severity={issue.severity} />
                                                    </div>

                                                    {/** Mobile view of crash type and severity */}
                                                    <div className='block md:hidden mt-2'>
                                                        {
                                                            issue.crash_type === 'Handled' ?
                                                                <span className='px-2 py-1 bg-green-700 border-[1px] border-green-900 rounded text-white'>Handled</span> :
                                                                <span className='px-2 py-1 bg-red-700 border-[1px] border-red-900 rounded text-white'>Unhandled</span>

                                                        }
                                                        <CrashSeverity dataTip='Crash Severity' className='ml-3' severity={issue.severity} />
                                                    </div>

                                                </div>


                                            </div>

                                            <div className='flex flex-row mt-6 items-center content-center justify-items-center mb-12'>

                                                <div className='flex flex-col md:flex-row'>

                                                    <div className='flex flex-row'>
                                                        <div className='ml-5 md:ml-12'>
                                                            <p className='text-center text-lg font-bold'>
                                                                First Occurrence
                                                            </p>
                                                            <p className='text-center'>
                                                                {convertEloquentTimeToHumanReadable(firstOccurrence.current, true)}
                                                            </p>
                                                        </div>

                                                        <div className='ml-5 md:ml-12'>
                                                            <p className='text-center text-lg font-bold'>
                                                                Last Occurrence
                                                            </p>
                                                            <p className='text-center'>
                                                                {convertEloquentTimeToHumanReadable(lastOccurrence.current, true)}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className='flex flex-row mt-3 md:mt-0'>
                                                        <div className='w-full ml-5 md:ml-12'>
                                                            <p className='text-center text-lg font-bold'>
                                                                Total Incidents
                                                            </p>
                                                            <p className='text-center'>
                                                                {totalIncidents.current}
                                                            </p>
                                                        </div>

                                                        <div className='w-full ml-5 md:ml-12'>
                                                            <p className='text-center text-lg font-bold'>
                                                                Affected Users
                                                            </p>
                                                            <p className='text-center'>
                                                                {affectedUsers.current}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className='flex flex-row mt-3 md:mt-0'>
                                                        <div className='w-full ml-5 md:ml-12'>
                                                            <p className='text-center text-lg font-bold'>
                                                                Current Status
                                                            </p>
                                                            <div className='text-center mx-auto items-center justify-items-center content-center'>
                                                                {issue.status}
                                                            </div>
                                                        </div>

                                                        <div className='w-full ml-0 md:ml-12'>
                                                            <p className='text-center text-lg font-bold'>
                                                                Assigned To
                                                            </p>
                                                            <div className='text-center mx-auto items-center justify-items-center content-center ml-16 md:ml-8'>
                                                                <AssigneePicker new_selected_assignee={issue.assigned_to}
                                                                                currently_assigned_to={issue.assigned_to}
                                                                                current_assigned_to_details={issue.assigned_to_details}
                                                                                setModalState={setModalState}
                                                                                crash_group_id={issue.crash_group_id}
                                                                                project_id={issue.project_id}
                                                                                newAssigneeSelected={newAssigneeSelected}
                                                                                getIssues={() => getIssue(issue.crash_group_id)}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className='ml-3 flex flex-row'>
                                                <SmallButtonWithMenu label=
                                                    {
                                                        issue.status !== CrashStatus.Resolved ?
                                                            <><FaCheck className='inline mr-2' /> Resolve</>
                                                        : <><FaCheckCircle data-tip='Issue is resolved' className='text-green-700 mt-1 text-center' /></>
                                                    }

                                                         labelIdentifier='Resolve'
                                                         menuEnabled={true}
                                                         defaultLabelEnabled={issue.status !== CrashStatus.Resolved}
                                                         defaultLabelClick={() => confirmIssueStatusUpdate('Resolved')}
                                                         items={resolveMenuItems}/>
                                                <SmallButtonWithMenu label={issue.filter_id === 0 ? <><FaBell className='text-primary-light inline mr-2' /> Ignore This Crash Group</> :
                                                    <><FaBellSlash className='text-primary-light inline mr-2' /> Receive Notifications for Issue</>}
                                                         labelIdentifier='Ignore'
                                                         defaultLabelClick={showIgnoreIssueConfirmation}/>
                                            </div>


                                            <div className='flex flex-wrap border-b-[1px] border-b-gray-400'>
                                                <IssueTab tabSelected={setSelectedTab} label="Incidents"
                                                          hasBadgeIcon={false}
                                                          badgeCount={issue.incidents.length}
                                                          selected={selectedTab === "Incidents"} />
                                                <IssueTab tabSelected={setSelectedTab} label="Crash Group Summary"
                                                          hasBadgeIcon={false}
                                                          selected={selectedTab === "Crash Group Summary"} />
                                                <IssueTab tabSelected={setSelectedTab} label="Comments"
                                                          hasBadgeIcon={true}
                                                          badgeCount={issue.comments.length}
                                                          selected={selectedTab === "Comments"} />
                                                <IssueTab tabSelected={setSelectedTab} label="Issue Activities"
                                                          hasBadgeIcon={false}
                                                          selected={selectedTab === "Issue Activities"} />
                                            </div>

                                            {
                                                (
                                                    () => {
                                                        switch (selectedTab)
                                                        {
                                                            case "Incidents":
                                                                return <Incidents incidents={issue.incidents}
                                                                    project_type={issue.project_details.project_type}
                                                                    getIssue={getIssue}/>
                                                            case "Crash Group Summary":
                                                                return <CrashGroupSummary {...issue} />
                                                            case "Comments":
                                                                return <CrashComments comments={issue.comments}
                                                                    crash_group_id={issue.crash_group_id}
                                                                    project_id={issue.project_id}
                                                                    setModalState={setModalState}
                                                                    getIssue={getIssue}
                                                                    project_members={projectMembers}/>
                                                            case "Issue Activities":
                                                                return <IssueActivities project_id={issue.project_id}
                                                                                        crash_group_id={issue.crash_group_id} />
                                                            default:
                                                                return (
                                                                    <p className='text-center font-bold italic'>
                                                                        Something has gone wrong
                                                                    </p>
                                                                )
                                                        }
                                                    }
                                                )()
                                            }
                                        </>
                                    )
                                }
                                else
                                {
                                    return null;
                                }
                            }
                        )()
                    }
                </ContentWrapper>
            </div>
            <ReactTooltip />
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}