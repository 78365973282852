import * as React from "react";
import {AssigneePickerProps} from "../Models/AssigneePickerProps";
import {useEffect, useRef, useState} from "react";
import useProjectMembers from "../Hooks/useProjectMembers";
import {FaCaretDown, FaUser, FaUserSlash} from "react-icons/fa";
import {ProjectTeamMembers} from "../Models/ProjectTeamMembers";
import ReactTooltip from "react-tooltip";

export default function AssigneePicker(props: AssigneePickerProps) {

    const [selectedAssignee, setSelectedAssignee] = useState(props.new_selected_assignee);
    const [selectedAssigneeDetails, setSelectedAssigneeDetails] = useState<ProjectTeamMembers>(null)
    const {projectMembers, projectMembersError, projectMembersLoading} = useProjectMembers(props.project_id)
    const [expanded, setExpanded] = useState(false);
    const clickListener = useRef(false);

    useEffect(() => {
        if (projectMembers !== null && selectedAssignee !== 0)
        {
            for (let i = 0; i < projectMembers.length; i++)
            {
                if (projectMembers[i].user_id === selectedAssignee)
                {
                    setSelectedAssigneeDetails(projectMembers[i]);
                    break;
                }
            }
        }
    }, [projectMembers, props.new_selected_assignee])

    const handleShowAndHide = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setExpanded(!expanded);
    }

    useEffect(() => {
        if (!clickListener.current)
        {
            const body = document.body;
            if (body !== null) {
                body.addEventListener("click", function () {
                    setExpanded(false);
                })

                clickListener.current = true;
            }
        }

        return (
            () => {
                if (clickListener.current)
                {
                    document.removeEventListener("click", function(){});
                }
            }
        )
    }, [])

    return (
        <>
            <div className='mx-auto w-full flex flex-row cursor-pointer' onClick={(e) => handleShowAndHide(e)}>
                <div className='inline text-center'>
                    {
                        (
                            () => {
                                if (props?.new_selected_assignee === 0)
                                {
                                    return (
                                        <div className='text-center w-full'>
                                            <FaUser className='mx-auto text-center w-[32px] h-[32px]' data-tip='Not Assigned' />
                                        </div>
                                    )
                                }
                                else
                                {
                                    if (selectedAssigneeDetails?.profile_image === "")
                                    {
                                        return (
                                            <div data-tip={selectedAssigneeDetails?.name} className='w-[32px] h-[32px] text-white bg-primary-light rounded-full align-text-bottom p-1 text-white mr-2 mt-2'>
                                                {
                                                    selectedAssigneeDetails.name[0].toUpperCase()
                                                }
                                            </div>
                                        )
                                    }
                                    else
                                    {
                                        return (
                                            <div className='text-center mx-auto w-full'>
                                                <img data-tip={selectedAssigneeDetails?.name} className='text-center mx-auto align-middle rounded w-[32px] h-[32px]'
                                                     src={selectedAssigneeDetails?.profile_image} alt='Profile Image' />
                                            </div>
                                        )
                                    }
                                }
                            }
                        )()
                    }
                </div>
                <FaCaretDown className='inline align-middle mt-2 ml-1' />
            </div>
            <ul className={`${expanded ? "absolute" : "hidden"} bg-gray-200 rounded border-[1px] border-gray-500 mt-1 ml-2`}>
                <li className='px-3 py-2 hover:bg-gray-300 transition-all ease-in-out cursor-pointer' onClick={() => props.newAssigneeSelected(null,
                        props.currently_assigned_to, props.current_assigned_to_details, props.setModalState, props.project_id, props.crash_group_id, props.selected_time_frame, props.getIssues)}>
                    <div className='flex flex-row'>
                        <div>
                            <FaUserSlash className='mx-auto text-center w-[32px] h-[32px]' />
                        </div>
                        <div className='ml-3'>
                            Not Assigned
                        </div>
                    </div>
                </li>
                {
                    projectMembers?.map((member, index) => {
                        return (
                            <li className='px-3 py-2 hover:bg-gray-300 transition-all ease-in-out cursor-pointer' onClick={() => props.newAssigneeSelected(member,
                                    props.currently_assigned_to, props.current_assigned_to_details, props.setModalState, props.project_id, props.crash_group_id, props.selected_time_frame, props.getIssues)}>
                                <div className='flex flex-row'>
                                    <div>
                                        {
                                            member.profile_image !== "" ?
                                                <img data-tip={member.name} className='text-center mx-auto align-middle rounded w-[32px] h-[32px]'
                                                     src={member.profile_image} alt='Profile Image' /> :
                                                member.name[0].toUpperCase()
                                        }
                                    </div>
                                    <div className='ml-3'>
                                        {member.name}
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
            <ReactTooltip />
        </>

    )
}

AssigneePicker.defaultProps = {
    selected_time_frame: null,
    getIssues: null
}