import * as React from "react";
import '../ComponentStyles/OverflowMenu.css'
import {FaEllipsisV} from "react-icons/fa";
import {CSSProperties, useEffect, useState} from "react";
import {generateRandomString} from "../JSFuncs/HelperFunctions";

export type OverflowMenuItem = {
    label: string,
    link: any,
    itemBackgroundColor?: string,
    textColor?: string
}

export type OverflowMenuProps = {
    menu_items: Array<OverflowMenuItem>,
    id: string
}

function OverflowMenu(props: OverflowMenuProps)
{
    const [randomId] = useState(generateRandomString());
    const [menuVisible, setMenuVisible] = useState(false);
    const [mousePos, setMousePos] = useState({x: 0, y: 0})

    const handleOverflowClick = (event) => {
        event.stopPropagation();
        setMenuVisible(!menuVisible);
        const overflowItemContainer = document.getElementById(randomId) as HTMLElement;
        if (overflowItemContainer !== null)
        {
            overflowItemContainer.style.opacity = "0";
        }
        setMousePos({
            x: event.pageX,
            y: event.pageY
        });
    }

    const handleMenuItemClick = (e, customEvent) => {
        e.stopPropagation();
        setMenuVisible(false);
        customEvent();
    }

    useEffect(() => {
        const overflowItemContainer = document.getElementById(randomId) as HTMLElement;
        if (overflowItemContainer !== null)
        {
            const containerLeftPos = overflowItemContainer.offsetLeft
            const containerWidth = overflowItemContainer.offsetWidth;
            const viewportWidth = window.innerWidth;

            const currentRightPos = containerLeftPos + containerWidth;

            if (currentRightPos > viewportWidth)
            {
                const newLeftPos = currentRightPos - viewportWidth + 40;

                overflowItemContainer.style.left = newLeftPos + "px";
            }

            overflowItemContainer.style.top =  (mousePos.y + 20) + "px";
            overflowItemContainer.style.opacity = "1";
        }
    }, [mousePos, randomId]);

    useEffect(() => {
        document.querySelector("body").addEventListener('click', function () {
            // @ts-ignore
            const overflowContainer = document.querySelector("ul.overflowMenuContainer") as HTMLElement
            if (overflowContainer !== null) {
                overflowContainer.style.display = "none";
                if (menuVisible) {
                    setMenuVisible(false);
                }
                //setMenuVisible(current => !current);
            }
        });
    }, [menuVisible]);

    const style = menuVisible ? {display: 'block'} : {display: 'none'};

    const menuItemsArr = [];
    if (typeof props.menu_items !== typeof  undefined) {

        props.menu_items.forEach((menu, index) => {

            const style : CSSProperties = {}

            if (typeof menu.itemBackgroundColor !== typeof undefined)
            {
                style.backgroundColor = menu.itemBackgroundColor
            }
            if (typeof menu.textColor !== typeof undefined)
            {
                style.color = menu.textColor + " !important";
            }

            if (typeof menu.link === "string") {
                menuItemsArr.push(
                    <li key={index} style={style}>
                        <a style={style} href={menu.link} title={menu.label}>{menu.label}</a>
                    </li>
                )
            } else {
                menuItemsArr.push(
                    <li key={index} style={style}>
                        <button style={style} onClick={(e) => handleMenuItemClick(e, menu.link)}>{menu.label}</button>
                    </li>
                )
            }

        });
    }

    return (
        <div>
            <span><FaEllipsisV onClick={handleOverflowClick} /></span>
            <ul id={randomId} className='overflowMenuContainer' style={style}>
                {menuItemsArr}
            </ul>
        </div>
    )
}

export default OverflowMenu;