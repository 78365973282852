import {modalStateFunc} from "./Default";

export enum ProjectType {
    All = "All",
    Android = "Android",
    ReactJS = "ReactJS",
    CPP = "CPP",
    CSharp = "C#",
    PHP = "PHP",
    Javascript = "Javascript",
    Python = "Python",
    Ruby = "Ruby",
    IOS = "iOS",
    Flutter = "Flutter",
    Custom_SDK = "Custom SDK"
}

export interface GetProjectAPIResponse {
    status: number,
    message: string,
    data: Array<GetProjectAPIData>
}

export type ProjectCardProps = {
    project_details: GetProjectAPIData
    setModalState: modalStateFunc,
    getProjects : () => void
}

export interface GetProjectAPIData {
    project_id: string,
    user_id: number,
    project_name: string,
    project_type: ProjectType,
    organisation_id: number,
    registered_by: number,
    last_day_crash_count?: number,
    stats?: Array<ChartData>,
    crash_free_sessions?: number
}

export interface ChartData {
    labels: Array<string>,
    data: Array<number>
}