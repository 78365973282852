import * as React from "react";
import {useEffect, useState} from "react";
import {convertFileInputToBase64} from "../../JSFuncs/HelperFunctions";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {toast} from "react-toastify";
import {getProjectVersions} from "../../Functions/ProjectManagement";
import {Form, FormButtonContainer, PrimaryButton, Select} from "devso-react-library";

export type ReactJSSourceMapProps = {
    project_id: string,
    versions: Array<string>
    handleApiError: Function
}

function ReactJSSourceMap(props: ReactJSSourceMapProps) {

    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState("0");

    const uploadReactJSSourceMap = (formData) => {

        // @ts-ignore
        const fileInput = document.querySelector("#source_map_input").files;
        if (fileInput.length > 0) {
            const file = fileInput[0];

            convertFileInputToBase64(file).then(async function (result) {
                const postArray = {
                    //projectId: props.project_id,
                    version: selectedVersion,
                    source_map_base_64: result
                }

                try
                {
                    const response = await sendRequest(postArray, `/projects/project/${props.project_id}/source_map`, HTTP_METHOD.PUT);
                    if (response.status === 0)
                    {
                        toast.success('Source map has been successfully uploaded');
                    }
                    else
                    {
                        props.handleApiError(response);
                    }
                }
                catch (err)
                {
                    if (typeof err.response !== typeof undefined)
                    {
                        props.handleApiError(err.response.data);
                    }
                    else
                    {
                        props.handleApiError({
                            status: 100,
                            message: "Unable to upload source map. Error: " + err.toString(),
                            data: null
                        })
                    }
                }
            });
        } else {
            toast.warning("Source map was not provided");
        }
    }

    useEffect(() => {
        getProjectVersions(props.project_id).then(function (result: any) {
            //setProjectVersions(result);
            loadOptions()
        }).catch(function (err) {
            props.handleApiError(err);
        });
    }, [props.project_id]);


    const loadOptions = () => {
        const versions = [];
        props.versions.forEach((version, index) => {
            versions.push(
                <option key={index} value={version}>{version}</option>
            )
        });

        return versions;
    }

    return (
        <div>
            <h2>ReactJS Source Map Update</h2>
            <p>
                If you have recently published a new version of a ReactJS
                project you are required to upload a new source map. The
                new source map that you upload here will be assigned to the next
                new version number that is detected.
            </p>

            <Form loading={formLoading} errors={formError} handleSubmit={uploadReactJSSourceMap}>

                <div className='text-right'>
                    <label>
                        <span>Source Map File: </span>
                        <input type='file' id='source_map_input' className='ml-4 w-[540px]' />
                    </label>
                </div>


                <Select label='Version' value={selectedVersion} onChange={setSelectedVersion} api_field_name='version'>
                    <option value='0'>Detect Latest Version</option>
                    {loadOptions()}
                </Select>
                <FormButtonContainer>
                    <PrimaryButton label='Upload Sourcemap' />
                </FormButtonContainer>
            </Form>

            {/*<form onSubmit={uploadReactJSSourceMap}>
                <div className='form-group row'>
                    <label>
                        <span>Source Map File: </span>
                        <input style={{width: 280}} type='file' id='source_map_input' className='ml-4' />
                    </label>

                </div>

                <div className='form-group row mt-6'>
                    <label>
                        <span>Source Map Project Version: </span>
                        <select value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)} style={{width: 280}}>
                            <option value='0'>Detect Latest Version</option>
                            {loadOptions()}
                        </select>
                    </label>
                    <p style={{fontSize: 'small', marginLeft: 14, marginTop: 5, marginBottom: 0, paddingBottom: 0}}>
                        When 'Detect Latest Version' is selected, the source map you upload here will be
                        automatically assigned when Crash Catch detects a new version
                    </p>
                </div>

                <button type='submit' className='btn btn-primary w-1/2 md:w-3/4 ml-auto mr-auto'>Upload Source Map</button>
            </form>*/}

            <p style={{marginTop: 10}}>
                For information regarding source maps check
                out our <a href="https://crashcatch.com/docs/project-management/source-maps" target='_blank' rel='noreferrer' title='Crash Catch Source Map Upload Documentation'>docs</a>
            </p>
        </div>
    )
}

export default ReactJSSourceMap;