import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import ReactHtmlParser from 'react-html-parser'
import AndroidDeviceDetails from "./Android/AndroidDeviceDetails";
import ReactJSDeviceDetails from "./ReactJS/ReactJSDeviceDetails";
import PHPDeviceDetails from "./PHP/PHPDeviceDetails";
import CustomDeviceDetails from "./Custom/CustomDeviceDetails";
import IOSDeviceDetails from "./IOS/IOSDeviceDetails";
import FlutterDeviceDetails from "./Flutter/FlutterDeviceDetails";
import {toast} from "react-toastify";
import {ModalDialog, returnDefaultModalState, returnErrorModal, PrimaryButton, SecondaryButton} from "devso-react-library";

type IncidentDetailsProps = {

}

export type CrashGroupDetailsAPIData = {
    VisibleCrashes: IncidentDetailsProps[],
    HiddenCrashes: IncidentDetailsProps[]
}

function IncidentDetails(props)
{
    const [newDeviceName, setNewDeviceName] = useState('');
    const [isFromDevelopmentDevice, setIsFromDevelopmentDevice] = useState(false);
    const [modalState, setModalState] = useState(null);

    const confirmFilerDeviceReporting = useCallback(async () => {
        const postArray = {
            project_id: props.incident.project_id,
            //crash_group_id: props.incident.CrashGroupID,
            //crash_id: props.incident.CrashID,
            //device_id: props.incident.DeviceID,
            device_name: newDeviceName
        }

        try
        {
            const response = await sendRequest(postArray, `/dev-device/${props.incident.device_id}`, HTTP_METHOD.PUT);
            if (response.status === 0)
            {
                setIsFromDevelopmentDevice(true);
                toast.success("Device successfully marked as development device");
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }, [newDeviceName, props.incident]);

    const showFilterDeviceDetailsModal = useCallback(() => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure?";
        tempModalState.content = <div>
            <p>
                Are you sure you want to stop reporting incidents for this device for this project?
            </p>
            <p>
                Any incident that is raised for any crash group under this project will be shown
                under the "Not Reported Incidents" tab and you will not receive an automatic alert.
            </p>
            <StopDeviceReportingForm deviceName={newDeviceName} setNewDeviceName={setNewDeviceName} />
        </div>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                confirmFilerDeviceReporting();
            }} />,
            <SecondaryButton label='Cancel' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }, [confirmFilerDeviceReporting, newDeviceName]);

    useEffect(() => {
        /**
         * Hack to ensure that the form for StopDeviceReportingform actually passes the up to date information
         * as it is not automatically updated when the form passes the new value because its not in the render
         * process. So when this detects the new device name updated from the form component, it will re-render the modal
         * with the update to date input form
         */
        if (newDeviceName !== '') {
            showFilterDeviceDetailsModal();
        }
    }, [newDeviceName, showFilterDeviceDetailsModal])

    useEffect(() => {
        if (props.incident !== null) {
            setIsFromDevelopmentDevice(props.incident.is_from_development_device === "1");
        }
    }, [props])

    if (props.incident !== null)
    {

        const PrettyPrintJSON : any = ({data}) => {
            if ((data !== null) && data.length !== 0)
            {

                return (<div>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>);
            }
            else
            {
                return "N/A";
            }
        }

        return (
            <div className='w-full'>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className='flex-1 !max-w-1/2'>
                        <h3>Incident Details</h3>
                        <table className='dataTable'>
                            <tbody>
                            <tr>
                                <td>Incident ID:</td>
                                <td>{props.incident.crash_id}</td>
                            </tr>
                            <tr>
                                <td>Occurrence:</td>
                                <td>{props.incident.created_at}</td>
                            </tr>
                            <tr>
                                <td>Class File:</td>
                                <td>{props.incident.class_script_name}</td>
                            </tr>
                            {
                                props.project_type !== "iOS" ? <tr>
                                    <td>Line No:</td>
                                    <td>{props.incident.line_no}</td>
                                </tr> : null
                            }
                            <tr>
                                <td>Exception Group:</td>
                                <td>{props.incident.exception_group}</td>
                            </tr>
                            <tr>
                                <td>Exception Message:</td>
                                <td>{props.incident.exception_message}</td>
                            </tr>
                            <tr>
                                <td>Project Version:</td>
                                <td>{props.incident.version_name}</td>
                            </tr>
                            <tr>
                                <td>Stacktrace</td>
                                <td>{ReactHtmlParser(props.incident.stacktrace)}</td>
                            </tr>
                            <tr>
                                <td>Severity:</td>
                                <td>{props.incident.severity}</td>
                            </tr>
                            <tr>
                                <td>Custom Properties:</td>
                                <td><PrettyPrintJSON data={props.incident.custom_properties}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='flex-1 ml-5 !max-w-1/2'>
                        <div style={{display: 'inline-block', textAlign: 'center', verticalAlign: 'center'}}>
                            <h3 style={{display: 'inline-block'}}>
                                Device Details
                            </h3>
                            {
                                isFromDevelopmentDevice === true ?
                                    <div className='devDeviceBadge'>From Development Device</div> : ''
                            }
                        </div>

                        {
                            (
                                () => {
                                    return (
                                        () => {
                                            switch (props.project_type)
                                            {
                                                case "Android":
                                                    return <AndroidDeviceDetails {...props.incident} />
                                                case "ReactJS":
                                                    return <ReactJSDeviceDetails {...props.incident} />
                                                case "PHP":
                                                    return <PHPDeviceDetails {...props.incident} />
                                                case "iOS":
                                                    return <IOSDeviceDetails {...props.incident} />
                                                case "Flutter":
                                                    return <FlutterDeviceDetails {...props.incident} />
                                                case "Custom SDK":
                                                    return <CustomDeviceDetails {...props.incident} />
                                            }
                                        }
                                    )()
                                }
                            )()
                        }

                        <div style={{textAlign: 'center', marginTop: 20}}>
                            {
                                isFromDevelopmentDevice === false ?
                                    <button className='btn btn-danger'
                                            onClick={showFilterDeviceDetailsModal}>Stop Reporting on this Device
                                    </button> : null
                            }

                        </div>


                    </div>
                </div>

                {/*<Modal show={showFilterDeviceDetailsModal}>
                    <Modal.Header>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Are you sure you want to stop reporting incidents for this device for this project?
                        </p>
                        <p>
                            Any incident that is raised for any crash group under this project will be shown
                            under the "Not Reported Incidents" tab and you will not receive an automatic alert.
                        </p>
                        <input type='text' onChange={(e) => setNewDeviceName(e.target.value)} className='form-control'
                               placeholder='Device Name' value={newDeviceName}/>
                        <p style={{fontSize: 'small', textAlign: 'center'}}>
                            The device name is used as a reference only
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='button' className='btn btn-secondary'
                                onClick={() => setShowFilterDeviceDetailsModal(false)}>Dismiss</Button>
                        <Button type='button' className='btn btn-danger' onClick={confirmFilerDeviceReporting}>Yes...I'm
                            sure</Button>
                    </Modal.Footer>
                </Modal>*/}
                <ModalDialog {...modalState} />
            </div>
        );
    }
    else {
        return (
            <p>
                There are no hidden incidents for this crash group
                <ModalDialog {...modalState} />
            </p>
        )
    }
}

type StopDeviceReportingFormProps = {
    setNewDeviceName: Function,
    deviceName: string
}

function StopDeviceReportingForm(props: StopDeviceReportingFormProps)
{
    const [deviceName, setDeviceName] = useState('');

    const handleDeviceNameChange = (value) => {
        props.setNewDeviceName(value);
        setDeviceName(value);
    }

    return (
        <div>
            <input type='text' onChange={(e) => handleDeviceNameChange(e.target.value)} className='form-control'
                   placeholder='Device Name' value={deviceName}/>
            <p className='text-xs text-center mt-2'>
                The device name is used as a reference only
            </p>
        </div>
    )
}

export default IncidentDetails;