import * as React from "react";
import {ProjectInstructionsTypeProps} from "../Projects/FinalProjectSetup";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";

function PHPInstruction(props: ProjectInstructionsTypeProps)
{
    return (
        <div>
            <div className='text-center items-center justify-items-center content-center'>
                <img className='ml-auto mr-auto' src='/images/platforms/php.png' alt='PHP' />
            </div>

            <h2>Installation</h2>
            <p>
                The Crash Catch PHP library, is easily installed by downloading the repository
                from our <a href='https://github.com/Crash-Catch/CrashCatchLib-PHP'>GitHub</a> repository
                and adding it to your project, or better alternative, install it somewhere and add to your PHP include_path.
            </p>
            <p>
                We're going to base the instructions with it being installed somewhere on your development environment/server
                and add the library to the PHP.ini include_path.
            </p>

            <p>
                Once downloaded and installed, add the path to where you installed the library in the php.ini file
                under the setting include_path. Common place is on linux its under /usr/share/php and if its Windows
                and using Wamp then it might be {`$\{WAMP_INSTALL_PATH\}` /*eslint-disable-line*/}\bin\php\php_7.4\share
            </p>
            <p>
                Note that the share directory might need to be created first.
            </p>

            <p>
                Once installed and your web server software has been restarted to load the new configuration,
                add the library to your existing project
            </p>
            <p>
                To create an instance of the Crash Catch library and ensure it is initialised on every API request/page
                that your user might visit, ensure that it is added to a script which can be included in every file within
                your project. Add the following to include the library
            </p>
            <SyntaxHighlighter language="php">
                {
`
require_once 'CrashCatch.php';
`
                }
            </SyntaxHighlighter>

            <p>
                You can then call the initialisation method as below to initialise and start reporting crashes to your
                account
            </p>
            <SyntaxHighlighter language="php">
                {
`
$crashcatch = new CrashCatch("`+props.project_id+`", "`+props.api_key+`", "1.0.0.0");
`
                }
            </SyntaxHighlighter>

            <p>
                At this point Crash Catch will start reporting your daily active users and report any unhandled crashes
                however, if you want to report on crashes and errors that you are handing via a try catch then this can be done as follows.
            </p>
            <SyntaxHighlighter language="php">
                {
`
try
{
    throw new MyCustomException("This is my own exception");
}
catch (MyCustomException $ex)
{
    $crashcatch->reportCrash($ex);
}
`
                }
            </SyntaxHighlighter>

            <p>
                You can also optionally add a priority to the crash report (default is Low) by passing LOW, MEDIUM or HIGH
                using the CrashCatchSeverity enum as below
            </p>
            <SyntaxHighlighter language="php">
                {
`
try
{
    throw new MyCustomException("This is my own exception");
}
catch (MyCustomException $ex)
{
    $crashcatch->reportCrash($ex, CrashCatchSeverity::MEDIUM);
}
`
                }
            </SyntaxHighlighter>

            <p>
                You can also submit custom properties to provide extra information to help diagnose the problem, in order to
                this you need to pass 3 parameters, being the exception object, the severity, and then the custom properties.
                The custom properties can be a JSON object or JSON array, similar to the below
            </p>
            <SyntaxHighlighter language="php">
                {
`
try
{
    throw new MyCustomException("This is my own exception");
}
catch (MyCustomException $ex)
{
    $crashcatch->reportCrash($ex, CrashCatchSeverity::LOW, array("key1" => "value1"));
}

`
                }
            </SyntaxHighlighter>

            <p>
                For more information to see other examples of how to send crashes, check
                the <a href='https://github.com/Crash-Catch/CrashCatchLib-PHP' target='_blank' rel='nofollow noreferrer'>GitHub</a> repository
                or check out our <a href='https://docs.crashcatch.com' target='_blank' rel='nofollow noreferrer'>docs</a>.
            </p>
        </div>
    )
}

export default PHPInstruction;