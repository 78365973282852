import {useEffect, useState} from "react";
import {GetProjectActivitiesAPIData, GetProjectActivitiesAPIResponse} from "../Models/ProjectActivities";
import {sendRequest} from "../JSFuncs/APIManager";

export default function useProjectActivities(project_id: string, crash_group_id: number = null) {

    const [projectActivities, setProjectActivities] = useState<Array<GetProjectActivitiesAPIData>>(null);
    const [projectActivitiesLoading, setProjectActivitiesLoading] = useState(false);
    const [projectActivitiesError, setProjectActivitiesError] = useState(null);

    const getProjectActivities = async(project_id: string, crash_group_id: number = null) => {
        try
        {
            setProjectActivitiesLoading(true);
            setProjectActivitiesError(null);

            let requestUrl = '';

            if (typeof project_id !== typeof undefined && project_id !== null && project_id !== "0")
            {
                requestUrl = `/activities/${project_id}`

                if (crash_group_id !== null && crash_group_id !== 0)
                {
                    requestUrl = `/activities/${project_id}/${crash_group_id}`
                }
            }
            else
            {
                requestUrl = `/activities`;
            }

            /*const requestUrl = typeof project_id !== typeof undefined && project_id !== null && project_id !== "0" ?
                `/activities/${project_id}` : '/activities';*/

            const response : GetProjectActivitiesAPIResponse = await sendRequest(null, requestUrl);
            setProjectActivities(response.data);
        }
        catch (err)
        {
            setProjectActivitiesError(err);
        }
        finally {
            setProjectActivitiesLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getProjectActivities(project_id, crash_group_id);
            }
        )()
    }, [])

    return {projectActivities, projectActivitiesLoading, projectActivitiesError, getProjectActivities};
}