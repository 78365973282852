import {useEffect, useState} from "react";
import {
    GetActiveUserAnalysisAPIData,
    GetActiveUserAnalysisAPIResponse, GetCrashAnalysisAPIData, GetCrashAnalysisAPIResponse,
    GetCrashAnalysisChartData
} from "../../Models/DashboardStats";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";

export default function useCrashAnalysis(project_id: string, time_frame: string) {

    const [crashAnalysis, setCrashAnalysis] = useState(null);
    const [crashAnalysisLoading, setCrashAnalysisLoading] = useState(false);
    const [crashAnalysisError, setCrashAnalysisError] = useState(null);
    const [rawCrashAnalysis, setRawCrashAnalysis] = useState<any>(null);

    const getCrashAnalysis = async (project_id: string, time_frame: string) => {
        try
        {
            setCrashAnalysisLoading(true);
            setCrashAnalysisError(null);

            const data = {
                project_id: project_id,
                time_frame: time_frame
            }

            const response : GetCrashAnalysisAPIResponse = await sendRequest(data,'/dashboard/crash-analysis', HTTP_METHOD.POST);

            const chartDataObj = {
                labels: response.data.chart_labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Crashes Received',
                        borderColor: '#1d1aa5',
                        backgroundColor: 'rgba(60,57,198,0.28)',
                        borderWidth: 1.5,
                        data: response.data.chart_data.crash_data
                    },
                    {
                        label: 'New Project Version Release',
                        backgroundColor: '#5f5656',
                        borderColor: 'rgb(49,35,35)',
                        borderWidth: 1,
                        data: response.data.chart_data.app_release_data
                    }
                ]
            }
            setRawCrashAnalysis(response.data);
            setCrashAnalysis(chartDataObj);
        }
        catch (err)
        {
            setCrashAnalysisError(err);
        }
        finally {
            setCrashAnalysisLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getCrashAnalysis(project_id, time_frame);
            }
        )()
    }, [project_id, time_frame])

    return {crashAnalysis, crashAnalysisError, crashAnalysisLoading, rawCrashAnalysis}
}