import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {CrashCommentsProps} from "../../Models/CrashComments";
import {convertEloquentTimeToHumanReadable} from "../../JSFuncs/DataTimeManager";
import TimeAgo from 'react-timeago'
import ReactTooltip from "react-tooltip";
import {PrimaryButton, returnErrorModal} from "devso-react-library";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {toast} from "react-toastify";
import getCaretCoordinates from 'textarea-caret';
import {ProjectTeamMembers} from "../../Models/ProjectTeamMembers";
import {getIndexOfSpaceBeforeCaretPosition} from "../../JSFuncs/StringUtils";
import Parser from 'html-react-parser';

export default function CrashComments(props: CrashCommentsProps) {

    const [newComment, setNewComment] = useState('');
    const [commentSubmitLoading, setCommentSubmitLoading] = useState(false);
    const [mentionsShown, setMentionsShown] = useState(false);
    const [filteredMentions, setFilteredMentions] = useState<Array<ProjectTeamMembers>>(props.project_members);
    const [mentionsHidden, setMentionsHidden] = useState(false);

    const submitComment = async () => {
        try
        {
            const data = {
                new_comment: newComment
            }
            setCommentSubmitLoading(true);
            await sendRequest(data, `/comments/${props.project_id}/${props.crash_group_id}`, HTTP_METHOD.POST);
            toast.success("Successfully submitted new comment");
            setNewComment('');
            await props.getIssue(props.crash_group_id);
        }
        catch (err)
        {
            props.setModalState({...returnErrorModal(err, "Failed to submit comment", props.setModalState)});
        }
        finally
        {
            setCommentSubmitLoading(false);
        }
    }

    //const [position, setPosition] = useState({top: 0, left: 0});
    const position = useRef({top: 0, left: 0});

    useEffect(() => {
        if (newComment[newComment.length-1] === "@")
        {
            const elem = document.getElementById("txtNewComment") as HTMLInputElement;
            const mentions = document.getElementById("mentions") as HTMLElement;

            const caret = getCaretCoordinates(elem, elem.selectionEnd);
            mentions.style.left = caret.left + "px";
            mentions.style.top = caret.top + 20 + "px";
            setMentionsShown(true);
        }
        else
        {


            if (mentionsShown && newComment[newComment.length-1] === " ")
            {
                setMentionsShown(false);
                setFilteredMentions([...props.project_members])
            }
            else if (mentionsShown)
            {

                const elem = document.getElementById("txtNewComment") as HTMLInputElement;
                const caret_position = elem.selectionStart;

                const startOfMention = getIndexOfSpaceBeforeCaretPosition(elem, caret_position);
                const mention = newComment.substring(startOfMention, caret_position)
                    .replace("@", "").trim().toLowerCase();

                const temp = props.project_members.filter(e => e.name.toLowerCase().startsWith(mention));

                setFilteredMentions([...temp]);

                if (temp.length === 0)
                {
                    setMentionsHidden(true);
                }
                else
                {
                    setMentionsHidden(false);
                }

            }
        }
    }, [newComment])

    const newCommentOnKeyDown = (e) => {
        const elem = document.getElementById("txtNewComment") as HTMLInputElement;
        const caret_position = elem.selectionStart;

        if (e.key === "Tab")
        {
            if (mentionsShown)
            {
                e.preventDefault();

                const member = props.project_members[0];
                updateCommentWithMentionValue(elem, caret_position, member);
            }
        }
    }

    const updateCommentWithMentionValue = (elem: HTMLInputElement, caret_position: number, member: ProjectTeamMembers) => {
        const indexOfSpaceBeforeMention = getIndexOfSpaceBeforeCaretPosition(elem, caret_position);

        let updatedComment = newComment.substring(0, indexOfSpaceBeforeMention);
        updatedComment += ` @${member.name}`;
        updatedComment += newComment.substring(caret_position) + " ";

        //const updatedComment = `${startOfCommentBeforeMention}${member.name} ${commentAfterMention}`
        setNewComment(updatedComment);
        setMentionsShown(false);
        elem.selectionEnd = caret_position + member.name.length + 1;
        elem.focus();
    }

    const mentionSelected = (member: ProjectTeamMembers) => {
        const elem = document.getElementById("txtNewComment") as HTMLInputElement;
        const caret_position = elem.selectionStart;

        updateCommentWithMentionValue(elem, caret_position, member);
    }


    return (
        <>

            <h2>Comments</h2>

            <div className='relative'>

                <div id='mentions' className={`${!mentionsShown || mentionsHidden ? "hidden" : "absolute"}  bg-primary p-3 rounded absolute text-white`}>
                    <ul className='m-0 p-0'>
                        {
                            filteredMentions?.map(member => {
                                const usersName = member.name;

                                let initial = null;
                                let profileImageShown = false;

                                    if (member.profile_image.length === 0)
                                    {
                                        initial = member.name[0];
                                    }
                                    else
                                    {
                                        initial = <img data-tip={usersName} className='rounded-full w-[32px] h-[32px]' aria-hidden alt='Users profile image'
                                                       src={member.profile_image}/>
                                        profileImageShown = true;
                                    }

                                return (
                                    <li className='hover:underline cursor-pointer m-0 p-0'
                                        onClick={() => mentionSelected(member)}>
                                        <div className='flex flex-row w-full m-0 p-0'>
                                            <div>
                                                {
                                                    profileImageShown ? initial :
                                                        <div data-tip={usersName}
                                                             className='cursor-default w-[32px] h-[32px] text-center bg-primary-light rounded-full align-text-bottom p-1 text-white mr-2 mt-2'>
                                                            {initial}
                                                        </div>
                                                }
                                            </div>
                                            <div className='ml-3 mt-1'>
                                                <p className='m-0 p-0'>{member.name}</p>
                                            </div>

                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>

                </div>

                <textarea id='txtNewComment' className=" !bg-white focus:!border-primary focus:!border-[1px] !shadow !appearance-none !border !rounded !w-full !accent-primary !py-2 !px-3 !text-black !leading-tight focus:!shadow-outline"
                    placeholder='Enter your comment here...'
                    value={newComment} onChange={(e) => setNewComment(e.target.value)}
                    onKeyDown={(e) => newCommentOnKeyDown(e)}
                    />
                <p className='text-right text-sm text-gray-400'>
                    Markdown Supported
                </p>
                <div className='text-right my-2 ml-2'>
                    <PrimaryButton loading={commentSubmitLoading} label='Submit New Comment' onClick={submitComment} />
                </div>
            </div>

            {
                props.comments.map((comment, index) => {

                    const usersName = comment.user_details.name;

                    let initial = null;
                    let profileImageShown = false;

                    if (comment.user_details.profile_image.length === 0)
                    {
                        initial = comment.user_details.name[0];
                    }
                    else
                    {
                        initial = <img data-tip={usersName} className='rounded-full w-[32px] h-[32px]' aria-hidden alt='Users profile image'
                                       src={comment.user_details.profile_image}/>
                        profileImageShown = true;
                    }

                    //Render mentions to be styled
                    const comment_words = comment.comment.split(' ');
                    comment_words.forEach((word, index) => {
                        if (word.startsWith("@"))
                        {
                            const mention = word.replace("@", "");
                            //Get the member so if the user typed the whole name without fixing
                            //casing, we display the name accurately
                            const member = props.project_members.find(m => m.name.toLowerCase() === mention.toLowerCase())
                            if (member)
                            {
                                comment_words[index] = "<span className='font-bold'>@"+member.name+"</span>"
                            }
                        }
                    })

                    const updated_comment = comment_words.join(' ');

                    return (
                        <div className='bg-white border-[1px] border-gray-300 p-3 rounded m-2'>
                            <div key={comment.comment_id} className='mt-4 mb-4 flex flex-row'>
                                {
                                    profileImageShown ? initial :
                                        <div data-tip={usersName}
                                             className='cursor-default w-[32px] h-[32px] text-center bg-primary-light rounded-full align-text-bottom p-1 text-white mr-2 mt-2'>
                                            {initial}
                                        </div>
                                }

                                <div className='w-full ml-3'>
                                    <div className='flex flex-col md:flex-row'>
                                        <div className='w-full'>
                                            <p className='m-0 p-0'>{Parser(updated_comment)}</p>
                                        </div>

                                        {/*<p className='text-sm text-gray-500 m-0 p-0'>{convertEloquentTimeToHumanReadable(comment.created_at, true)}</p>*/}
                                        <div className='min-w-[120px] max-w-[140px] md:text-right md:float-right' data-tip={convertEloquentTimeToHumanReadable(comment.created_at, true)}>
                                            <TimeAgo date={comment.created_at} formatter={(value, unit, suffix) => {
                                                if (unit === 'second' && value < 60) return "just now";
                                                //if (unit  === "minute") return `${value} ${value === 1 ? "minute" : "minutes"} ago`;
                                                return `${value} ${unit}${value > 1 ? "s" : ""} ${suffix}`
                                            }} />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    )

                })
            }
            {
                props.comments.length === 0 ?
                    <p className='text-center italic'>There are currently no comments</p> : null
            }
            <ReactTooltip />
        </>
    )
}