import * as React from "react";

import '../../ComponentStyles/CrashTabSelection.css'
import {useEffect, useState} from "react";
import {CrashTabCountProps} from "../../Models/CrashDetails";

function CrashTabSelection(props: CrashTabCountProps)
{
    const [selectedTab, setSelectedTab] = useState('Summary');

    useEffect(() => {
        const elementId = selectedTab.toLowerCase();

        const tabs = document.getElementsByClassName("tab-pane");
        if (tabs !== null)
        {
            for (let i = 0; i < tabs.length; i++)
            {
                const tab = tabs[i] as HTMLElement;
                tab.style.display = "none";
            }
        }

        const element = document.getElementById(elementId) as HTMLElement;
        if (element !== null)
        {
            element.style.display = "block";
        }

    }, [selectedTab]);

    useEffect(() => {
        const element = document.getElementById("summary") as HTMLElement;
        if (element !== null)
        {
            element.style.display = "block";
        }
    }, []);

    return (
        <ul className='crash_tab_selection'>
            <li data-selected={selectedTab === 'Summary' ? "true" : "false"} onClick={() => setSelectedTab('Summary')}>Summary</li>
            <li data-selected={selectedTab === 'Incidents' ? "true" : "false"} onClick={() => setSelectedTab('Incidents')}>Incidents <span className='countBadge'>{props.incidentCount}</span> </li>
            <li id='comments-tab' data-selected={selectedTab === 'Comments' ? "true" : "false"} onClick={() => setSelectedTab('Comments')}>Comments <span id='commentCount' className='countBadge'>0</span> </li>
        </ul>
    )
}

export default CrashTabSelection;