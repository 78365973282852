import * as React from "react"
import PageHandler from "./PageHandlerType";
import {FaTrash} from "react-icons/fa";

export type ClientsProps = {
    confirmDeleteClient: Function,
    clientsAndPagingHandler: any
}

function Clients(props: ClientsProps)
{
    const showDeleteConfirm = (client_id: string) => {
        props.confirmDeleteClient(client_id);
    }

    const getClientRows = () => {
        if (props.clientsAndPagingHandler !== null && typeof props.clientsAndPagingHandler.pageHandler !== typeof undefined) {
            const dataArr = [];
            for (let i = 0; i < props.clientsAndPagingHandler.clients.length; i++) {
                const client = props.clientsAndPagingHandler.clients[i];
                dataArr.push(
                    <tr key={i}>
                        <td>{ client.created_at}</td>
                        <td>{client.client_name}</td>
                        <td className='hidden md:table-cell'>{client.country}</td>
                        <td>{client.City}</td>
                        <td className='hidden md:table-cell'>{client.ip_address}</td>
                        <td>
                            <FaTrash style={{color: 'red', cursor: 'hand'}}
                                     onClick={() => showDeleteConfirm(client.client_id)}/>
                        </td>
                    </tr>
                )
            }
            return dataArr;
        }
        else
        {
            return [];
        }
    }

    if ((props.clientsAndPagingHandler !== null) && typeof props.clientsAndPagingHandler.pageHandler !== typeof undefined) {
        return (
            <div className='ml-auto mr-auto w-full items-center justify-items-center'>
                <table className='w-full text-left ml-auto mr-auto'>
                    <thead>
                    <tr>
                        <th>Time Logged In</th>
                        <th>Client Name</th>
                        <th className='hidden md:table-cell'>Country</th>
                        <th>City</th>
                        <th className='hidden md:table-cell'>IP Address</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <PageHandler data={getClientRows()} colSpan={6}
                             pageHandler={props.clientsAndPagingHandler.pageHandler}
                             skippedKeys={["ClientID", "UserID", "AuthToken"]}/>


                </table>

            </div>
        )
    }
    else
    {
        return null;
    }
}

export default Clients;