import * as React from "react";
import {FaCaretDown} from "react-icons/fa";
import {ButtonMenuItem, SmallButtonWithMenuProps} from "../Models/SmallButtonWithMenuProps";
import {useEffect, useRef, useState} from "react";

export default function SmallButtonWithMenu(props: SmallButtonWithMenuProps) {

    const [menuExpanded, setMenuExpanded] = useState(false);

    const clickListener = useRef(false);

    const openMenu = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setMenuExpanded(!menuExpanded);
    }

    const handleMenuItemClick = (e, item: ButtonMenuItem) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuExpanded(false);
        item.itemClick(item);
    }

    useEffect(() => {
        if (!clickListener.current)
        {
            const body = document.body;
            if (body !== null) {
                body.addEventListener("click", function () {
                    setMenuExpanded(false);
                })

                clickListener.current = true;
            }
        }

        return (
            () => {
                if (clickListener.current)
                {
                    document.removeEventListener("click", function(){});
                }
            }
        )
    }, [])

    return (
        <div className='text-gray-800 text-sm my-4 border-gray-300 border-[1px] w-auto bg-gray-200 mr-2'>
            <div className='flex flex-row'>
                <div className={`px-2 w-full align-middle py-1 ${props.defaultLabelEnabled ? "cursor-pointer hover:bg-gray-300 transition-all ease-in-out" : ""}`}
                    onClick={() => props.defaultLabelClick(props.labelIdentifier)}>
                    {props.label}
                </div>
                {
                    props.menuEnabled ?
                        <div className='border-l-[1px] w-[32px] border-l-gray-300 px-2 pt-1 cursor-pointer hover:bg-gray-300 transition-all ease-in-out' onClick={(e) => openMenu(e)}>
                            <FaCaretDown className='text-gray-800' />
                        </div> : null
                }
            </div>

            {
                props.menuEnabled ?
                    <ul className={`${menuExpanded ? "absolute" : "hidden"} bg-gray-200 shadow-md shadow-gray-500 border-gray-400 border-[1px] mt-1 min-w-[120px] w-auto`}>
                        {
                            props.items.map((item, index) => {
                                return (
                                    <li key={index} className='p-3 cursor-pointer hover:bg-gray-300 transition-all ease-in-out'
                                        onClick={(e) => handleMenuItemClick(e, item)}>
                                        {item.label}
                                    </li>
                                )
                            })
                        }
                    </ul> : null
            }

        </div>
    )
}

SmallButtonWithMenu.defaultProps = {
    menuEnabled: false,
    menuSelected: null,
    items: null,
    defaultLabelEnabled: true
}