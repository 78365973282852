import {useEffect, useState} from "react";
import {IssueDetails, IssuesAPIResponse} from "../../Models/Issues";
import {sendRequest} from "../../JSFuncs/APIManager";

export default function useIssue(crash_group_id: number) {

    const [issue, setIssue] = useState<IssueDetails>(null);
    const [issueLoading, setIssueLoading] = useState(false);
    const [issueError, setIssueError] = useState(null);

    const getIssue = async (crash_group_id: number) => {
        try
        {
            if (issue === null)
            {
                setIssueLoading(true);
            }
            setIssueError(null);
            const response : IssuesAPIResponse = await sendRequest(null, `/issues/${crash_group_id}`);
            setIssue(response.data);
        }
        catch (err)
        {
            setIssueError(err);
        }
        finally {
            setIssueLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getIssue(crash_group_id);
            }
        )()
    }, [])

    return {issue, issueLoading, issueError, getIssue}
}