import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import '../ComponentStyles/Mentions.css'

export type MentionsProps = {
    userList: Array<any>,
    currentControlText: string,
    textUpdatedEvent: Function,
    controlPos: any
    controlCurPos: any,
    controlId: string
}

function Mentions(props: MentionsProps)
{
    const [mentionsProcessing, setMentionsProcessing] = useState(false);
    const [mentionsVisible, setMentionsVisible] = useState(false);
    const [rows, setRows] = useState([]);
    let atSymbol = useRef(-1);

    const mentionNameClicked = useCallback((id, name) => {
    const lengthOfMention = props.currentControlText.length - atSymbol.current;
    const atString = props.currentControlText.substr(atSymbol.current, lengthOfMention);
    const updatedText = props.currentControlText.replace(atString, "//"+name+"//");

        setMentionsProcessing(false);
        setMentionsVisible(false);
        props.textUpdatedEvent(updatedText);
        atSymbol.current = -1;
    }, [props]);


    useEffect(() => {
        const rowsArr = [];
        for (let i = 0; i < props.userList.length; i++)
        {
            rowsArr.push(
                <li onClick={() =>
                    mentionNameClicked(props.userList[i].link, props.userList[i].title)}
                    key={props.userList[i].link}>{props.userList[i].title}</li>
            )
        }
        setRows(rowsArr);
    }, [props.userList, mentionNameClicked])



    let style : any = {visibility: 'hidden'}

    if (props.currentControlText !== null && typeof props.currentControlText !== typeof undefined) {
        if (props.currentControlText.substr(props.currentControlText?.length - 2, 2) !== '@@') {

            if (mentionsVisible || props.currentControlText.substr(props.currentControlText.length - 1, 1) === "@") {
                style = {visibility: 'visible'}
                //setMentionsVisible(true);
                //mentionsVisible = true;

                if (!mentionsVisible) {
                    setMentionsVisible(true);
                }
            }
            if ((mentionsVisible && props.currentControlText.substr(props.currentControlText.length - 1, 1) === ' ') || !mentionsProcessing) {
                style = {visibility: 'hidden'}
                //setMentionsVisible(false);
                //mentionsVisible = false;
                if (mentionsVisible) {
                    setMentionsVisible(false);
                }
                atSymbol.current = -1;
            }

            //const xPos = props.controlPos.x;
            //const yPos = props.controlPos.y;

            style.top = props.controlPos.y;
            style.left = props.controlPos.x;

            //If the menu is visible, filter the rows based on the input
            //Find the @ symbol from the current cursor position

            if (atSymbol.current === -1 && mentionsVisible) {
                for (let i = props.controlCurPos.ch; i >= 0; i--) {
                    if (props.currentControlText[i] === '@') {
                        atSymbol.current = i;
                        break;
                    }
                }
            }

            if (!mentionsProcessing) {
                setMentionsProcessing(true);
                //myState.mentionedProcessing = true;
                //setState(myState);
            }
        }
    }
    //processUserList();

    return (
        <div className='mentionsContainer' style={style}>
            <ul>
                {rows}
            </ul>
        </div>
    )
}

export default Mentions;