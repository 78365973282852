import * as React from 'react'
import {useEffect, useState} from 'react'
import TopNavBar from "../TopNavBar";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import * as helpers from "../../JSFuncs/HelperFunctions";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import ReactTooltip from "react-tooltip";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnErrorModal, returnDefaultModalState,
    SecondaryButton
} from "devso-react-library"
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";

function AddTeamMembers()
{
    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: 'Manage Team Members',
            link: '/team-members'
        },
        {
            label: 'Add Team Member',
            link: ''
        }
    ]

    const [personsName, setPersonsName] = useState('');
    const [email, setEmail] = useState('');
    const [memberType, setMemberType] = useState('Admin');
    const [memberSelectedProjects, setMemberSelectedProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [modalState, setModalState] = useState(null);
    const [projectSearch, setProjectSearch] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const history = useHistory();


    const addRemoveSelectedProject = (event) => {
        const memberSelectedProjectsArr = memberSelectedProjects;
        if (event.target.checked)
        {
            memberSelectedProjectsArr.push(event.target.value);
        }
        else
        {
            for (let i = 0; i < memberSelectedProjectsArr.length; i++)
            {
                if (memberSelectedProjectsArr[i] === event.target.value)
                {
                    memberSelectedProjectsArr.splice(i, 1);
                    break;
                }
            }
        }
        setMemberSelectedProjects(memberSelectedProjectsArr);

        overrideSubmitButtonDisableStatus();
    }

    const fetchProjects = async () => {
        try
        {
            //TODO Add Project Model for API Response
            const result : any = await sendRequest(null, '/projects');
            if (result.status === 0)
            {
                setProjects(result.data);
            }
            else
            {
                const tempModalState = returnErrorModal(result, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }

    const loadProjectRows = () => {
        const projectRows = [];

        projectRows.push(
            <tr>
                <td colSpan={3}>
                    <input type='text' className='form-control' placeholder='Search Project Name'
                        onChange={(e) => setProjectSearch(e.target.value)}
                        value={projectSearch}/>
                </td>
            </tr>
        )

        for (let i = 0; i < projects.length; i++) {

            if (projectSearch.length > 0)
            {
                if (projects[i].project_name.toLowerCase().indexOf(projectSearch.toLowerCase()) === -1)
                {
                    continue;
                }
            }

            projectRows.push(
                <tr key={projects[i].project_id}>
                    <td style={{width: 25 + 'px'}}>
                        <input type='checkbox' onChange={addRemoveSelectedProject}
                               value={projects[i].project_id}/>
                    </td>
                    <td>{projects[i].project_name}</td>
                    <td style={{textAlign: 'center'}} data-tip={projects[i].project_type}>
                        {getProjectIconFromString(projects[i].project_type)}
                    </td>
                </tr>
            )
        }

        return projectRows;
    }

    const submitTeamMemberInvite = async (postArray) => {
        /*event.preventDefault();
        const postArray : any = {
            persons_name: personsName,
            email_address: email,
            member_type: memberType
        }*/

        postArray.member_type = memberType;

        if (memberType === "Member")
        {
            postArray.selected_projects = memberSelectedProjects;
        }

        try
        {
            setFormLoading(true);
            setFormErrors(null);
            const response = await sendRequest(postArray, '/invitations', HTTP_METHOD.PUT);
            if (response.status === 0)
            {
                if (response.message === "EmailExists")
                {
                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Email Already Exists";
                    tempModalState.content = <p>
                        The email address provided already exists. Please use another email address
                    </p>
                    tempModalState.buttons = [
                        <SecondaryButton label='Dismiss' onClick={() => {
                            const tempModalState = returnDefaultModalState();
                            setModalState({...tempModalState});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
                else if (response.message === "InviteAlreadyExists")
                {
                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Invite Already Sent";
                    tempModalState.content = <p>
                        The email address has already been invited. Please check the pending invite tab where you can re-send the invite if required.
                    </p>
                    tempModalState.buttons = [
                        <SecondaryButton label='Dismiss' onClick={() => {
                            const tempModalState = returnDefaultModalState();
                            setModalState({...tempModalState});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
                else
                {
                    toast.success("Pending invite already sent");
                    //window.location.href = '/team-members';
                    history.push('/team-members');
                }
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        finally {
            setFormLoading(false);
        }
    }

    const updateMemberType = (memberType) => {
        setMemberType(memberType);
        setMemberSelectedProjects([]);
        overrideSubmitButtonDisableStatus();
    }

    const overrideSubmitButtonDisableStatus = () => {
        if (memberType === "Member" && memberSelectedProjects.length > 0)
        {
            helpers.disableSubmitButtons(false);
        }
        else if (memberType === "Member" && memberSelectedProjects.length === 0)
        {
            helpers.disableSubmitButtons(true);
        }
        else if (memberType === "Admin")
        {
            helpers.disableSubmitButtons(false);
        }
    }

    useEffect(() => {
        fetchProjects();

    }, []);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [projects]);


    const projectStyle = memberType === "Admin" ? {display: 'none'} : {};

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <div className='w-full md:w-3/4 ml-auto mr-auto'>
                        <h1>Add Team Member</h1>
                        <Form handleSubmit={submitTeamMemberInvite} loading={formLoading} errors={formErrors} >
                            <TextField type={TextFieldType.TEXT} label='Team Members Name' placeholder='Enter Team Members Name' api_field_name='persons_name' value={personsName} onChange={setPersonsName} />

                            <TextField type={TextFieldType.EMAIL} label='Team Members Email' placeholder='Enter Team Members Email' api_field_name='email_address' value={email} onChange={setEmail} />

                            <p className='m-0 p-0 mb-4 font-bold'>Member Type:</p>

                            <div className='flex flex-col'>
                                <div className='mb-4'>
                                    <label className='items-center m-0 p-0' htmlFor='rdoAdmin'>
                                        <input className='mr-2 mt-0 mb-0' checked={memberType === 'Admin'} onChange={() => updateMemberType("Admin")} type='radio' name='memberType' id='rdoAdmin' />
                                        Admin
                                    </label><br />
                                    <span className='p-0 text-sm'>
                                        Admin users have access to all projects, project settings, user management etc
                                    </span>
                                </div>
                                <div>
                                    <label className='mr-2' htmlFor='rdoMember'>
                                        <input className='mr-2 mt-0 mb-0' checked={memberType === 'Member'} onChange={() => updateMemberType("Member")} type='radio' name='memberType' id='rdoMember'/>
                                        Team Member
                                    </label><br />
                                    <span className='p-0 text-sm'>
                                        Team members only have access to the projects that they are assigned to
                                    </span>
                                </div>
                            </div>





                            <div style={projectStyle}>
                                <p style={{fontWeight: 'bold'}}>Select which projects this team member can access</p>

                                <table className='w-full'>
                                    <thead>
                                    <tr>
                                        <th style={{width: 25 + 'px'}}>&nbsp;</th>
                                        <th>Project Name</th>
                                        <th style={{textAlign: 'center'}}>Platform</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loadProjectRows()}
                                    </tbody>
                                </table>

                            </div>

                            <FormButtonContainer loading={formLoading}>
                                <PrimaryButton label="Invite New User" />
                            </FormButtonContainer>

                        </Form>
                    </div>
                </ContentWrapper>
            </div>

            <ModalDialog {...modalState} />
            <ReactTooltip />
        </Wrapper>
    )

    /*return (
        <div className='wrapper'>
            <TopNavBar displayTimePeriodButton={false} title='Add Team Member' />

            <BreadcrumbNav menu_items={breadcrumbNav} />
            <div className='mainContentNoSidebar'>

                <div className='w-full md:w-3/4 ml-auto mr-auto'>

                    <Form handleSubmit={submitTeamMemberInvite} loading={formLoading} errors={formErrors} >
                        <TextField type={TextFieldType.TEXT} label='Team Members Name' placeholder='Enter Team Members Name' api_field_name='persons_name' value={personsName} onChange={setPersonsName} />

                        <TextField type={TextFieldType.EMAIL} label='Team Members Email' placeholder='Enter Team Members Email' api_field_name='email_address' value={email} onChange={setEmail} />

                        <p className='m-0 p-0 -mb-4'>Member Type:</p>

                        <label className='inline-flex items-center m-0 p-0' htmlFor='rdoAdmin'>
                            <input className='mr-2 mt-0 mb-0' checked={memberType === 'Admin'} onChange={() => updateMemberType("Admin")} type='radio' name='memberType' id='rdoAdmin' />
                            Admin
                        </label>
                        <span className='-mt-4 p-0'>
                            Admin users have access to all projects, project settings, user management etc
                        </span>


                        <label className='mr-2' htmlFor='rdoMember'>
                            <input className='mr-2 mt-0 mb-0' checked={memberType === 'Member'} onChange={() => updateMemberType("Member")} type='radio' name='memberType' id='rdoMember'/>
                            Team Member
                        </label>
                        <span className='-mt-4 p-0'>
                            Team members only have access to the projects that they are assigned to
                        </span>


                        <div style={projectStyle}>
                            <p style={{fontWeight: 'bold'}}>Select which projects this team member can access</p>

                            <table className='w-full'>
                                <thead>
                                <tr>
                                    <th style={{width: 25 + 'px'}}>&nbsp;</th>
                                    <th>Project Name</th>
                                    <th style={{textAlign: 'center'}}>Platform</th>
                                </tr>
                                </thead>
                                <tbody>
                                {loadProjectRows()}
                                </tbody>
                            </table>

                        </div>

                        <FormButtonContainer loading={formLoading}>
                            <PrimaryButton label="Invite New User" />
                        </FormButtonContainer>

                    </Form>
                </div>
            </div>

            <ModalDialog {...modalState} />
            <ReactTooltip />

        </div>

    )*/
}

export default AddTeamMembers;