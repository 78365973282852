import * as React from "react";
import {useEffect, useState} from "react";
import IssueCard from "./IssueCard";
import {IssuesAPIData} from "../../Models/Issues";
import {modalStateFunc} from "../../Models/Default";

export interface IssueTable {
    project_id: string
    issues: Array<IssuesAPIData>,
    selectedTimeFrame: string
    setModalState: modalStateFunc,
    getIssues?: (selectedTimeFrame: string) => void
}

export default function (props: IssueTable) {
    const [issueCards, setIssueCards] = useState([]);


    useEffect(() => {
        const items = [];

        props.issues?.map((issue, index) => {
            if (issue.exception_group.length > 0 && (issue.project_id === props.project_id || props.project_id === "0"))
            {
                items.push(<IssueCard key={index} {...issue} setModalState={props.setModalState}
                                      selectedTimeFrame={props.selectedTimeFrame}
                                      getIssues={props.getIssues}/>);
            }
            else
            {
                return null;
            }
        })

        setIssueCards([...items]);
    }, [props])

    return (
        <div className='mt-4'>
            <div className={`${issueCards.length === 0 ? "hidden" : "md:flex md:flex-row hidden"} p-3 border-[1px] rounded border-gray-300 bg-gray-200`}>
                <div data-group='crash_details_header' className='w-1/2 sizzy-red-2'>
                    &nbsp;
                </div>
                <div className='w-1/2 flex flex-row sizzy-red-2'>
                    <div className='text-center w-[150px]'>
                        <h2 className='text-center'>Affected Users</h2>
                    </div>
                    <div className='text-center ml-6 w-[150px]'>
                        <h2 className='text-center'>Incident Count</h2>
                    </div>
                    <div className='text-center w-[150px]'>
                        <h2 className='text-center'>Assigned To</h2>
                    </div>
                </div>

            </div>
            {
                issueCards
            }
            {
                issueCards.length === 0 ?
                    <p className='text-center italic p-3 border-[1px] rounded border-gray-300 bg-white text-xl font-bold'>

                        There are no issues matching your current filter
                    </p> : null

            }
        </div>
    )
}