import {useEffect, useState} from "react";
import {GetActiveUserAnalysisAPIData, GetActiveUserAnalysisAPIResponse} from "../../Models/DashboardStats";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";

export default function useActiveUserAnalysis(project_id: string, time_frame: string) {

    const [activeUserAnalysis, setActiveUserAnalysis] = useState(null);
    const [activeUserAnalysisLoading, setActiveUserAnalysisLoading] = useState(false);
    const [activeUserAnalysisError, setActiveUserAnalysisError] = useState(null);
    const [rawActiveUserAnalysis, setRawActiveUserAnalysis] = useState<GetActiveUserAnalysisAPIData>(null);

    const getActiveUserAnalysis = async (project_id: string, time_frame: string) => {
        try
        {
            setActiveUserAnalysisLoading(true);
            setActiveUserAnalysisError(null);

            const data = {
                project_id: project_id,
                time_frame: time_frame
            }

            const response : GetActiveUserAnalysisAPIResponse = await sendRequest(data,'/dashboard/active-user-analysis', HTTP_METHOD.POST);

            const chartDataObj = {
                labels: response.data.chart_labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Unique Daily Active Users',
                        borderColor: '#1d1aa5',
                        backgroundColor: 'rgba(60,57,198,0.28)',
                        borderWidth: 1.5,
                        data: response.data.chart_data.active_user_data
                    },
                    {
                        label: 'New Project Version Release',
                        backgroundColor: '#5f5656',
                        borderColor: 'rgb(49,35,35)',
                        borderWidth: 1,
                        data: response.data.chart_data.app_release_data
                    }
                ]
            }
            setRawActiveUserAnalysis(response.data);
            setActiveUserAnalysis(chartDataObj);
        }
        catch (err)
        {
            setActiveUserAnalysisError(err);
        }
        finally {
            setActiveUserAnalysisLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getActiveUserAnalysis(project_id, time_frame);
            }
        )()
    }, [project_id, time_frame])

    return {activeUserAnalysis, activeUserAnalysisLoading, activeUserAnalysisError, rawActiveUserAnalysis}
}