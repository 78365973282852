import {IssueTabProps} from "../Models/IssueTabProps";
import {FaComment} from "react-icons/fa";

export default function IssueTab(props: IssueTabProps) {
    return (
        <div onClick={() => props.tabSelected(props.label)} className={`${props.selected ? "border-b-primary border-b-[4px]" : "text-gray-500 cursor-pointer"} pb-2 mb-4 mx-3 transition-all ease-in-out`}>
            <p className='inline'>{props.label}</p>
            {
                props.hasBadgeIcon ?
                    <div className='inline ml-3 bg-gray-200 rounded-full  p-1 px-2'>
                        {props.badgeCount} <FaComment className='text-gray-400 inline ml-1 mb-2 mt-1' />
                    </div> :

                !props.hasBadgeIcon && props.badgeCount !== undefined ?
                    <div className='inline ml-3 bg-gray-200 rounded-full  p-1 px-2'>
                        {props.badgeCount}
                    </div> : null

            }
        </div>
    )
}

IssueTab.defaultProps = {
    hasBadge: false,
    badgeCount: undefined
}