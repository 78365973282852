import * as React from "react"
import {ProjectInstructionsTypeProps} from "../Projects/FinalProjectSetup";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";

function IOSInstructions(props: ProjectInstructionsTypeProps)
{
    return (
        <div>
            <div className='text-center items-center justify-items-center content-center'>
                <img className='ml-auto mr-auto' src='/images/platforms/ios.png' alt='iOS' />
            </div>

            <h2>Note on Unhandled Exceptions</h2>
            <p>
                Unlike the other libraries that are available for Crash Catch, unfortunately the iOS crash reporting
                library doesn't support unhandled exception reporting. We spent a lot of time investigating the possibility
                of implementing this functionality as we have done with all of our other libraries, but from our research
                we found that the general consensus, is not to, and that its basically impossible to get something working
                properly especially for some very severe crashes.
            </p>
            <p>
                Due to this, we only support reporting handled errors, i.e. errors that you report on by calling CrasCatch.ReportCrash
                from within a try/catch error block and for anything unhandled you should continue to use the crash report that
                you receive from the Apple App Store.
            </p>
            <p>
                For more information about this and the complications involved with unhandled crash reporting, you
                can check out our <a target='_blank' rel='noreferrer noopenner' href='https://blog.boardiesitsolutions.com/crash-catch-ios-library-unhandled-exception-handling-or-not/'>
                blog post that goes into more information</a>.
            </p>

            <h2>Installation</h2>
            <p>
                Get the latest tag release of the source code from GitHub
                at <a href= 'https://github.com/Crash-Catch/CrashCatchLib-iOS/tags'>https://github.com/Crash-Catch/CrashCatchLib-iOS/tags</a>.
                Add the project source code within a directory of your project and then right click on your
                project name in xcode and select add files. Select the xcodeproj of Crash Catch Library, ensure
                "Create Groups" and "copy items if needed is selected".
            </p>
            <p>
                Once the project has been added, you again need to select your project and go to the
                "General" tab and scroll down to "Framework, Libraries and Embedded Content".
            </p>
            <p>
                Click the + button and then ensure that	CrashCatchLib_iOS.framework is selected.
            </p>
            <p>
                That should be it, the project is now available for use.
            </p>

            <h2>Using the Library</h2>
            <p>
                Currently at this time, the iOS library for Crash Catch, only catches errors that you
                specifically report on, such as in try/catch handlers, unfortunately,
                it doesn't capture unhandled exceptions. For more information as to the reason
                for this, you can visit our <a rel='nofollow noopenner noreferrer' href='https://blog.boardiesitsolutions.com/crash-catch-ios-library-unhandled-exception-handling-or-not' target='_blank'>blog post - Crash Catch iOS Library - Unhandled Exception Handling - or not!</a>.
            </p>
            <p>
                You either need to create an instance of Crash Catch within each screen (View Controller) and
                initialise Crash Catch anywhere where you want to be able to report crashes, or
                alternatively, and recommended, is to initialise it once, and store a reference to it
                so that it can be accessed from multiple locations within the code.
            </p>

            <h2>Create and initialise Crash Catch library</h2>

            <SyntaxHighlighter language='swift'>
                {
                    `
import CrashCatchLib_iOS

class ViewController: UIViewController {

    var crashcatch : CrashCatch!;
  
    
    override func viewDidLoad() {
        super.viewDidLoad()
        
        self.crashcatch = CrashCatch()
        // Do any additional setup after loading the view.
    }
}
                    `
                }
            </SyntaxHighlighter>

            <p>
                Next you need to initialise the library, this can either be done in the viewDidLoad method or
                after some other event has occurred if required, e.g. if you need to set something up else first.
                Don't worry though, if an error is attempted to be reported before initialisation,
                the error report is kept so when it initialises, it will then send any previous
                error reports attempted.
            </p>

            <p>
                To initialise the library you need to call the initialise method passing in your api key,
                project id and project version string. An example of this is shown below:
            </p>

            <SyntaxHighlighter language='swift'>
                {
                    `
self.crashcatch.initialise(project_id: "`+props.project_id+`", api_key: "`+props.api_key+`", version: "1.0.0")
                    `
                }
            </SyntaxHighlighter>

            <h2>Sending Crash Reports</h2>
            <p>
                Sending crash reports couldn't be easier. First, you need create a CCException object that
                contains two parameters:
            </p>

            <ul>
                <li>name</li>
                <li>reason</li>
            </ul>

            <p>
                These can be anything you want, but name would be considered something such as a title for the
                error, this is how the error will be grouped, something like
                <span className='font-weight-bold'>"API Fault"</span> for example
            </p>

            <p>
                The reason is the actual error message, such as something
                like <span className='font-weight-bold'>"API returned unexpected response".</span>
            </p>

            <p>
                Once you've created the object, you then pass this to the ReportCrash method along
                with a severity parameter. An example error report is shown below:
            </p>

            <SyntaxHighlighter language='swift'>
                {
                    `
let ccException = CCException(name: "API Fault", reason: "API returned unexpected response");
self.crashcatch.ReportCrash(exception: ccException, severity: CrashCatch.Severity.LOW);
                    `
                }
            </SyntaxHighlighter>

            <h2>Providing custom properties to crash report </h2>
            <p>
                Proving custom properties can allow you to provide additional information that may help
                you understand and replicate why an error occurred. You can do this by passing an optional
                parameter to the ReportCrash method which takes a dictionary.
            </p>
            <p>
                An example of this is shown below
            </p>

            <SyntaxHighlighter language='swift'>
                {
                    `
let customProperties: [String: Any] = [
\t"Key1": "Value 1",
    "Key2": "Value 2"
];

let ccException = CCException(name: "API Fault", reason: "API returned unexpected response");
self.crashcatch.ReportCrash(exception: ccException, severity: CrashCatch.Severity.LOW, customProperties: customProperties);
                    `
                }
            </SyntaxHighlighter>

            <p>
                That's it, that's the library initialised and able to report crashes.
            </p>

            <p>
                If you have any questions or issues setting up the library, then please let us know.
                You can raise a support ticket by visiting <a href='https://support.boardiesitsolutions.com'>https://support.boardiesitsolutions.com</a>.
            </p>
        </div>
    )
}

export default IOSInstructions;