import * as React from "react";
import {AndroidDeviceDetails} from "../../../Models/DeviceDetails";

export default function AndroidDevice(props: AndroidDeviceDetails) {
    return (
        <table className='dataTable'>
            <tbody>
                <tr>
                    <td>Manufacturer</td>
                    <td>{props.device_brand}</td>
                </tr>
                <tr>
                    <td>Model</td>
                    <td>{props.device_model}</td>
                </tr>
                <tr>
                    <td>API Level</td>
                    <td>{props.api_level}</td>
                </tr>
                <tr>
                    <td>Kernel Version</td>
                    <td>{props.kernel_version}</td>
                </tr>
                <tr>
                    <td>ROM Build</td>
                    <td>{props.rom_build}</td>
                </tr>
                <tr>
                    <td>Mobile Network</td>
                    <td>{props.mobile_network}</td>
                </tr>
                <tr>
                    <td>Screen Resolution</td>
                    <td>{props.screen_resolution}</td>
                </tr>
                <tr>
                    <td>Locale</td>
                    <td>{props.locale}</td>
                </tr>
            </tbody>
        </table>
    )
}