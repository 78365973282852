import {CrashFilterStatus, CrashFilterType, CrashGroup} from "../Models/CrashDetails";
import {HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import {FaPlusCircle, FaTrash, FaUser} from "react-icons/fa";
import * as React from "react";
import {toast} from "react-toastify";

/*export const fetchCrashGroups = async (project_id: string, assigneeFilter: number, crashTypeFilter: CrashFilterType,
                                 crashStatusFilter: CrashFilterStatus) => {
    //return new Promise(async function(resolve, reject){

}*/

export const getProjectVersions = async (project_id: string) => {

    try
    {
        const response = await sendRequest(null, `/projects/project/${project_id}/versions`);
        const projectVersionsArr = [];
        response.data.forEach(versionDetails => {
            projectVersionsArr.push(versionDetails.version);
        });

        return projectVersionsArr;
    }
    catch (err)
    {
        throw err;
    }
}

export const getProjectTeamMembersAndOtherAvailableMembers = (project_id: string, showConfirmDeleteProjectTeamMember: Function, showConfirmAddUserToProjectModal: Function) => {
    return new Promise(async function (resolve, reject){
        const postArray = {
            project_id: project_id
        };

        try
        {
            const response = await sendRequest(null, `/projects/project/${project_id}/members`);
            const projectTeamMembersArr = [];
            for (let i = 0; i < response.data.ProjectTeamMembers.length; i++)
            {
                const registeredAtArray = response.data.ProjectTeamMembers[i].created_at.split(" ");
                const registeredAt = registeredAtArray[i];
                projectTeamMembersArr.push(
                    <tr key={i}>
                        <td className='hidden sm:table-cell text-center'>
                            {
                                response.data.ProjectTeamMembers[i].profile_image.length !== 0 ? <img className='rounded-circle' style={{width: 40, height: 40}}
                                                                                                     src={response.data.ProjectTeamMembers[i].profile_image} alt={response.data.ProjectTeamMembers[i].name} />
                                    : <FaUser className='rounded-circle' style={{backgroundColor: 'white', fontSize: 40}} />
                            }
                        </td>
                        <td className='hidden sm:table-cell'>{registeredAt}</td>
                        <td>{response.data.ProjectTeamMembers[i].name}</td>
                        <td>{response.data.ProjectTeamMembers[i].email}</td>
                        <td className='text-right'>
                            <FaTrash className='text-red-700 text-right' onClick={() => {
                                showConfirmDeleteProjectTeamMember(response.data.ProjectTeamMembers[i].name,
                                    response.data.ProjectTeamMembers[i].user_id);
                            }} />
                        </td>
                    </tr>
                )}

            const availableUsersArr = [];
            if (response.data.AvailableUsers.length > 0) {
                for (let i = 0; i < response.data.AvailableUsers.length; i++) {
                    const registeredAtArray = response.data.AvailableUsers[i].created_at.split(" ");
                    const registeredAt = registeredAtArray[0];
                    availableUsersArr.push(
                        <tr key={i}>
                            <td className='hidden sm:table-cell text-center'>
                                {
                                    response.data.AvailableUsers[i].profile_image.length !== 0 ?
                                        <img className='rounded-circle' style={{width: 40, height: 40}}
                                             src={response.data.AvailableUsers[i].profile_image}
                                             alt={response.data.AvailableUsers[i].name}/>
                                        : <FaUser className='rounded-circle'
                                                  style={{backgroundColor: 'white', fontSize: 40}}/>
                                }
                            </td>
                            <td className='hidden sm:table-cell'>{registeredAt}</td>
                            <td>{response.data.AvailableUsers[i].name}</td>
                            <td>{response.data.AvailableUsers[i].email}</td>
                            <td className='text-right'>
                                <FaPlusCircle onClick={() => showConfirmAddUserToProjectModal(project_id, response.data.AvailableUsers[i].id, response.data.AvailableUsers[i].name)}
                                              className='text-primary cursor-pointer text-right' title='Add User to Project' />
                            </td>
                        </tr>
                    )
                }
            }
            else
            {
                availableUsersArr.push(
                    <tr key={0}>
                        <td colSpan={5} style={{textAlign: 'center', fontStyle: 'italic'}}>
                            There are no other team members available
                        </td>
                    </tr>
                )
            }

            resolve({
                AvailableUsers: availableUsersArr,
                TeamMemberRows: projectTeamMembersArr
            })
        }
        catch (err)
        {
            if (typeof err.response !== typeof undefined)
            {
                reject(err.response.data);
            }
            else
            {
                reject({
                    status: 100,
                    message: "An unexpected error has occurred",
                    data: null
                });
            }

        }
    })

};

export const getProjectSettings = (project_id: string) => {
    return new Promise(async function(resolve, reject){

        try
        {
            const response = await sendRequest(null, `/projects/project/${project_id}`);
            resolve(response.data);
        }
        catch (err)
        {
            reject(err);
        }
    })

}

/*export const getProjectMembers = async (project_id: string, crashGroup: CrashGroup) => {

    const result = await sendRequest(null, `/projects/project/${project_id}/members`);
    if (result.status === 0)
    {
        let selectedAssignmentName = '';
        let selectedAssignmentId;
        const projectMembersArr = [];
        for (let i = 0; i < result.data.ProjectTeamMembers.length; i++) {
            if ((typeof crashGroup !== typeof undefined) && crashGroup.assigned_to !== 0) {
                if (result.data.ProjectTeamMembers[i].user_id === crashGroup.assigned_to) {
                    selectedAssignmentName = result.data.ProjectTeamMembers[i].name;
                    selectedAssignmentId = result.data.ProjectTeamMembers[i].user_id;
                }
            } else {
                selectedAssignmentName = "Not Assigned";
                selectedAssignmentId = 9;
            }

            projectMembersArr.push({
                link: result.data.ProjectTeamMembers[i].user_id,
                title: result.data.ProjectTeamMembers[i].name
            });
        }
        return {
            SelectedAssignmentDetails: {
                userId: selectedAssignmentId,
                assignmentName: selectedAssignmentName
            },
            ProjectMembers: projectMembersArr
        }
    }
    else
    {
        throw result;
    }
}*/

/*export const getProjectDetails = async (project_id: string) => {
    return new Promise(async function (resolve, reject){

        const result = await sendRequest(null, `/projects/project/${project_id}`);
        if (result.status === 0)
        {
            resolve(result.data);
        }
        else
        {
            reject(result);
        }
    })

}*/


export const addUserToProject = (project_id: string, user_id: number, name: string, showConfirmDeleteProjectTeamMember: Function, showConfirmAddUserToProjectModal: Function) => {
    return new Promise(async function(resolve, reject){
        try
        {
            const response = await sendRequest(null, `/projects/project/${project_id}/member/${user_id}`, HTTP_METHOD.POST);
            if (response.status === 0)
            {
                toast.success("User has been successfully added as a team member to this project");
                getProjectTeamMembersAndOtherAvailableMembers(project_id, showConfirmDeleteProjectTeamMember, showConfirmAddUserToProjectModal).then(function (result: any) {
                    //setAvailableUserRows(result.AvailableUsers);
                    //setTeamMemberRows(result.TeamMemberRows)
                    resolve(result);
                });
            }
            else
            {
                reject(response);
            }
        }
        catch (err)
        {
            if (typeof err.response.data !== typeof undefined)
            {
                reject(err.response.data);
            }
            else
            {
                reject({
                    status: 100,
                    message: "An unexpected error has occurred",
                    data: null
                });
            }
        }
    })

}

export const deleteProjectTeamMember = (project_id: string, name: string, user_id: number, showConfirmDeleteProjectTeamMember: Function, showConfirmAddUserToProjectModal: Function) => {
    return new Promise(async function (resolve, reject){
        try
        {
            const response = await sendRequest(null, `/projects/project/${project_id}/member/${user_id}`, HTTP_METHOD.DELETE);
            if (response.status === 0)
            {
                toast.success("Team member successfully removed from project");
                getProjectTeamMembersAndOtherAvailableMembers(project_id, showConfirmDeleteProjectTeamMember, showConfirmAddUserToProjectModal).then(function(result: any){
                    //setAvailableUserRows(result.AvailableUsers);
                    //setTeamMemberRows(result.TeamMemberRows)
                    resolve(result);
                });
            }
            else
            {
                reject(response);
            }
        }
        catch (err)
        {
            if (typeof err.response.data !== typeof undefined)
            {
                reject(err.response.data);
            }
            else
            {
                reject({
                    status: 100,
                    message: "An unexecpted error has occurred",
                    data: null
                })
            }
        }
    })
};