import * as React from "react";
import {IssueDetails} from "../../Models/Issues";
import IssueStats24Hours from "./Summary/IssueStats24Hours";
import IssueStats30Days from "./Summary/IssueStats30Days";
import {ProjectType} from "../../Models/Projects";

export default function CrashGroupSummary(issue: IssueDetails) {

    const getAffectedVersions = () => {

        const foundVersions = [];
        const rows = [];
        issue.incidents.forEach(incident => {
            const versionIndex = foundVersions.findIndex(e => e.version === incident.version_name);
            if (versionIndex === -1) //Version not found
            {
                foundVersions.push({
                    version: incident.version_name,
                    count: 1
                })
            }
            else
            {
                foundVersions[versionIndex].count++;
            }
        });

        foundVersions.sort((a, b) =>
            a.version.localeCompare(b.version)
        )

        foundVersions.reverse();

        foundVersions.map((version, index) => {
            const percentage = Math.round((version.count / issue.incidents.length) * 100);
            rows.push(
                <tr key={index}>
                    <td>{version.version}</td>
                    <td>{`${percentage}%`}</td>
                </tr>
            )
        })

        return rows;
    }

    const getAffectedDevices = () => {
        const foundDevices = [];
        const rows = [];
        issue.incidents.forEach(incident => {
            let deviceIndex = -1;
            let deviceName = "";
            switch (issue.project_details.project_type)
            {
                case ProjectType.ReactJS:
                    deviceName = `${incident.device_details.browser} ${incident.device_details.browser_version}`
                    deviceIndex = foundDevices.findIndex(e => e.name === deviceName);
            }

            if (deviceIndex === -1)
            {
                foundDevices.push({
                    name: deviceName,
                    count: 1
                });
            }
            else
            {
                foundDevices[deviceIndex].count++;
            }
        });

        foundDevices.sort((a, b) =>
            a.name.localeCompare(b.name)
        )

        foundDevices.map((device, index) => {
            const percentage = Math.round((device.count / issue.incidents.length) * 100);
            rows.push(
                <tr key={index}>
                    <td>{device.name}</td>
                    <td>{`${percentage}%`}</td>
                </tr>
            )
        })

        return rows;
    }

    return (
        <>
            <div className='flex flex-col md:flex-row'>

                <div className='w-full md:w-fit mx-2'>
                    <table className='hidden md:block dataTable'>
                        <tbody>
                            <tr>
                                <td>Current Status</td>
                                <td>{issue.status}</td>
                            </tr>
                            <tr>
                                <td>Class File/Script Name</td>
                                <td>{issue.class_script_name}</td>
                            </tr>
                            <tr>
                                <td>Line No</td>
                                <td>{issue.line_no}</td>
                            </tr>
                            <tr>
                                <td>Exception Message</td>
                                <td>{issue.incidents[0].exception_message}</td>
                            </tr>
                            <tr>
                                <td>Latest Stacktrace</td>
                                <td>
                                    {
                                        issue.incidents[0].stacktrace.split('\n').map(line => {
                                            if (line.trim().length > 0) {
                                                return (
                                                    <div className='text-sm bg-gray-200 w-full px-1 py-2 border-[1px] border-gray-400'>
                                                        {line}
                                                    </div>
                                                )
                                            }
                                            else
                                            {
                                                return null;
                                            }
                                        })
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='block md:hidden w-full mt-4'>
                        <p>
                            <span className='font-bold'>Current Status</span><br />
                            {issue.status}
                        </p>
                        <p>
                            <span className='font-bold'>Class/Script Name</span><br />
                            {issue.class_script_name}
                        </p>
                        <p>
                            <span className='font-bold'>Line No</span><br />
                            {issue.line_no}
                        </p>
                        <p>
                            <span className='font-bold'>Exception Message</span><br />
                            {issue.incidents[0].exception_message}
                        </p>
                        <p>
                            <span className='font-bold'>Latest Stacktrace</span><br />
                        </p>
                        {
                            issue.incidents[0].stacktrace.split('\n').map(line => {
                                if (line.trim().length > 0) {
                                    return (
                                        <div className='text-sm bg-gray-200 w-full px-1 py-2 border-[1px] border-gray-400 break-all'>
                                            {line}
                                        </div>
                                    )
                                }
                                else
                                {
                                    return null;
                                }
                            })
                        }

                    </div>
                </div>

                <div className='w-full md:wfit flex flex-wrap mx-2'>
                    <IssueStats24Hours crash_group_id={issue.crash_group_id} />
                    <IssueStats30Days crash_group_id={issue.crash_group_id} />

                    <div className='w-full md:w-[360px] p-2 m-2 bg-white border-[1px] border-gray-300'>
                        <h2>Affected Versions</h2>
                        <table className='dataTable'>
                            <thead>
                            <tr>
                                <th>Version Name</th>
                                <th>% of Crashes Affected</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                getAffectedVersions()
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className='w-full md:w-[360px] p-2 m-2 bg-white border-[1px] border-gray-300'>
                        <h2>Affected Versions</h2>
                        <table className='dataTable'>
                            <thead>
                            <tr>
                                <th>Device Name</th>
                                <th>% of Crashes Affected</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                getAffectedDevices()
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}