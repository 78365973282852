import * as React from "react";
import TopNavBar from "./TopNavBar";

import { FaEnvelope } from 'react-icons/fa';

function NotFound()
{
    return (
        <div className='wrapper' style={{minHeight: 100 + '% !important', margin: 0 + ' !important', padding: 0 + ' !important'}}>

            <TopNavBar displayTimePeriodButton={false} />
            <div className="mainContentNoSidebar">



                <div className='items-center justify-center justify-items-center text-center'>
                    <div className='w-full items-center justify-items-center text-center'>
                        <img style={{width: 320}} className='ml-auto mr-auto text-center items-center text-center mb-12' src='/images/logo.png' alt='Logo' />
                    </div>
                    <h1 style={{fontSize: 70 + 'px', fontWeight: 'bold'}}>404</h1>
                    <p style={{fontSize: 'xx-large', fontWeight: 'bold'}}>The Page Was Not Found</p>

                    <p>
                        You can check our <a href='https://status.devso.io' rel='nofollow noreferrer' target='_blank'>Status Pages</a> to
                        see if there are any ongoing issues.
                    </p>
                    <p style={{fontWeight: 'bold'}}>
                        <FaEnvelope className='inline'/> Need some help. Raise a support a ticket on our <a href='https://support.devso.io' rel='noreferrer' target='_blank'>support portal</a>
                    </p>

                    <div style={{width: 50 + '%', marginLeft: 'auto', marginRight: 'auto'}}>

                        <div className='row' style={{marginTop: 60 + 'px'}}>
                            <div className='col-md-3'>
                                <a style={{color: 'black'}} href='https://blog.devso.io' target='_blank' rel='noreferrer'>Boardies IT Solutions Blog</a>
                            </div>
                            <div className='col-md-3'>
                                <a style={{color: 'black'}} href='https://support.devso.io' target='_blank' rel='noreferrer'>Crash Catch Docs</a>
                            </div>
                            <div className='col-md-3'>
                                <a style={{color: 'black'}} href='https://support.devso.io' target='_blank' rel='noreferrer'>Support Portal</a>
                            </div>
                            <div className='col-md-3'>
                                <a style={{color: 'black'}} href='https://status.devso.io' target='_blank' rel='noreferrer'>Status Pages</a>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default NotFound;