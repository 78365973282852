import * as React from "react";
import {useState} from "react";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import {
    Loading,
    LoadingSize, ModalDialog, ModalState,
    PrimaryButton,
    returnErrorComponent,
    Select,
    TextField,
    TextFieldType
} from "devso-react-library";
import useProjects from "../../Hooks/useProjects";
import Footer from "../Footer";
import Projectcard from "./ProjectCard";
import {useHistory} from "react-router-dom";
import {ProjectType} from "../../Models/Projects";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import Wrapper from "../Wrapper";

export default function ProjectDashboard() {

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Projects",
            link: ""
        }
    ]

    const [searchTerm, setSearchTerm] = useState('');
    const {projects, projectsLoading, projectError, getProjects} = useProjects();
    const [modalState, setModalState] = useState<ModalState>(null);

    const history = useHistory();

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <div className='flex flex-row mb-6'>
                        <div className='w-1/2'>
                            <h1>Projects</h1>
                        </div>
                        <div className='w-1/2 text-right'>
                            <PrimaryButton label='Create Project'
                                onClick={() => history.push('/add-project')}/>
                        </div>
                    </div>

                    <TextField type={TextFieldType.TEXT} label={null}
                               api_field_name={'search_term'}
                               value={searchTerm} onChange={setSearchTerm}
                               placeholder='Search for Project' />

                    {
                        (
                            () => {
                                if (projectsLoading)
                                {
                                    return <Loading size={LoadingSize.SMALL} />
                                }
                                else if (!projectsLoading && projectError !== null)
                                {
                                    return returnErrorComponent(projectError, "Failed to get projects");
                                }
                                else if (!projectsLoading && projects !== null)
                                {
                                    return projects.length > 0 ?
                                        <div className=' mt-5 overflow-y-auto'>
                                            <div className='flex flex-wrap'>
                                                {
                                                    projects.map(project => {
                                                        if (searchTerm === "" || project.project_name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
                                                        {
                                                            return (
                                                                <Projectcard project_details={project} setModalState={setModalState}
                                                                    getProjects={getProjects}/>
                                                            )
                                                        }
                                                        else
                                                        {
                                                            return null;
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div> :
                                        <>
                                            <p className='italic text-center'>
                                                You currently have no projects
                                            </p>

                                            <div className='text-center'>
                                                <PrimaryButton label='Create your first project'
                                                               onClick={() => history.push('/add-project')} />
                                            </div>

                                        </>

                                }
                                else
                                {
                                    return null;
                                }
                            }
                        )()
                    }

                </ContentWrapper>

                <Footer />
            </div>
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}