import * as React from "react";

export type ReactJSDeviceDetailsProps = {
    browser: string,
    browser_version: string,
    browser_width_height: string,
    screen_resolution: string,
    locale: string
}

function ReactJSDeviceDetails(props: ReactJSDeviceDetailsProps)
{
    return (
        <table className="dataTable">
            <tbody>
                <tr>
                    <td>Browser</td>
                    <td>{props.browser}</td>
                </tr>
                <tr>
                    <td>Browser Version</td>
                    <td>{props.browser_version}</td>
                </tr>
                <tr>
                    <td>Browser Width Height</td>
                    <td>{props.browser_width_height}</td>
                </tr>
                <tr>
                    <td>Screen Resolution</td>
                    <td>{props.screen_resolution}</td>
                </tr>
                <tr>
                    <td>Locale</td>
                    <td>{props.locale}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ReactJSDeviceDetails;