import * as React from "react";
import {useEffect, useState} from "react";
import GroupToggleButtons from "../GroupToggleButtons";
import {Bar} from "react-chartjs-2";
import {fetchCrashGroupStats} from "../../Functions/CrashManagement";
import {returnErrorComponent} from "devso-react-library";

function CrashSummaryBarChart(props)
{
    const [currentTimePeriodAndChartTitle, setCurrentTimePeriodAndChartTitle] = useState({currentTimePeriod: '1 Hour', chartTitle: 'Crash Counts Per 1 Hour'});
    const [chartData, setChartData] = useState({});
    const [errors, setErrors] = useState(null);

    const groupButtonOptions = [
        {
            label: "1 Hour",
            selected: true
        },
        {
            label: "3 Hours",
            selected: false
        },
        {
            label: "1 Day",
            selected: false
        },
        {
            label: "1 Week",
            selected: false
        },
        {
            label: "1 Month",
            selected: false
        },
        {
            label: "3 Months",
            selected: false
        }
    ];

    const handleTimePeriodClickChanged = (timePeriod) => {
        let chartTitle = '';
        switch (timePeriod)
        {
            case "1 Hour":
                chartTitle = 'Crash Counts Per 5 Minutes';
                break;
            case "3 Hours":
                chartTitle = "Crash Counts Per 15 Minutes";
                break;
            case "1 Day":
                chartTitle = "Crash Counts Per 1 Hour";
                break;
            case "1 Week":
                chartTitle = "Crash Counts Per 1 Day";
                break;
            case "1 Month":
                chartTitle = "Crash Counts Per 1 Week";
                break;
            case "3 Months":
                chartTitle = "Crash Counts Per 3 Months";
                break;
        }
        const obj = {
            currentTimePeriod: timePeriod,
            chartTitle: chartTitle
        }
        //setCurrentTimePeriodAndChartTitle({currentTimePeriod: timePeriod, chartTitle: chartTitle});
        setCurrentTimePeriodAndChartTitle({...obj});
    };

    useEffect(() => {
        fetchCrashGroupStats(props.project_id, props.crash_group, currentTimePeriodAndChartTitle).then(function(result){
            setChartData(result);
        }).catch(function(err){
            setErrors(returnErrorComponent(err, "Failed to crash group stats"));
        });
    }, [props.project_id, props.crash_group, currentTimePeriodAndChartTitle]);

    if (errors === null) {
        return (
            <div className='w-full'>
                <div style={{clear: 'both', textAlign: 'right'}}>
                    <GroupToggleButtons selectedLabel={currentTimePeriodAndChartTitle.currentTimePeriod}
                                        handleToggleChange={handleTimePeriodClickChanged}
                                        buttonOptions={groupButtonOptions}/>
                </div>
                <Bar height={150} data={chartData} options={{
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    precision: 0,
                                    min: 0
                                }
                            }
                        ]
                    }
                }}/>
                <p style={{textAlign: 'center', fontSize: 'small', paddingTop: 10 + 'px'}}>
                    Chart dates/times are in UTC
                </p>

            </div>
        )
    }
    else
    {
        return errors;
    }
}

export default CrashSummaryBarChart;