import * as React from "react";
import {CrashCatch} from "crashcatchlib-reactjs"
import isDev from 'react-is-dev';
import {FaProjectDiagram} from "react-icons/fa";
import {Link} from "react-router-dom";


export const initialiseCrashCatch = (callback = null) => {

    const ifDevEnv = isDev(React);
    if (!ifDevEnv)
    {
        const crashcatch = new CrashCatch();
        crashcatch.initialiseCrashCatch("64172089", "5sqrwtkibg40efno6xj81dh2m", "2.1.1", function (result){

            if (callback !== null)
            {
                callback({
                    result: result,
                    crashcatch: crashcatch
                });
            }
        })
    }
}

const getCurrentPath = function()
{
    return window.location.href.substr(window.location.href.lastIndexOf("/")+1);
};

const returnFormattedDateStringFromDateObj = function(dateObj, includeTime = true)
{
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() - 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getUTCHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    if (includeTime) {
        return day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    }
    else
    {
        return day + "-" + month + "-" + year;
    }
};

export const validationCompleted = function()
{
    const validInputCount = document.querySelectorAll("[data-input-valid='true']").length;
    const invalidInputCount = document.querySelectorAll("[data-input-valid='false']").length;

    if (validInputCount > 0 && invalidInputCount === 0)
    {
        disableSubmitButtons(false);
    }
    else
    {
        disableSubmitButtons(true);
    }
}

export const disableSubmitButtons = (disabled) => {
    const submitButtons : any = document.querySelector("button[type='submit']");
    if (submitButtons !== null)
    {
        submitButtons.disabled = disabled;
    }
}

/*export const returnDefaultModalStatus = () : ModalDialogProps => {
    return {
        show: false,
        title: '',
        icon: null,
        primaryButtonLabel: '',
        primaryButtonFunc: null,
        secondaryButtonLabel: null,
        secondaryButtonFunc: null,
        body: null
    }
}*/

export const getProjectIconFromString = (projectType: string, style : any|undefined = undefined) => {

    if (typeof style === typeof undefined)
    {
        style = {width: 25 + 'px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'};
    }

    switch (projectType)
    {
        case "Android":
            return <img data-tip='Android' style={style} src='/images/platforms/android.png' alt='Android' />;
        case "ReactJS":
            return <img data-tip='ReactJS' style={style} src='/images/platforms/reactjs.png' alt='ReactJS' />;
        case "PHP":
            return <img data-tip='PHP' style={style} src='/images/platforms/php.png' alt='PHP' />;
        case "CPP":
            return <img data-tip='C++' style={style} src='/images/platforms/cpp.png' alt='C++' />;
        case "Javascript":
            return <img data-tip='Javascript' style={style} src='/images/platforms/js.png' alt='Javascript' />;
        case "Python":
            return <img data-tip='Python' style={style} src='/images/platforms/python.png' alt='Python' />;
        case "Ruby":
            return <img data-tip='Ruby' style={style} src='/images/platforms/ruby.png' alt='Ruby' />;
        case "C#":
            return <img data-tip='C#' style={style} src='/images/platforms/c_sharp.png' alt='C#' />;
        case "Custom SDK":
            return <img data-tip='Custom SDK' style={style} src='/images/logo.png' alt='Custom SDK' />;
        case "iOS":
            return <img data-tip='iOS' style={style} src='/images/platforms/ios.png' alt='iOS' />
        case "Flutter":
            return <img data-tip='Flutter' style={style} src='/images/platforms/flutter.png' alt='Flutter' />
        default:
            console.error("Invalid Project Platform icon retrieval: Project Type: " + projectType);
            return <FaProjectDiagram />
    }
}

export const getProjectLinkFromProjectDetails = (projectDetails) => {
    let link;
    switch (projectDetails.project_type)
    {
        case 'Android':
        case "ReactJS":
        case "PHP":
        case "Custom SDK":
        case "iOS":
        case "Flutter":
            //link = <a href={"/crash-details/" + projectDetails.project_id + "/" + projectDetails.project_type}>{projectDetails.project_name}</a>
            link = <Link to={"/crash-details/" + projectDetails.project_id + "/" + projectDetails.project_type}>{projectDetails.project_name}</Link>
            break;
            //case 'ReactJS'
        default:
            console.error("Invalid project language for dashboard link. Project language was: " + projectDetails.project_type);
    }
    return link
}

export const convertFileInputToBase64 = file => {
    return new Promise(function(resolve, reject){
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function useEnvironment()
{
    return process.env.NODE_ENV;
}

export function generateRandomString(length = 5)
{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// export function returnErrorComponent(exception, errorTitle)
// {
//     if (exception.response?.data?.message === "Unauthenticated.")
//     {
//         const redirectUrlBase64 = btoa(window.location.href);
//         window.location.href = "/login/You are required to login to view that page/" + redirectUrlBase64;
//     }
//     else {
//         return (
//             <div className='text-center border border-2 border-red-600 rounded ml-auto mr-auto p-3'>
//                 <FaTimes style={{fontSize: '3em'}} className='text-center text-red-600 ml-auto mr-auto'/>
//                 <p className='font-bold text-xl'>{errorTitle}</p>
//                 {
//                     typeof exception.response?.data?.message !== typeof undefined ?
//                         exception.response.data.message : "An unexpected error has occurred"
//                 }
//             </div>
//         )
//     }
// }
//
// export function returnErrorModal(exception, errorTitle, setModalState) {
//     if (exception.response?.data?.message === "Unauthenticated.")
//     {
//         const redirectUrlBase64 = btoa(window.location.href);
//         window.location.href = "/login/You are required to login to view that page/" + redirectUrlBase64;
//     }
//     else {
//         if (exception.response?.status === 404)
//         {
//             const tempModalState : ModalDialogProps = returnDefaultModalStatus();
//             tempModalState.show = true;
//             tempModalState.title = "Page or API Request Not Found";
//             tempModalState.body = "The page or API request that was attempted, could not be found";
//             tempModalState.primaryButtonFunc = () => {
//                 const temp = returnDefaultModalStatus();
//                 temp.show = false;
//                 setModalState(temp);
//             }
//             tempModalState.primaryButtonLabel = "OK";
//             setModalState(tempModalState);
//             return tempModalState;
//         }
//         else if (typeof exception.response?.data?.message !== typeof undefined) {
//             const tempModalState: ModalDialogProps = returnDefaultModalStatus();
//             tempModalState.show = true;
//             tempModalState.title = errorTitle
//             tempModalState.body = exception.response.data.message;
//             tempModalState.primaryButtonFunc = () => {
//                 const temp = returnDefaultModalStatus();
//                 temp.show = false;
//                 setModalState(temp);
//             }
//             tempModalState.primaryButtonLabel = "OK"
//             setModalState(tempModalState);
//             return tempModalState;
//         } else {
//             const tempModalState: ModalDialogProps = returnDefaultModalStatus();
//             tempModalState.show = true;
//             tempModalState.title = errorTitle;
//             tempModalState.body = <p>An unexpected error has occurred<br /><span className='text-sm'>{exception.toString()}</span></p>;
//             tempModalState.primaryButtonFunc = () => {
//                 const temp = returnDefaultModalStatus();
//                 temp.show = false;
//                 setModalState(temp);
//             }
//             tempModalState.primaryButtonLabel = "OK";
//             setModalState(tempModalState);
//             return tempModalState;
//         }
//     }
// }


export {getCurrentPath, returnFormattedDateStringFromDateObj};
