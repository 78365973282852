import * as React from "react"
import {useEffect, useState} from "react"
import TopNavBar from "./TopNavBar";
import BreadcrumbNav, {BreadcrumbNavLink} from "./BreadcrumbNav";
import {FaUser} from "react-icons/fa";
import {convertFileInputToBase64} from "../JSFuncs/HelperFunctions";
import {FormValidationError, HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import {toast} from "react-toastify";
import Clients from "./Clients";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnErrorModal, returnDefaultModalState,SecondaryButton
} from "devso-react-library";
import Wrapper from "./Wrapper";
import Sidebar from "./Sidebar";
import ContentWrapper from "./ContentWrapper";

function Settings()
{
    const [userProfile, setUserProfile] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [modalState, setModalState] = useState(null);
    const [clientsAndPagingHandler, setClients] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const getUserProfile = async () => {
        try
        {
            const response = await sendRequest(null, '/users/profile');
            setUserProfile(response.data);
            setName(response.data.name);
            setEmail(response.data.email);
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
            //setAPIResult(err.response.data);
        }
    }

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: "Settings",
            link: ''
        }
    ]

    const handleAddProfilePictureClick = async () => {
        const profilePictureInput = document.getElementById("profilePictureSelector");


        profilePictureInput.addEventListener("input", function(event){

           // @ts-ignore
            const file = document.querySelector("#profilePictureSelector").files[0];
           convertFileInputToBase64(file).then(async function (base64) {

               const y = base64.toString().endsWith("==") ? 2 : 1
               const fileSize = (base64.toString().length * (3 / 4)) - y

               //Profile picture not bigger than 2mb
               if (fileSize > 2000000) {
                   //Create a fake API response to give to the API error handler
                   const apiResult = {
                       result: 2, //General Error
                       data: null,
                       message: 'Profile pictures cannot be larger than 2MB'
                   }
                   const tempModalState = returnErrorModal(apiResult, "An error has occurred", setModalState);
                   setModalState({...tempModalState});
                   return;
               }

               const postArray = {
                   profile_image_base64: base64
               }

               try
               {
                   await sendRequest(postArray, '/users/profile', HTTP_METHOD.PUT);
                   getUserProfile();

               }
               catch(err)
               {
                   const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                   setModalState({...tempModalState});
               }
           });
        });

        profilePictureInput.click();



    }

    const submitProfileChangeDetails = async (postArray) => {
        try
        {
            setLoading(true);
            const response = await sendRequest(postArray, '/users/profile', HTTP_METHOD.POST);
            toast.success("User profile was successfully updated");
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setErrors(err.response.data.errors);
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        finally {
            setLoading(false);
        }
    }

    const submitPasswordChange = async (postArray) => {
        try
        {
            setLoading(true);
            await sendRequest(postArray, '/users/password', HTTP_METHOD.POST);
            toast.success("Password has been successfully changed");
            setErrors(null);
        }
        catch (err)
        {
            if (err.response.status === 422)
            {
                setErrors(err.response.data.errors);
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        finally {
            setLoading(false);
        }

    }

    const confirmDeleteClient = (client_id) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Delete Client";
        tempModalState.content = <div>
            <p>
                Are you sure you want to delete this client?
            </p>
            <p>
                This may cause an authentication error if you are deleting your currently logged in client
            </p>
        </div>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                deleteClient(client_id)
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }

    const deleteClient = async (client_id: string) => {
        try
        {
            await sendRequest(null, `/users/clients/${client_id}`, HTTP_METHOD.DELETE);
            await getClients();
            toast.success("Client has been successfully removed");
            const tempModalState = returnDefaultModalState();
            setModalState({...tempModalState});
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    };

    const getClients = async () => {
        try
        {
            const response = await sendRequest(null, '/users/clients');
            setClients({
                clients: response.data.Data,
                pageHandler: response.data.PageHandler
            })
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }


    useEffect(() => {
        getUserProfile();
        getClients();
    }, []);

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full md:w-1/2 mt-4'>
                            <h2>Your Profile</h2>
                            <div className='flex flex-col'>
                                <div className='flex flex-col md:flex-row items-center'>
                                    <div className='w-full md:w-3/4 justify-center items-center justify-items-center text-center'>
                                        <input className='hidden' type='file' id='profilePictureSelector' accept="image/*" />
                                        <div className='w-full text-center ml-auto mr-auto items-center content-center justify-items-center'>
                                            {
                                                (userProfile !== null) && (userProfile.profile_image !== null) && userProfile.profile_image.length > 0 ?

                                                    <img style={{width: 120, height: 120}} src={userProfile.profile_image}
                                                         className='rounded-circle ml-auto mr-auto text-center' alt='The users profile' /> :
                                                    <FaUser className='rounded-circle ml-auto mr-auto text-center md:opacity-70' style={{backgroundColor: 'white', fontSize: 120}} />

                                            }

                                            <div className='hidden md:block mt-3'>
                                                <button className='link' onClick={handleAddProfilePictureClick}>Add Profile Picture</button>
                                                <p className='mt-4 text-xs text-center text-grey-700'>
                                                    Profile pictures cannot be bigger than 2mb<br />
                                                    Profile pictures are best at 120px x 120px
                                                </p>
                                            </div>

                                            <div className='md:hidden'>
                                                <img src='/images/add_image.png' style={{width: 32}} title='Add User profile' onClick={handleAddProfilePictureClick}
                                                     aria-hidden alt='Add Profile Image' className='-mt-24 ml-52 md:ml-32 lg:ml-64 clear-both' />
                                                <p className='mt-20 text-xs text-center text-grey-700'>
                                                    Profile pictures cannot be bigger than 2mb<br />
                                                    Profile pictures are best at 120px x 120px
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full md:w-3/4'>
                                        <Form handleSubmit={submitProfileChangeDetails} errors={errors} loading={loading}>
                                            <TextField type={TextFieldType.TEXT} label='Name' placeholder='Enter your name' api_field_name='name' value={name} onChange={setName} />
                                            <TextField type={TextFieldType.EMAIL} label='Your Email' placeholder='someone@example.com' api_field_name='email' value={email} onChange={setEmail}/>

                                            <FormButtonContainer>
                                                <PrimaryButton label='Update' />
                                            </FormButtonContainer>
                                        </Form>
                                    </div>

                                </div>

                                <div>
                                    <h2 style={{borderBottom: 'solid thin grey'}}>Reset Password</h2>
                                    <Form handleSubmit={submitPasswordChange} errors={errors} loading={false}>
                                        <TextField type={TextFieldType.PASSWORD} label='Password' placeholder='Enter new password' api_field_name='password' value={password} onChange={setPassword} />
                                        <TextField type={TextFieldType.PASSWORD} label='Confirm Password' placeholder='Confirm New Password' api_field_name='password_confirmation' value={confirmPassword} onChange={setConfirmPassword} />
                                        <FormButtonContainer>
                                            <PrimaryButton label='Change Password' />
                                        </FormButtonContainer>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-1/2 w-full md:m-4 mt-5'>
                            <h2>Where you are logged in</h2>
                            <p>
                                Below is a list of places where your account has been logged in. If you
                                do not recognise somewhere, you can delete it to revoke access for that login.
                            </p>
                            <p>
                                If this is the case, then it is advised that you change your password as soon as possible
                            </p>
                            <div className='items-center justify-items-center ml-auto mr-auto w-full'>
                                <Clients clientsAndPagingHandler={clientsAndPagingHandler} confirmDeleteClient={confirmDeleteClient} />
                            </div>
                        </div>
                    </div>

                </ContentWrapper>
            </div>
        </Wrapper>
    )

    /*return (

        <div className="wrapper">

            <TopNavBar displayTimePeriodButton={false} title='Settings' />

            <BreadcrumbNav menu_items={breadcrumbNav} />

            <div className='mainContentNoSidebar' style={{padding: 10}}>

                <h1>Settings</h1>

                <div className='flex flex-col md:flex-row'>
                    <div className='w-full md:w-1/2'>
                        <div className='flex flex-col'>
                            <div className='flex flex-col md:flex-row items-center'>
                                <div className='w-full md:w-3/4 justify-center items-center justify-items-center text-center'>
                                    <input className='hidden' type='file' id='profilePictureSelector' accept="image/*" />
                                    <div className='w-full text-center ml-auto mr-auto items-center content-center justify-items-center'>
                                        {
                                            (userProfile !== null) && (userProfile.profile_image !== null) && userProfile.profile_image.length > 0 ?

                                                <img style={{width: 120, height: 120}} src={userProfile.profile_image}
                                                    className='rounded-circle ml-auto mr-auto text-center' alt='The users profile' /> :
                                                <FaUser className='rounded-circle ml-auto mr-auto text-center md:opacity-70' style={{backgroundColor: 'white', fontSize: 120}} />

                                        }

                                        <div className='hidden md:block mt-3'>
                                            <button className='link' onClick={handleAddProfilePictureClick}>Add Profile Picture</button>
                                            <p className='mt-4 text-xs text-center text-grey-700'>
                                                Profile pictures cannot be bigger than 2mb<br />
                                                Profile pictures are best at 120px x 120px
                                            </p>
                                        </div>

                                        <div className='md:hidden'>
                                            <img src='/images/add_image.png' style={{width: 32}} title='Add User profile' onClick={handleAddProfilePictureClick}
                                                 aria-hidden alt='Add Profile Image' className='-mt-24 ml-52 md:ml-32 lg:ml-64 clear-both' />
                                            <p className='mt-20 text-xs text-center text-grey-700'>
                                                Profile pictures cannot be bigger than 2mb<br />
                                                Profile pictures are best at 120px x 120px
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full md:w-3/4'>
                                    <Form handleSubmit={submitProfileChangeDetails} errors={errors} loading={loading}>
                                        <TextField type={TextFieldType.TEXT} label='Name' placeholder='Enter your name' api_field_name='name' value={name} onChange={setName} />
                                        <TextField type={TextFieldType.EMAIL} label='Your Email' placeholder='someone@example.com' api_field_name='email' value={email} onChange={setEmail}/>

                                        <FormButtonContainer>
                                            <PrimaryButton label='Update' />
                                        </FormButtonContainer>
                                    </Form>
                                </div>

                            </div>

                            <div>
                                <h2 style={{borderBottom: 'solid thin grey'}}>Reset Password</h2>
                                <Form handleSubmit={submitPasswordChange} errors={errors} loading={false}>
                                    <TextField type={TextFieldType.PASSWORD} label='Password' placeholder='Enter new password' api_field_name='password' value={password} onChange={setPassword} />
                                    <TextField type={TextFieldType.PASSWORD} label='Confirm Password' placeholder='Confirm New Password' api_field_name='password_confirmation' value={confirmPassword} onChange={setConfirmPassword} />
                                    <FormButtonContainer>
                                        <PrimaryButton label='Change Password' />
                                    </FormButtonContainer>
                                </Form>
                            </div>
                        </div>


                    </div>

                    <div className='md:w-1/2 w-full md:m-4 mt-5'>
                        <h2>Where you are logged in</h2>
                        <p>
                            Below is a list of places where your account has been logged in. If you
                            do not recognise somewhere, you can delete it to revoke access for that login.
                        </p>
                        <p>
                            If this is the case, then it is advised that you change your password as soon as possible
                        </p>
                        <div className='items-center justify-items-center ml-auto mr-auto w-full'>
                            <Clients clientsAndPagingHandler={clientsAndPagingHandler} confirmDeleteClient={confirmDeleteClient} />
                        </div>
                    </div>



                </div>

            </div>
            <ModalDialog {...modalState} />
        </div>


    )*/
}

export type ClientsProps = {
    confirmDeleteClient: Function,
    clientsAndPagingHandler: any
}

export default Settings;