import * as React from "react";
import {useState} from "react";
import TopNavBar from "./TopNavBar";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom"
import {HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnErrorModal
} from "devso-react-library";

export type ResetPasswordProps = {
    match: {
        params: {
            resetPasswordData
        }
    }
}

function ResetPassword(props: any)
{
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [modalState, setModalState] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const history = useHistory();


    const changePassword = async (postData) => {


        if (password !== confirmPassword)
        {
            const errors = {
                confirm_password: "Your passwords do not match"
            };
            setFormErrors(errors);
            return;
        }

        const postArray = {
            password: password,
            passwordResetData: props.match.params.resetPasswordData
        };

        try
        {
            setFormErrors(null);
            setFormLoading(true);
            await sendRequest(postArray, '/account-management/perform-password-reset', HTTP_METHOD.POST);
            toast.success("Your password has been successfully updated. Please login below");
            history.push('/Login');
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        finally
        {
            setFormLoading(false);
        }
    }

    return (
        <div className='wrapper'>

            <TopNavBar hideCreateProject={true} hideMenu={true} displayTimePeriodButton={false} title='Forgotten My Password' />

            <div className="mainContentNoSidebar">


                <div className="container" style={{position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'}}>

                    <div style={{textAlign: 'center'}}>
                        {/*<div style={{display: 'inline-block'}}>
                            <img src='/images/logo.png' style={{width: 240}} alt='Crash Catch Logo' />
                        </div>
                        <div style={{display: 'inline-block', fontWeight: 'bold', fontSize: 'large'}}>
                            <p>
                                Crash reporting for every developer, every platform and every programming language
                            </p>
                        </div>*/}

                        <div className='grid grid-cols-2 w-1/2 ml-auto mr-auto'>
                            <div className=''>
                                <img src='/images/logo.png' style={{width: 240}} alt='Crash Catch Logo' />
                            </div>
                            <div className='mt-15 pt-30 items-center justify-items-center'>
                                <p className='font-bold mt-12'>
                                    Crash reporting for every developer, every platform and every programming language
                                </p>
                            </div>
                        </div>

                        <div className='w-1/2 ml-auto mr-auto text-left'>
                            <Form handleSubmit={changePassword} errors={formErrors} loading={formLoading}>
                                <TextField type={TextFieldType.PASSWORD} label='New Password' placeholder='Enter your password'
                                           api_field_name='password' value={password} onChange={setPassword} />

                                <TextField type={TextFieldType.PASSWORD} label='Confirm Password' placeholder='Confirm your password'
                                           api_field_name='confirm_password' value={confirmPassword} onChange={setConfirmPassword} />

                                <span className='text-sm text-grey-400'>
                                    Your password must be at least 7 characters, have at least 1 lower case character,
                                    have at least 1 uppercase character, have at least one digit and at least 1 special character
                                </span>

                                <FormButtonContainer loading={formLoading}>
                                    <PrimaryButton label='Reset Password' />
                                </FormButtonContainer>
                            </Form>
                        </div>
                        {/*<form onSubmit={changePassword} style={{width: '60%', marginLeft: 'auto', marginRight: 'auto'}}>

                            <label>
                                <span>New Password</span>
                                <ValidatedInput type='password'
                                    placeholder='Please enter your password'
                                    id='txtPassword'
                                    value={password}
                                    validateError='Please enter your password'
                                    onChange={setPassword} validationCompleted={validationCompleted} />

                            </label>


                            <label>
                                <span>Confirm Password</span>
                                <ValidatedInput type='password'
                                    placeholder='Please confirm your password'
                                    id='txtConfirmPassword'
                                    value={confirmPassword}
                                    validateError='Please confirm your password'
                                    onChange={setConfirmPassword} validationCompleted={validationCompleted} />

                            </label>

                            <div className='formButtonContainer'>
                                <button className='btn btn-primary' type='submit' disabled>Change Password</button>
                            </div>
                        </form>*/}
                    </div>


                </div>

            </div>


            <ModalDialog {...modalState} />
        </div>
    )
}

export default ResetPassword;