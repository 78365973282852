import * as React from "react";
import {useEffect, useState} from "react";
import {CrashFilterStatus, CrashFilterType, CrashGroup, CrashSidebarProps} from "../../Models/CrashDetails";
import CrashGroupItem from "./CrashGroupItem";
import '../../ComponentStyles/Sidebar.css'
import DropdownMenu from "../DropdownMenu";
import {Loading, LoadingSize} from "devso-react-library";
import ReactTooltip from "react-tooltip";

function CrashSidebar(props: CrashSidebarProps)
{
    const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [crashItems, setCrashItems] = useState(null);

    const handleMobileMenuClick = () => {

        if (!mobileMenuExpanded) { //Not visible so make it visible

            setMobileMenuExpanded(true);
        }
        else
        {
            setMobileMenuExpanded(false);
        }
    }

    const buildCrashGroupView = () => {
        if ((props.crash_groups !== null) && typeof props.crash_groups !== typeof undefined)
        {
            const crashItemsArr = [];
            if (props.crash_groups.length > 0)
            {
                for (let i = 0; i < props.crash_groups.length; i++)
                {
                    const isSelected = props.crash_groups[i].crash_group_id === props.selectedCrashGroupID && props.selectedCrashGroupID !== 0
                    crashItemsArr.push(<CrashGroupItem selected={isSelected} handleCrashGroupSelected={handleCrashGroupSelected}
                                                       key={i} crash_group={props.crash_groups[i]}/>);
                }
            }
            else
            {
                crashItemsArr.push(
                    <p key={0} className='text-center italic mt-3'>
                        No crash groups match your current filter
                    </p>
                )
            }
            return crashItemsArr;
        }
    }

    const handleCrashGroupSelected = (crashGroupItem : CrashGroup) => {
        const crashItemsArr = [];
        for (let i = 0; i < props.crash_groups.length; i++)
        {
            if (props.crash_groups[i].exception_group === crashGroupItem.exception_group
                && props.crash_groups[i].class_script_name === crashGroupItem.class_script_name
                && props.crash_groups[i].line_no === crashGroupItem.line_no)
            {
                crashItemsArr.push(<CrashGroupItem selected={true} handleCrashGroupSelected={handleCrashGroupSelected} key={i}
                                                   crash_group={props.crash_groups[i]}/>);
            }
            else
            {
                crashItemsArr.push(<CrashGroupItem selected={false} handleCrashGroupSelected={handleCrashGroupSelected} key={i}
                                                   crash_group={props.crash_groups[i]}/>);
            }
        }

        props.crashGroupSelected(crashGroupItem)
        setMobileMenuExpanded(false);
        setCrashItems(crashItemsArr);

    };

    const handleAssignmentFilterClick = (userId, title) => {
        props.assignmentFilterUpdated(userId, title);
    }

    const handleCrashTypeFilterUpdated = (filter : CrashFilterType) => {
        props.crashTypeFilterUpdated(filter);
    }

    const handleCrashStatusFilterUpdate = (status : CrashFilterStatus) => {
        props.crashStatusFilterUpdated(status)
    }

    const searchTermUpdated = props.searchTermUpdated;
    useEffect(() => {
        searchTermUpdated(searchTerm);
    }, [searchTerm, searchTermUpdated]);

    useEffect(() => {
        const projectMembersArr = [];

        projectMembersArr.push({
            link: -1,
            title: 'All Crash Groups'
        });
        projectMembersArr.push({
            link: 0,
            title: 'Unassigned Only'
        });

        for (let i = 0; i < props.projectMembers.length; i++) {
            projectMembersArr.push({
                link: props.projectMembers[i].UserID,
                title: props.projectMembers[i].Name
            });
        }

    }, [props.projectMembers])

    useEffect(() => {
        const svgMenuOpen = document.querySelector("#svgMenuOpen") as HTMLElement;
        const svgMenuClosed= document.querySelector("#svgMenuClosed") as HTMLElement;
        const menuItems = document.querySelector("#mobile_menu_items") as HTMLElement;

        if (mobileMenuExpanded)
        {
            svgMenuClosed.classList.remove('block');
            svgMenuClosed.classList.add('hidden');

            svgMenuOpen.classList.remove('hidden');
            svgMenuOpen.classList.add('block');

            menuItems.classList.remove('hidden');
            menuItems.classList.add('block');


            document.documentElement.style.overflow = "hidden";
        }
        else
        {
            svgMenuClosed.classList.remove('hidden');
            svgMenuClosed.classList.add('block');

            svgMenuOpen.classList.remove('block');
            svgMenuOpen.classList.add('hidden');

            menuItems.classList.remove('block');
            menuItems.classList.add('hidden');


            document.documentElement.style.overflow = 'scroll';
        }
    }, [mobileMenuExpanded])


    return (
        <div style={{height: 'calc(100% - 160px) !important'}} className=' bg-transparent border-r-2 border-grey-400 text-black overflow-x-hidden'>
            <div className='sidebar overflow-x-hidden border-r-2 border-grey-400 bg-transparent absolute  mr-2 overflow-auto content-center items-center justify-center block hidden sm:block md:block'>

                <input className='mt-0 pl-2 pr-2 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black'
                       type='text' placeholder='Search for Crash' onChange={(e) => setSearchTerm(e.target.value)} />


               <div className='bg-grey-300 p-3 border-b-2 border-grey-600 pt-3'>

                   <div className='flex flex-col sm:flex-row'>

                       <div className='flex-1 text-left'>
                           <span className='font-bold'>Filter by Assignment: </span> <DropdownMenu onMenuItemClick={handleAssignmentFilterClick}
                                                               textAndIconColor={'#000000'} title={props.assignedTo.name} menuItems={props.projectMembers} />
                       </div>
                       <div className='flex text-right'>
                           <span className='font-bold mr-2'>Total Crash Groups: </span> {crashItems !== null ? crashItems.length : 0}
                       </div>

                   </div>

                   <div className='flex flex-row'>
                       <div className='flex-1'>
                           <p className='mt-3 mb-0 pb-0 font-bold'>Filter By Crash Type</p>

                           <label className='radioBlock pr-2'>
                               <input type='radio' id='rdoCrashTypeFilterAll'
                                      name='rdoCrashTypeFilter' value='all' checked={props.crashFilterType === CrashFilterType.All}
                                      onChange={() => handleCrashTypeFilterUpdated(CrashFilterType.All)} />
                                      <span>All</span>
                           </label>

                           <label className='radioBlock'>
                               <input type='radio' id='rdoCrashTypeFilterHandled'
                                      name='rdoCrashTypeFilter' value='handled' checked={props.crashFilterType === CrashFilterType.Handled}
                                      onChange={() => handleCrashTypeFilterUpdated(CrashFilterType.Handled)} />
                                    <span>Handled</span>
                           </label>

                           <label className='radioBlock'>
                               <input type='radio' id='rdoCrashTypeFilterUnhandled'
                                      name='rdoCrashTypeFilter' value='unhandled' checked={props.crashFilterType === CrashFilterType.Unhandled}
                                      onChange={() => handleCrashTypeFilterUpdated(CrashFilterType.Unhandled)} />
                                <span>Unhandled</span>
                           </label>

                       </div>

                       <div className='flex-1'>
                           <p className='mt-3 mb-0 pb-0 font-bold'>Filter By Crash Status</p>
                           <label className='radioBlock' htmlFor='rdoCrashStatusFilterAll'>
                               <input type='radio' id='rdoCrashStatusFilterAll'
                                      name='rdoCrashStatusFilter' value='all' checked={props.crashFilterStatus === CrashFilterStatus.All}
                                      onChange={() => handleCrashStatusFilterUpdate(CrashFilterStatus.All)} />
                               <span>All</span>
                           </label>

                           <label className='radioBlock' htmlFor='rdoCrashStatusFilterOpen'>
                               <input type='radio' id='rdoCrashStatusFilterOpen'
                                      name='rdoCrashStatusFilter' value='all' checked={props.crashFilterStatus === CrashFilterStatus.Open}
                                      onChange={() => handleCrashStatusFilterUpdate(CrashFilterStatus.Open)} />
                               <span>Open</span>
                           </label>

                           <label className='radioBlock' htmlFor='rdoCrashStatusFilterClosed'>
                               <input type='radio' id='rdoCrashStatusFilterClosed'
                                      name='rdoCrashStatusFilter' value='all' checked={props.crashFilterStatus === CrashFilterStatus.Resolved}
                                      onChange={() => handleCrashStatusFilterUpdate(CrashFilterStatus.Resolved)} />
                               <span>Closed</span>
                           </label>
                       </div>
                   </div>


               </div>

                {/** Crash Items Loaded Here*/}
                <div className='crashGroupItems'>
                    {
                        !props.loading ? buildCrashGroupView() : <Loading size={LoadingSize.SMALL} label='Loading crash groups...please wait' />
                    }
                </div>


            </div>

            <div className='absolute -mt-9 -ml-1 bg-transparent text-black md:hidden sm:hidden items-center justify-center align-middle'>
                <button onClick={handleMobileMenuClick}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-0 focus:ring-grey-700 mr-3"
                        aria-expanded="false">
                    <span className="sr-only">Open main menu</span>

                    <svg id='svgMenuClosed' className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>

                    <svg id='svgMenuOpen' className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>

            </div>

            <div id='mobile_menu_items' className="hidden sm:hidden bg-grey-300 text-black absolute">

                <div className='bg-grey-300 p-3'>
                    <input className='mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black'
                           type='text' placeholder='Search for Crash' onChange={(e) => setSearchTerm(e.target.value)} />



                        <div className='flex flex-row pt-3'>

                            <div className='flex-1 text-left'>
                                <span className='font-bold'>Filter by Assignment:</span> <DropdownMenu onMenuItemClick={handleAssignmentFilterClick}
                                                                    textAndIconColor={'#000000'} title={props.assignedTo.name} menuItems={props.projectMembers} />
                            </div>
                            <div className='flex text-right'>
                                <span className='font-bold mr-2'>Total Crash Groups: </span> {crashItems !== null ? crashItems.length : 0}
                            </div>

                        </div>

                        <div className='flex flex-row'>
                            <div className='flex-1'>
                                <p className='mt-3 mb-0 pb-0 font-bold'>Filter By Crash Type</p>

                                <label className='radioBlock'>
                                    <input type='radio' id='rdoCrashTypeFilterAll'
                                           name='rdoCrashTypeFilterMobile' value='all' checked={props.crashFilterType === CrashFilterType.All}
                                           onChange={() => handleCrashTypeFilterUpdated(CrashFilterType.All)} />
                                    <span>All</span>
                                </label>

                                <label className='radioBlock'>
                                    <input type='radio' id='rdoCrashTypeFilterHandled'
                                           name='rdoCrashTypeFilterMobile' value='handled' checked={props.crashFilterType === CrashFilterType.Handled}
                                           onChange={() => handleCrashTypeFilterUpdated(CrashFilterType.Handled)} />
                                    <span>Handled</span>
                                </label>

                                <label className='radioBlock'>
                                    <input type='radio' id='rdoCrashTypeFilterUnhandled'
                                           name='rdoCrashTypeFilterMobile' value='unhandled' checked={props.crashFilterType === CrashFilterType.Unhandled}
                                           onChange={() => handleCrashTypeFilterUpdated(CrashFilterType.Unhandled)} />
                                    <span>Unhandled</span>
                                </label>

                            </div>

                            <div className='flex-1'>
                                <p className='mt-3 mb-0 pb-0 font-bold'>Filter By Crash Status</p>
                                <label className='radioBlock' htmlFor='rdoCrashStatusFilterAll'>
                                    <input type='radio' id='rdoCrashStatusFilterAll'
                                           name='rdoCrashStatusFilterMobile' value='all' checked={props.crashFilterStatus === CrashFilterStatus.All}
                                           onChange={() => handleCrashStatusFilterUpdate(CrashFilterStatus.All)} />
                                    <span>All</span>
                                </label>

                                <label className='radioBlock' htmlFor='rdoCrashStatusFilterOpen'>
                                    <input type='radio' id='rdoCrashStatusFilterOpen'
                                           name='rdoCrashStatusFilterMobile' value='all' checked={props.crashFilterStatus === CrashFilterStatus.Open}
                                           onChange={() => handleCrashStatusFilterUpdate(CrashFilterStatus.Open)} />
                                    <span>Open</span>
                                </label>

                                <label className='radioBlock' htmlFor='rdoCrashStatusFilterClosed'>
                                    <input type='radio' id='rdoCrashStatusFilterClosed'
                                           name='rdoCrashStatusFilterMobile' value='all' checked={props.crashFilterStatus === CrashFilterStatus.Resolved}
                                           onChange={() => handleCrashStatusFilterUpdate(CrashFilterStatus.Resolved)} />
                                    <span>Closed</span>
                                </label>
                            </div>
                        </div>
                </div>

                {/** Crash Items Loaded Here*/}
                <div className='crashGroupItems'>
                    {buildCrashGroupView()}
                </div>
            </div>


            <ReactTooltip />
        </div>
    )
}

export default CrashSidebar;