import * as React from "react"
import {useEffect, useState} from "react"
import DropdownMenu from "./DropdownMenu";
import AndroidCrashGroupDetails from "./CrashDetailsComponents_old/Android/AndroidCrashGroupDetails";
import {AffectedVersion, CrashGroup, ProjectMemberDetails} from "../Models/CrashDetails";
import {FaBell, FaBellSlash, FaStopCircle} from 'react-icons/fa'
import {HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import '../ComponentStyles/CrashGroupDetails.css'
import {toast} from "react-toastify";
import ReactJSCrashGroupDetails from "./CrashDetailsComponents_old/ReactJS/ReactJSCrashGroupDetails";
import PHPCrashGroupDetails from "./CrashDetailsComponents_old/PHP/PHPCrashGroupDetails";
import CustomCrashGroupDetails from "./CrashDetailsComponents_old/Custom/CustomCrashGroupDetails";
import IOSCrashGroupDetails from "./CrashDetailsComponents_old/IOS/IOSCrashGroupDetails";
import CrashTabSelection from "./CrashDetailsComponents_old/CrashTabSelection";
import FlutterCrashGroupDetails from "./CrashDetailsComponents_old/Flutter/FlutterCrashGroupDetails";
import {ModalDialog, returnDefaultModalState, returnErrorModal,PrimaryButton, SecondaryButton} from "devso-react-library";

export type CrashGroupDetailsProps = {
    crashGroup: CrashGroup,
    project_id: string,
    handleAssignmentUpdated: Function
    selectedAssignmentDetails: ProjectMemberDetails,
    refreshCrashGroupData: Function,
    projectMembers: Array<ProjectMemberDetails>,
    projectType: string,
    comment_id: undefined|number
}

function CrashGroupDetails(props: CrashGroupDetailsProps)
{
    const [showLatestVersionAndBelowModal, setShowLatestVersionAndBelowModal] = useState(false);
    const [affectedVersions, setAffectedVersions] = useState<Array<AffectedVersion>>([]);
    const [modalState, setModalState] = useState(null);


    const returnUserStyleCrashGroupStatus = (status) => {
        switch (status)
        {
            case "New":
            case "Testing":
            case "Resolved":
                return status;
            case "InProgress":
                return "In Progress";
            case "UnderReview":
                return "Under Review";
        }
    }
    const [currentCrashGroupStatus, setCurrentCrashGroupStatus] = useState(returnUserStyleCrashGroupStatus(props.crashGroup?.status));
    const [newCrashGroupStatus, setNewCrashGroupStatus] = useState(returnUserStyleCrashGroupStatus(currentCrashGroupStatus));


    useEffect(() => {
        const affectedVersionsRowsArr = []
        if (typeof props.crashGroup !== typeof undefined) {
            for (let i = 0; i < props.crashGroup?.affected_versions?.length; i++) {
                const affectedVersion = props.crashGroup.affected_versions[i];
                if (affectedVersion.being_reported === 1) {
                    affectedVersionsRowsArr.push(
                        <tr key={i}>
                            <td>{affectedVersion.version_name}</td>
                            <td>{affectedVersion.count}</td>
                            <td>&nbsp;</td>
                        </tr>
                    )
                } else {
                    affectedVersionsRowsArr.push(
                        <tr key={i}>
                            <td>{affectedVersion.version_name}</td>
                            <td>{affectedVersion.count}</td>
                            <td>
                                <div className='tooltipContainer'
                                     data-tip='This version and below is not being reported'>
                                    <FaStopCircle style={{color: 'red'}}/>
                                </div>
                            </td>
                        </tr>
                    );
                }
                setAffectedVersions(affectedVersionsRowsArr);
            }
        }
        const crashGroupStatus = returnUserStyleCrashGroupStatus(props.crashGroup?.status)
        setCurrentCrashGroupStatus(crashGroupStatus);
        setNewCrashGroupStatus(crashGroupStatus)
    }, [props.crashGroup]);

    const handleLatestVersionAndBelowCheckChanged = (event) => {
        if (event.target.checked)
        {
            setShowLatestVersionAndBelowModal(true);
        }
        else
        {
            setShowLatestVersionAndBelowModal(false);
        }
    }

    const handleUserAssignmentClick = (userId, selectedAssignmentName) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure"
        tempModalState.content = <p>
            Are you sure you want to assign this crash group to {selectedAssignmentName}
        </p>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                assignUserToCrashGroup(userId, selectedAssignmentName)
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState})
    }

    const assignUserToCrashGroup = async (userId: number, selectedAssignmentName: string  ) => {
        const postArray = {
            //crash_group_id: props.crashGroup.crash_group_id,
            user_id: userId,
            //project_id: props.project_id
        }

        try
        {
            const response = await sendRequest(postArray, `/crash/${props.project_id}/${props.crashGroup.crash_group_id}/assign_user`, HTTP_METHOD.PUT);
            if (response.status === 0)
            {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
                props.handleAssignmentUpdated(userId, selectedAssignmentName)
                toast.success("User has been successfully assigned");
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState})
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState})
        }
    }

    const toggleFilteringCrashGroup = async () => {
        const postArray : any = {}
        if (props.crashGroup?.filter_id === 0)
        {
            postArray.project_id = props.project_id;
            postArray.crash_group_id = props.crashGroup?.crash_group_id;
            if (showLatestVersionAndBelowModal === true)
            {
                postArray.latest_version_and_below = props.crashGroup?.latest_version;
            }
            else
            {
                postArray.latest_version_and_below = 0;
            }
        }
        else
        {
            postArray.project_id = props.project_id;
            postArray.filter_crash_group_id = props.crashGroup?.filter_id;
        }

        try
        {
            const response = await sendRequest(postArray, `/crash/${postArray.project_id}/${props.crashGroup?.crash_group_id}/stop_reporting_crash_group`, HTTP_METHOD.POST);
            if (response.status === 0)
            {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
                props.refreshCrashGroupData();
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState})
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState})
        }

    }

    const updateCrashGroupStatus = async (status) =>
    {
        const postArray = {
            //crash_group_id: props.crashGroup.crash_group_id,
            crash_status: status,
            //project_id: props.project_id
        }

        try
        {
            const response = await sendRequest(postArray, `/crash/${props.project_id}/${props.crashGroup.crash_group_id}/update_status`, HTTP_METHOD.POST);
            if (response.status === 0)
            {
                setCurrentCrashGroupStatus(status);
                setNewCrashGroupStatus(status);
                toast.success("Crash group successfully updated");
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState})
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState})
        }
    }

    const confirmDeletingComment = (comment_id: number) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Confirm Comment Deletion";
        tempModalState.content = <p>
            Are you sure you want to delete this comment
        </p>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                deleteComment(comment_id)
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }

    const deleteComment = async (comment_id: number) => {
        /*const postArray = {
            comment_id: comment_id
        };*/

        try
        {
            const response = await sendRequest(null, `/comments/${comment_id}`, HTTP_METHOD.DELETE);
            if (response.status === 0)
            {
                toast.success("Successfully deleted comment");
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
                //fetchComments();
                props.refreshCrashGroupData();
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState})
            }
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState})
        }
    }

    let crashData = null;
    switch (props.projectType)
    {
        case "Android":
            crashData = <AndroidCrashGroupDetails crashGroup={props.crashGroup} project_id={props.project_id}
                     selectedAssignmentDetails={props.selectedAssignmentDetails}
                    refreshCrashGroupData={props.refreshCrashGroupData} projectMembers={props.projectMembers}
                    affectedVersions={affectedVersions} project_type={props.projectType}
                    confirmDeletingComment={confirmDeletingComment}/>
            break;
        case "ReactJS":
            crashData = <ReactJSCrashGroupDetails crashGroup={props.crashGroup} project_id={props.project_id}
                                                   selectedAssignmentDetails={props.selectedAssignmentDetails}
                                                  refreshCrashGroupData={props.refreshCrashGroupData} projectMembers={props.projectMembers}
                                                  affectedVersions={affectedVersions} project_type={props.projectType}
                                                  confirmDeletingComment={confirmDeletingComment}/>
            break;
        case "PHP":
            crashData = <PHPCrashGroupDetails crashGroup={props.crashGroup} project_id={props.project_id}
                                                   selectedAssignmentDetails={props.selectedAssignmentDetails}
                                                  refreshCrashGroupData={props.refreshCrashGroupData} projectMembers={props.projectMembers}
                                                  affectedVersions={affectedVersions} project_type={props.projectType}
                                              confirmDeletingComment={confirmDeletingComment}/>
            break;
        case "iOS":
            crashData = <IOSCrashGroupDetails crashGroup={props.crashGroup} project_id={props.project_id}
                              project_type={props.projectType}
                              selectedAssignmentDetails={props.selectedAssignmentDetails}
                              refreshCrashGroupData={props.refreshCrashGroupData}
                              projectMembers={props.projectMembers} affectedVersions={affectedVersions}
                              confirmDeletingComment={confirmDeletingComment}/>
            break;
        case "Flutter":
            crashData = <FlutterCrashGroupDetails crashGroup={props.crashGroup} project_id={props.project_id}
                              project_type={props.projectType}
                              selectedAssignmentDetails={props.selectedAssignmentDetails}
                              refreshCrashGroupData={props.refreshCrashGroupData}
                              projectMembers={props.projectMembers} affectedVersions={affectedVersions}
                              confirmDeletingComment={confirmDeletingComment}/>
          break;
        case "Custom SDK":
            crashData = <CustomCrashGroupDetails crashGroup={props.crashGroup} project_id={props.project_id}
                      selectedAssignmentDetails={props.selectedAssignmentDetails}
                     refreshCrashGroupData={props.refreshCrashGroupData} projectMembers={props.projectMembers}
                     affectedVersions={affectedVersions} project_type={props.projectType}
                                                 confirmDeletingComment={confirmDeletingComment}/>
            break;
    }


    /*useEffect(() => {

        if (typeof props.comment_id !== typeof undefined && !isNaN(props.comment_id))
        {
            const commentTab = document.getElementById("comments-tab") as HTMLElement;
            if (commentTab !== null)
            {
                commentTab.click();
            }
        }
    }, [props.crashGroup, props.comment_id]);*/

    const projectStates = [
        {
            title: 'In Progress',
            link: function () {
                updateCrashGroupStatus('In Progress')
            }
        },
        {
            title: 'Under Review',
            link: function () {
                updateCrashGroupStatus('Under Review')
            }
        },
        {
            title: 'Testing',
            link: function () {
                updateCrashGroupStatus('Testing')
            }
        },
        {
            title: 'Resolved',
            link: function () {
                updateCrashGroupStatus('Resolved')
            }
        }
    ]

    const showStopReportingCrashGroupConfirm = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure";
        tempModalState.content = <div>
            <p>
                Are you sure you want to stop reporting on this crash group?
            </p>
            <p>
                Team members of this project will not receive a notification of further crashes matching
                the details below.
            </p>
            <p>
                            <span
                                style={{fontWeight: 'bold'}}>Exception Group: </span> {props.crashGroup.exception_group}
            </p>
            <p>
                            <span
                                style={{fontWeight: 'bold'}}>Class File/Line No: </span> {props.crashGroup.class_script_name}:{props.crashGroup.line_no}
            </p>

            <p>
                You can specify below whether this is for any version or only stop reporting
                crashes for the current latest version and below. You would therefore only receive
                a new crash notification if a new version is detected.
            </p>
            <label className='checkbox'>
                <input type='checkbox'
                       onChange={handleLatestVersionAndBelowCheckChanged}/>Current Latest Version and
                <span>Below Only</span>

            </label>
        </div>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                toggleFilteringCrashGroup();
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});
    }

    const showReportThisCrashGroupAgain = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure?"
        tempModalState.content = <div>
            <p>
                Are you sure you want to start reporting on this crash group again?
            </p>
            <p>
                Team members of this project will start to receive a notification of further crashes
                matching
                the details below.
            </p>
            <p>
                            <span
                                style={{fontWeight: 'bold'}}>Exception Group: </span> {props.crashGroup.exception_group}
            </p>
            <p>
                            <span
                                style={{fontWeight: 'bold'}}>Class File/Line No: </span> {props.crashGroup.ClassFile}:{props.crashGroup.line_no}
            </p>
        </div>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={() => {
                toggleFilteringCrashGroup();
            }} />,
            <SecondaryButton label='No' onClick={() => {
                const tempModalState = returnDefaultModalState();
                setModalState({...tempModalState});
            }} />
        ]
        setModalState({...tempModalState});

    }
    return (
        <div>

           <div className='crashDetailsHeader'>
               <span className='font-bold mr-2'>Selected Crash Group: </span>{props.crashGroup?.exception_group}
               <div className='flex flex-col flex-col-reverse sm:flex-row'>
                   <div className='flex-1'>
                        <CrashTabSelection incidentCount={props.crashGroup?.count} />
                   </div>

                   <div className='flex-1 mt-4 md:mt-0 text-center items-center justify-items-center'>
                       <p>
                           Assign Crash Group: <DropdownMenu onMenuItemClick={handleUserAssignmentClick}
                                                             title={props.selectedAssignmentDetails.assignmentName} menuItems={props.projectMembers} />
                       </p>
                   </div>

                   <div className='flex-1 mt-4 md:mt-0 text-center items-center justify-items-center'>
                       Update Crash Status:
                       <DropdownMenu menuItems={projectStates} title={newCrashGroupStatus} />
                   </div>
               </div>
           </div>

            {crashData}

            {

                props.crashGroup?.filter_id === 0 ?
                    <button className='btn btn-danger' onClick={showStopReportingCrashGroupConfirm}>
                        <FaBellSlash className='inline'/> Stop Reporting This Crash Group</button> :
                    <button className='btn btn-secondary' onClick={showReportThisCrashGroupAgain}>
                        <FaBell className='inline'/> Report on This Crash Group</button>
            }

            <ModalDialog {...modalState} />
        </div>
    )
}

export default CrashGroupDetails;