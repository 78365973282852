import * as React from "react";
import {useState} from "react";
import {BillingInfoProps} from "../../Models/Billing";
import {FaLink, FaPencilAlt, FaTimesCircle} from "react-icons/fa";
import {FaCheckCircle, FaQuestionCircle} from "react-icons/fa";
import UpdateCardAndPersonalDetails from "./UpdateCardAndPersonalDetails";
import {toast} from "react-toastify";
import UpdatePersonalDetails from "./UpdatePersonalDetails";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import ConfirmAccountDeletionForm from "../ConfirmAccountDeletionForm";
import Loading from "../StandardComponents/Loading";
import {LoadingSize} from "../../Models/StandardComponents/Loading";
import { useHistory } from "react-router-dom";
import {useGlobalState} from "../GlobalStateProvider";
import {convertEpochToHumanDate} from "../../JSFuncs/DataTimeManager";
import ReactTooltip from "react-tooltip";
import {ModalDialog, returnDefaultModalState, returnErrorModal,PrimaryButton, SecondaryButton} from "devso-react-library";
import PricingOptions from "../PricingOptions";

export default function BillingInfo(props: BillingInfoProps)
{
    const history = useHistory();
    const {globalState} = useGlobalState();
    const [modalState, setModalState] = useState(null);

    const resetModalDialog = () => {
        const tempModalState = returnDefaultModalState();
        setModalState({...tempModalState});
    }

    const showUpdateCardModal = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.largeModal = false;
        tempModalState.title = "Update Your Details";
        tempModalState.content = <UpdateCardAndPersonalDetails  billing={props.billingInfo}
                                    closeUpdateCardAndPersonalDetails={resetModalDialog}
                                    cardUpdatedSuccessfully={cardUpdatedSuccessfully}
                                    cardUpdateFailed={cardUpdateFailed}/>
        setModalState(tempModalState);
    }

    const cardUpdatedSuccessfully = async () => {
        toast.success("Thank you, your card has been updated successfully");
        const tempModalState = returnDefaultModalState();
        setModalState({...tempModalState});
        await props.refreshBillingInfo();
    }

    const cardUpdateFailed = (err) => {
        //The err is the exception object thrown by the update card modal.
        if (err.response?.status === 400) {
            if (err.response.data.status === 12) //Stripe Card Error
            {
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = "Card was Declined";
                tempModalState.content = <>
                    <p>
                        We're sorry but this card could not be added to your account. The error we received
                        is below:
                    </p>
                    <p className='font-bold'>
                        {err.response.data.data.stripe_error}
                    </p>
                    <p className='font-bold'>
                        The Decline Code was: {err.response.data.data.decline_code}
                    </p>
                </>
                tempModalState.buttons = [
                    <PrimaryButton label='OK' onClick={() => {
                        const temp = returnDefaultModalState();
                        setModalState({...temp})
                    }} />
                ]
                setModalState({...tempModalState});
            }
        }
        else
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }

    }

    const billingAddressUpdatedSuccessfully = async() => {
        toast.success("Thank you, your billing address has been updated successfully");
        const tempModalState = returnDefaultModalState();
        setModalState({...tempModalState});
        await props.refreshBillingInfo();
    }

    const billingAddressUpdateFailed = (err) => {
        const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
        setModalState({...tempModalState});
    }

    const showUpdateBillingInfoModal = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Update Billing Address";
        tempModalState.content = <UpdatePersonalDetails closeModalDialog={resetModalDialog}
                                     address_line1={typeof props.billingInfo.billing_details?.address?.line1 !== typeof undefined ? props.billingInfo.billing_details.address.line1 : ''}
                                     address_line2={typeof props.billingInfo.billing_details?.address?.line2 !== typeof undefined ? props.billingInfo.billing_details.address.line2 : ''}
                                     city={typeof props.billingInfo.billing_details?.address?.city !== typeof undefined ? props.billingInfo.billing_details.address.city : ''}
                                     state={typeof props.billingInfo.billing_details?.address?.state !== typeof undefined ? props.billingInfo.billing_details.address.state : ''}
                                     post_code={typeof props.billingInfo.billing_details?.address?.postal_code !== typeof undefined ? props.billingInfo.billing_details.address.postal_code : ''}
                                     billingAddressUpdatedSuccessfully={billingAddressUpdatedSuccessfully}
                                     billingAddressUpdateFailed={billingAddressUpdateFailed}   />
        setModalState({...tempModalState});
    }

    const confirmDeleteAccount = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Are you sure";
        tempModalState.content = <ConfirmAccountDeletionForm deletionConfirmed={deleteAccountConfirmed}
                                                          deletionCancelled={resetModalDialog} />
        setModalState({...tempModalState});
    }

    const deleteAccountConfirmed = async () => {
        try
        {
            let tempModalState = returnDefaultModalState();
            tempModalState.open = true;
            tempModalState.title = "Please Wait";
            tempModalState.content = <Loading size={LoadingSize.LARGE} label="Please wait, your account data is being deleted" />
            setModalState({...tempModalState});

            await sendRequest(null, '/account-management', HTTP_METHOD.DELETE);

            tempModalState = returnDefaultModalState();
            tempModalState.open = true;
            tempModalState.title = "Sorry, to see you go";
            tempModalState.content = <>
                <p className='font-bold'>
                    We're sorry to see you go, but your account has been successfully deleted
                </p>
                <p>
                    If you have any feedback, we would love to hear about your experience so please email
                    us at <a href='mailto://support@devso.io'>support@devso.io</a>
                </p>
            </>
            tempModalState.buttons = [
                <SecondaryButton label='Dismiss' onClick={() => {
                   history.push('/login/Your account has been removed');
                }} />
            ]
            setModalState({...tempModalState});
        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "Unable to Delete account", setModalState);
            setModalState({...tempModalState});
        }
    }



    return (
        <>
            <div className='border-b border-b-2 border-b-gray-300 h-full overflow-y-auto'>
                <p className='m-0 p-0'>Current Plan</p>
            </div>

            <div className='w-full'>
                <div className='grid grid-cols-2 mt-4'>
                    <div>
                        <p className='font-bold text-2xl mb-0 pb-0'>{props.billingInfo.current_plan === "Pro_plus" ? "Pro+" : props.billingInfo.current_plan} Plan</p>
                        <p className='mb-0 pb-0'>£{props.billingInfo.total_billable} a month
                            {props.billingInfo.discount_percent !== null ? <span className='text-sm'>&nbsp;(Includes your {props.billingInfo.discount_percent}% off discount)</span> : null}
                        </p>
                        <span className='text-sm'>Your current plan renews on {props.billingInfo.plan_renews}</span>

                        <p className='mt-3'>
                            Current Month Crash Quota: <span className='font-bold'>{props.billingInfo.current_month_crash_quota} of {props.billingInfo.plan_crash_limit}</span> available
                        </p>
                    </div>
                    <div>
                        <button className='btn btn-cta clear-both w-full' onClick={() => history.push(`update-plan/${props.billingInfo.current_plan}`)}>Update Plan</button>
                        <button className='btn btn-outline clear-both w-full' onClick={() => confirmDeleteAccount()}>Cancel Plan</button>
                    </div>
                </div>
                {
                    typeof globalState.payment_failed !== typeof undefined && globalState.payment_failed.last_payment_failed?
                        <div className='bg-red-200 border border-2 border-red-600 rounded p-3'>
                            <p className='font-bold'>
                                Unfortunately your last payment didn't go through. You can find out more info below about
                                the reason.
                            </p>
                            <p>
                                We allow a 7 day grace period before your account is temporarily suspended,
                                but don't worry, your account data will stay in place so when payment is received, all
                                of your data will remain in tact.
                            </p>
                            <p>
                                <span className='font-bold'>Payment Failure Reason: </span>
                                {globalState.payment_failed.last_payment_failed_reason}
                                <FaQuestionCircle className='inline' data-tip="This is the information we get back from our payment processor, this could be due to an issue with your bank or card." />
                            </p>
                            <p>
                                If you have any questions or issues, or believe you are seeing this message in error, then
                                please raise a support ticket at <a href='https://support.devso.io'>https://support.devso.io</a>
                                &nbsp; where we'll be happy to help.
                            </p>
                            <p>
                                <span className='font-bold'>Payment Now Due By: </span> {convertEpochToHumanDate(globalState.payment_failed.payment_time_required, false)}
                            </p>
                        </div> : null
                }

            </div>

            <div className='mt-8'>
                <div className='border-b border-b-2 border-b-grey-300'>
                    <p className='m-0 p-0'>Payment Card</p>
                </div>
                <div className='grid grid-cols-2 mt-4 mb-0 pb-0'>
                    <div>
                        <p>
                            {
                                props.billingInfo.billing_details !== null ?
                                    <span>* * * * {props.billingInfo.billing_details.card.last4}</span> :
                                    <span className='italic'>No card details provided</span>
                            }

                        </p>
                    </div>
                    <div>
                        {
                            props.billingInfo.billing_details !== null ?
                                <p>
                                    Expires {props.billingInfo.billing_details.card.expiry_month.toString().padStart(2, "0")} / {props.billingInfo.billing_details.card.expiry_year}
                                </p> : null
                        }

                    </div>
                </div>
                <button className='btn btn-link-hover -mt-3' onClick={showUpdateCardModal}><FaPencilAlt />Update Card Details</button>
            </div>

            <div className='mt-8'>
                <div className='border-b border-b-2 border-b-grey-300'>
                    <p className='m-0 p-0'>Billing Information</p>
                </div>

                <p className='mt-3'>
                    <span className='font-bold'>Name: </span>
                    {props.billingInfo.user_manging_payment.name}
                </p>

                <p>
                    <span className='font-bold'>Email: </span>
                    {props.billingInfo.user_manging_payment.email}
                </p>

                <p className='text-grey-400 text-sm'>
                    Name and email as shown above is taken from the account managers user profile.
                    They should update their own name and email if required from their user profile settings
                </p>
                <p>
                    <span className='font-bold'>Address: </span>
                    {(props.billingInfo.billing_details !== null) && props.billingInfo.billing_details.address.line1 !== null ?
                        <>
                            {props.billingInfo.billing_details.address.line1 ? <>{props.billingInfo.billing_details.address.line1}, <br /></> : null}
                            {props.billingInfo.billing_details.address.line2 ? <>{props.billingInfo.billing_details.address.line2}, <br /></> : null}
                            {props.billingInfo.billing_details.address.city ? <>{props.billingInfo.billing_details.address.city},<br /></> : null}
                            {props.billingInfo.billing_details.address.state ? <>{props.billingInfo.billing_details.address.state},<br /></> : null}
                            {props.billingInfo.billing_details.address.postal_code}
                        </>: <span className='italic'>Please provide your billing address</span>
                    }
                </p>
                <button className='btn btn-link-hover' onClick={showUpdateBillingInfoModal}><FaPencilAlt /> Update Billing Information</button>
            </div>

            <div className='mt-8'>
                <div className='border-b border-b-2 border-b-grey-300'>
                    <p className='m-0 p-0'>Billing History</p>
                </div>

                <table className='noColor'>
                    <tbody>
                        {
                            props.billingInfo.invoices.map(invoice => {
                                return (
                                    <tr className='bg-white'>
                                        <td>
                                            {invoice.created} {invoice.invoice_url !== null ?
                                                <FaLink className='inline cursor-pointer'
                                                    onClick={() => window.open(invoice.invoice_url, "_blank")} /> : null }
                                        </td>
                                        <td>
                                            ${invoice.amount_due}
                                        </td>
                                        <td>
                                            {invoice.plan} Plan
                                        </td>
                                        <td>
                                            {
                                                invoice.paid ? <FaCheckCircle data-tip='Invoice Paid' className='text-green-700' />
                                                    : <FaTimesCircle data-tip='Invoice Not Paid' className='text-red-600' />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <ReactTooltip />
            <ModalDialog {...modalState} />
        </>
    )
}