import {useEffect, useState} from "react";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {IssuesAPIData, IssuesAPIResponse} from "../../Models/Issues";

export default function useIssues(selectedTimeFrame: string) {

    const [issues, setIssues] = useState<Array<IssuesAPIData>>(null);
    const [issueErrors, setIssueErrors] = useState(null);
    const [issuesLoading, setIssuesLoading] = useState(false);

    const getIssues = async (selectedTimeFrame: string) => {
        try
        {
            setIssuesLoading(true);
            setIssueErrors(null);

            const data = {
                time_frame: selectedTimeFrame
            }

            const response : IssuesAPIResponse = await sendRequest(data, '/issues', HTTP_METHOD.POST);
            setIssues(response.data);

        }
        catch (err)
        {
            setIssueErrors(err);
        }
        finally
        {
            setIssuesLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getIssues(selectedTimeFrame);
            }
        )()
    }, [])

    return {issues, issueErrors, issuesLoading, getIssues}
}