import * as React from "react";
import {useEffect, useState} from "react";
import {Bar} from 'react-chartjs-2';
import {sendRequest} from "../../JSFuncs/APIManager";
import {FaArrowUp, FaArrowRight, FaArrowDown} from "react-icons/fa";
import {ModalDialog, ModalState, returnErrorModal} from "devso-react-library";

function ActiveUserAnalysis(props)
{
    const [chartData, setChartData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [chartAPIRawResult, setChartAPIRawResult] = useState<ChartAPIRawResult | any>({});
    const [modalState, setModalState] = useState<ModalState>(null);
    type ChartData = {
        version_name_release: Array<string>
    }

    type PercentIncreaseFromPreviousTimeFrame = {
        increase_from_previous: boolean,
        percentage: string
    }

    type ChartAPIRawResult = {
        chart_data: ChartData,
        percentage_increase_from_previous_timeframe: PercentIncreaseFromPreviousTimeFrame,
        current_active_sessions: number
    }

    const getDailyActiveUsers = async() => {
        const postArray = {
            //project_id: props.project_id,
            time_period: props.time_period
        };

        const response = await sendRequest(postArray, `/projects/project/${props.project_id}/active_users`);
        if (response.status === 0)
        {
            const chartDataObj = {
                labels: response.data.chart_labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Unique Daily Active Users',
                        borderColor: '#1d1aa5',
                        backgroundColor: 'rgba(60,57,198,0.28)',
                        borderWidth: 1.5,
                        data: response.data.chart_data.active_user_data
                    },
                    {
                        label: 'New Project Version Release',
                        backgroundColor: '#5f5656',
                        borderColor: 'rgb(49,35,35)',
                        borderWidth: 1,
                        data: response.data.chart_data.app_release_data
                    }
                ]
            }
            setChartData({...chartDataObj});
            setChartAPIRawResult(response.data);
            setIsLoading(false);
        }
        else
        {
            const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
            setModalState({...tempModalState})
        }
    }

    useEffect(() => {
        setIsLoading(true);
    }, [props.time_period])

    useEffect(() => {
        if (isLoading)
        {
            getDailyActiveUsers();
        }
    }, [isLoading, props.project_id, props.time_period]);

    if (!isLoading)
    {

        const chartOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:false,
                        min: 0,
                        //stepSize: 1,
                        autoSkip: true,
                        userCallback: function (label) {
                            if (Math.floor(label) === label){
                                return label;
                            }
                        }
                    }
                }],
                xAxes: [{
                    barPercentage: 0.4
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].label;

                        if (tooltipItem.datasetIndex === 0)
                        {
                            label += ": " + tooltipItem.yLabel;
                        }
                        else if (tooltipItem.datasetIndex === 1) {
                            label = "Version " + chartAPIRawResult.chart_data.version_name_release[tooltipItem.index] + " detected";
                        }
                        return label;
                    }
                }
            }
        }

        let percentageIncreaseDecrease : JSX.Element | string ;
        let timeScaleText = '';
        switch (props.time_period)
        {
            case "1 Day":
                timeScaleText = 'day';
                break;
            case "1 Week":
                timeScaleText = 'week'
                break;
            case "1 Month":
                timeScaleText = 'month'
        }
        if (chartAPIRawResult.percentage_increase_from_previous_timeframe.increase_from_previous)
        {
            percentageIncreaseDecrease = <span><FaArrowUp className='inline text-green-700 mr-3' />
                {chartAPIRawResult.percentage_increase_from_previous_timeframe.percentage}% increase when compared from the previous {timeScaleText}</span>
        }
        else
        {

            if (chartAPIRawResult.percentage_increase_from_previous_timeframe.percentage === "0.00")
            {
                percentageIncreaseDecrease = <span><FaArrowRight className='inline text-black mr-3' />
                    No change when compared to from the previous {timeScaleText}</span>
            }
            else
            {
                percentageIncreaseDecrease = <span><FaArrowDown className='inline text-red-700 mr-3' />
                    {chartAPIRawResult.percentage_increase_from_previous_timeframe.percentage}% decrease when compared from the previous {timeScaleText}</span>
            }
        }

        return (
            <div>
                <p style={{fontWeight: 'bold', textAlign: 'center'}}>
                    Unique Daily Active Users
                </p>
                <Bar data={chartData} options={chartOptions} />

                <p style={{fontWeight: 'bold', textAlign: 'center', marginBottom: 0, paddingBottom: 0}}>
                    Current Active Sessions: {chartAPIRawResult.current_active_sessions}
                </p>
                <p style={{textAlign: 'center', margin: 0, padding: 0}}>{percentageIncreaseDecrease}</p>
                <p style={{fontSize: 'small', textAlign: 'center'}}>Dates &amp; times are in UTC</p>

                <ModalDialog {...modalState} />
            </div>
        )
    }
    else
    {
        return (
            <div style={{textAlign: 'center', fontStyle: 'bold', padding: 10, minHeight: 450, maxHeight: 450, verticalAlign: 'center'}}>
                <img src='/images/loading-large.gif' alt='loading' />
                <ModalDialog {...modalState} />
            </div>
        )
    }
}

export default ActiveUserAnalysis;