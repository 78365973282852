import * as React from "react";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import '../ComponentStyles/PageHandler.css'
import {useCallback, useEffect, useState} from "react";

export type PageHandlerType = {
    MaxRecordsToShow: number,
    TotalPages: number,
    NumberOfRecords: number,
    CurrentPage: number
}

export type PageHandlerProps = {

    colSpan: number,
    pageHandler: PageHandlerType,
    data: any,
    skippedKeys: Array<string>
}


function PageHandler(props: PageHandlerProps)
{
    const [rows, setRows] = useState([]);
    const [pageHandler, setPageHandler] = useState(props.pageHandler);

    const displayData = useCallback(()  => {
        if ((typeof pageHandler !== typeof undefined) && pageHandler !== null) {

            const rowsArr = [];
            let startIndex = (pageHandler.CurrentPage - 1) * pageHandler.MaxRecordsToShow;
            for (let i = 0; i < props.data.length; i++) {
                if (i >= pageHandler.MaxRecordsToShow) {
                    break;
                }

                if (startIndex >= props.data.length)
                {
                    break;
                }

                rowsArr.push(props.data[startIndex]);
                startIndex++;
            }
            setRows(rowsArr);
        }
    }, [props.data, pageHandler]);

    const previousPage = () => {

        if (pageHandler.CurrentPage > 1)
        {
            const updatedPageHandler = pageHandler;
            updatedPageHandler.CurrentPage--;
            setPageHandler(updatedPageHandler);
            displayData();
        }
    }

    const nextPage = () => {
        if (pageHandler.CurrentPage <= pageHandler.TotalPages)
        {
            const updatedPageHandler = pageHandler;
            updatedPageHandler.CurrentPage++;
            setPageHandler(updatedPageHandler);
            displayData();
        }
    }

    useEffect(() => {
        displayData();
    }, [pageHandler, displayData]);

    if (pageHandler !== null) {
        const previousClass = pageHandler.CurrentPage === 1 ? 'previousNextButton disabled inline' : 'previousNextButton inline';
        const nextClass = pageHandler.CurrentPage < pageHandler.TotalPages ? 'previousNextButton inline' : 'previousNextButton disabled inline';

        return (
            <tbody>
            {rows}
            <tr className='pageHandler'>
                <td colSpan={props.colSpan}>
                    {
                        (pageHandler !== null) && typeof pageHandler !== typeof undefined ?
                            <span><FaChevronLeft className={previousClass}
                                                 onClick={previousPage}/> Page {pageHandler.CurrentPage} of {pageHandler.TotalPages}<FaChevronRight
                                className={nextClass} onClick={nextPage}/></span> : ''
                    }
                </td>
            </tr>
            </tbody>
        )
    }
    else
    {
        return null;
    }
}

export default PageHandler;