import * as React from "react";
import {ConfirmAccountDeletionProps} from "../Models/ConfirmAccountDeletionProps";
import {useState} from "react";

export default function ConfirmAccountDeletionForm(props: ConfirmAccountDeletionProps) {

    const [text, setText] = useState('');

    return (
        <>
            <p>
                If you delete your account all of your information including all of your crashes, and projects
                will be removed and you will no longer receive any further notifications of any errors within your projects.
            </p>
            <p>
                This operation cannot be undone
            </p>
            <p>
                Enter <span className='font-bold'>delete-account-forever</span> in the box below to delete your account
            </p>
            <input
                className="!bg-white focus:!border-primary focus:!border-[1px] !shadow !appearance-none !border !rounded !w-full !accent-primary !py-2 !px-3 !text-black !leading-tight focus:!shadow-outline"
                type='text' placeholder='Enter delete-account-forever to confirm' value={text} onChange={(e) => setText(e.target.value)} />
            <div className='m-3 flex-row text-right'>
                <button className='btn btn-secondary' onClick={() => props.deletionCancelled()}>Cancel</button>
                <button className='btn btn-danger' disabled={text !== 'delete-account-forever'} onClick={() => props.deletionConfirmed()}>Delete My Account</button>
            </div>

        </>
    )
}