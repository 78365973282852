import * as React from "react";

export default function Wrapper(props) {
    return (
        <div className='flex flex-col md:flex-row'>
            {
                props.children
            }
        </div>
    )
}