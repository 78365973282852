import * as React from "react";
import {useEffect} from "react";
import {useGlobalState} from "./GlobalStateProvider";
import TrialOrPaymentBanner from "./TrialOrPaymentBanner";

export default function BaseComponent(props) {

    const { setGlobalState, globalState } = useGlobalState();
    let tempGlobalStorage = localStorage.getItem("global_state");

    if (typeof globalState?.payment_failed === typeof undefined && typeof globalState?.trial === typeof undefined)
    {
        if (tempGlobalStorage !== null) {
            const tempGlobalStorageJson = JSON.parse(tempGlobalStorage);
            //tempGlobalStorageJson.trialOrPaymentBannerShow = true;
            setGlobalState({...tempGlobalStorageJson});
        }
    }

    return (
        <>
            {props.children}
        </>
    )
}