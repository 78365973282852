import * as React from "react";
import DropdownMenu from "./DropdownMenu";
import Cookies from 'js-cookie';
import {
    FaBars,
    FaBug, FaCaretUp,
    FaCog,
    FaCreditCard,
    FaExternalLinkAlt,
    FaList,
    FaProjectDiagram, FaTable,
    FaUserAlt,
    FaUsers
} from "react-icons/fa";
import {sendRequest} from "../JSFuncs/APIManager";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useGlobalState} from "./GlobalStateProvider";
import useProfileImage from "../Hooks/useProfileImage";
import {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive'
import ReactTooltip from "react-tooltip";

export default function Sidebar() {

    const menuItems = [
        {
            icon: FaUserAlt,
            link: '/settings',
            title: 'Settings'
        },
        {
            icon: FaCreditCard,
            link: '/account-billing',
            title: 'Account & Billing'
        },
        {
            icon: FaUsers,
            link: '/team-members',
            title: 'Manage Team Members'
        },
        {
            icon: null,
            link: async () => {
                await performLogout();
            },
            title: 'Log out'
        },
        {
            type: 'separator',
            className: 'userMenu'
        },
        {
            icon: null,
            link: 'https://status.devso.io',
            title: 'Service Status',
            endIcon: FaExternalLinkAlt,
            target: '_blank'
        },
        {
            icon: null,
            link: 'https://support.devso.io',
            title: 'Get Support',
            endIcon: FaExternalLinkAlt,
            target: '_blank'
        },
        {
            icon: null,
            link: 'https://blog.devso.io.com',
            title: 'View Blog',
            endIcon: FaExternalLinkAlt,
            target: '_blank'
        }
    ]

    const history = useHistory();
    const {globalState, setGlobalState} = useGlobalState();
    const profileImage = useProfileImage();

    const performLogout = async () => {
        try
        {
            await sendRequest(null, '/auth/logout');
            Object.keys(Cookies.get()).forEach(cookieName => {
                Cookies.remove(cookieName);
            })
            history.push('/login/You have been successfully logged out');
        }
        catch (err)
        {

        }
    }

    const location = useLocation();

    const navItems = [
        {
            icon: FaProjectDiagram,
            label: "Projects",
            href: '/project-dashboard',
            active: location.pathname.indexOf('/project-dashboard') !== -1
        },
        {
            icon: FaTable,
            label: "Dashboard",
            href: '/dashboard',
            active: location.pathname.indexOf('/dashboard') !== -1
        },
        {
            icon: FaBug,
            label: "Issues",
            href: '/issues',
            active: location.pathname.indexOf('/issues') !== -1
        },
        {
            icon: FaList,
            label: "Activity",
            href: '/activities',
            active: location.pathname.indexOf('/activities') !== -1
        },
        {
            icon: FaCog,
            label: "Settings",
            href: '/settings',
            active: location.pathname.indexOf('/settings') !== -1
        }
    ];

    const [expanded, setExpanded] = useState(false);

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 700px)'
    })

    useEffect(() => {
        if (isTabletOrMobileDevice)
        {
            const element = document.getElementById("nav_container");
            element.style.display = "none";
        }

    }, [])

    useEffect(() => {
        window.setTimeout(function() {
            if (!expanded && isTabletOrMobileDevice)
            {
                const element = document.getElementById("nav_container");
                element.style.display = "none";
            }
        }, 300)
        if (expanded && isTabletOrMobileDevice)
        {
            const element = document.getElementById("nav_container");
            element.style.display = "block";
        }

    }, [expanded])

    const email = decodeURIComponent(Cookies.get("Email")).replace("+", " ");
    const email_display = email.length > 17 ? email.substring(0, 17) + "..." : email
    console.log("Cookie USER ID is:", Cookies.get("Name"));
    return (
        <div className='absolute md:relative z-40 md:h-screen w-full md:w-[180px] bg-primary p-4 md:flex-none flex flex-row'>
            <div className='w-full'>
                <div className='flex flex-row'>
                    <div className='w-[32px] h-[32px] bg-primary-light rounded-full align-text-bottom p-1 text-white mr-2 mt-2'>

                        {
                            (
                                () => {
                                    if ((globalState?.profileImage === null || globalState.profileImage === ""
                                            || globalState?.profileImage === undefined)
                                        || (profileImage === null || profileImage === ""))
                                    {
                                        return (
                                            <p className='text-center align-middle font-bold'>
                                                {
                                                    Cookies.get("Name") !== "" && Cookies.get("Name") !== undefined ?
                                                    decodeURIComponent(Cookies.get('Name')[0]?.toUpperCase()) : null
                                                }
                                            </p>
                                        )
                                    }
                                    else
                                    {
                                        return <img className='text-center align-middle rounded-full'
                                                    src={globalState.profileImage} alt='Profile Image' />
                                    }
                                }
                            )()
                        }

                    </div>
                    <div>
                        <DropdownMenu title={decodeURIComponent(Cookies.get('Name')).replace("+", " ")} menuItems={menuItems} />
                        <span data-tip={email} className='hidden md:block text-gray-500 text-sm'>
                        {email_display}
                    </span>
                    </div>
                </div>
                <ul id='nav_container' className={`${expanded || !isTabletOrMobileDevice ? "h-[210px] opacity-100" : "h-[0px] opacity-0"} transition-all ease-in-out duration-300 text-gray-300 mt-6 mx-0 p-0`}>
                    {
                        navItems.map((nav, index) => {
                            const Icon = nav.icon
                            return (
                                <li key={index} className={`py-2 flex flex-row cursor-pointer hover:text-white ease-in-out transition-all duration-300 ${nav.active ? "font-bold text-white" : ""}`}>
                                    <Link to={nav.href}>
                                        <Icon className='mr-3 mb-0 inline' />
                                        <p className='mb-8 inline'>
                                            {nav.label}
                                        </p>
                                    </Link>

                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='w-1/2 md:hidden block text-white text-right cursor-pointer'
                onClick={() => setExpanded(!expanded)}>
                {
                    !expanded ? <FaBars className='text-white text-right float-right mt-3 mr-5' />
                        : <FaCaretUp className='text-white text-right float-right mt-3 mr-5' />
                }

            </div>
            <ReactTooltip />
        </div>
    )
}