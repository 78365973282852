import {sendRequest} from "../JSFuncs/APIManager";
import {CrashGroup} from "../Models/CrashDetails";

export const fetchCrashGroupStats = async(project_id: string, crashGroup: CrashGroup, currentTimePeriodAndChartTitle: any) => {
    //return new Promise(function(resolve, reject){

        if (crashGroup !== null && typeof crashGroup !== typeof undefined) {
            const postArray = {
                //project_id: project_id,
                //crash_group_id: crashGroup.crash_group_id,
                time_period: currentTimePeriodAndChartTitle.currentTimePeriod
            };

            const response = await sendRequest(postArray, `/crash/${project_id}/${crashGroup.crash_group_id}/stats`);
            if (response.status === 0)
            {
                const chartDataObj = {
                    labels: response.data.labels,
                    datasets: [
                        {
                            label: currentTimePeriodAndChartTitle.chartTitle,
                            backgroundColor: '#0B0958',
                            data: response.data.data
                        }
                    ]
                }
                return chartDataObj;

            }
            else
            {
                //throw response;
            }

        }
    //});
}
