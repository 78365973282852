import * as React from "react";
import ContentWrapper from "../ContentWrapper";
import Sidebar from "../Sidebar";
import Wrapper from "../Wrapper";
import useProjectDetails from "../../Hooks/useProjectDetails";
import {getProjectIconFromString} from "../../JSFuncs/HelperFunctions";
import TimeRangePicker from "../TimeRangePicker";
import {useEffect, useState} from "react";
import ActiveUser from "../Dashboard/ActiveUser";
import HandledVsUnhandled from "../Dashboard/HandledVsUnhandled";
import NumberOfErrors from "../Dashboard/NumberOfErrors";
import NumberOfAffectedUsers from "../Dashboard/NumberOfAffectedUsers";
import StatusCounts from "../Dashboard/StatusCounts";
import ProjectActivities from "./ProjectActivities";
import IssueTable from "../Issues/IssueTable";
import useIssues from "../../Hooks/Issue/useIssues";
import {ModalDialog, ModalState} from "devso-react-library";
import CrashAnalysis from "../Dashboard/CrashAnalysis";
import { useMediaQuery } from 'react-responsive'
import SeverityCounts from "../Dashboard/SeverityCounts";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import OverflowMenu, {OverflowMenuItem} from "../OverflowMenu";
import Footer from "../Footer";

export default function ProjectDetails(props) {

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Projects",
            link: "/project-dashboard"
        },
        {
            label: "View Project Details",
            link: ""
        }
    ]

    const projectDetails = useProjectDetails(props.match.params.project_id);

    const [selectedTimeFrame, setSelectedTimeFrame] = useState('1 Day');
    const {issues, issueErrors, issuesLoading, getIssues} = useIssues(selectedTimeFrame);

    const [modalState, setModalState] = useState<ModalState>(null);

    const isMobile = useMediaQuery({
        query: '(max-device-width: 428px)'
    })

    const isTablet = useMediaQuery({
        query: '(min-device-width: 833px)'
    });

    const isLandscape = useMediaQuery({
        query: '(orientation: landscape)'
    });

    const isSmallLaptop = useMediaQuery({
        query: '(min-device-width:1439px)'
    });

    const isLargeDisplay = useMediaQuery({
        query: '(min-device-width: 1920px)'
    });

    useEffect(() => {
        (
            async () => {
                await getIssues(selectedTimeFrame);
            }
        )()
    }, [selectedTimeFrame])

    const overflowMenuItems : Array<OverflowMenuItem> = [
        {
            label: "Project Settings",
            link: `/project-settings/${projectDetails?.project_id}`
        }
    ]

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <BreadcrumbNav menu_items={breadcrumbNav} />
                    <div className='flex flex-row'>

                        <div className='w-full md:w-3/4'>
                            <div className='flex flex-row'>
                                <div className='mt-1'>
                                    {
                                        getProjectIconFromString(projectDetails?.project_type)
                                    }
                                </div>
                                <div className='ml-4 mb-2'>
                                    <h1>{projectDetails?.project_name}</h1>
                                </div>
                                <div className='ml-3 mt-2'>
                                    <OverflowMenu menu_items={overflowMenuItems} id='projectOverflowMenu' />
                                </div>

                            </div>

                            <TimeRangePicker setTimeRangeSelected={setSelectedTimeFrame} />

                            <div className='!h-[calc(100vh-160px)] overflow-y-auto items-center justify-items-center content-center'>
                                {
                                    (
                                        () => {
                                            if (isLargeDisplay)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row'>
                                                            <NumberOfErrors project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <StatusCounts project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-row'>
                                                            <CrashAnalysis project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <HandledVsUnhandled project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else if (isSmallLaptop)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row'>
                                                            <NumberOfErrors project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <StatusCounts project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-row'>
                                                            <CrashAnalysis project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>
                                                        <div className='flex flex-row'>
                                                            <HandledVsUnhandled project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else if (isTablet)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row'>
                                                            <NumberOfErrors project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />

                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <CrashAnalysis project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <HandledVsUnhandled project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>

                                                    </>
                                                )
                                            }
                                            else if (isMobile)
                                            {
                                                return (
                                                    <>
                                                        <div className='flex flex-row justify-items-center mx-auto mt-4'>
                                                            <NumberOfErrors project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <NumberOfAffectedUsers project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <CrashAnalysis project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <ActiveUser project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <HandledVsUnhandled project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                            <SeverityCounts project_id={props.match.params.project_id} time_frame={selectedTimeFrame} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            else
                                            {
                                                return (
                                                    <>
                                                        <p>Is Mobile: {isMobile ? "true" : "false"}</p>
                                                        <p>Is Tablet: {isTablet ? "true" : "false"}</p>
                                                        <p>Is Small Laptop: {isSmallLaptop ? "true" : "false"}</p>
                                                        <p>Is Large Display: {isLargeDisplay ? "true" : "false"}</p>
                                                    </>
                                                )
                                            }
                                        }
                                    )()
                                }
                            </div>

                            <div className='mr-4'>
                                <IssueTable getIssues={() => getIssues(selectedTimeFrame)} issues={issues}
                                            selectedTimeFrame={selectedTimeFrame}
                                            project_id={props.match.params.project_id}
                                            setModalState={setModalState} />
                            </div>
                        </div>

                        <div className='w-1/4 hidden md:block'>
                            <ProjectActivities project_id={props.match.params.project_id} />
                        </div>
                    </div>




                </ContentWrapper>
                <Footer />
            </div>
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}