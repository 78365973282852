import {GetProjectAPIData, ProjectType} from "./Projects";
import {UserDetails} from "./Default";
import {ModalState} from "devso-react-library";
import {ProjectTeamMembers} from "./ProjectTeamMembers";
import {AndroidDeviceDetails, FlutterDeviceDetails, PHPDeviceDetails, ReactJSDeviceDetails} from "./DeviceDetails";
import {CrashComments} from "./CrashComments";

export interface IssuesAPIResponse {
    status: number,
    message: string,
    data: Array<IssuesAPIData> & IssuesAPIData & IssueDetails
}

export interface IssuesAPIData {
    crash_group_id: number,
    project_id: string,
    exception_group: string,
    class_script_name: string,
    line_no: number,
    crash_type: CrashType,
    status: CrashStatus,
    severity: CrashSeverity,
    assigned_to: number,
    project_name: string,
    project_type: ProjectType,
    organisation_id: number,
    registered_by: number,
    api_key: string,
    exception_message: string,
    created_at: string,
    incident_count: number,
    affected_users: number,
    assigned_to_details: UserDetails,
    last_occurrence: string
}

export enum CrashType {
    Handled = "Handled",
    Unhandled = "Unhandled"
}

export enum CrashStatus {
    New = "New",
    In_Progress = "InProgress",
    Under_Review = "UnderReview",
    Testing = "Testing",
    Resolved = "Resolved"
}

export enum CrashSeverity {
    Low = "Low",
    Medium = "Medium",
    High = "High"
}

export interface IssueCardProps {
    setModalState: (modalState: ModalState) => void,
    selectedTimeFrame: string,
    //updateAssigneeRequest: (assignee: ProjectTeamMembers, project_id: string, crash_group_id: number) => void,
    getIssues: (timeFrame: string) => void
}

export interface IssueDetails {
    crash_group_id: number,
    project_id: string,
    exception_group: string,
    class_script_name: string,
    line_no: number,
    crash_type: CrashType,
    status: CrashStatus,
    severity: CrashSeverity,
    assigned_to: number
    assigned_to_details: UserDetails,
    project_details: GetProjectAPIData,
    incidents: Array<Incident>,
    comments: Array<CrashComments>,
    filter_id: number,
}

export interface Incident {
    crash_id: number,
    crash_group_id: number,
    created_at: string,
    project_id: string,
    device_id: string,
    exception_message: string,
    version_name: string,
    stacktrace: string,
    custom_properties: string,
    ip_address: string,
    country: string,
    city: string,
    report_on_crash: number,
    stack_decoded: number,
    device_details: ReactJSDeviceDetails & AndroidDeviceDetails & FlutterDeviceDetails & PHPDeviceDetails,
    is_dev_device: boolean
}

/*export interface Comments {
    comment_id: number,
    crash_group_id: number,
    submitted_by: number,
    created_at: string,
    project_id: string,
    comment: string,
    user_details: UserDetails
}*/

export interface IncidentsProps {
    incidents: Array<Incident>,
    project_type: ProjectType,
    getIssue(crash_group_id: number)
}

export interface IssueStats24HoursProps {
    crash_group_id: number
}

export interface IssueActivitiesProps {
    project_id: string,
    crash_group_id: number
}