import {useEffect, useState} from "react";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";

export default function useStatusCounts(project_id: string, time_frame: string) {

    const [crashStatuses, setCrashStatuses] = useState(null);
    const [crashStatusesLoading, setCrashStatusesLoading] = useState(false);
    const [crashStatusesError, setCrashStatusesError] = useState(null);

    const getProjectStats = async (project_id: string, time_frame: string) => {
        try
        {
            setCrashStatusesError(null);
            setCrashStatusesLoading(true);

            const data = {
                project_id: project_id,
                time_frame: time_frame
            }

            const response = await sendRequest(data, '/dashboard/status-counts', HTTP_METHOD.POST);
            setCrashStatuses(response.data);
        }
        catch (err)
        {
            setCrashStatusesError(err);
        }
        finally {
            setCrashStatusesLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getProjectStats(project_id, time_frame);
            }
        )()
    }, [project_id, time_frame])

    return {crashStatuses, crashStatusesError, crashStatusesLoading};
}