import * as React from "react";
import TopNavBar from "../TopNavBar";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import CrashSummary from "./CrashSummary";
import {useCallback, useEffect, useState} from "react";
import {
    CrashFilterStatus,
    CrashFilterType,
    CrashGroup,
    CrashSummaryTimePeriod,
    ProjectMemberDetails, ProjectStats
} from "../../Models/CrashDetails";
import CrashSidebar from "./CrashSidebar";
import CrashGroupDetails from "../CrashGroupDetails";
import useCrashGroups from "../../Hooks/useCrashGroups";
import useProjectDetails from "../../Hooks/useProjectDetails";
import useProjectStats from "../../Hooks/useProjectStats";
import useProjectMembers_old from "../../Hooks/useProjectMembers_old";

type CrashDetailsProps = {
    match: {
        params: {
            project_id: string,
            project_type: string,
            crash_group_id?: string,
            comment_id?: string
        }
    },
    crashGroup?: CrashGroup,
    assignedTo?: number
}

function CrashDetails(props: any)
{
    let breadcrumbNav: Array<BreadcrumbNavLink>;

    const groupButtonOptions = [
        {
            label: CrashSummaryTimePeriod["1 Day"],
            selected: false
        },
        {
            label: CrashSummaryTimePeriod["1 Week"],
            selected: false
        },
        {
            label: CrashSummaryTimePeriod["1 Month"],
            selected: false
        }
    ];

    const [timePeriod, setTimePeriod] = useState<CrashSummaryTimePeriod>(CrashSummaryTimePeriod["1 Day"]);
    const [selectedCrashGroup, setSelectedCrashGroup] = useState(null);
    const [sideBarSearchTerm, setSidebarSearchTerm] = useState('');
    const [crashGroupFiltered, setCrashGroupFiltered] = useState([]);
    const [crashGroupAssignmentFilter, setCrashGroupAssignmentFilter] = useState({id: -1, name: 'All Crash Groups'});
    const [crashTypeFilter, setCrashTypeFilter] = useState(CrashFilterType.All);
    const [crashStatusFilter, setCrashStatusFilter] = useState(CrashFilterStatus.All);


    const {crashGroups, crashGroupsLoading, crashGroupsError, getCrashGroups} = useCrashGroups(props.match.params.project_id, crashGroupAssignmentFilter, crashTypeFilter, crashStatusFilter);
    const projectDetails = useProjectDetails(props.match.params.project_id);
    const projectStats = useProjectStats(props.match.params.project_id, timePeriod);
    const {selectedAssignmentDetails, projectMembers, setSelectedAssignmentDetails } = useProjectMembers_old(props.match.params.project_id, props.crashGroups);

    const clearCrashGroupSelection = (event) => {
        event.preventDefault();

        setSelectedCrashGroup(null);
    }

    const refreshCrashGroupData = async () => {
        await getCrashGroups(props.match.params.project_id, crashGroupAssignmentFilter.id,
            crashTypeFilter, crashStatusFilter);
    }

    const handleCrashGroupSelection = (crashGroupItem) => {

        if ((crashGroupItem !== null && crashGroupItem.UserDetails !== null) && crashGroupItem.assigned_to !== 0) {
            const assignmentDetails: ProjectMemberDetails = {
                userId: crashGroupItem.assigned_to,
                assignmentName: crashGroupItem.assigned_to_details.name
            }
            setSelectedAssignmentDetails(assignmentDetails);
        } else {
            const assignmentDetails: ProjectMemberDetails = {
                userId: 0,
                assignmentName: 'Not Assigned'
            }
            setSelectedAssignmentDetails(assignmentDetails);
        }
        if (typeof crashGroupItem !== typeof undefined) {
            setSelectedCrashGroup(crashGroupItem);
        }

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }



    if (selectedCrashGroup === null || typeof selectedCrashGroup === typeof undefined) {
        breadcrumbNav = [
            {
                label: 'Dashboard',
                link: '/dashboard'
            },
            {
                label: 'Project Summary',
                link: '/android-crash-details/' + props.match.params.project_id + "/" + props.match.params.project_type
            }
        ]
    } else {
        breadcrumbNav = [
            {
                label: 'Dashboard',
                link: '/dashboard'
            },
            {
                label: 'Project Summary',
                link: function(e) {
                    clearCrashGroupSelection(e);
                }
            },
            {
                label: 'View Crash Group: ' + selectedCrashGroup?.exception_group,
                link: ''
            }
        ]
    }

    const selectedAssignmentDetailsUpdated = (userId: number, assignmentName: string) =>
    {
        const tempSelectedAssignmentDetails = selectedAssignmentDetails;
        tempSelectedAssignmentDetails.assignmentName = assignmentName
        tempSelectedAssignmentDetails.userId = userId;
        setSelectedAssignmentDetails({...tempSelectedAssignmentDetails});
    }

    const filterCrashGroupOnAssignment = (assignmentId, name) => {
        setCrashGroupAssignmentFilter({id: assignmentId, name: name});
    }

    const filterCrashGroupOnCrashType = (crashType: CrashFilterType) => {
        setCrashTypeFilter(crashType)
    };

    const filterCrashGroupOnCrashStatus = (crashStatus: CrashFilterStatus) => {
        setCrashStatusFilter(crashStatus);
    }

    const searchTermUpdated = useCallback((searchTerm) => {

        setSidebarSearchTerm(searchTerm);
        let searchGroupFilteredArr = [];
        if (crashGroups !== null)
        {
            searchGroupFilteredArr = crashGroups.filter(crashGroup => crashGroup.exception_group.toLowerCase().includes(searchTerm.toLowerCase()));
        }


        setCrashGroupFiltered(searchGroupFilteredArr);

    }, [crashGroups])

    const showTimePeriodButtons = selectedCrashGroup === null;
    return (
        <div className='wrapper'>
            <TopNavBar items={groupButtonOptions} displayTimePeriodButton={showTimePeriodButtons}
                       handleToggleChange={setTimePeriod} currentTimePeriod={timePeriod} title='Crash Details'/>

            <BreadcrumbNav menu_items={breadcrumbNav}/>
            <CrashSidebar loading={crashGroupsLoading} crash_groups={sideBarSearchTerm.length === 0 ? crashGroups : crashGroupFiltered}
                          selectedCrashGroupID={selectedCrashGroup !== null ? selectedCrashGroup?.crash_group_id : 0}
                          crashGroupSelected={handleCrashGroupSelection}
                          project_id={props.match.params.project_id}
                          assignmentFilterUpdated={filterCrashGroupOnAssignment}
                          assignedTo={crashGroupAssignmentFilter}
                          crashFilterType={crashTypeFilter}
                          crashTypeFilterUpdated={filterCrashGroupOnCrashType}
                          crashFilterStatus={crashStatusFilter}
                          crashStatusFilterUpdated={filterCrashGroupOnCrashStatus}
                          searchTermUpdated={searchTermUpdated}
                          projectMembers={projectMembers}/>


            <div className='mainContent'>
                <h1>
                    {
                        projectDetails !== null && typeof projectDetails !== typeof undefined ? "Viewing Project Summary for " + projectDetails.project_name :
                            "Viewing Project Summary"
                    }
                </h1>

                {
                    (
                        () => {
                            return (
                                () => {
                                    if (selectedCrashGroup === null || typeof selectedCrashGroup === typeof undefined) {
                                        switch (props.match.params.project_type) {
                                            case "Android":
                                            case "ReactJS":
                                            case "PHP":
                                            case "iOS":
                                            case "Flutter":
                                            case "Custom SDK":
                                                return (
                                                    <CrashSummary time_period={timePeriod}
                                                                  project_id={props.match.params.project_id}
                                                                  project_stats={(projectStats !== null) && typeof projectStats !== typeof undefined ? projectStats.ProjectStats : null}
                                                                  affected_files={(projectStats !== null) && typeof projectStats !== typeof undefined ? projectStats.AffectedFiles : null}/>
                                                )
                                            default:
                                                throw new Error("Invalid project type: " + props.match.params.project_type);
                                        }
                                    } else {
                                        return (
                                            <CrashGroupDetails refreshCrashGroupData={refreshCrashGroupData}
                                                               crashGroup={selectedCrashGroup}
                                                               project_id={props.match.params.project_id}
                                                               projectMembers={projectMembers}
                                                               selectedAssignmentDetails={selectedAssignmentDetails}
                                                               projectType={props.match.params.project_type}
                                                               comment_id={parseInt(props.match.params.comment_id)}
                                                               handleAssignmentUpdated={selectedAssignmentDetailsUpdated} />
                                        )
                                    }
                                }
                            )()
                        }
                    )()
                }
            </div>

        </div>
    )
}

export default CrashDetails;