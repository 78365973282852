import * as React from "react";
import {sendRequest} from "../JSFuncs/APIManager";
import {useEffect, useState} from "react";

export default function useProjectStats(project_id, time_period) {

    const [projectStats, setProjectStats] = useState(null);

    const getProjectStats = async (project_id, time_period) => {

        const postArray = {
            time_period: time_period
        }
        const response = await sendRequest(postArray, `/crash/stats/${project_id}`);
        if (response.status === 0)
        {
            const affectedFilesArr = [];
            for (let i = 0; i < response.data.crash_group_affected_files.length; i++) {
                const fileStats = response.data.crash_group_affected_files[i];
                affectedFilesArr.push(
                    <li data-tip={fileStats.class_script_name + " received " + fileStats.count + " crashes"}>{fileStats.count} - {fileStats.class_script_name}</li>
                )
            }
            setProjectStats({
                ProjectStats: response.data,
                AffectedFiles: affectedFilesArr
            });
        }
        else {
            console.error("useProjectStats", response.data);
        }
    }

    useEffect(() => {
        (
            async function () {
                await getProjectStats(project_id, time_period);
            }
        )()
    }, [project_id, time_period]);

    return projectStats;
}