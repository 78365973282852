import * as React from "react";
import ReactTooltip from "react-tooltip";
import {FaBug, FaComment, FaExclamation} from "react-icons/fa";
import {FaCheckCircle, FaUser} from "react-icons/fa";
import {CrashGroupItemProps} from "../../Models/CrashDetails";

import '../../ComponentStyles/CrashGroupItem.css'
import '../../ComponentStyles/CrashGroupDetails.css'

function CrashGroupItem(props: CrashGroupItemProps)
{
    const className = props.selected ? 'crashGroupItem active' : 'crashGroupItem';

    return (
        <div data-crash-group-id={props.crash_group.crash_group_id} className={className} onClick={() => props.handleCrashGroupSelected(props.crash_group)}>
            <div className='flex flex-row'>
                <div>
                    <span>
                        <div className='crashGroupStatInfo'>
                            <div data-tip='Number of crashes for this group' className='tooltipContainer'>
                                <FaBug className='inline text-white' />
                                <span>{props.crash_group.count}</span>
                            </div>

                            <div data-tip='Number of comments' className='tooltipContainer'>
                                <FaComment className='inline text-white'/>
                                <span>{props.crash_group.comment_count}</span>
                            </div>

                            <div data-tip='Severity' className='tooltipContainer'>
                                <FaExclamation className='inline text-white' />
                                <span>{props.crash_group.severity}</span>
                            </div>

                            <div data-tip='Affected Users' className='tooltipContainer'>
                                <FaUser className='inline text-white' />
                                <span>{props.crash_group.affected_users}</span>
                            </div>

                        </div>
                    </span>
                    <p className='crashGroupTitle'>{props.crash_group.exception_group}</p>
                    <p className='crashGroupSubTitle'>{props.crash_group.class_script_name !== null && props.crash_group.class_script_name.length > 35 ? props.crash_group.class_script_name.substr(0, 35) + "..." : props.crash_group.class_script_name}:{props.crash_group.line_no}</p>
                </div>
                <div className='text-right'>
                    <p className='lastOccurrence'>
                        {props.crash_group.last_occurrence}
                    </p>
                    {
                        props.crash_group.crash_type === 'Handled' ?
                            <span className='crashTypeBadge crashTypeBadgeHandled'>Handled</span> :
                            <span className='crashTypeBadge crashTypeBadgeUnhandled'>Unhandled</span>

                    }
                    {
                        props.crash_group.Status === 'Resolved' ?
                            <div data-tip='Crash Group Resolved' style={{display: 'inline-block', marginLeft: 10}}>
                                <FaCheckCircle style={{color: '#51B043'}} />
                            </div> : null
                    }

                </div>
            </div>

            <ReactTooltip />

        </div>
    );
}

export default  CrashGroupItem;