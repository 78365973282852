import {useEffect, useState} from "react";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";

export default function useHandledVsUnhandled(project_id: string, time_frame: string) {

    const [handledVsUnhandled, setHandledVsUnhandled] = useState(null);
    const [handledVsUnhandledLoading, setHandledVsUnhandledLoading] = useState(false);
    const [handledVsUnhandledError, setHandledVsUnhandledError] = useState(null);

    const getHandledVsUnhandled = async (project_id: string, time_frame: string) => {
        try
        {
            setHandledVsUnhandledError(null);
            setHandledVsUnhandledLoading(true);

            const data = {
                project_id: project_id,
                time_frame: time_frame
            }

            const response = await sendRequest(data, '/dashboard/handled-vs-unhandled', HTTP_METHOD.POST);
            setHandledVsUnhandled(response.data);
        }
        catch (err)
        {
            setHandledVsUnhandledError(err);
        }
        finally {
            setHandledVsUnhandledLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getHandledVsUnhandled(project_id, time_frame);
            }
        )()
    }, [project_id, time_frame])

    return {handledVsUnhandled, handledVsUnhandledError, handledVsUnhandledLoading}
}