import * as React from "react";
import {CrashCatchContext} from 'crashcatchlib-reactjs';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo)
    {
        this.setState({hasError: true});
        const crash_catch = this.context;
        crash_catch.reportUnhandledException(error);
    }

    render() {
        return this.props.children
    }
}
export default ErrorBoundary