import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import ReactTooltip from "react-tooltip";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from "react-markdown/with-html";
import {FaTrashAlt} from "react-icons/fa";
import Mentions from "./Mentions";
import {ProjectMemberDetails} from "../Models/CrashDetails";
import {ModalDialog, ModalState, returnErrorModal} from "devso-react-library";

export type CrashCommentsProps = {
    project_id: string,
    crash_group_id: number
    confirmDeletingComment: Function,
    members: Array<ProjectMemberDetails>
}

const fetchComments = async (project_id: string, crash_group_id: number, confirmDeletingComment: Function) => {

    const response = await sendRequest(null, `/comments/${project_id}/${crash_group_id}`);
    if (response.data !== null)
    {
        const commentsArr = [];
        if (response.data !== null) {
            if (response.data.length > 0) {
                for (let i = 0; i < response.data.length; i++) {
                    commentsArr.push(<Comment refreshComments={fetchComments}
                                              key={response.data[i].CommentID} details={response.data[i]}
                                              confirmCommentDelete={confirmDeletingComment}/>);
                }
            } else {
                commentsArr.push(<p style={{fontStyle: 'italic', textAlign: 'center'}}>There are no comments
                    for this incident</p>)
            }

            //comp.setState(comp.myState);
            //setComments(commentsArr);

            const commentCounterContainer = document.querySelector("span#commentCount");
            if (commentCounterContainer !== null) {
                commentCounterContainer.innerHTML = response.data.length;
            }
        }
        return commentsArr
    }
};

function CrashComments(props: CrashCommentsProps)
{
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState([]);
    const [commentBoxPos, setCommentBoxPos] = useState({});
    //const [simpleMDECursorPos, setSimpleMDECursorPos] = useState({line: 0,ch: 3});
    const [simpleMDEKey, setSimpleMDEKey] = useState((Math.random() * 9999) + 1);
    const [modalState, setModalState] = useState<ModalState>(null);


    const simpleMDECursorPos = useRef({line: 0, ch: 3, sticky: true});

    const setSimpleMDECursorPos = (obj) => {
        simpleMDECursorPos.current = obj
    }

    const submitNewComment = async () => {
        const mentionedUsers = returnListOfMentions();

        const postArray = {
            new_comment: newComment,
            //project_id: props.project_id,
            //crash_group_id: props.crash_group_id,
            //mentions: JSON.stringify(mentionedUsers)
            mentions: mentionedUsers
        };

        const response = await sendRequest(postArray, `/comments/${props.project_id}/${props.crash_group_id}`, HTTP_METHOD.PUT);
        if (response.status === 0)
        {
            setNewComment('');
            fetchComments(props.project_id, props.crash_group_id, props.confirmDeletingComment).then(function(result: any){
                //setNewComment('');
                setComments(result);
            }).catch(function(err){
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            });
        }
        else
        {
            //setAPIResult(response);
        }

    }

    const returnListOfMentions = () => {
        const regex = /\/\/[a-zA-Z0-9].*?\/\//mg;

        const str = newComment;
        let m;

        const mentions = [];

        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            m.forEach((match) => {
                mentions.push(match);
            });
        }

        return mentions;
    }

    useEffect(() => {
        const commentsTab : HTMLElement = document.querySelector("#comments");
        if (commentsTab !== null)
        {
            const observer = new MutationObserver(function(){
                if (commentsTab.style.display !== 'none')
                {

                    //Now check the position of the comments wrapper
                    const commentsWrapper = document.querySelector("#txtComment-wrapper");
                    if (commentsWrapper !== null)
                    {
                        const pos = commentsWrapper.getBoundingClientRect();
                        //setCommentBoxPos(pos);
                    }
                }
            });

            observer.observe(commentsTab, {attributes: true, childList: true});

        }
        fetchComments(props.project_id, props.crash_group_id, props.confirmDeletingComment).then(function(result){
            setNewComment('');
            setComments(result);
        });
    }, [props.project_id, props.crash_group_id, props.confirmDeletingComment]);


    const commentUpdatedEvent = (updatedText) => {
        const id = Math.random() * 9999 + 1;
        setSimpleMDEKey(id);
        setNewComment(updatedText);
    }
    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return {
            autofocus: true,
            spellChecker: false,
        }
    }, []);

    return (
        <div>

            <div style={{maxHeight: 200, overflow: 'auto', marginBottom: 20}}>
                {comments}
            </div>
            <hr/>

            <SimpleMDE  key={simpleMDEKey} id='txtComment' getLineAndCursor={setSimpleMDECursorPos}
                        options={autofocusNoSpellcheckerOptions} onChange={(value) => setNewComment(value)} value={newComment} />

            <button type='button' onClick={submitNewComment} className='btn btn-primary'
                    disabled={newComment.length === 0}>Post New Comment
            </button>

            <Mentions textUpdatedEvent={commentUpdatedEvent} controlId='txtComment'
                      controlCurPos={simpleMDECursorPos.current} controlPos={commentBoxPos}
                      currentControlText={newComment} userList={props.members}/>

            <ModalDialog {...modalState} />
        </div>
    )

}

function Comment(props)
{
    return (
        <div className='commentWrapper'>
            <div className='commentBadge tooltipContainer' data-tip={props.details.name}>
                {props.details.name[0]}
            </div>
            <div className='commentContainer'>
                <ReactMarkdown source={props.details.comment} escapeHtml={false} />
                <span>{props.details.created_at} <FaTrashAlt className='inline cursor-pointer' color='red' title='Delete Comment'
                                    onClick={() => props.confirmCommentDelete(props.details.comment_id)}/></span>
            </div>

            <ReactTooltip/>
        </div>
    )
}

export default CrashComments;