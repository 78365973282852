export function convertEpochToHumanDate(epoch: number, includeTime: boolean = false)
{
    const dateObj = new Date(0);
    dateObj.setUTCSeconds(epoch);

    let dateString = "";
    dateString = dateObj.getDate().toString().padStart(2, '0') + "-"
        + (dateObj.getMonth() +1).toString().padStart(2, '0') + "-" + dateObj.getFullYear();

    if (includeTime)
    {
        dateString += " " + dateObj.getHours().toString().padStart(2, "0") + ":"
            + dateObj.getMinutes().toString().padStart(2, "0") + ":" + dateObj.getSeconds();
    }

    return dateString;
}

export function convertEloquentTimeToHumanReadable(dateTimeString: string, includeTime: boolean = false)
{
    if (typeof dateTimeString !== typeof undefined) {
        const parts = dateTimeString.split('T');
        const date = parts[0].trim();
        let time = parts[1].trim();

        time = time.substring(0, time.indexOf('.'));
        if (includeTime) {
            return date + ' ' + time;
        } else {
            return date;
        }
    }
    else
    {
        return "";
    }
}