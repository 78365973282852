import * as React from "react";
import {useEffect, useState} from "react";
import {CrashFilterStatus, CrashFilterType} from "../Models/CrashDetails";
import {sendRequest} from "../JSFuncs/APIManager";

export default function useCrashGroups(project_id, crashGroupAssignmentFilter, crashTypeFilter, crashStatusFilter) {
    const [crashGroups, setCrashGroups] = useState(null);
    const [crashGroupsLoading, setCrashGroupsLoading] = useState(false);
    const [crashGroupsError, setCrashGroupsError] = useState(null);

    const getCrashGroups = async (project_id, crashGroupAssignmentFilter, crashTypeFilter, crashStatusFilter) => {

        try
        {
            setCrashGroupsLoading(true);
            const postArray: any = {
                project_id: project_id,
                crash_status: crashStatusFilter,
                crash_type: crashTypeFilter,
                assignment_filter: crashGroupAssignmentFilter.id,
                //crashTypeFilter: crashTypeFilter
            };
            switch (crashTypeFilter) {
                case CrashFilterType.All:
                    postArray.crash_type_filter = "All";
                    break;
                case CrashFilterType.Handled:
                    postArray.crash_type_filter = "Handled";
                    break;
                case CrashFilterType.Unhandled:
                    postArray.crash_type_filter = "Unhandled";
                    break;
            }

            switch (crashStatusFilter) {
                case CrashFilterStatus.All:
                    postArray.crash_status = 'all';
                    break;
                case CrashFilterStatus.Open:
                    postArray.crash_status = 'open';
                    break;
                case CrashFilterStatus.Resolved:
                    postArray.crash_status = 'resolved';
                    break;
            }

            const result = await sendRequest(postArray, `/crash/groups/${project_id}`);
            if (result.status === 0) {
                //Ensure that if a crash group is selected, that the data for it is updated as this fetch
                //couldn't have come from the Android Crash Group Details and something within the crash group
                //was toggled
                //const crashGroupData = result.data;
                //let updatedSelectedCrashGroup;
                setCrashGroups(result.data);
                /*return {
                    SelectedCrashGroup: updatedSelectedCrashGroup,
                    CrashGroups: crashGroupData
                }*/
            } else {
                throw result.response;
            }
        }
        catch (err)
        {
            console.error("useCrashGroups", err);
        }
        finally {
            setCrashGroupsLoading(false);
        }

        /*setCrashGroupsLoading(true);
        await fetchCrashGroups(project_id, crashGroupAssignmentFilter.id,
            crashTypeFilter, crashStatusFilter).then(function(result: any){
            setCrashGroups(result?.CrashGroups);
            setCrashGroupsLoading(false);
        }).catch(function(err){
            setCrashGroupsError(err);
            setCrashGroupsLoading(false);
        });*/
    }

    useEffect(() => {
        (
            async function () {
                await getCrashGroups(project_id, crashGroupAssignmentFilter, crashTypeFilter, crashStatusFilter);
            }
        )();
    }, [crashStatusFilter, crashTypeFilter, crashGroupAssignmentFilter])

    return {crashGroups, crashGroupsLoading, crashGroupsError, getCrashGroups};
}