import * as React from "react";
import {IssueActivitiesProps} from "../../Models/Issues";
import useProjectActivities from "../../Hooks/useProjectActivities";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";

export default function IssueActivities(props: IssueActivitiesProps) {

    const {projectActivities, projectActivitiesLoading, projectActivitiesError, getProjectActivities} =
        useProjectActivities(props.project_id, props.crash_group_id);

    return (
        <>
            <h2>Issue Activities</h2>
            {
                (
                    () => {
                        if (projectActivitiesLoading)
                        {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!projectActivitiesLoading && projectActivitiesError !== null)
                        {
                            return returnErrorComponent(projectActivitiesError, "Failed to get issue activities");
                        }
                        else if (!projectActivitiesLoading && projectActivities !== null)
                        {
                            return projectActivities.map(activity => {
                                return (
                                    <div className='p-3 border-gray-500 border-[1px] m-2'>
                                        <div className='flex flex-row'>
                                            {
                                                (
                                                    () => {
                                                        if (activity.activity_completed_by_details !== null)
                                                        {
                                                            if (activity.activity_completed_by_details.profile_image !== null)
                                                            {
                                                                return <img className='w-[32px] h-[32px] rounded-full' data-tip={activity.activity_completed_by_details.name}
                                                                            src={activity.activity_completed_by_details.profile_image} alt='profile' />
                                                            }
                                                            else
                                                            {
                                                                return (
                                                                    <div className='w-[32px] h-[32px] text-white text-center align-middle font-bold !bg-primary rounded-full' data-tip={activity.activity_completed_by_details.name}>
                                                                        {
                                                                            activity.activity_completed_by_details.name[0].toUpperCase()
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                        else
                                                        {
                                                            return (
                                                                <div className='w-[32px] h-[32px] !bg-primary text-center align-middle font-bold text-white rounded-full' data-tip='Crash Catch'>
                                                                    C
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                )()
                                            }
                                            <div className='ml-4'>
                                                <p className='font-bold'>{activity.message}</p>
                                                <p className='text-gray-500 text-sm'>
                                                    {activity.created_at}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                        else
                        {
                            return null;
                        }
                    }
                )()
            }
        </>
    )
}