import * as React from "react";
import {useState} from "react";
import {HTTP_METHOD, InvalidCredentials, sendRequest} from '../JSFuncs/APIManager'
import { useMediaQuery } from 'react-responsive'
import * as H from "history";
import {Link, useHistory} from "react-router-dom"
import {useGlobalState} from "./GlobalStateProvider";
import {
    Form,
    FormButtonContainer,
    FormInputDirection,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog, returnDefaultModalState,SecondaryButton
} from "devso-react-library";
import Footer from "./Footer";
import {FaFacebook, FaLinkedin, FaTwitter} from "react-icons/fa";

export type LoginProps = {
    match: {
        params: {
            msg: string,
            redirect: string
        }
    }
}

export interface RouteComponentProps<LoginProps> {
    match: match<LoginProps>;
    location: H.Location;
    history: H.History;
    staticContext?: any;
}

export interface match<P> {
    params: P;
    isExact: boolean;
    path: string;
    url: string;
}


function Login(props: any)
{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error_msg, setErrorMsg] = useState('');
    const [show_notice, setShowNotice] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const history = useHistory();
    const { setGlobalState, globalState } = useGlobalState();
    const [modalState, setModalState] = useState(null);

    const handleSubmit = async (formData) => {

        try {
            setFormLoading(true);
            setFormErrors(null);
            const response = await sendRequest(formData, '/auth/login', HTTP_METHOD.POST);
            if (response.message === "AccountCancelled") {

            } else {
                //window.location.href = '/dashboard';
                const tempGlobalState = globalState;
                tempGlobalState.trialOrPaymentBannerShow = false;

                tempGlobalState.trial = {
                    in_trial: response.data.in_trial,
                    days_of_trial_remaining: response.data.in_trial ? response.data.trial_days_remaining : null
                }

                if (response.data.in_trial)
                {
                    tempGlobalState.trialOrPaymentBannerShow = true;
                }
                else if (response.data.last_payment_failed)
                {
                    tempGlobalState.trialOrPaymentBannerShow = true;
                }

                tempGlobalState.payment_failed = {
                    last_payment_failed: response.data.last_payment_failed,
                    last_payment_failed_reason: response.data.last_payment_failed_reason,
                    payment_time_required: response.data.payment_time_required,
                    payment_required: false //Still within the 7 day grace period for non payment
                }
                localStorage.setItem("global_state", JSON.stringify(tempGlobalState));
                setGlobalState({...tempGlobalState});

                console.log("Setting trial banner to show", tempGlobalState);

                const redirect = props.match.params.redirect;
                if (typeof redirect !== typeof undefined)
                {
                    const redirectUrl = atob(redirect);
                    window.location.href = redirectUrl;
                }
                else
                {
                    history.push('/project-dashboard');
                }
            }
        }
        catch (e)
        {
            if (e.response?.status === 401 && e.response.data.message === "AccountDisabled")
            {
                setErrorMsg('Your account has been disabled. Please contact your account manager if you believe this is incorrect');
                setShowNotice(true);
            }
            else if (e.response?.status === 402) //Payment Required
            {
                const response = e.response.data;

                const tempGlobalState = globalState;
                tempGlobalState.trial = {
                    in_trial: false,
                    days_of_trial_remaining: 0,
                };


                tempGlobalState.payment_failed = {
                    last_payment_failed: response.data.last_payment_failed,
                    last_payment_failed_reason: response.data.last_payment_failed_reason,
                    payment_time_required: response.data.payment_time_required,
                    payment_required: true //Gone over the 7 days non payment grace period
                }
                tempGlobalState.trialOrPaymentBannerShow = true;
                setGlobalState({...tempGlobalState});
                console.log("Setting trial or payent banner in login", tempGlobalState);
                localStorage.setItem("global_state", JSON.stringify(tempGlobalState));
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = "Payment Required";
                tempModalState.content = <p>{response.message}</p>
                tempModalState.buttons = [
                    <PrimaryButton label='Go to Account & Billing' onClick={() => {
                        history.push("/account-billing");
                    }} />,
                    <SecondaryButton label='Dismiss' onClick={() => {
                        const temp = returnDefaultModalState();
                        setModalState({...temp});
                    }} />
                ]
                setErrorMsg(response.message);
                setShowNotice(true);
                setModalState({...tempModalState});
            }
            else if (e.response?.data.status === 100 && e.response.data.message.length > 0)
            {
                setErrorMsg(e.response.data.message);
                setShowNotice(true);
            }
            /*else if (e instanceof InvalidCredentials)
            {
                setErrorMsg('Your username and/or password combination was not valid');
            }*/
            else if (e.response?.status === 422)
            {
                setFormErrors(e.response.data.errors);
            }
            else
            {
                setErrorMsg(e.toString());
                setShowNotice(true);
            }
        }
        finally {
            setFormLoading(false);
        }
    };
    const style = show_notice === false && typeof props.match.params.msg === typeof undefined ? {display: 'none'} : {};
    const year = new Date().getFullYear();
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 833px)'
    })
    if (isTabletOrMobileDevice)
    {
        return (
            <div className='h-screen bg-primary overflow-y-auto'>
                <div className='grid place-items-center h-screen'>
                    <img className='w-1/3 mx-auto' src='/images/logo-inverted.png' alt='Crash Catch Logo' />
                    <h2 className='text-white text-sm font-bold text-center'>
                        Crash reporting for every developer, no matter your development stack
                    </h2>
                    <div className='bg-white shadow-md shadow-gray-400 rounded w-3/4 p-5 -mt-29'>
                        <LoginStatus style={style}
                                     error_msg={typeof props.match.params.msg !== typeof undefined ? props.match.params.msg : error_msg}/>
                        <Form className='w-full mx-auto' handleSubmit={handleSubmit} errors={formErrors}
                              loading={formLoading} direction={FormInputDirection.Horizontal}>

                            <TextField type={TextFieldType.EMAIL} label='Email' api_field_name='email'
                                       placeholder='someone@example.com' value={email} onChange={setEmail}/>
                            <TextField type={TextFieldType.PASSWORD} label='Password'
                                       placeholder='Enter your password' api_field_name='password'
                                       value={password} onChange={setPassword}/>

                            <FormButtonContainer>
                                <PrimaryButton label='Login'/>
                            </FormButtonContainer>
                        </Form>
                        <div className='mt-8'>
                            <p className='text-center font-bold'>
                                Not yet got an account, <Link className='font-bold' to='/choose-plan'
                                                              title='Register your new account'>&nbsp;
                                you can sign up</Link>&nbsp;
                                and start your 14 day trial
                            </p>

                            <p className='text-center'>
                                <Link to='/forgotten-password'
                                      title='I forgot my password - request a password reset'>Forgot my
                                    password</Link>
                            </p>

                            <p className='text-center notice'>
                                By logging in you are agreeing to our &nbsp;
                                <Link to='/privacy-policy' title='View our privacy policy'>privacy policy</Link> and
                                &nbsp;our <Link to='/terms-of-service' title='View our terms of service'>terms of
                                service</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='h-screen'>
                <div className='h-full flex flex-row'>
                    <div className='h-full w-1/2 bg-primary grid place-items-center h-screen text-white shadow-md shadow-primary/80'>
                        <div className='mx-2'>
                            <img className='w-1/2 mx-auto' src='/images/logo-inverted.png' alt='Crash Catch Logo'/>
                            <h2 className='text-white text-center mt-10'>Crash reporting for every developer, no matter
                                your development stack</h2>
                        </div>
                    </div>
                    <div className='w-1/2 grid place-items-center h-screen'>
                        <div className='w-3/4'>
                            <p className='text-center font-bold text-2xl font-bold'>
                                Welcome to Crash Catch
                            </p>
                            <LoginStatus style={style}
                                         error_msg={typeof props.match.params.msg !== typeof undefined ? props.match.params.msg : error_msg}/>
                            <Form className='w-full mx-auto' handleSubmit={handleSubmit} errors={formErrors}
                                  loading={formLoading} direction={FormInputDirection.Horizontal}>

                                <TextField type={TextFieldType.EMAIL} label='Email' api_field_name='email'
                                           placeholder='someone@example.com' value={email} onChange={setEmail}/>
                                <TextField type={TextFieldType.PASSWORD} label='Password'
                                           placeholder='Enter your password' api_field_name='password'
                                           value={password} onChange={setPassword}/>

                                <FormButtonContainer>
                                    <PrimaryButton label='Login'/>
                                </FormButtonContainer>
                            </Form>
                            <div className='mt-8'>
                                <p className='text-center font-bold'>
                                    Not yet got an account, <Link className='font-bold' to='/choose-plan'
                                                                  title='Register your new account'>&nbsp;
                                    you can sign up</Link>&nbsp;
                                    and start your 14 day trial
                                </p>

                                <p className='text-center'>
                                    <Link to='/forgotten-password'
                                          title='I forgot my password - request a password reset'>Forgot my
                                        password</Link>
                                </p>

                                <p className='text-center notice'>
                                    By logging in you are agreeing to our &nbsp;
                                    <Link to='/privacy-policy' title='View our privacy policy'>privacy policy</Link> and
                                    &nbsp;our <Link to='/terms-of-service' title='View our terms of service'>terms of
                                    service</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /*return (
        <>
            <div className='flex h-screen w-full h-full'>
                <div className='mx-auto justify-center items-center mt-5'>
                    <div className='flex flex-col sm:flex-row text-center items-center justify-center'>
                        <div className='mr-4 w-filltext-center'>
                            <img style={{width: 120}} className='ml-auto mr-auto sm:ml' src='/images/logo.png' alt='Crash Catch Logo' />
                        </div>
                        <div className='w-fill'>
                            <h1 className='hidden sm:block mb-0 pb-0 font-bold text-primary'>Crash Catch</h1>
                            <p className='m-0 p-0 font-bold'>
                                Crash reporting for every developer, every platform and every language
                            </p>
                        </div>
                    </div>

                    <div className='p-0 mt-5'>

                        <Form className='w-3/4 mx-auto' handleSubmit={handleSubmit} errors={formErrors} loading={formLoading} direction={FormInputDirection.Horizontal}>
                            <LoginStatus style={style} error_msg={typeof props.match.params.msg !== typeof undefined ? props.match.params.msg : error_msg} />

                            <TextField type={TextFieldType.EMAIL} label='Email' api_field_name='email'
                                       placeholder='someone@example.com' value={email} onChange={setEmail}/>
                            <TextField type={TextFieldType.PASSWORD} label='Password'
                                       placeholder='Enter your password' api_field_name='password'
                                       value={password} onChange={setPassword} />

                            <FormButtonContainer>
                                <PrimaryButton label='Login' />
                            </FormButtonContainer>

                        </Form>

                        <div className='mt-5'>
                            <p className='text-center font-bold'>
                                Not yet got an account, <Link to='/choose-plan' title='Register your new account'>you can sign up</Link>&nbsp;
                                and start your 14 day trial
                            </p>

                            <p className='text-center'>
                                <Link to='/forgotten-password' title='I forgot my password - request a password reset'>Forgot my password</Link>
                            </p>

                            <p className='text-center notice' >
                                By logging in you are agreeing to our &nbsp;
                                <Link to='/privacy-policy' title='View our privacy policy'>privacy policy</Link> and
                                &nbsp;our <Link to='/terms-of-service' title='View our terms of service'>terms of service</Link>
                            </p>
                        </div>
                    </div>

                    <ModalDialog {...modalState} />
                </div>

            </div>
            <div className='absolute bottom-0 w-full'>
                <Footer />
            </div>

        </>
    )*/
}

type LoginStatusProps = {
    error_msg: string,
    style: object
}

function LoginStatus(props: LoginStatusProps)
{
    const className = 'alert-warning';
    const msg = props.error_msg;
    return(
        <div style={props.style} className={'alert ' + className}>
            <p style={{textAlign: 'center', margin: 0, padding: 0}}>{msg}</p>
        </div>
    )
}

export default Login;