import * as React from "react";

export default function ContentWrapper(props) {
    return (
        <div className={`z-10 p-0 md:p-4 bg-gray-100 h-[calc(100vh-100px)] md:h-[calc(100vh-84px)] overflow-y-auto mt-20 mx-2 md:mt-0`}>
            {props.children}
        </div>
    )
}

ContentWrapper.defaultProps = {
    scrolling: false
}