import * as React from "react";
import {useState} from "react";
import UpdatePersonalDetails from "./UpdatePersonalDetails";
import {UpdateCardAndPersonalDetailsProps} from "../../Models/Billing";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {
    Form,
    FormButtonContainer,
    FormInputDirection,
    PrimaryButton, SecondaryButton,
    TextField,
    TextFieldType
} from "devso-react-library";

export default function UpdateCardAndPersonalDetails(props: UpdateCardAndPersonalDetailsProps) {

    const [formErrors, setFormErrors] = useState(null);
    const [formLoading, setFormLoading] = useState(false);

    const [nameOnCard, setNameOnCard] = useState('');
    const [addressLine1, setAddressLine1] = useState(props.billing.billing_details !== null? props.billing.billing_details.address.line1 : '');
    const [addressLine2, setAddressLine2] = useState(props.billing.billing_details !== null ? props.billing.billing_details.address.line2 : '');
    const [city, setCity] = useState(props.billing.billing_details !== null ? props.billing.billing_details.address.city : '');
    const [state, setState] = useState(props.billing.billing_details !== null ? props.billing.billing_details.address.state : '');
    const [postCode, setPostCode] = useState(props.billing.billing_details !== null ? props.billing.billing_details.address.postal_code : '')

    const stripe = useStripe();
    const elements = useElements();

    const handleFormSubmit = async (formData) => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setFormLoading(true);
        const cardNumber = elements.getElement(CardNumberElement);
        //const cardCVC = elements.getElement(CardCvcElement);
        //const cardExpiry = elements.getElement(CardExpiryElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
            billing_details: {
                name: nameOnCard,
                address: {
                    line1: addressLine1,
                    line2: addressLine2,
                    city: city,
                    state: state,
                    postal_code: postCode,
                }
            },
        });

        //Because this isn't a normal form, the children aren't seen so manually build
        //the formData from the state we have in this component
        formData.name_on_card = nameOnCard;
        formData.address_line1 = addressLine1;
        formData.address_line2 = addressLine2;
        formData.city = city;
        formData.state = state;
        formData.post_code = postCode;
        formData.stripe_token = paymentMethod.id


        try
        {
            const response = await sendRequest(formData, '/billing/update-card', HTTP_METHOD.POST);
            props.cardUpdatedSuccessfully();

            const trialButton = document.getElementsByClassName("trial_button");
            if (trialButton.length > 0)
            {
                trialButton[0].remove();
            }
            const trialBanner = document.getElementsByClassName("trialExpiredBanner")[0] as HTMLElement
            if ((trialBanner !== null) && typeof trialBanner !== typeof undefined)
            {
                trialBanner.parentNode.removeChild(trialBanner);
            }
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else {
                props.cardUpdateFailed(err);
            }
        }
        finally {
            setFormLoading(false);
        }
    }

    const baseStripeElementOptions = {
        style: {
            base: {
                iconColor: '#666EE8',
                fontFamily: '"Oxanium" sans-serif',
                fontSize: '16px',
                color: '#495057',
                '::placeholder': {
                    color: '#9f9f9f',
                },
                backgroundColor: 'white',
                border: 'solid thin #000000'
            },
            font: {
                src: '../../../fonts/Oxanium-VariableFont_wght.ttf',
                family: 'Oxanium'
            },
            invalid: {
                color: '#9e2146',
            },
        }
    }

    return (
        <>
            <Form errors={formErrors} loading={formLoading} handleSubmit={handleFormSubmit}>

                <div className='flex flex-col'>

                    <div className='m-4'>
                        <h2>Payment Card Information</h2>
                        <div className='mt-4'>
                            <TextField type={TextFieldType.TEXT} direction={FormInputDirection.Vertical} label='Name On Card:' placeholder='Name as shown on card'
                                       api_field_name='name_on_card' value={nameOnCard} onChange={setNameOnCard} />
                        </div>

                        <div className='mt-4 mb-4'>
                            <label className='mt-0'>
                                Card Number:
                                <CardNumberElement className='stripe-element' id='txtCardNumber' options={baseStripeElementOptions} />
                            </label>
                        </div>

                        <div className='mt-4 mb-4'>
                            <label className='mt-0'>
                                Card CVC:
                                <CardCvcElement className='stripe-element' options={baseStripeElementOptions} />
                            </label>
                        </div>

                        <div className='mt-4 mb-4'>
                            <label className='mt-0 mb-2'>
                                Card Expiry:
                                <CardExpiryElement className='stripe-element' options={baseStripeElementOptions} />
                            </label>
                        </div>
                        <img className='mt-6 mx-auto w-1/2' src='/images/stripe.png' alt='Powered by Stripe' title='Powered By Stripe' />
                    </div>
                    <div className='m-4'>
                        <h2>Your Details</h2>
                        <UpdatePersonalDetails address_line1={addressLine1}
                            address_line2={addressLine2}
                            city={city}
                            state={state}
                            post_code={postCode}
                            setAddressLine1={setAddressLine1}
                            setAddressLine2={setAddressLine2}
                            setCity={setCity}
                            setState={setState}
                            setPostCode={setPostCode}/>
                    </div>
                </div>


                <FormButtonContainer>
                    <PrimaryButton label="Update Your Details" />
                    <SecondaryButton onClick={() => props.closeUpdateCardAndPersonalDetails()}
                            label='Cancel' />
                </FormButtonContainer>

            </Form>
        </>
    )
}