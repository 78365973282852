import {useEffect, useState} from "react";
import {sendRequest} from "../JSFuncs/APIManager";
import {ProjectTeamMembers, ProjectTeamMembersAPIResponse} from "../Models/ProjectTeamMembers";

export default function useProjectMembers(project_id: string) {

    const [projectMembers, setProjectMembers] = useState<Array<ProjectTeamMembers>>(null)
    const [projectMembersError, setProjectMembersError] = useState(null);
    const [projectMembersLoading, setProjectMembersLoading] = useState(false);

    const getProjectMembers = async (project_id: string) => {
        try
        {
            setProjectMembersLoading(true);
            setProjectMembersError(null);

            const response : ProjectTeamMembersAPIResponse = await sendRequest(null, `/projects/project/${project_id}/members`);
            setProjectMembers(response.data.ProjectTeamMembers);
        }
        catch (err)
        {
            setProjectMembersError(err);
        }
        finally
        {
            setProjectMembersLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getProjectMembers(project_id);
            }
        )()
    }, [])

    return {projectMembers, projectMembersError, projectMembersLoading, getProjectMembers};
}