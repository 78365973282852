import * as React from "react";
import {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {sendRequest} from "../../JSFuncs/APIManager";
import {FaArrowDown, FaArrowRight, FaArrowUp} from "react-icons/fa";
import Loading from "../StandardComponents/Loading";
import {LoadingSize} from "../../Models/StandardComponents/Loading";
import {returnErrorComponent} from "devso-react-library";

type CrashCountChartProps = {
    project_id: string,
    time_period: string
}

const fetchCrashCounts = async (isLoading: boolean, project_id: string, time_period: string) => {
    return new Promise(async function(resolve, reject){
        if (isLoading)
        {
            const postArray = {
                project_id: project_id,
                time_period: time_period
            };

            try
            {
                const result = await sendRequest(postArray, `/crash/counts/${project_id}`);
                if (result.status === 0)
                {
                    const chartDataObj = {
                        labels: result.data.chart_labels,
                        datasets: [
                            {
                                type: 'line',
                                label: 'Crash Counts',
                                borderColor: '#1d1aa5',
                                backgroundColor: 'rgba(60,57,198,0.28)',
                                borderWidth: 1.5,
                                data: result.data.chart_data.crash_count_data
                            },
                            {
                                label: 'New Project Version Release',
                                backgroundColor: '#5f5656',
                                borderColor: 'rgb(49,35,35)',
                                borderWidth: 1,
                                data: result.data.chart_data.app_release_data
                            }
                        ]
                    }
                    resolve({
                        ChartData: chartDataObj,
                        ChartAPIRawResult: result.data
                    });
                }
                else
                {
                    reject(result);
                }
            }
            catch (err)
            {
                reject(err);
            }

        }
    })
}

function CrashCountChart(props: CrashCountChartProps)
{
    const [chartData, setChartData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [chartAPIRawResult, setChartAPIRawResult] = useState<ChartAPIRawResult | any>({});
    const [errors, setErrors] = useState(null);

    type ChartData = {
        version_name_release: Array<string>
    }

    type PercentIncreaseFromPreviousTimeFrame = {
        increaseFromPrevious: boolean,
        percentage: string
    }

    type ChartAPIRawResult = {
        chart_data: ChartData,
        percentIncraseFromPreviousTimeFrame: PercentIncreaseFromPreviousTimeFrame,
        current_active_sessions: number
    }



    useEffect(() => {
        setIsLoading(true);
        //fetchActiveDailyUsers();
    }, [props.time_period])

    useEffect(() => {
        fetchCrashCounts(isLoading, props.project_id, props.time_period).then(function(result: any){

            setChartData({...result.ChartData});
            setChartAPIRawResult(result.ChartAPIRawResult);
            setIsLoading(false);
        }).catch(function(err){
            setErrors(returnErrorComponent(err, "Error fetching crash counts"));
            setIsLoading(false);
        });
    }, [isLoading, props.project_id, props.time_period]);

    if (!isLoading && errors === null)
    {
        const chartOptions : any = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:false,
                        min: 0,
                        //stepSize: 1,
                        autoSkip: true,
                        userCallback: function (label, index, labels) {
                            if (Math.floor(label) === label){
                                return label;
                            }
                        }
                    }
                }],
                xAxes: [{
                    barPercentage: 0.4
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].label;

                        if (tooltipItem.datasetIndex === 0)
                        {
                            label += ": " + tooltipItem.yLabel;
                        }
                        else if (tooltipItem.datasetIndex === 1) {
                            label = "Version " + chartAPIRawResult.chart_data.version_name_release[tooltipItem.index] + " detected";
                        }
                        return label;
                    }
                }
            }
        }

        let percentageIncreaseDecrease : JSX.Element | string = '';
        let timeScaleText = '';
        switch (props.time_period)
        {
            case "1 Day":
                timeScaleText = 'day';
                break;
            case "1 Week":
                timeScaleText = 'week'
                break;
            case "1 Month":
                timeScaleText = 'month'
        }

        if (chartAPIRawResult.percentage_increase_from_previous_time_period.increase_from_previous)
        {
            percentageIncreaseDecrease = <span><FaArrowUp className='inline text-red-700 mr-3' />
                {chartAPIRawResult.percentage_increase_from_previous_time_period.percentage}% increase when compared to the previous {timeScaleText}</span>
        }
        else
        {
            if (chartAPIRawResult.percentage_increase_from_previous_time_period.percentage > 0)
            {
                percentageIncreaseDecrease = <span><FaArrowDown className='inline text-green-700 mr-3' />
                    {chartAPIRawResult.percentage_increase_from_previous_time_period.percentage}% decrease when compared to the previous {timeScaleText}</span>
            }
            else
            {
                percentageIncreaseDecrease = <span><FaArrowRight className='inline text-black mr-3' />
                    No change when compared to the previous {timeScaleText}</span>
            }
        }

        return (
            <div>
                <p style={{fontWeight: 'bold', textAlign: 'center'}}>
                    Crash Counts
                </p>
                <Bar data={chartData} options={chartOptions}/>

                <p style={{textAlign: 'center', margin: 0, padding: 0}}>{percentageIncreaseDecrease}</p>
                <p style={{fontSize: 'small', textAlign: 'center'}}>Dates &amp; times are in UTC</p>

                {/*<p style={{fontWeight: 'bold', textAlign: 'center', marginBottom: 0, paddingBottom: 0}}>
                Current Active Sessions: {chartAPIRawResult.current_active_sessions}
            </p>
            <p style={{textAlign: 'center', margin: 0, padding: 0}}>{percentageIncreaseDecrease}</p>
            <p style={{fontSize: 'small', textAlign: 'center'}}>Dates &amp; times are in UTC</p>*/}

            </div>
        )
    }
    else if (errors !== null)
    {
        return errors;
    }
    else
    {
        return <Loading size={LoadingSize.LARGE} label="Getting Crash Counts" />
        /*return (
            <div style={{textAlign: 'center', fontStyle: 'bold', padding: 10, minHeight: 450, maxHeight: 450, verticalAlign: 'center'}}>
                <img src='/images/loading-large.gif' alt='loading' />
            </div>
        )*/
    }
}

export default CrashCountChart;