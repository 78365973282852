import {useEffect, useState} from "react";
import {sendRequest} from "../../JSFuncs/APIManager";

export default function useIssueStats24Hours(crash_group_id: number) {

    const [chartData, setChartData] = useState(null);
    const [incidentCount, setIncidentCount] = useState(null);
    const [statsLoading, setStatsLoading] = useState(false);
    const [statsError, setStatsError] = useState(null);
    const [rawCrashAnalysis, setRawCrashAnalysis] = useState(null);

    const get24HourStats = async (crash_group_id: number) => {
        try
        {
            setStatsError(null);
            setStatsLoading(true);

            const response = await sendRequest(null, `/issues/${crash_group_id}/24-hour`);

            const chartDataObj = {
                labels: response.data.chart_data.chart_labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Crashes Received',
                        borderColor: '#1d1aa5',
                        backgroundColor: 'rgba(60,57,198,0.28)',
                        borderWidth: 1.5,
                        data: response.data.chart_data.chart_data.crash_data
                    },
                    {
                        label: 'New Project Version Release',
                        backgroundColor: '#5f5656',
                        borderColor: 'rgb(49,35,35)',
                        borderWidth: 1,
                        data: response.data.chart_data.chart_data.app_release_data
                    }
                ]
            }
            setRawCrashAnalysis(response.data);
            setIncidentCount(response.data.incident_count);
            setChartData(chartDataObj);
        }
        catch (err)
        {
            setStatsError(err);
        }
        finally {
            setStatsLoading(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await get24HourStats(crash_group_id);
            }
        )()
    }, []);

    return {chartData, rawCrashAnalysis, incidentCount, statsLoading, statsError};
}