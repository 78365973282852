import * as React from "react";
import {useState} from "react";
import {UpdatePersonalDetailsForm} from "../../Models/Billing";

import {HTTP_METHOD, sendRequest} from "../../JSFuncs/APIManager";
import {Form, FormInputDirection, PrimaryButton, SecondaryButton, TextField, TextFieldType} from "devso-react-library";

export default function UpdatePersonalDetails(props: UpdatePersonalDetailsForm) {

    const [name, setName] = useState('');
    const [address_line1, setAddressLine1] = useState(props.address_line1);
    const [address_line2, setAddressLine2] = useState(props.address_line2);
    const [city, setCity] = useState(props.city);
    const [state, setState] = useState(props.state);
    const [postCode, setPostCode] = useState(props.post_code);

    const updateForm = () => {

    }
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleFormSubmit = async (formData) => {
        try
        {
            setFormLoading(true);
            await sendRequest(formData, '/billing/update-address', HTTP_METHOD.POST);
            props.billingAddressUpdatedSuccessfully();
        }
        catch (err)
        {

            if (err.response?.status === 422)
            {
                setErrors(err.response.data.errors);
            }
            else
            {
                props.billingAddressUpdateFailed(err);
            }

        }
    }

    if (typeof props.closeModalDialog === typeof undefined)
    {
        return (
            <>

                <div className='mt-4 mb-4'>
                    <TextField type={TextFieldType.TEXT} label='Address Line 1:' placeholder='Enter first line of address'
                               api_field_name='address_line_1' value={props.address_line1} onChange={props.setAddressLine1} />
                </div>

                <div className='mt-4 mb-4'>
                    <TextField type={TextFieldType.TEXT} label='Address Line 2:' placeholder='Enter second line of address (optional)'
                               api_field_name='address_line_2' value={props.address_line2} onChange={props.setAddressLine2} />
                </div>

                <div className='mt-4 mb-4'>
                    <TextField type={TextFieldType.TEXT} label='City:' placeholder='Enter City'
                               api_field_name='city' value={props.city} onChange={props.setCity} />
                </div>

                <div className='mt-4 mb-4'>
                    <TextField type={TextFieldType.TEXT} label='State/County:' placeholder='Enter State/County'
                               api_field_name='state' value={props.state} onChange={props.setState} />
                </div>

                <div className='mt-4 mb-4'>
                    <TextField type={TextFieldType.TEXT} label='Post Code/ZIP Code:' placeholder='Enter Post Code/Zip Code'
                               api_field_name='post_code' value={props.post_code} onChange={props.setPostCode} />
                </div>
            </>
        )
    }
    else
    {
        return (
                <Form errors={errors} direction={FormInputDirection.Vertical} handleSubmit={handleFormSubmit} loading={formLoading} >

                    <TextField type={TextFieldType.TEXT} label='Address Line 1:' placeholder='Enter first line of address'
                               api_field_name='address_line1' value={address_line1} onChange={setAddressLine1} />

                    <TextField type={TextFieldType.TEXT} label='Address Line 2:' placeholder='Enter second line of address (optional)'
                               api_field_name='address_line2' value={address_line2} onChange={setAddressLine2} />

                    <TextField type={TextFieldType.TEXT} label='City:' placeholder='Enter City'
                               api_field_name='city' value={city} onChange={setCity} />

                    <TextField type={TextFieldType.TEXT} label='State/County:' placeholder='Enter State/County'
                               api_field_name='state' value={state} onChange={setState} />


                    <TextField type={TextFieldType.TEXT} label='Post Code/ZIP Code:' placeholder='Enter Post Code/Zip Code'
                               api_field_name='post_code' value={postCode} onChange={setPostCode} />

                    <div className='formButtonContainer'>
                        <PrimaryButton label='Update Details' />
                        <SecondaryButton onClick={() => props.closeModalDialog()} label='Cancel' />
                    </div>

                </Form>
            )

    }
}