import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import TopNavBar from "../TopNavBar";
import {FaQuestionCircle, FaFileCode, FaEnvelope} from "react-icons/fa";
import {sendRequest} from "../../JSFuncs/APIManager";
import AndroidInstructions from "../ProjectInstructions/AndroidInstructions";
import ReactJSInstructions from "../ProjectInstructions/ReactJSInstructions";
import PHPInstruction from "../ProjectInstructions/PHPInstructions";
import BreadcrumbNav, {BreadcrumbNavLink} from "../BreadcrumbNav";
import CustomInstructions from "../ProjectInstructions/CustomInstructions";
import IOSInstructions from "../ProjectInstructions/IOSInstructions";
import FlutterInstructions from "../ProjectInstructions/FlutterInstructions";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {ModalDialog, ModalState, returnErrorModal} from "devso-react-library";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import Footer from "../Footer";

type FinalProjectSetupProps = {
    match: any
}

export type ProjectInstructionsTypeProps = {
    project_id: string,
    api_key: string
}

function FinalProjectSetup(props: FinalProjectSetupProps)
{
    const history = useHistory();

    const breadcrumbNav : Array<BreadcrumbNavLink> = [
        {
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            label: "Project Setup",
            link: "/final-project-setup/" + props.match.params.platform + "/" + props.match.params.project_id + "/" + props.match.params.api_key
        }
    ]

    const checkProjectReceivedCrashInterval = useRef(null);
    const [modalState, setModalState] = useState<ModalState>(null);

    const checkIfProjectReceivedCrash = useCallback(async () => {

        try
        {
            const response = await sendRequest(null, `/projects/project/${props.match.params.project_id}/crash_received`);
            if (response.status === 0)
            {
                //Data returns the crash count for the current project

                if (response.data.crash_count !== 0)
                {
                    window.clearInterval(checkProjectReceivedCrashInterval.current);
                    //window.location.href = "/crash-details/" + props.match.params.project_id + "/" + props.match.params.platform;
                    history.push(`/project-details/${props.match.params.project_id}`);
                }
            }
            else
            {
                const tempModalState = returnErrorModal(response, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }

        }
        catch (err)
        {
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        }
    }, [props]);

    useEffect(() => {
        checkProjectReceivedCrashInterval.current = window.setInterval(function(){
            checkIfProjectReceivedCrash();
        }, 3000);
    }, [checkProjectReceivedCrashInterval, checkIfProjectReceivedCrash]);


    let platformInstructions = null;
    switch (props.match.params.platform)
    {
        case "Android":
            platformInstructions = <AndroidInstructions
                project_id={props.match.params.project_id} api_key={props.match.params.api_key} />
            break;
        case "ReactJS":
            platformInstructions = <ReactJSInstructions
                project_id={props.match.params.project_id} api_key={props.match.params.api_key} />
            break;
        case "PHP":
            platformInstructions = <PHPInstruction
               project_id={props.match.params.project_id} api_key={props.match.params.api_key} />
           break;
        case "iOS":
            platformInstructions = <IOSInstructions
                project_id={props.match.params.project_id} api_key={props.match.params.api_key} />
            break;
        case "Flutter":
            platformInstructions = <FlutterInstructions
                project_id={props.match.params.project_id} api_key={props.match.params.api_key} />
            break;
        case "Custom SDK":
            platformInstructions = <CustomInstructions
               project_id={props.match.params.project_id} api_key={props.match.params.api_key} />
           break;
        default:
            throw new Error("Invalid platform detected: " + props.match.params.platform);
    }

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <h2></h2>
                    <div className='flex'>
                        <div>
                            <p style={{fontSize: 'xx-large', fontWeight: 'bold'}}>One more step to start receiving alerts for your project</p>
                            <p style={{fontSize: 'x-large'}}>Follow the instructions below to integrate Crash Catch into your project</p>

                            {platformInstructions}
                        </div>
                        <div className='m-4'>

                            <div className='ml-auto mr-auto text-center items-center w-full'>
                                <img className='ml-auto mr-auto' src='/images/loading-large.gif' alt='Waiting for first crash' />
                                <p className='font-bold mt-3'>
                                    We're waiting on the first crash to be received
                                </p>
                            </div>

                            <p>
                                <FaQuestionCircle className='inline mr-2' /> Need some help, <a href='https://support.devso.io' target='_blank' rel='noreferrer nofollow'>
                                raise a support ticket</a> and we'll be happy to help
                            </p>
                            <p>
                                <FaEnvelope className='inline mr-2' /> Check out our <a href='https://docs.crashcatch.com' target='_blank' rel='noreferrer nofollow'>documentation</a> for more information
                            </p>
                            <p>
                                <FaFileCode className='inline mr-2' /> Not a developer on this project...<a href='/add-team-member' title='Add Team Member'>Invite someone who is</a>
                            </p>

                        </div>
                    </div>

                </ContentWrapper>
                <Footer />
            </div>
        </Wrapper>
    )

    /*return (
        <div className='wrapper'>
            <TopNavBar displayTimePeriodButton={false} title='Create Project'/>

            <BreadcrumbNav menu_items={breadcrumbNav} />


            <div className='mainContentNoSidebar h-full pb-5'>

                <div className='flex'>
                    <div>
                        <p style={{fontSize: 'xx-large', fontWeight: 'bold'}}>One more step to start receiving alerts for your project</p>
                        <p style={{fontSize: 'x-large'}}>Follow the instructions below to integrate Crash Catch into your project</p>

                        {platformInstructions}
                    </div>
                    <div className='m-4'>

                        <div className='ml-auto mr-auto text-center items-center w-full'>
                            <img className='ml-auto mr-auto' src='/images/loading-large.gif' alt='Waiting for first crash' />
                            <p className='font-bold mt-3'>
                                We're waiting on the first crash to be received
                            </p>
                        </div>

                        <p>
                            <FaQuestionCircle className='inline mr-2' /> Need some help, <a href='https://support.boardiesitsolutions.com' target='_blank' rel='noreferrer nofollow'>
                            raise a support ticket</a> and we'll be happy to help
                        </p>
                        <p>
                            <FaEnvelope className='inline mr-2' /> Check out our <a href='https://docs.crashcatch.com' target='_blank' rel='noreferrer nofollow'>documentation</a> for more information
                        </p>
                        <p>
                            <FaFileCode className='inline mr-2' /> Not a developer on this project...<a href='/add-team-member' title='Add Team Member'>Invite someone who is</a>
                        </p>

                    </div>
                </div>

            </div>
            <ModalDialog {...modalState} />
        </div>
    )*/
}
export default FinalProjectSetup;