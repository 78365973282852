import * as React from "react";
import {IssueStats24HoursProps} from "../../../Models/Issues";
import useIssueStats24Hours from "../../../Hooks/Issue/useIssueStats24Hours";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";
import {Bar} from 'react-chartjs-2';

export default function IssueStats24Hours(props: IssueStats24HoursProps) {

    const {chartData, rawCrashAnalysis, incidentCount, statsLoading, statsError} = useIssueStats24Hours(props.crash_group_id);

    const chartOptions = {
        legend: {
            //display: false
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: false
                    }
                }
            ],
            yAxes: [{
                ticks: {
                    beginAtZero:false,
                    min: 0,
                    //stepSize: 1,
                    autoSkip: true,
                    userCallback: function (label) {
                        if (Math.floor(label) === label){
                            return label;
                        }
                    }
                }
            }],
            dataset: {
                barPercentage: 0.4
            }
            /*xAxes: [{
                barPercentage: 0.4
            }]*/
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label;

                    if (tooltipItem.datasetIndex === 0)
                    {
                        label += ": " + tooltipItem.yLabel;
                    }
                    else if (tooltipItem.datasetIndex === 1) {
                        label = "Version " + rawCrashAnalysis.chart_data.chart_data.version_name_release[tooltipItem.index] + " detected";
                    }
                    return label;
                }
            }
        }
    }

    return (
        <div className='w-[360px] p-2 m-2 bg-white border-[1px] border-gray-300'>
            <h2>Incidents in Last 24 Hours</h2>
            {
                (
                    () => {
                        if (statsLoading) {
                            return <Loading size={LoadingSize.SMALL} />
                        }
                        else if (!statsLoading && statsError !== null)
                        {
                            return returnErrorComponent(statsError, "Failed to get 24 hour issue stats");
                        }
                        else if (!statsLoading && chartData !== null)
                        {
                            return (
                                <>
                                    <p className='font-bold mb-3'>{incidentCount}</p>
                                    <Bar data={chartData} options={chartOptions} />
                                </>

                            )
                        }
                        else
                        {
                            return null;
                        }
                    }
                )()
            }
        </div>
    )
}