import * as React from "react";
import {useEffect, useState} from "react";
import {sendRequest} from "../../JSFuncs/APIManager";
import ReactTooltip from "react-tooltip";
import IncidentDetails, {CrashGroupDetailsAPIData} from "./IncidentDetails";
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {CrashGroup} from "../../Models/CrashDetails";
import {ModalDialog, ModalState, returnErrorModal} from "devso-react-library";

export type CrashGroupOccurrencesProps = {
    crashGroups: any,
    project_id: string,
    project_type: string
}

const fetchCrashGroupDetails = async (crashGroupDetailsLoading: boolean, project_id: string, crashGroups: CrashGroup) => {

    if (crashGroupDetailsLoading)
    {
        const postArray = {
            start_counter: 0,
        };

        const response = await sendRequest(postArray, `/crash/groups/${project_id}/${crashGroups.crash_group_id}`);
        if (response.status === 0)
        {
            return response.data;
        }
        else
        {
            throw response;
        }
    }
    return null;
};

function CrashGroupOccurrences(props: CrashGroupOccurrencesProps)
{
    const [crashGroupDetailsLoading, setCrashGroupDetailsLoading] = useState(true);
    const [crashGroupDetails, setCrashGroupDetails] = useState<CrashGroupDetailsAPIData | any>({});
    const [currentReportedIncidentIndex, setCurrentReportedIncidentIndex] = useState(0);
    const [currentNonReportedIncidentIndex, setCurrentNonReportIncidentIndex] = useState(0);
    const [currentTab, setCurrentTab] = useState('visibleIncidents');
    const [modalState, setModalState] = useState<ModalState>(null);

    const handlePreviousIncidentClick = () => {
        if (currentTab === 'visibleIncidents')
        {
            setCurrentReportedIncidentIndex(currentReportedIncidentIndex-1);
        }
        else
        {
            setCurrentNonReportIncidentIndex(currentNonReportedIncidentIndex-1);
        }
    };

    const handleNextIncidentClick = () => {
        if (currentTab === 'visibleIncidents')
        {
            setCurrentReportedIncidentIndex(currentReportedIncidentIndex+1);
        }
        else
        {
            setCurrentNonReportIncidentIndex(currentNonReportedIncidentIndex+1);
        }
    }


    useEffect(() => {
        fetchCrashGroupDetails(crashGroupDetailsLoading, props.project_id, props.crashGroups).then(function(result){
            setCrashGroupDetailsLoading(false);
            if ((result !== null) && typeof result !== typeof undefined) {
                setCrashGroupDetails(result)
            }
        }).catch(function(err){
            const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
            setModalState({...tempModalState});
        });
    }, [crashGroupDetailsLoading, props]);

    useEffect(() => {
        setCurrentReportedIncidentIndex(0);
        setCurrentNonReportIncidentIndex(0);
        setCrashGroupDetailsLoading(true);
    }, [props]);

    useEffect(() => {

    }, [crashGroupDetailsLoading])

    console.log("Visible Crash Count:", crashGroupDetails.visible_crashes?.length);
    if (!crashGroupDetailsLoading)
    {
        let visibleIncidentDetails;
        let previousButtonDisabled;
        let nextButtonDisabled;
        if (((typeof crashGroupDetails === typeof undefined)
            || (crashGroupDetails.visible_crashes !== null)) && ((typeof crashGroupDetails.visible_crashes === typeof undefined)
            || crashGroupDetails.visible_crashes.length === 0))
        {
            visibleIncidentDetails = <IncidentDetails incident={null} project_type={props.project_type} />
            if (currentTab === "visibleIncidents")
            {
                previousButtonDisabled = false;
                nextButtonDisabled = false;
            }
        }
        else
        {
            visibleIncidentDetails = <IncidentDetails project_type={props.project_type} incident={crashGroupDetails.visible_crashes[currentReportedIncidentIndex]} />
            if (currentTab === "visibleIncidents")
            {
                previousButtonDisabled = currentReportedIncidentIndex <= 0;
                nextButtonDisabled = (currentReportedIncidentIndex + 1) >= crashGroupDetails.visible_crashes?.length;
            }
        }

        //Non Reported Incidents
        let hiddenIncidentDetails;
        if ((typeof crashGroupDetails === typeof undefined)
            || (typeof crashGroupDetails.hidden_crashes === typeof undefined)
            || crashGroupDetails.hidden_crashes.length === 0)
        {
            hiddenIncidentDetails = <IncidentDetails incident={null} project_type={props.project_type} />
            if (currentTab === "hiddenIncidents")
            {
                previousButtonDisabled = false;
                nextButtonDisabled = false;
            }
        }
        else
        {
            hiddenIncidentDetails = <IncidentDetails project_type={props.project_type} incident={crashGroupDetails.hidden_crashes[currentNonReportedIncidentIndex]} />
            if (currentTab === "hiddenIncidents")
            {
                previousButtonDisabled = currentNonReportedIncidentIndex <= 0;
                nextButtonDisabled = (currentNonReportedIncidentIndex + 1) >= crashGroupDetails.hidden_crashes.length;
            }
        }

        const updateSelectedTab = (index) => {
            switch (index)
            {
                case 0:
                    setCurrentTab("visibleIncidents");
                    break;
                case 1:
                    setCurrentTab("hiddenIncidents");
                    break;

            }
        }

        return (
            <div>
                <div>
                    <div data-tip='Previous Incident' className='tooltipContainer'
                         style={{display: 'inline-block', marginLeft: 5 + 'px'}}>
                        <button className='btn' onClick={handlePreviousIncidentClick} type='button' disabled={previousButtonDisabled}>
                            <FaChevronLeft className={`${previousButtonDisabled ? "text-gray-500" : "text-primary"}`}/></button>
                    </div>
                    <div data-tip='Next Incident' className='tooltipContainer'
                         style={{display: 'inline-block', marginLeft: 5 + 'px'}}>
                        <button className='btn' onClick={handleNextIncidentClick} type='button' disabled={nextButtonDisabled}>
                            <FaChevronRight className={`${nextButtonDisabled ? "text-gray-500" : "text-primary"}`}/></button>
                    </div>
                </div>

                <Tabs onSelect={updateSelectedTab}>
                    <TabList>
                        <Tab>Reported Incidents</Tab>
                        <Tab>Non-reported Incidents</Tab>
                    </TabList>

                    <TabPanel >
                        <h2>Reported Incident</h2>
                        {visibleIncidentDetails}
                    </TabPanel>

                    <TabPanel>
                        <h2>Non-reported Incidents</h2>
                        {hiddenIncidentDetails}
                    </TabPanel>
                </Tabs>

                <ReactTooltip/>
                <ModalDialog {...modalState} />
            </div>
        )
    }
    else
    {
        return (
            <div style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                <p style={{fontWeight: 'bold', fontSize: 'large'}}>
                    Loading Crash Group Data...Please Wait
                </p>
                <img src='/images/loading-large.gif' alt='loading' />
            </div>
        )
    }
}

export default CrashGroupOccurrences;