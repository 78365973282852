import * as React from "react";
import {useState} from "react";
import {HTTP_METHOD, sendRequest} from "../JSFuncs/APIManager";
import {Link, useHistory} from "react-router-dom";
import {getDefaultGlobalState, useGlobalState} from "./GlobalStateProvider";
import {
    Form,
    FormButtonContainer,
    PrimaryButton,
    TextField,
    TextFieldType,
    ModalDialog,
    returnDefaultModalState, returnErrorModal, SecondaryButton
} from "devso-react-library";
import ReCAPTCHA from "react-google-recaptcha";
import TopNav from "./TopNav";

export enum PlanType {
    Solo = 'solo',
    Startup = 'startup',
    Pro = 'pro',
    ProPlus = 'pro_plus'
}

export type SignUpProps = {
    match: {
        params: {
            plan: PlanType
        }
    }
}

function SignUp(props: any)
{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [password, setPassword] = useState('');
    const [coupon, setCoupon] = useState('');
    const [recaptcha, setRecaptcha] = useState('');
    const [modalState, setModalState] = useState(null)
    const history = useHistory();
    const { setGlobalState, globalState } = useGlobalState();
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const registerUser = async (postArray) => {


        /*const postArray : any = {
            name: name,
            email: email,
            company: company,
            password: password,
            gRecaptchaResponse: recaptcha
        }*/

        postArray.gRecaptchaResponse = recaptcha;

        switch (props.match.params.plan)
        {
            case PlanType.Solo:
                postArray.plan = "solo";
                break;
            case PlanType.Startup:
                postArray.plan = "startup";
                break;
            case PlanType.Pro:
                postArray.plan = "pro";
                break;
            case PlanType.ProPlus:
                postArray.plan = "pro_plus"
                break;
            default:
                console.error("Invalid plan type detected");
        }

        try
        {
            setFormErrors(null);
            setFormLoading(true);
            const response = await sendRequest(postArray, '/auth/register', HTTP_METHOD.POST);
            if (response.status === 0)
            {
                if (response.message === "EmailRegistered")
                {
                    /*setModalState({show: true, title: 'Email Already Registered',
                        body: 'The email address you provided is already in use. Either use a different email address, or click forgotten my password',
                        primaryButtonFunction: clearModalState, primaryButtonLabel: 'OK'});*/
                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Email Already Registered";
                    tempModalState.content = <p>
                        The email address you provided is already in use. Either use a different email address, or click forgotten my password
                    </p>
                    tempModalState.buttons = [
                        <SecondaryButton label='OK' onClick={() => {
                            const tempModalState = returnDefaultModalState();
                            setModalState({...tempModalState});
                        }} />
                    ]
                    setModalState({...tempModalState});

                    //recaptchaRef.reset();
                }
                else if (response.message === "PasswordPolicyNotMet")
                {
                    /*setModalState({show: true, title: 'Password Policy Not Met', body: <span>The password you entered does not meet the password policy
                        requirements.<br />Passwords are required to have at least 1 upper case character, and 1
                            digit and needs to be at least 8 characters</span>,
                        primaryButtonLabel: 'OK', primaryButtonFunction: clearModalState});*/

                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Password Policy Not Met";
                    tempModalState.content = <p>
                        The password you entered does not meet the password policy
                        requirements.<br />Passwords are required to have at least 1 upper case character, and 1
                        digit and needs to be at least 8 characters
                    </p>
                    tempModalState.buttons = [
                        <SecondaryButton label='OK' onClick={() => {
                            const tempModalState = returnDefaultModalState();
                            setModalState({...tempModalState});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
                else if (response.message === "OrganisationRegistered")
                {
                    /*setModalState({show: true, title: 'Organisation Already Registered', body: <span>The organisation you provided
                            has already been registered. To join this organisation, please speak to your administrator to add you as a team member</span>,
                        primaryButtonFunction: clearModalState, primaryButtonLabel: 'OK'});*/

                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Organisation Already Registered";
                    tempModalState.content = <p>
                        The organisation you provided
                        has already been registered. To join this organisation, please speak to your administrator to add you as a team member
                    </p>
                    tempModalState.buttons = [
                        <SecondaryButton label='OK' onClick={() => {
                            const tempModalState = returnDefaultModalState();
                            setModalState({...tempModalState});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
                else if (response.message === "InvalidInviteLink")
                {
                    /*setModalState({show: true, title: 'Invalid Invite Link', body: 'The invite link you have used '
                        + 'is not valid. It has either been revoked or has expired. Please contact your '
                        + 'administrator to resend the invite if required',
                        primaryButtonLabel: 'OK', primaryButtonFunction: clearModalState});*/
                    const tempModalState = returnDefaultModalState();
                    tempModalState.open = true;
                    tempModalState.title = "Invalid Invite Link";
                    tempModalState.content = <p>
                        The invite link you have used
                        is not valid. It has either been revoked or has expired. Please contact your
                        administrator to resend the invite if required
                    </p>
                    tempModalState.buttons = [
                        <SecondaryButton label='OK' onClick={() => {
                            const tempModalState = returnDefaultModalState();
                            setModalState({...tempModalState});
                        }} />
                    ]
                    setModalState({...tempModalState});
                }
                else
                {
                    //window.location.href = "/dashboard";
                    const tempGlobalState = getDefaultGlobalState();
                    setGlobalState({...tempGlobalState});
                    localStorage.setItem("global_state", JSON.stringify(tempGlobalState));
                    history.push('/dashboard');
                }
            }
        }
        catch (err)
        {
            if (err.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else
            {
                const tempModalState = returnErrorModal(err, "An error has occurred", setModalState);
                setModalState({...tempModalState});
            }
        }
        finally {
            setFormLoading(false);
        }
    }

    const returnPlanInformation = () => {
        switch (props.match.params.plan)
        {
            case PlanType.Solo:
                return (
                    <div className='text-center'>
                        <h2>You are signing up for</h2>
                        <p className='font-bold text-2xl'>Crash Catch <span style={{textDecoration: 'underline'}}>Solo Plan</span></p>

                        <p style={{fontWeight: 'bold', textAlign: 'center', paddingTop: 20}}>
                            Current Price is 50% off for the lifetime of your subscription<br />
                            <span style={{textDecoration: 'line-through', fontWeight: 'bold'}}>£10.00</span>&nbsp;
                            <span className='font-bold ml-3 text-xl'>£5.00</span>
                        </p>

                    </div>
                )
            case PlanType.Startup:
                return (
                    <div className='text-center'>
                        <h2>You are signing up for</h2>
                        <p className='font-bold text-2xl'>Crash Catch <span style={{textDecoration: 'underline'}}>Startup Plan</span></p>

                        <p style={{fontWeight: 'bold', textAlign: 'center', paddingTop: 20}}>
                            Current Price is 50% off for the lifetime of your subscription<br />
                            <span style={{textDecoration: 'line-through', fontWeight: 'bold'}}>£20.00</span>&nbsp;
                            <span className='font-bold ml-3 text-xl'>£10.00</span>
                        </p>

                    </div>
                )
            case PlanType.Pro:
                return (
                    <div className='text-center'>
                        <h2>You are signing up for</h2>
                        <p className='font-bold text-2xl'>Crash Catch <span style={{textDecoration: 'underline'}}>Pro Plan</span></p>

                        <p style={{fontWeight: 'bold', textAlign: 'center', paddingTop: 20}}>
                            Current Price is 50% off for the lifetime of your subscription<br />
                            <span style={{textDecoration: 'line-through', fontWeight: 'bold'}}>£30.00</span>&nbsp;
                            <span className='font-bold ml-3 text-xl'>£15.00</span>
                        </p>
                    </div>
                )
            case PlanType.ProPlus:
                return (
                    <div className='text-center'>
                        <h2>You are signing up for</h2>
                        <p className='font-bold text-2xl'>Crash Catch <span style={{textDecoration: 'underline'}}>Pro+ Plan</span></p>

                        <p style={{fontWeight: 'bold', textAlign: 'center', paddingTop: 20}}>
                            Current Price is 50% off for the lifetime of your subscription<br />
                            <span style={{textDecoration: 'line-through', fontWeight: 'bold'}}>50.00</span>&nbsp;
                            <span className='font-bold ml-3 text-xl'>£25.00</span>
                        </p>
                    </div>
                )
        }
    }

    return (

        <div className='h-screen overflow-y-auto'>
            <div className='h-full flex flex-col md:flex-row'>
                <div className='h-full w-full md:w-1/2 bg-primary pb-5 grid place-items-center h-screen text-white md:shadow-md md:shadow-primary/80'>
                    <div className='mx-2'>
                        <img className='w-1/4 mx-auto' src='/images/logo-inverted.png' alt='Crash Catch Logo'/>
                        <h2 className='text-white text-center mt-10'>Crash reporting for every developer, no matter
                            your development stack</h2>

                        <div className='md:mt-8 mt-0 '>
                            {returnPlanInformation()}

                            <a href='https://stripe.com' target='_blank' rel='nofollow noopener noreferrer'>
                                <img className='w-1/3 mx-auto mt-10' src='/images/stripe.png' title='Powered by Stripe' alt='Powered by Stripe' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 grid place-items-center h-screen'>
                    <div className='w-full md:w-3/4 px-2'>

                        <p className='text-center text-xl mb-3'>
                            Sign up below and integrate your first project in just a few minutes
                        </p>

                        <Form  handleSubmit={registerUser} loading={formLoading} errors={formErrors} >
                            <TextField type={TextFieldType.TEXT} label='Your Name' placeholder='Enter your name'
                                       api_field_name='name' value={name} onChange={setName} />

                            <TextField type={TextFieldType.EMAIL} label='Your Email' placeholder='someone@example.com'
                                       api_field_name='email' value={email} onChange={setEmail} />

                            <TextField type={TextFieldType.TEXT} label='Company/Organisation'
                                       placeholder='Enter company or organisation name'
                                       api_field_name='company' value={company} onChange={setCompany} />

                            <span className='text-xs'>
                                    Not got a company, not a problem, this is just a reference for your account,
                                    so can be anything - maybe just use your name
                                </span>

                            <TextField type={TextFieldType.PASSWORD} label='Password' placeholder='Enter your password'
                                       api_field_name='password' value={password} onChange={setPassword} />

                            <span className='text-xs'>
                                    Your password must be at least 7 characters, have at least 1 lower case character,
                                    have at least 1 uppercase character, have at least one digit and at least 1 special character
                                </span>

                            <TextField type={TextFieldType.TEXT} label='Discount Code'
                                       placeholder='Enter discount code if you have one'
                                       api_field_name='coupon' value={coupon} onChange={setCoupon} />

                            <div className='form_group_recaptcha m-2'>
                                <ReCAPTCHA
                                    sitekey="6Lev9s0ZAAAAANopO71AnVTAdVVKjYWrY09oV1rL"
                                    onChange={setRecaptcha} />
                            </div>

                            <FormButtonContainer loading={formLoading}>
                                <PrimaryButton label="Create Account" />
                            </FormButtonContainer>
                        </Form>

                        <p className='text-center text-sm'>
                            By signing up you are agreeing to our <Link to='/privacy-policy'>privacy policy</Link>&nbsp;
                            and our <Link to='/terms-of-service'>Terms of Service</Link>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )

        {/*<div className='h-full overflow-y-auto'>

            <TopNav />

            <div className='mainContentNoSidebar'>

                <div className='w-3/4 ml-auto mr-auto'>
                    <div className='flex flex-col sm:flex-row'>
                        <div className='w-1/2 ml-auto mr-auto'>
                            <Form  handleSubmit={registerUser} loading={formLoading} errors={formErrors} >
                                <TextField type={TextFieldType.TEXT} label='Your Name' placeholder='Enter your name'
                                           api_field_name='name' value={name} onChange={setName} />

                                <TextField type={TextFieldType.EMAIL} label='Your Email' placeholder='someone@example.com'
                                           api_field_name='email' value={email} onChange={setEmail} />

                                <TextField type={TextFieldType.TEXT} label='Company/Organisation'
                                           placeholder='Enter company or organisation name'
                                           api_field_name='company' value={company} onChange={setCompany} />

                                <span className='text-xs'>
                                    Not got a company, not a problem, this is just a reference for your account,
                                    so can be anything - maybe just use your name
                                </span>

                                <TextField type={TextFieldType.PASSWORD} label='Password' placeholder='Enter your password'
                                           api_field_name='password' value={password} onChange={setPassword} />

                                <span className='text-xs'>
                                    Your password must be at least 7 characters, have at least 1 lower case character,
                                    have at least 1 uppercase character, have at least one digit and at least 1 special character
                                </span>

                                <TextField type={TextFieldType.TEXT} label='Discount Code'
                                           placeholder='Enter discount code if you have one'
                                           api_field_name='coupon' value={coupon} onChange={setCoupon} />

                                <div className='form_group_recaptcha m-2'>
                                    <ReCAPTCHA
                                        sitekey="6Lev9s0ZAAAAANopO71AnVTAdVVKjYWrY09oV1rL"
                                        onChange={setRecaptcha} />
                                </div>

                                <FormButtonContainer loading={formLoading}>
                                    <PrimaryButton label="Create Account" />
                                </FormButtonContainer>
                            </Form>
                        </div>
                        <div className='mt-8'>
                            {returnPlanInformation()}
                        </div>
                    </div>
                </div>


            </div>
            <ModalDialog {...modalState} />
        </div>*/}

}

export default SignUp;